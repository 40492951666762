"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bbase2StoreProxy = void 0;
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const result_1 = require("../../primitives/result");
const string_1 = require("../../primitives/string");
const utils_1 = require("../../utils/utils");
class Bbase2StoreProxy {
  constructor(config) {
    this._config = Object.assign({}, config);
    const err = this._checkConfig();
    if (err) throw err;
    this._connectionState = new RxJS.BehaviorSubject(this._getDefaultStoreState());
  }
  getKeys(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.getKeys(params)), (0, operators_1.reduce)((_, res) => res, null));
  }
  setKeys(params) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.setKeys(params)), (0, operators_1.reduce)((_, res) => res, null));
  }
  listKeys(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listKeys(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      keys: acc.keys.concat(res.result.keys),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      keys: [],
      lastEvaluatedKey: null
    }));
  }
  _checkConfig() {
    if (!this._config) return new error_1.BbitError('empty-db-config');
    if (!this._config.backends) {
      this._config.backends = [];
    }
    for (const newBackend of this._config.backends) {
      if (!newBackend.id || newBackend.id.length === 0) {
        newBackend.id = utils_1.BbitUtils.makeId();
      }
    }
    return null;
  }
  _getDefaultStoreState() {
    return {
      backendType: 'proxy',
      locationKey: this._config.backends.map(b => b.id).join(','),
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey,
      latencyMs: 0,
      isPersistent: false,
      supportsAttachments: false
    };
  }
  addBackend(newBackend) {
    if (!newBackend.id || newBackend.id.length === 0) {
      newBackend.id = utils_1.BbitUtils.makeId();
    }
    this.removeBackend(newBackend.id);
    this._config.backends.push(newBackend);
    return newBackend;
  }
  removeBackend(backendId) {
    this._config.backends = this._config.backends.filter(backend => backend.id !== backendId);
  }
  getEnvironment() {
    return {
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey
    };
  }
  getLatestStoreState() {
    return this._connectionState.value;
  }
  observeStoreState() {
    return this._connectionState.asObservable();
  }
  listBuckets(params) {
    const validRes = string_1.BbitString.validateSystemFieldValue(params, '_buckettype');
    if (result_1.BbitResult.isError(validRes)) {
      return validRes.toObservable();
    }
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listBuckets(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      buckets: acc.buckets.concat(res.result.buckets),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      buckets: [],
      lastEvaluatedKey: null
    }));
  }
  getBucket(params) {
    for (const field of ['_bucket', '_buckettype']) {
      const validRes = string_1.BbitString.validateSystemFieldValue(params, field);
      if (result_1.BbitResult.isError(validRes)) {
        return validRes.toObservable();
      }
    }
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.getBucket(params)));
  }
  recalculateBucketStats(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.recalculateBucketStats(params)));
  }
  putBucket(params) {
    for (const field of ['_bucket', '_buckettype']) {
      const validRes = string_1.BbitString.validateSystemFieldValue(params, field);
      if (result_1.BbitResult.isError(validRes)) {
        return validRes.toObservable();
      }
    }
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.putBucket(params)), (0, operators_1.reduce)((_, res) => res, null));
  }
  getDoc(params) {
    if (!params) return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
      param: 'bbase2InMemoryStore getDoc param',
      value: params,
      reason: 'must be a non null object'
    }));
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        return fieldValid.toObservable();
      }
    }
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.getDoc(params)));
  }
  listDocs(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listDocs(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      docs: acc.docs.concat(res.result.docs),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      docs: [],
      lastEvaluatedKey: null
    }));
  }
  listDocRevisions(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listDocRevisions(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      docs: acc.docs.concat(res.result.docs),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      docs: [],
      lastEvaluatedKey: null
    }));
  }
  putDocs(docs, writeAuth) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.putDocs(docs, writeAuth)), (0, operators_1.reduce)((_, res) => res, null));
  }
  getLockScope(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.getLockScope(params))).toPromise();
  }
  setLockScope(params, auth) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.setLockScope(params, auth)), (0, operators_1.reduce)((_, res) => res, null)).toPromise();
  }
  listLockScopes(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listLockScopes(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      scopes: acc.scopes.concat(res.result.scopes),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      scopes: [],
      lastEvaluatedKey: null
    }));
  }
  getLockScopeStats(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.getLockScopeStats(params))).toPromise();
  }
  listLockScopeStats(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listLockScopeStats(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      scopes: acc.scopes.concat(res.result.scopes),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      scopes: [],
      lastEvaluatedKey: null
    }));
  }
  recalculateLockScope(params, auth) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.recalculateLockScope(params, auth)), (0, operators_1.reduce)((_, res) => res, null)).toPromise();
  }
  acquireLocks(input) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.acquireLocks(input)), (0, operators_1.reduce)((_, res) => res, null)).toPromise();
  }
  deleteExpiredLockTokens(params, auth) {
    const writeBackends = this._config.backends.filter(b => b.allowWrite);
    return RxJS.of(...writeBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.deleteExpiredLockTokens(params, auth)), (0, operators_1.reduce)((_, res) => res, null)).toPromise();
  }
  getLockToken(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.race(...readBackends.map(backend => backend.store.getLockToken(params))).toPromise();
  }
  listLockTokens(params) {
    const readBackends = this._config.backends.filter(b => b.allowRead);
    return RxJS.of(...readBackends).pipe((0, operators_1.mergeMap)(backend => backend.store.listLockTokens(params).pipe((0, operators_1.map)(result => ({
      backend,
      result
    })))), (0, operators_1.reduce)((acc, res) => ({
      tokens: acc.tokens.concat(res.result.tokens),
      lastEvaluatedKey: res.result.lastEvaluatedKey
    }), {
      tokens: [],
      lastEvaluatedKey: null
    }));
  }
}
exports.Bbase2StoreProxy = Bbase2StoreProxy;
