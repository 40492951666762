"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitI18n = void 0;
const luxon_1 = require("luxon");
const rxjs_1 = require("rxjs");
const error_1 = require("../primitives/error");
const number_1 = require("../primitives/number");
const object_1 = require("../primitives/object");
const string_1 = require("../primitives/string");
const utils_1 = require("../utils/utils");
const interfaces_1 = require("./interfaces");
class BbitI18n {
  static languageChanges() {
    return BbitI18n._languageSubject;
  }
  static observeLanguage() {
    return BbitI18n._languageSubject.asObservable();
  }
  static timezoneChanges() {
    return BbitI18n._timezoneSubject;
  }
  static observeTimezone() {
    return BbitI18n._timezoneSubject.asObservable();
  }
  static addTranslations(translations) {
    const e = utils_1.BbitUtils.getGlobal('@bbit/i18n', 'translations', {});
    (translations || []).forEach(translation => {
      e[translation.key] = object_1.BbitObject.mapValues(translation.text, language => {
        return object_1.BbitObject.mapKeys(language, (value, key) => {
          if (key.length === 1) {
            return key + '-';
          }
          return key;
        });
      });
    });
    utils_1.BbitUtils.setGlobal('@bbit/i18n', 'translations', e);
  }
  static getTranslations() {
    return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'translations', []);
  }
  static setLanguage(language) {
    let isValid = true;
    if (!language || language.length === 0) {
      language = BbitI18n.getBrowserLanguage();
    }
    let temp = null;
    if (language.length === 2) {
      temp = BbitI18n._getLanguageFromTwoChar(language);
      if (utils_1.BbitUtils.getGlobal('@bbit/i18n', 'language') === temp) {
        return true;
      }
    } else {
      if (utils_1.BbitUtils.getGlobal('@bbit/i18n', 'language') === language) {
        return true;
      }
      temp = language;
    }
    if (!BbitI18n._isLanguageValid(temp)) {
      temp = utils_1.BbitUtils.getGlobal('@bbit/i18n', 'defaultLanguage');
      isValid = false;
    }
    BbitI18n._languageSubject.next(temp);
    utils_1.BbitUtils.setGlobal('@bbit/i18n', 'language', temp);
    luxon_1.Settings.defaultLocale = temp;
    return isValid;
  }
  static setDefaultFallbackLanguages(languages) {
    BbitI18n._defaultFallbackLanguages = Array.isArray(languages) ? languages : [];
  }
  static getDefaultFallbackLanguages() {
    return BbitI18n._defaultFallbackLanguages;
  }
  static setDefaultLanguage(language) {
    if (!BbitI18n._isLanguageValid(language)) {
      BbitI18n.setLanguage(BbitI18n._getFallbackDefaultLanguage());
      utils_1.BbitUtils.setGlobal('@bbit/i18n', 'defaultLanguage', BbitI18n._getFallbackDefaultLanguage());
      return false;
    }
    BbitI18n.setLanguage(language);
    utils_1.BbitUtils.setGlobal('@bbit/i18n', 'defaultLanguage', language);
    return true;
  }
  static setDefaultTimezone(timezone) {
    if (!BbitI18n._isTimezoneValid(timezone)) {
      BbitI18n.setTimezone(BbitI18n._getFallbackDefaultLanguage());
      utils_1.BbitUtils.setGlobal('@bbit/i18n', 'defaultTimezone', BbitI18n._getFallbackDefaultTimezone());
      return false;
    }
    BbitI18n.setTimezone(timezone);
    utils_1.BbitUtils.setGlobal('@bbit/i18n', 'defaultTimezone', timezone);
    return true;
  }
  static setTimezone(timezone) {
    let isValid = true;
    let temp;
    if (!BbitI18n._isTimezoneValid(timezone)) {
      temp = utils_1.BbitUtils.getGlobal('@bbit/i18n', 'defaultTimezone');
      isValid = false;
    } else {
      temp = timezone;
    }
    BbitI18n._timezoneSubject.next(temp);
    utils_1.BbitUtils.setGlobal('@bbit/i18n', 'timezone', temp);
    luxon_1.Settings.defaultZone = temp;
    return isValid;
  }
  static _isTimezoneValid(timezone) {
    return luxon_1.DateTime.local().setZone(timezone).isValid;
  }
  static getTranslation(key) {
    const translations = BbitI18n.getTranslations();
    return translations[key];
  }
  static t(item, useLanguageOrOptions) {
    var _a;
    if (!item) {
      return '';
    }
    const options = typeof useLanguageOrOptions === 'string' ? undefined : useLanguageOrOptions;
    const useLanguage = typeof useLanguageOrOptions === 'string' ? useLanguageOrOptions : options === null || options === void 0 ? void 0 : options.language;
    let translation = null;
    let language = BbitI18n.getLanguage();
    switch (true) {
      case (useLanguage === null || useLanguage === void 0 ? void 0 : useLanguage.length) > 0:
        language = string_1.BbitString.stripBeginningHashtag(useLanguage);
        break;
      case typeof item !== 'string' && ((_a = item.language) === null || _a === void 0 ? void 0 : _a.length) > 0:
        language = string_1.BbitString.stripBeginningHashtag(item.language);
        break;
    }
    if (language.length === 2) {
      language = BbitI18n._getLanguageFromTwoChar(language);
    }
    if (!BbitI18n._isLanguageValid(language)) {
      language = BbitI18n.getLanguage();
    }
    if (typeof item === 'string') {
      return item;
    }
    if (item.hasOwnProperty('key') && item.key) {
      translation = BbitI18n.getTranslation(item.key);
      if (!translation) {
        return 'MISSING_TRANSLATION:' + item.key;
      }
    } else if (item.hasOwnProperty('value') && item.value) {
      return BbitI18n.t(item.value);
    } else if (item.hasOwnProperty('data') && item.data) {
      return BbitI18n.t(item.data);
    } else if (item.hasOwnProperty('message') && item.message) {
      return BbitI18n.t(item.message);
    }
    const translationDef = translation ? translation : item.object;
    if (object_1.BbitObject.isEmpty(translationDef)) {
      if (options === null || options === void 0 ? void 0 : options.onMissingTranslation) {
        return options.onMissingTranslation(item);
      }
      return 'MISSING_LANGUAGE_ON_KEY:' + item.key;
    }
    const fallbackLanguages = (options === null || options === void 0 ? void 0 : options.fallbackLanguages) || BbitI18n._defaultFallbackLanguages;
    if (object_1.BbitObject.isEmpty(translationDef[language]) && (fallbackLanguages === null || fallbackLanguages === void 0 ? void 0 : fallbackLanguages.length) > 0) {
      const fallbacks = fallbackLanguages.map(l => {
        l = string_1.BbitString.stripBeginningHashtag(l);
        if (l.length === 2) {
          return BbitI18n._getLanguageFromTwoChar(l);
        }
        if (!BbitI18n._isLanguageValid(l)) {
          return BbitI18n.getLanguage();
        }
        return l;
      }).filter(Boolean);
      for (const fallbackLanguage of fallbacks) {
        if (!object_1.BbitObject.isEmpty(translationDef[fallbackLanguage])) {
          language = fallbackLanguage;
          break;
        }
      }
    }
    if (!translationDef[language]) {
      if (options === null || options === void 0 ? void 0 : options.onMissingTranslation) {
        return options.onMissingTranslation(item);
      }
      return 'MISSING_LANGUAGE_ON_KEY:' + item.key;
    }
    let text = null;
    const translationNumber = item.n && number_1.BbitNumber.isNumber(item.n) ? item.n : '-';
    if (item.symbol) {
      text = BbitI18n.getI18nText(translationDef[language], 's' + translationNumber);
    } else if (item.abbrevation) {
      text = BbitI18n.getI18nText(translationDef[language], 'a' + translationNumber);
    } else {
      text = BbitI18n.getI18nText(translationDef[language], 'f' + translationNumber);
    }
    return BbitI18n._replacePlaceholders(text, item.values);
  }
  static getLanguage() {
    return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'language', BbitI18n._getFallbackDefaultLanguage());
  }
  static getMainLanguage() {
    const language = utils_1.BbitUtils.getGlobal('@bbit/i18n', 'language', BbitI18n._getFallbackDefaultLanguage());
    if (!language) {
      throw new error_1.BbitError('global-language-not-set', {
        function: 'BbitI18n.getMainLanguage()'
      });
    }
    return language.substr(0, 2);
  }
  static getPostgresLanguage(language) {
    if (!language) {
      language = utils_1.BbitUtils.getGlobal('@bbit/i18n', 'language', BbitI18n._getFallbackDefaultLanguage());
    }
    switch (language) {
      case interfaces_1.BbitLanguage.DE_CH:
        return 'german';
      case interfaces_1.BbitLanguage.FR_CH:
        return 'french';
      case interfaces_1.BbitLanguage.EN_US:
        return 'english';
    }
    return 'german';
  }
  static getTimezone() {
    return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'timezone', BbitI18n._getFallbackDefaultTimezone());
  }
  static getBrowserLanguage() {
    if (typeof window === 'undefined') {
      return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'defaultLanguage');
    }
    const lang = window.navigator.language || window.navigator.userLanguage;
    if (BbitI18n._isLanguageValid(lang)) {
      return lang;
    }
    return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'defaultLanguage');
  }
  static getCodeFromTranslationType(translation) {
    let letter;
    if (!translation) {
      return null;
    }
    switch (translation.type) {
      case interfaces_1.TranslationType.SYMBOL:
        letter = 's';
        break;
      case interfaces_1.TranslationType.ABBREVATION:
        letter = 'a';
        break;
      default:
        letter = 'f';
        break;
    }
    if (!translation.count) {
      return letter + '-' + (translation.exact ? '!' : '');
    }
    return letter + translation.count.toString() + (translation.exact ? '!' : '');
  }
  static getI18nText(translation, code) {
    if (!translation) {
      return undefined;
    }
    if (!code) {
      return undefined;
    }
    if (code.slice(-1) === '!') {
      return translation[code.substr(0, code.length - 1)];
    }
    const fallbackCode = 'f' + code.substr(1);
    return translation[code] || translation[code.substr(0, 1) + '-'] || translation[code.substr(0, 1)] || translation[fallbackCode] || translation[fallbackCode.substr(0, 1) + '-'] || translation[fallbackCode.substr(0, 1)];
  }
  static _replacePlaceholders(s, v) {
    if (v && v.length > 0) {
      for (let i = 0; i < v.length; i++) {
        s = s.replace(new RegExp('%' + i, 'g'), v[i]);
      }
    }
    return s;
  }
  static _isLanguageValid(language) {
    switch (language) {
      case 'de':
      case 'de-CH':
      case 'en':
      case 'en-US':
      case 'fr':
      case 'fr-CH':
        return true;
      default:
        return false;
    }
  }
  static _getLanguageFromTwoChar(language) {
    switch (language) {
      case 'de':
        return interfaces_1.BbitLanguage.DE_CH;
      case 'en':
        return interfaces_1.BbitLanguage.EN_US;
      case 'fr':
        return interfaces_1.BbitLanguage.FR_CH;
      default:
        return utils_1.BbitUtils.getGlobal('@bbit/i18n', 'defaultLanguage');
    }
  }
  static _getFallbackDefaultLanguage() {
    return interfaces_1.BbitLanguage.DE_CH;
  }
  static _getFallbackDefaultTimezone() {
    return 'Europe/Zurich';
  }
}
exports.BbitI18n = BbitI18n;
BbitI18n._languageSubject = new rxjs_1.BehaviorSubject(null);
BbitI18n._timezoneSubject = new rxjs_1.BehaviorSubject(null);
BbitI18n._defaultFallbackLanguages = [];
