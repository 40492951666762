"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitSchemalessDocument = void 0;
const immer_1 = require("immer");
const luxon_1 = require("luxon");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const log_1 = require("../../log/log");
const object_array_1 = require("../../object-array/object-array");
const array_1 = require("../../primitives/array");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const number_1 = require("../../primitives/number");
const object_1 = require("../../primitives/object");
const result_1 = require("../../primitives/result");
const semaphore_1 = require("../../primitives/semaphore");
const string_1 = require("../../primitives/string");
const utils_1 = require("../../utils/utils");
const bbase2_utils_1 = require("../utils/bbase2-utils");
const interfaces_1 = require("../utils/interfaces");
const interfaces_2 = require("./interfaces");
(0, immer_1.enablePatches)();
class BbitSchemalessDocument {
  static splitPath(params) {
    if (!params.field) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'BbitSchemalessDocument.splitPath({ FIELD })',
        value: params.field,
        reason: 'must be defined'
      });
    }
    const split = array_1.BbitArray.isArray(params.field) ? params.field : string_1.BbitString.split(params.field, {
      separator: '.'
    });
    if (params.skipSubListIndexMapping) {
      return split;
    }
    if (!params.subListIndexes) {
      params.subListIndexes = [];
    }
    let maxIndex = 0;
    const path = split.map(part => (part + '').replace(/\[(\d+)\]/gi, (_, digit) => {
      maxIndex = digit + 1;
      return params.subListIndexes[digit];
    }));
    if (params.subListIndexes.length > maxIndex && params.subListIndexes[maxIndex] !== undefined) {
      path.push(params.subListIndexes[maxIndex]);
    }
    return path;
  }
  constructor(config, bbaseStore) {
    this.bbaseStore = bbaseStore;
    this._revChanges = new rxjs_1.Subject();
    this._onChange = new rxjs_1.Subject();
    this._saveMutex = new semaphore_1.Semaphore({
      maxConcurrency: 1
    });
    this._retrieveMutex = new semaphore_1.Semaphore({
      maxConcurrency: 1
    });
    this._log = log_1.BbitLog.scope({
      class: 'BbitSchemalessDocument'
    });
    this._reset = new rxjs_1.Subject();
    this._config = config;
    if (!this._config._id || this._config._id.length === 0) {
      this._config._id = utils_1.BbitUtils.makeId();
    }
    this._config.readonly = !!this._config.readonly;
    this.destroy('init');
  }
  observe() {
    return this._onChange.asObservable();
  }
  observeRevChanges() {
    return this._revChanges.asObservable();
  }
  observeBeforeResets() {
    return this._reset.asObservable();
  }
  observeBehavior(func) {
    const callFunc = lastChange => {
      const returnVal = func({
        lastChange,
        currentDoc: this.getImmutableDoc()
      });
      if (result_1.BbitResult.isResult(returnVal)) {
        return returnVal.toPromise();
      }
      return returnVal;
    };
    return (0, rxjs_1.merge)((0, rxjs_1.defer)(() => callFunc(null)), this._onChange.pipe((0, operators_1.mergeMap)(l => callFunc(l)))).pipe((0, operators_1.distinctUntilChanged)());
  }
  isLoaded() {
    return !!this._frozenDoc;
  }
  isModified() {
    return this._changeHistoryOrder.length > 0;
  }
  isUnknownOnServer() {
    return this._isUnknownOnServer;
  }
  observeIsModified() {
    return this.observeBehavior(() => result_1.BbitResult.createSuccess(this.isModified()));
  }
  isNew() {
    if (!this._frozenDoc) {
      return undefined;
    }
    const splitRev = bbase2_utils_1.Bbase2Utils.splitRevision(this._frozenDoc._rev);
    if (!splitRev || splitRev.version < 1) {
      return true;
    }
    return false;
  }
  isReadOnly() {
    return this._config.readonly;
  }
  getBucket() {
    var _b;
    return ((_b = this._frozenDoc) === null || _b === void 0 ? void 0 : _b._bucket) || this._config._bucket;
  }
  getId() {
    var _b;
    return ((_b = this._frozenDoc) === null || _b === void 0 ? void 0 : _b._id) || this._config._id;
  }
  getRev() {
    var _b;
    return ((_b = this._frozenDoc) === null || _b === void 0 ? void 0 : _b._rev) || this._config._rev;
  }
  getIndex() {
    var _b;
    return ((_b = this._frozenDoc) === null || _b === void 0 ? void 0 : _b._index) || interfaces_1.Bbase2Indexes.HEAD;
  }
  getImmutableDoc() {
    return this._frozenDoc || {
      _bucket: this._config._bucket,
      _id: this._config._id
    };
  }
  getChangeHistory() {
    return this._changeHistoryOrder;
  }
  getChangeById(id) {
    return this._changes[id];
  }
  listChanges() {
    return this._changeHistoryOrder.map(id => this._changes[id]);
  }
  getLastChange() {
    const changes = this.getChangeHistory();
    if (changes.length === 0) {
      return undefined;
    }
    const lastChangeId = changes[changes.length - 1];
    return this.getChangeById(lastChangeId);
  }
  applyChanges(changes, options) {
    if (!changes) {
      return result_1.BbitResult.createComplete();
    }
    if (options === null || options === void 0 ? void 0 : options.allAtOnce) {
      return this.modify(proxy => {
        for (const change of changes) {
          if (change._id) {
            if (this._changes[change._id]) {
              continue;
            }
          }
          (0, immer_1.applyPatches)(proxy, (options === null || options === void 0 ? void 0 : options.backwards) ? change.back : change.forward);
        }
      });
    }
    for (const change of changes) {
      if (change._id) {
        if (this._changes[change._id]) {
          continue;
        }
      }
      this.modify(proxy => {
        (0, immer_1.applyPatches)(proxy, (options === null || options === void 0 ? void 0 : options.backwards) ? change.back : change.forward);
      });
    }
    return result_1.BbitResult.createSuccess();
  }
  modify(modifier, options = {}) {
    let result;
    if (this.isLoaded() && this.isReadOnly() && !options.skipReadonlyCheck) {
      return result_1.BbitResult.createError(new error_1.BbitError('modifying-readonly-doc'));
    }
    const newChangeId = utils_1.BbitUtils.makeId();
    let changes = 0;
    const newDoc = (0, immer_1.produce)(this.getImmutableDoc(), proxyDoc => {
      result = modifier(proxyDoc);
    }, (forward, back) => {
      changes = forward === null || forward === void 0 ? void 0 : forward.length;
      if (changes > 0) {
        const newChange = {
          _id: newChangeId,
          forward,
          back
        };
        if (!(options === null || options === void 0 ? void 0 : options.doNotAddToHistory)) {
          this._changeHistoryOrder.push(newChange._id);
        }
        this._changes[newChange._id] = newChange;
      }
    });
    if (changes > 0 && (!result || result_1.BbitResult.isOk(result))) {
      this._frozenDoc = newDoc;
      this._onChange.next(this._changes[newChangeId]);
    }
    if (result) {
      return result;
    }
    return result_1.BbitResult.createComplete();
  }
  get(params) {
    return _a.get(params.originalValue ? this.getImmutableOriginal() : this.getImmutableDoc(), params);
  }
  static get(document, params) {
    const length = params.path.length;
    let index = -1;
    let nested = document;
    while (nested != null && ++index < length) {
      let key = params.path[index];
      const parsedKey = number_1.BbitNumber.parseInteger(key);
      if (Number.isFinite(parsedKey) && object_array_1.BbitObjectArray.isObjectArray(nested)) {
        key = nested._arrayOrder[parsedKey];
      }
      nested = nested[key];
      if (nested === undefined) {
        return undefined;
      }
    }
    return nested;
  }
  set(params) {
    const res = this.modify(proxyDoc => {
      return result_1.BbitResult.createSuccess(_a.set(proxyDoc, params));
    }, {
      doNotAddToHistory: params.doNotAddToHistory
    });
    return res;
  }
  static set(proxyDoc, params) {
    let basePath = '';
    const length = params.path.length;
    const lastIndex = length - 1;
    let index = -1;
    let nested = proxyDoc;
    let oldValue;
    let hasChanged = false;
    while (nested != null && ++index < length) {
      let key = params.path[index];
      const parsedKey = number_1.BbitNumber.parseInteger(key);
      if (Number.isFinite(parsedKey) && object_array_1.BbitObjectArray.isObjectArray(nested)) {
        const inArrayKey = nested._arrayOrder[parsedKey];
        if (!inArrayKey) {
          object_array_1.BbitObjectArray.push(nested, {
            _id: key + ''
          });
        } else {
          key = inArrayKey;
        }
      } else if (!Number.isFinite(parsedKey) && array_1.BbitArray.isArray(nested)) {
        key = nested.findIndex(element => element && element._id === key);
      }
      if (index !== lastIndex) {
        const objValue = nested[key];
        if (!objValue) {
          const nextPath = number_1.BbitNumber.parseInteger(params.path[index + 1]);
          const isAccessById = string_1.BbitString.isString(params.path[index + 1]) && params.path[index + 1].startsWith('#');
          if (Number.isFinite(nextPath) || isAccessById) {
            nested[key] = object_array_1.BbitObjectArray.objectizeArray(isAccessById ? [{
              _id: params.path[index + 1] + ''
            }] : []);
          } else {
            nested[key] = {};
          }
        }
      } else {
        oldValue = (0, immer_1.isDraft)(nested[key]) ? (0, immer_1.original)(nested[key]) : nested[key];
        const isEqual = params.isEqual || ((oldValue, newValue) => oldValue === newValue);
        if (!isEqual(oldValue, params.value)) {
          nested[key] = params.value;
          hasChanged = true;
        }
      }
      nested = nested[key];
      basePath += '.' + key;
    }
    return {
      path: basePath,
      oldValue,
      hasChanged
    };
  }
  add(params) {
    let id;
    const res = this.modify(proxyDoc => {
      const objArray = _a.get(proxyDoc, params);
      if (!object_array_1.BbitObjectArray.isObjectArray(objArray)) {
        const newObj = object_array_1.BbitObjectArray.create();
        id = object_array_1.BbitObjectArray.push(newObj, params.element);
        _a.set(proxyDoc, {
          path: params.path,
          value: newObj
        });
      } else {
        if (params.afterRowId) {
          id = object_array_1.BbitObjectArray.insertAfter(objArray, params.afterRowId, params.element);
        } else if (params.beforeRowId) {
          id = object_array_1.BbitObjectArray.insertBefore(objArray, params.beforeRowId, params.element);
        } else {
          id = object_array_1.BbitObjectArray.push(objArray, params.element);
        }
      }
    });
    if (result_1.BbitResult.isError(res)) {
      return res;
    }
    return result_1.BbitResult.createSuccess(id);
  }
  moveArrayElement(params) {
    let id;
    const res = this.modify(proxyDoc => {
      var _b;
      const objArray = _a.get(proxyDoc, params);
      if (!object_array_1.BbitObjectArray.isObjectArray(objArray)) {
        const parent = _a.get(proxyDoc, {
          path: params.path.slice(0, params.path.length - 1)
        });
        if (object_array_1.BbitObjectArray.isObjectArray(parent)) {
          id = params.path[params.path.length - 1];
          switch (params.type) {
            case 'up':
              object_array_1.BbitObjectArray.moveUp(parent, id);
              break;
            case 'down':
              object_array_1.BbitObjectArray.moveDown(parent, id);
              break;
          }
        } else {
          return result_1.BbitResult.createError(new error_1.BbitError('path-is-not-an-object-array', params));
        }
      } else {
        id = string_1.BbitString.isString(params.element) ? params.element : ((_b = params.element) === null || _b === void 0 ? void 0 : _b._id) ? params.element._id : params.element;
        if (!id) {
          this._log.warning(new Error('schemalessDoc.moveArrayElementUp: no id provided'));
        }
        switch (params.type) {
          case 'up':
            object_array_1.BbitObjectArray.moveUp(objArray, id);
            break;
          case 'down':
            object_array_1.BbitObjectArray.moveDown(objArray, id);
            break;
        }
      }
      return result_1.BbitResult.createSuccess();
    });
    if (result_1.BbitResult.isError(res)) {
      return res;
    }
    return result_1.BbitResult.createSuccess(id);
  }
  remove(params) {
    let elementId;
    let oldElement;
    const res = this.modify(proxyDoc => {
      var _b;
      const objArray = _a.get(proxyDoc, params);
      if (!object_array_1.BbitObjectArray.isObjectArray(objArray)) {
        const parent = _a.get(proxyDoc, {
          path: params.path.slice(0, params.path.length - 1)
        });
        if (object_array_1.BbitObjectArray.isObjectArray(parent)) {
          elementId = params.path[params.path.length - 1];
          oldElement = (0, immer_1.isDraft)(parent) ? (0, immer_1.original)(parent[elementId]) : parent[elementId];
          object_array_1.BbitObjectArray.remove(parent, elementId);
        } else {
          return result_1.BbitResult.createError(new error_1.BbitError('path-is-not-an-object-array', params));
        }
      } else {
        elementId = string_1.BbitString.isString(params.element) ? params.element : ((_b = params.element) === null || _b === void 0 ? void 0 : _b._id) ? params.element._id : params.element;
        if (!elementId) {
          this._log.warning(new Error('schemalessDoc.remove: no id provided'));
        } else {
          oldElement = (0, immer_1.isDraft)(objArray) ? (0, immer_1.original)(objArray[elementId]) : objArray[elementId];
          object_array_1.BbitObjectArray.remove(objArray, elementId);
        }
      }
      return result_1.BbitResult.createSuccess();
    });
    if (result_1.BbitResult.isError(res)) {
      return res;
    }
    return result_1.BbitResult.createSuccess({
      elementId,
      oldElement
    });
  }
  changeTo(params) {
    return this.modify(proxyDoc => {
      this._changeSourceObjectToMatchTarget(params.doc, proxyDoc, '');
    });
  }
  _changeSourceObjectToMatchTarget(target, source, basePath) {
    for (const key in target) {
      if (target.hasOwnProperty(key)) {
        switch (true) {
          case array_1.BbitArray.isArray(target[key]):
            {
              const mapped = object_array_1.BbitObjectArray.objectizeArray(target[key]);
              if (object_1.BbitObject.isObject(mapped)) {
                if (!source.hasOwnProperty(key) || !object_1.BbitObject.isObject(source[key])) {
                  source[key] = {};
                }
                this._changeSourceObjectToMatchTarget(target[key], source[key], basePath + '.' + key);
              } else {
                this._setValueIfDifferent(source, key, target[key]);
              }
              break;
            }
          case object_1.BbitObject.isObject(target[key]):
            this._changeSourceObjectToMatchTarget(target[key], source[key] || {}, basePath + '.' + key);
            break;
          default:
            this._setValueIfDifferent(source, key, target[key]);
        }
      }
    }
  }
  _setValueIfDifferent(obj, key, value) {
    if (!obj.hasOwnProperty(key) || obj[key] !== value) {
      obj[key] = value;
    }
  }
  goBack() {
    const lastChangeId = this._changeHistoryOrder[this._changeHistoryOrder.length - 1];
    const lastChange = this._changes[lastChangeId];
    return this.applyChanges([Object.assign(Object.assign({}, lastChange), {
      _id: null
    })], {
      backwards: true
    });
  }
  destroy(source) {
    if (this._frozenDoc) {
      this._reset.next(source);
    }
    this._frozenDoc = undefined;
    this._originalDoc = undefined;
    this._changes = {};
    this._changeHistoryOrder = [];
  }
  reset() {
    this._frozenDoc = this._originalDoc;
    this.clearChangeHistory('reset');
  }
  clearChangeHistory(source) {
    this._changes = {};
    this._changeHistoryOrder = [];
    this._onChange.next(null);
  }
  getImmutableOriginal() {
    return this._originalDoc;
  }
  import(params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _b, _c, _d, _e, _f;
      if (!(params === null || params === void 0 ? void 0 : params.doc)) {
        this._log.warning(new error_1.BbitError('empty-document', {
          reason: 'can not import an empty document in schemalessDocument',
          value: params === null || params === void 0 ? void 0 : params.doc,
          _bucket: this.getBucket(),
          _id: this.getId()
        }));
        return {
          _bucket: null,
          _id: null,
          _rev: null,
          _index: null,
          _createdAt: null,
          _changedAt: null,
          importResult: null
        };
      }
      if (params.doc._id && params.doc._id !== this.getId()) {
        throw new error_1.BbitError('id-miss-match', {
          reason: '_id in source object to import must match the schemalessDocument',
          providedId: params.doc._id,
          expectedId: this.getId()
        });
      }
      if (params.doc._bucket && params.doc._bucket !== this.getBucket()) {
        throw new error_1.BbitError('bucket-miss-match', {
          reason: '_bucket in source object to import must match the schemalessDocument',
          providedBucket: params.doc._bucket,
          expectedBucket: this.getBucket()
        });
      }
      const isConflicted = [interfaces_1.Bbase2Indexes.CONFLICTED].includes(params.doc._index);
      if (isConflicted) {
        if (!params.mergeHandler) {
          throw new error_1.BbitError('doc-is-' + params.doc._index, {
            _bucket: params.doc._bucket,
            _id: params.doc._id,
            _rev: params.doc._rev,
            _index: params.doc._index,
            _changeReason: params.doc._changeReason,
            _conflictedReason: params.doc._conflictedReason
          });
        }
        const mergeRes = yield params.mergeHandler({
          current: this,
          other: params.doc,
          isOtherTemporary: true,
          isImmutable: params.isImmutable
        });
        this._frozenDoc = mergeRes._frozenDoc;
        this._originalDoc = mergeRes._originalDoc;
        this._lastRetrieve = mergeRes._lastRetrieve;
        this._changeHistoryOrder = mergeRes._changeHistoryOrder;
        this._changes = mergeRes._changes;
        return {
          _bucket: this.getBucket(),
          _id: this.getId(),
          _rev: this.getRev(),
          _index: this.getIndex(),
          _createdAt: (_b = this.getImmutableDoc()) === null || _b === void 0 ? void 0 : _b._createdAt,
          _changedAt: (_c = this.getImmutableDoc()) === null || _c === void 0 ? void 0 : _c._changedAt,
          importResult: interfaces_2.BbaseSchemalessDocImportResult.MERGED
        };
      }
      const headRev = bbase2_utils_1.Bbase2Utils.splitRevision((_d = this._frozenDoc) === null || _d === void 0 ? void 0 : _d._rev) || {
        version: 0,
        hash: '0'
      };
      const importRev = bbase2_utils_1.Bbase2Utils.splitRevision(params.doc._rev) || {
        version: 0,
        hash: '0'
      };
      if (headRev.version > 0 && importRev.version === headRev.version) {
        if (importRev.hash !== headRev.hash) {
          throw new error_1.BbitError('doc-import-hash-miss-match', {
            _bucket: params.doc._bucket,
            _id: params.doc._id,
            _index: params.doc._index,
            headRevision: headRev.version,
            importRevision: importRev.version,
            headHash: headRev.hash,
            importHash: importRev.hash
          });
        }
        if (!params.ignoreSameVersion) {
          return {
            _bucket: this.getBucket(),
            _id: this.getId(),
            _rev: this.getRev(),
            _index: this.getIndex(),
            _createdAt: (_e = this.getImmutableDoc()) === null || _e === void 0 ? void 0 : _e._createdAt,
            _changedAt: (_f = this.getImmutableDoc()) === null || _f === void 0 ? void 0 : _f._changedAt,
            importResult: interfaces_2.BbaseSchemalessDocImportResult.SAME_VERSION
          };
        }
      }
      if (importRev.version < headRev.version && headRev.version > 0) {
        throw new error_1.BbitError('doc-is-older-than-head', {
          reason: 'did not import document due to not higher revision',
          _bucket: params.doc._bucket,
          _id: params.doc._id,
          _index: params.doc._index,
          headRevision: headRev.version,
          importRevision: importRev.version
        });
      }
      this._latestServerRev = params.doc._rev;
      const doc = params.isImmutable ? params.doc : object_1.BbitObject.cloneDeep(params.doc);
      this.destroy('import');
      const modifyRes = this.modify(proxyDoc => {
        proxyDoc._rev = null;
        for (const key in doc) {
          proxyDoc[key] = doc[key];
        }
        proxyDoc._id = this.getId();
        proxyDoc._bucket = this.getBucket();
        return result_1.BbitResult.createSuccess({
          _bucket: proxyDoc._bucket,
          _id: proxyDoc._id,
          _rev: proxyDoc._rev,
          _index: proxyDoc._index,
          _createdAt: proxyDoc._createdAt,
          _changedAt: proxyDoc._changedAt,
          importResult: interfaces_2.BbaseSchemalessDocImportResult.SUCCESS
        });
      }, {
        doNotAddToHistory: true,
        skipReadonlyCheck: params.skipReadonlyCheck
      });
      this._originalDoc = this._frozenDoc;
      this.clearChangeHistory('import');
      if (result_1.BbitResult.isOk(modifyRes)) {
        this._revChanges.next(modifyRes.data);
      }
      return modifyRes.toPromise();
    });
  }
  clone() {
    const doc = new _a({
      readonly: false,
      _bucket: this.getBucket(),
      _id: this.getId()
    }, this.bbaseStore);
    const loadRes = doc.import({
      doc: this.getImmutableDoc(),
      isImmutable: false,
      reapplyChanges: false
    });
    if (result_1.BbitResult.isError(loadRes)) {
      return loadRes;
    }
    return result_1.BbitResult.createSuccess(doc);
  }
  save(params) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.isReadOnly()) {
        throw new error_1.BbitError('doc-is-readonly');
      }
      const saveRes = yield this._saveMutex.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
        var _b;
        const res = yield this.bbaseStore.putDocs(this.getImmutableDoc(), (_b = params.session) === null || _b === void 0 ? void 0 : _b.getBbaseAuth()).toPromise();
        const importRes = yield this.import({
          doc: res.newDoc,
          isImmutable: true,
          mergeHandler: params.mergeHandler,
          reapplyChanges: false
        });
        return importRes;
      }));
      if ((saveRes === null || saveRes === void 0 ? void 0 : saveRes.importResult) === interfaces_2.BbaseSchemalessDocImportResult.MERGED) {
        const mergedSaveRes = (yield params.mergeSaveHandler ? params.mergeSaveHandler() : this.save(params)) || {};
        if (!mergedSaveRes.importResult || mergedSaveRes.importResult === interfaces_2.BbaseSchemalessDocImportResult.SUCCESS) {
          mergedSaveRes.importResult = interfaces_2.BbaseSchemalessDocImportResult.MERGED;
        }
        return mergedSaveRes;
      }
      return saveRes;
    });
  }
  setLatestServerRev(rev) {
    var _b, _c;
    const inputRev = ((_b = bbase2_utils_1.Bbase2Utils.splitRevision(rev)) === null || _b === void 0 ? void 0 : _b.version) || 0;
    if (inputRev > 0) {
      this._isUnknownOnServer = false;
    }
    const latestServerRev = ((_c = bbase2_utils_1.Bbase2Utils.splitRevision(this._latestServerRev)) === null || _c === void 0 ? void 0 : _c.version) || 0;
    if (inputRev >= latestServerRev) {
      this._latestServerRev = rev;
      return true;
    }
    return false;
  }
  isStale(options) {
    var _b, _c, _d;
    let maxCacheSeconds = (_b = options === null || options === void 0 ? void 0 : options.maxCacheSeconds) !== null && _b !== void 0 ? _b : 60;
    if (!this.isUnknownOnServer()) {
      if (!this._frozenDoc) {
        return true;
      }
      const localRev = ((_c = bbase2_utils_1.Bbase2Utils.splitRevision(this._frozenDoc._rev)) === null || _c === void 0 ? void 0 : _c.version) || 0;
      const latestServerRev = ((_d = bbase2_utils_1.Bbase2Utils.splitRevision(this._latestServerRev)) === null || _d === void 0 ? void 0 : _d.version) || 0;
      if (latestServerRev > localRev) {
        return true;
      }
      const isNew = localRev < 1;
      if (isNew && !(options === null || options === void 0 ? void 0 : options.skipIsNewCheck)) {
        return false;
      }
    } else {
      maxCacheSeconds = Math.min(maxCacheSeconds, 10);
    }
    return !this._lastRetrieve || Math.abs(this._lastRetrieve.diffNow().get('seconds')) >= maxCacheSeconds;
  }
  retrieveFromDatabase(options) {
    return this._retrieveMutex.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
      var _b;
      const _rev = this.isReadOnly() && ((_b = bbase2_utils_1.Bbase2Utils.splitRevision(this.getRev())) === null || _b === void 0 ? void 0 : _b.version) > 0 ? this.getRev() : undefined;
      const getParams = {
        _bucket: this.getBucket(),
        _id: this.getId(),
        _rev
      };
      if (!this.isStale(options)) {
        return {
          _bucket: this.getBucket(),
          _id: this.getId(),
          _rev: this.getRev()
        };
      }
      const doc = yield (0, rxjs_1.lastValueFrom)(this.bbaseStore.getDoc(getParams));
      this._isUnknownOnServer = !doc;
      this._lastRetrieve = luxon_1.DateTime.local();
      if (!doc) {
        if ((options === null || options === void 0 ? void 0 : options.createIfNotExists) && (options === null || options === void 0 ? void 0 : options.createIfNotExistsHandler)) {
          yield options.createIfNotExistsHandler(options.initNewParams);
          return {
            _bucket: this.getBucket(),
            _id: this.getId(),
            _rev: this.getRev()
          };
        }
        const errMeta = {
          func: 'schematicDoc.retrieveFromDatabase()',
          getParams,
          isReadOnly: this.isReadOnly(),
          isLoaded: this.isLoaded(),
          isNew: this.isNew(),
          isModified: this.isModified(),
          lastRetrieve: this._lastRetrieve
        };
        console.error(new Error('unknown document'), errMeta);
        this._log.warn(new error_1.BbitError('unknown-document', errMeta));
        return {
          _bucket: this.getBucket(),
          _id: this.getId(),
          _rev: this.getRev()
        };
      }
      this._log.debug('retrieveFromDatabase REMOTE!', {
        getParams,
        _rev: doc._rev,
        _index: doc._index,
        isReadOnly: this.isReadOnly(),
        isLoaded: this.isLoaded(),
        isNew: this.isNew(),
        isModified: this.isModified(),
        lastRetrieve: this._lastRetrieve.toISO()
      });
      return this.import({
        doc,
        isImmutable: true,
        mergeHandler: options === null || options === void 0 ? void 0 : options.mergeHandler,
        reapplyChanges: true,
        skipReadonlyCheck: true
      });
    }));
  }
  markAsModifiedOnDatabase() {
    this._lastRetrieve = null;
    this._isUnknownOnServer = false;
  }
}
exports.BbitSchemalessDocument = BbitSchemalessDocument;
_a = BbitSchemalessDocument;
BbitSchemalessDocument.simpleChangesMerger = _b => __awaiter(void 0, [_b], void 0, function* ({
  current,
  other,
  isOtherTemporary,
  isImmutable
}) {
  const changeSets = current.listChanges();
  const newestVersion = new _a({
    _bucket: other._bucket,
    _id: other._id
  }, current.bbaseStore);
  if (isOtherTemporary) {
    yield newestVersion.retrieveFromDatabase({
      maxCacheSeconds: 5
    });
  } else {
    yield newestVersion.import({
      doc: other,
      isImmutable,
      reapplyChanges: false
    });
  }
  const res = newestVersion.applyChanges(changeSets, {
    backwards: false,
    allAtOnce: false
  });
  if (result_1.BbitResult.isError(res)) {
    return res.toPromise();
  }
  return newestVersion;
});
