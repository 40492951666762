"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitHttpServerRoutes = void 0;
const permission_checker_1 = require("../auth/permission-checker");
const jwk_store_1 = require("../crypto/jwk-store");
const log_1 = require("../log/log");
const error_1 = require("../primitives/error");
const utils_1 = require("../utils/utils");
const interfaces_1 = require("./interfaces");
class BbitHttpServerRoutes {
  constructor(globalContext, env) {
    this.globalContext = globalContext;
    this.env = env;
    this._routes = {};
    this._routeOrder = [];
    this._catchOrder = [];
    this._authProviders = [];
    this._trustedIssuers = {};
    this._log = log_1.BbitLog.scope({
      class: 'BbitHttpServerRoutes'
    });
  }
  getLoadedRouteHandler(routeId) {
    return this._routes[routeId];
  }
  getLoadedCatchHandler(routeId) {
    return this._routes[routeId];
  }
  serializeLoadedRoutes() {
    return {
      routes: this._routeOrder.map(r => this.serializeRoutePath(r)),
      catches: this._catchOrder.map(r => this.serializeRoutePath(r))
    };
  }
  setCorsVerifier(func) {
    this._corsVerifier = func;
  }
  addTrustedIssuer(issuer) {
    this._trustedIssuers[issuer.getIssuerClaim()] = issuer;
  }
  getTrustedIssuer(issuerUrl) {
    return this._trustedIssuers[jwk_store_1.BbitJWKStore.ensureTrailingSlash(issuerUrl)];
  }
  listTrustedIssuers() {
    return Object.keys(this._trustedIssuers);
  }
  addAuthProvider(provider) {
    this._authProviders.push(provider);
  }
  setAuthProviders(providers) {
    this._authProviders = providers || [];
  }
  serializeRoutePath(id) {
    var _a, _b, _c;
    return (this._routes[id].def.methods.length >= 6 ? 'ALL' : this._routes[id].def.methods.join(',')) + ' ' + this._routes[id].pathRegex + (((_b = (_a = this._routes[id].def.auth) === null || _a === void 0 ? void 0 : _a.requiredActions) === null || _b === void 0 ? void 0 : _b.length) > 0 ? ` (${(_c = this._routes[id].def.auth) === null || _c === void 0 ? void 0 : _c.requiredActions.join(',')})` : '');
  }
  remove(routeId) {
    this._catchOrder = this._catchOrder.filter(id => id !== routeId);
    this._routeOrder = this._routeOrder.filter(id => id !== routeId);
    delete this._routes[routeId];
  }
  removeAll() {
    this._catchOrder = [];
    this._routeOrder = [];
    this._routes = {};
  }
  add(params) {
    const routeId = utils_1.BbitUtils.makeId();
    if (!(params === null || params === void 0 ? void 0 : params.handler)) {
      throw new error_1.BbitError('route-must-have-a-handler', {
        route: params
      });
    }
    if (!params.methods || params.methods.length === 0) {
      params.methods = [interfaces_1.BbitHttpMethods.OPTIONS, interfaces_1.BbitHttpMethods.GET, interfaces_1.BbitHttpMethods.PUT, interfaces_1.BbitHttpMethods.POST, interfaces_1.BbitHttpMethods.DELETE, interfaces_1.BbitHttpMethods.HEAD];
    }
    if (params.cors) {
      const innerHandler = params.handler;
      params.handler = routeParams => {
        routeParams.res.cors(Object.assign({
          origin: '*',
          maxAge: 864000,
          methods: params.methods
        }, params.cors));
        return innerHandler(routeParams);
      };
      this.add(Object.assign(Object.assign({}, params), {
        cors: undefined,
        methods: [interfaces_1.BbitHttpMethods.OPTIONS],
        handler: _a => __awaiter(this, [_a], void 0, function* ({
          res
        }) {
          res.cors(Object.assign({
            origin: '*',
            maxAge: 864000,
            methods: params.methods
          }, params.cors));
        })
      }));
    }
    const fullPath = `/${`${params.basePath || ''}/${params.path.replace(/^\//, '')}`.replace(/^\//, '')}`;
    const pathParams = [];
    if (/[^\w\d_:\/\+\.\-]+/i.test(fullPath)) {
      throw new error_1.BbitError('registering-invalid-route-path', {
        path: fullPath
      });
    }
    const pathRegex = '^' + fullPath.replace(/(:[\w\d_\.\-]+)/gi, substring => {
      pathParams.push(substring.substr(1));
      return '([^/]+)';
    }) + '$';
    this._routes[routeId] = {
      handlerType: 'route',
      def: params,
      fullPath,
      pathRegex: new RegExp(pathRegex, 'i'),
      pathParams
    };
    this._routeOrder.push(routeId);
    return routeId;
  }
  catch(params) {
    const routeId = utils_1.BbitUtils.makeId();
    if (!(params === null || params === void 0 ? void 0 : params.handler)) {
      throw new error_1.BbitError('route-must-have-a-handler', {
        route: params
      });
    }
    if (!params.methods || params.methods.length === 0) {
      params.methods = [interfaces_1.BbitHttpMethods.OPTIONS, interfaces_1.BbitHttpMethods.GET, interfaces_1.BbitHttpMethods.PUT, interfaces_1.BbitHttpMethods.POST, interfaces_1.BbitHttpMethods.DELETE, interfaces_1.BbitHttpMethods.HEAD];
    }
    const fullPath = '/' + (params.basePath || '');
    const pathParams = [];
    if (/[^\w\d_:\/\+\.\-]+/i.test(fullPath)) {
      throw new error_1.BbitError('registering-invalid-route-path', {
        path: fullPath
      });
    }
    const pathRegex = '^' + fullPath.replace(/(:[\w\d_\.\-]+)/gi, substring => {
      pathParams.push(substring.substr(1));
      return '([^/]+)';
    }) + '';
    this._routes[routeId] = {
      handlerType: 'catch',
      def: params,
      fullPath,
      pathRegex: new RegExp(pathRegex, 'i'),
      pathParams
    };
    this._catchOrder.push(routeId);
    return routeId;
  }
  getMatchingRouteIDs(routeSource, method, path) {
    return routeSource.filter(id => {
      const route = this._routes[id];
      return route.def.methods.includes(method) && route.pathRegex.test(path);
    });
  }
  _loopThroughRoutes(type, res, body) {
    return __awaiter(this, void 0, void 0, function* () {
      const routeSource = type === 'route' ? this._routeOrder : this._catchOrder;
      const routes = this.getMatchingRouteIDs(routeSource, res.request.method, res.request.path);
      res.startTimer(type + '-auth', 'Route matching and auth checks');
      const getRouteParams = routeId => {
        const route = this._routes[routeId];
        const regexRes = route.pathRegex.exec(res.request.path);
        return Object.assign({}, route.def.additionalRouteParams || {}, route.pathParams.reduce((acc, name, i) => Object.assign(Object.assign({}, acc), {
          [name]: decodeURIComponent(regexRes[i + 1])
        }), {}));
      };
      const isAuthValid = res.session.isAuthValid();
      const getRequiredResourcesErrors = [];
      const routesWithEvaluatedPermission = routes.reduce((acc, routeId) => {
        var _a, _b, _c, _d;
        const routeParams = getRouteParams(routeId);
        if (this._routes[routeId].handlerType === 'catch') {
          const errorHandler = this.getLoadedCatchHandler(routeId);
          if (!isAuthValid) {
            return Object.assign(Object.assign({}, acc), {
              [routeId]: {
                routeParams,
                allowed: !!errorHandler.def.auth
              }
            });
          }
          return Object.assign(Object.assign({}, acc), {
            [routeId]: {
              routeParams,
              allowed: true
            }
          });
        }
        const currentRoute = this.getLoadedRouteHandler(routeId);
        const needsPermissions = ((_b = (_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.def.auth) === null || _a === void 0 ? void 0 : _a.requiredActions) === null || _b === void 0 ? void 0 : _b.length) > 0;
        if (!isAuthValid) {
          return Object.assign(Object.assign({}, acc), {
            [routeId]: {
              routeParams,
              allowed: !needsPermissions
            }
          });
        }
        if (!needsPermissions) {
          return Object.assign(Object.assign({}, acc), {
            [routeId]: {
              routeParams,
              allowed: true
            }
          });
        }
        const actions = (((_c = currentRoute.def.auth) === null || _c === void 0 ? void 0 : _c.requiredActions) || []).filter(value => !!value);
        let resources = [];
        try {
          resources = ((currentRoute.def.auth.requiredResources ? (_d = currentRoute.def.auth) === null || _d === void 0 ? void 0 : _d.requiredResources(Object.assign(Object.assign({
            req: res.request,
            routeParams
          }, this.env), {
            session: res.session,
            sessionContext: res.sessionContext
          })) : []) || []).filter(value => !!value);
        } catch (err) {
          getRequiredResourcesErrors.push(err);
          return Object.assign(Object.assign({}, acc), {
            [routeId]: {
              routeParams,
              permissions: [{
                effect: 'Deny',
                evaluatedPath: 'implicit',
                principal: null,
                actions,
                resources,
                conditions: undefined,
                reason: 'auth.requiredResources() error: ' + err
              }],
              allowed: false
            }
          });
        }
        const permission = res.session.getPermission({
          actions,
          resources
        });
        return Object.assign(Object.assign({}, acc), {
          [routeId]: {
            routeParams,
            actions,
            resources,
            permission,
            allowed: permission_checker_1.BbitPermissionChecker.isAllowed(permission)
          }
        });
      }, {});
      if ((getRequiredResourcesErrors === null || getRequiredResourcesErrors === void 0 ? void 0 : getRequiredResourcesErrors.length) > 0) {
        return res.error(getRequiredResourcesErrors[0], interfaces_1.BbitHttpCodes.Code405_MethodNotAllowed, {
          getRequiredResourcesErrors: getRequiredResourcesErrors.length > 1 ? getRequiredResourcesErrors : undefined
        });
      }
      const authenticatedRoutes = routes.filter(r => {
        var _a;
        return (_a = routesWithEvaluatedPermission[r]) === null || _a === void 0 ? void 0 : _a.allowed;
      });
      const requiredActions = routes.reduce((acc, r) => {
        var _a;
        return acc.concat(((_a = routesWithEvaluatedPermission[r]) === null || _a === void 0 ? void 0 : _a.actions) || []);
      }, []);
      const requiredResources = routes.reduce((acc, r) => {
        var _a;
        return acc.concat(((_a = routesWithEvaluatedPermission[r]) === null || _a === void 0 ? void 0 : _a.resources) || []);
      }, []);
      this._log.debug(`${type} with regex `, {
        path: res.request.path,
        method: res.request.method,
        routesWithEvaluatedPermission,
        requiredActions,
        requiredResources,
        routes: routes.map(r => this.serializeRoutePath(r))
      });
      res.endTimer(`${type}-auth`);
      if (authenticatedRoutes.length === 0) {
        if (type === 'error') {
          return body;
        }
        if (res.request.method === interfaces_1.BbitHttpMethods.OPTIONS) {
          res.cors({
            origin: res.request.headers['origin'],
            credentials: !!res.request.headers.Authorization
          });
          return res.send(undefined);
        }
        if (routes.length > 0 && type === 'route') {
          if (isAuthValid) {
            return res.error(new error_1.BbitError('permission-denied', {
              isAuthValid,
              requiredActions,
              requiredResources,
              permissions: isAuthValid ? res.session.listPermissions() : undefined
            }), interfaces_1.BbitHttpCodes.Code403_Forbidden);
          }
          this._log.warn('auth-required', {
            isAuthValid,
            requiredActions,
            requiredResources
          });
          return res.status(interfaces_1.BbitHttpCodes.Code403_Forbidden).json({
            isAuthValid: false,
            error: 'permission-denied',
            errorCode: 'permission-denied',
            retryable: false
          });
        }
        this._log.error('unknown-path-or-method', Object.assign(Object.assign({
          routeSource
        }, this.serializeLoadedRoutes()), {
          method: res.request.method,
          path: res.request.path
        }));
        return res.error(new error_1.BbitError('unknown-path-or-method', {
          path: res.request.path,
          method: res.request.method
        }), interfaces_1.BbitHttpCodes.Code404_NotFound);
      }
      if (res.request.headers['x-bbit-log'] === 'verbose') {
        res.header('x-bbit-auth-required-actions', requiredActions.join(' / '));
        res.header('x-bbit-auth-required-resources', requiredResources.join(' / '));
      }
      res.startTimer(type + '-run', 'Runtime of route handler');
      for (const routeId of authenticatedRoutes) {
        const route = this._routes[routeId];
        const evaluated = routesWithEvaluatedPermission[routeId];
        try {
          body = yield route.def.handler({
            res,
            body: body !== null && body !== void 0 ? body : res.request.body,
            routeParams: evaluated.routeParams,
            permission: evaluated.permission,
            globalContext: this.globalContext
          });
        } catch (error) {
          this._log.error('route-handler-error', {
            error,
            routeId,
            routeParams: evaluated.routeParams,
            permission: evaluated.permission
          });
          return error;
        }
        if (res.getResponse()) {
          return;
        }
      }
      res.endTimer(`${type}-run`);
      return type === 'error' ? res.error(body) : res.json(body);
    });
  }
  validateAuth(req) {
    return __awaiter(this, void 0, void 0, function* () {
      for (const provider of this._authProviders) {
        try {
          const session = yield provider.validateAuth(req, this.globalContext);
          if (session) {
            return session;
          }
        } catch (error) {
          this._log.warn('error on authProvider', {
            provider,
            error
          });
        }
      }
      return Object.assign({
        isValid: false,
        validator: null,
        mfaCheckedAt: null,
        authCheckedAt: null,
        subject: null,
        issuer: null,
        token: null,
        userId: null,
        deviceId: null,
        audience: null,
        permissions: []
      }, this.env);
    });
  }
  handleRequest(res) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this._corsVerifier) {
        const cors = yield this._corsVerifier(res);
        if (cors) {
          res.cors(cors);
        }
      }
      const err = yield this._loopThroughRoutes('route', res);
      if (err) {
        const unhandledError = yield this._loopThroughRoutes('error', res, err);
        if (unhandledError) {
          throw err;
        }
      }
      return res.getResponse();
    });
  }
}
exports.BbitHttpServerRoutes = BbitHttpServerRoutes;
