"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bbase2StockBookingStoreBookings = exports.bbase2StockBookingGetLockScopeKeys = exports.bbase2StockBookingLockScopeBucketPrefix = void 0;
const luxon_1 = require("luxon");
const error_1 = require("../../primitives/error");
const unit_1 = require("../../unit/unit");
const interfaces_1 = require("./interfaces");
exports.bbase2StockBookingLockScopeBucketPrefix = 'stock-bookings';
const bbase2StockBookingGetLockScopeKeys = sb => {
  if (!sb.period || !sb.item || !sb.skuKey) {
    throw new error_1.BbitError('invalidStockBookingKeys', Object.assign(Object.assign({}, sb), {
      userMessage: {
        key: 'invalidStockBookingKeys',
        n: 1
      }
    }));
  }
  return {
    lockScopeBucket: `${exports.bbase2StockBookingLockScopeBucketPrefix}-${sb.period}-${sb.item}`,
    lockScopeKey: sb.skuKey
  };
};
exports.bbase2StockBookingGetLockScopeKeys = bbase2StockBookingGetLockScopeKeys;
const bbase2StockBookingStoreBookings = (bbaseStore, writeAuth, newDoc, existingTokens) => __awaiter(void 0, void 0, void 0, function* () {
  const newBookings = ([interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED].includes(newDoc._index) ? newDoc === null || newDoc === void 0 ? void 0 : newDoc._stockBookings : []) || [];
  const existingStorageTokens = (existingTokens || []).filter(o => o.lockScopeBucket.startsWith(`${exports.bbase2StockBookingLockScopeBucketPrefix}-`));
  const newStorageTokens = newBookings.map(sb => Object.assign(Object.assign({}, (0, exports.bbase2StockBookingGetLockScopeKeys)({
    period: sb.period,
    item: sb.item,
    skuKey: sb.skuKey
  })), {
    tokenKey: sb._id,
    type: sb.isReservation ? interfaces_1.Bbase2LockTokenType.LEASE : interfaces_1.Bbase2LockTokenType.CONCURRENCY,
    utcTimeStampInMS: luxon_1.DateTime.fromISO(sb.date).toUTC().toMillis(),
    stockBooking: sb,
    _delete: !!sb._delete,
    allowEmptyCounters: sb.isReservation,
    expiresInS: null,
    source: {
      _bucket: newDoc._bucket,
      _id: newDoc._id,
      _rev: newDoc._rev,
      _createdAt: newDoc._createdAt
    }
  }));
  const newTokenMap = newStorageTokens.reduce((acc, val) => {
    acc[`${val.lockScopeBucket}/${val.lockScopeKey}/${val.tokenKey}`] = val;
    return acc;
  }, {});
  const tokensToRemove = existingStorageTokens.filter(o => !newTokenMap[`${o.lockScopeBucket}/${o.lockScopeKey}/${o.tokenKey}`]).map(o => Object.assign(Object.assign({}, o), {
    _delete: true
  }));
  if (newStorageTokens.length > 0 || tokensToRemove.length > 0) {
    const lockScopeIndex = {};
    yield Promise.all(newStorageTokens.map(scope => __awaiter(void 0, void 0, void 0, function* () {
      var _a, _b, _c, _d, _e, _f, _g, _h;
      const scopeKey = `${scope.lockScopeBucket}/${scope.lockScopeKey}`;
      if (!lockScopeIndex[scopeKey]) {
        lockScopeIndex[scopeKey] = {};
        const existingScope = yield bbaseStore.getLockScope({
          lockScopeBucket: scope.lockScopeBucket,
          lockScopeKey: scope.lockScopeKey
        });
        if (!existingScope || !existingScope.settings) {
          lockScopeIndex[scopeKey] = yield bbaseStore.setLockScope({
            _bucket: scope.lockScopeBucket,
            _id: scope.lockScopeKey,
            unit: (_b = (_a = scope.stockBooking) === null || _a === void 0 ? void 0 : _a.quantity) === null || _b === void 0 ? void 0 : _b.unit,
            unitCountFactor: 1000,
            allowNegativeConcurrency: true,
            allowMoreLeasesThanConcurrency: true,
            statsResolutions: {
              dailyCET: {
                startTimeISO: luxon_1.DateTime.fromSQL('2021-01-01', {
                  zone: 'Europe/Zurich'
                }).startOf('day').toISO(),
                endTimeISO: luxon_1.DateTime.fromSQL('2021-01-01', {
                  zone: 'Europe/Zurich'
                }).endOf('day').toISO()
              }
            },
            stock: {
              storage: (_c = scope.stockBooking) === null || _c === void 0 ? void 0 : _c.storage,
              period: (_d = scope.stockBooking) === null || _d === void 0 ? void 0 : _d.period,
              item: (_e = scope.stockBooking) === null || _e === void 0 ? void 0 : _e.item,
              productionStep: (_f = scope.stockBooking) === null || _f === void 0 ? void 0 : _f.productionStep,
              lotNumber: (_g = scope.stockBooking) === null || _g === void 0 ? void 0 : _g.lotNumber,
              serialNumber: (_h = scope.stockBooking) === null || _h === void 0 ? void 0 : _h.serialNumber
            },
            _changePropertyIfDefined: false
          }, writeAuth);
        } else {
          lockScopeIndex[scopeKey] = existingScope;
        }
      }
    })));
    for (const booking of newStorageTokens) {
      const scope = lockScopeIndex[`${booking.lockScopeBucket}/${booking.lockScopeKey}`];
      const count = Math.round(unit_1.BbitUnit.from(booking.stockBooking.quantity).convertTo(scope.unit).amount * (scope.unitCountFactor || 1000));
      switch (booking.type) {
        case interfaces_1.Bbase2LockTokenType.LEASE:
          booking.leaseCount = count;
          break;
        case interfaces_1.Bbase2LockTokenType.CONCURRENCY:
          booking.concurrencyCount = count;
          break;
      }
    }
    console.debug('acquireLocks', [].concat(tokensToRemove, newStorageTokens));
    const result = yield bbaseStore.acquireLocks({
      lockTokens: [].concat(tokensToRemove, newStorageTokens),
      auth: writeAuth
    });
    return result;
  }
  return [];
});
exports.bbase2StockBookingStoreBookings = bbase2StockBookingStoreBookings;
