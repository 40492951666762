"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitProofOfWork = void 0;
const string_1 = require("../primitives/string");
const universal_crypto_1 = require("./universal-crypto");
class BbitProofOfWork {
  constructor(serverOptions = {}, crypto = universal_crypto_1.BbitUniversalCrypto.singleton()) {
    this.serverOptions = serverOptions;
    this.crypto = crypto;
  }
  createChallenge(_a) {
    return __awaiter(this, arguments, void 0, function* ({
      complexity
    }) {
      if (!(complexity >= this.serverOptions.minComplexity)) {
        complexity = this.serverOptions.minComplexity;
      }
      if (!complexity) {
        throw new Error(`Complexity ${complexity} must be a number`);
      }
      if (!this.serverOptions.serverSecret) {
        throw new Error('serverSecret is required');
      }
      const salt = this.crypto.generateRandomString(this.serverOptions.minSaltLength || 16);
      const timestamp = Date.now();
      const expiration = Math.floor((timestamp + (this.serverOptions.serverTimeout || 120000)) / 1000);
      const nonce = yield this.crypto.sha256AsHex(this.crypto.utf8StringToArrayBuffer(`${complexity}${salt}${expiration}${this.serverOptions.serverSecret}`));
      return {
        complexity,
        expiration,
        salt,
        nonce
      };
    });
  }
  _readTimestamp(buffer, off) {
    return buffer.readUInt32BE(off) * 0x100000000 + buffer.readUInt32BE(off + 4);
  }
  _writeTimestamp(buffer, timestamp, off) {
    const hi = timestamp / 0x100000000 >>> 0;
    const lo = (timestamp & 0xffffffff) >>> 0;
    buffer.writeUInt32BE(hi, off);
    buffer.writeUInt32BE(lo, off + 4);
    return off + 8;
  }
  _genAnswer(buf) {
    const now = Date.now();
    let off = this._writeTimestamp(buf, now, 0);
    const words = off + ((buf.length - off) / 4 | 0) * 4;
    for (; off < words; off += 4) buf.writeUInt32LE(Math.random() * 0x100000000 >>> 0, off);
    for (; off < buf.length; off++) buf[off] = Math.random() * 0x100 >>> 0;
  }
  solveChallenge(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        complexity,
        expiration,
        nonce
      } = params;
      const timestamp = Date.now();
      const now = Math.floor(timestamp / 1000);
      if (now > expiration) {
        throw new Error('expired');
      }
      const answerBuffer = Buffer.alloc(BbitProofOfWork.ANSWER_NONCE_SIZE);
      for (;;) {
        this._genAnswer(answerBuffer);
        const answer = answerBuffer.toString('hex');
        const hash = yield this.crypto.sha256AsHex(this.crypto.utf8StringToArrayBuffer(`${nonce}${answer}`));
        if (this.checkComplexity(hash, complexity)) return Object.assign(Object.assign({}, params), {
          answer
        });
      }
    });
  }
  checkComplexity(hexHash, complexity) {
    if (!complexity) {
      throw new Error(`Complexity ${complexity} must be a number`);
    }
    if (string_1.BbitString.isEmpty(hexHash)) {
      throw new Error('hexHash must be a non-empty string');
    }
    if (!(complexity < hexHash.length * 8)) {
      throw new Error(`Complexity ${complexity} must be lower than hash binary length of ${hexHash.length * 8}`);
    }
    const hashBuffer = Buffer.from(hexHash, 'hex');
    let off = 0;
    let i;
    for (i = 0; i <= complexity - 8; i += 8, off++) {
      if (hashBuffer[off] !== 0) return false;
    }
    const mask = 0xff << 8 + i - complexity;
    return (hashBuffer[off] & mask) === 0;
  }
  verify(params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!this.serverOptions.serverSecret) {
        throw new Error('serverSecret is required');
      }
      if (!(((_a = params === null || params === void 0 ? void 0 : params.salt) === null || _a === void 0 ? void 0 : _a.length) > this.serverOptions.minSaltLength)) {
        throw new Error('invalid salt length, its shorter than serverOptions.minSaltLength');
      }
      const {
        complexity,
        salt,
        expiration
      } = params;
      const timestamp = Date.now();
      const now = Math.floor(timestamp / 1000);
      if (now > expiration) {
        throw new Error('expired');
      }
      if (!(complexity >= this.serverOptions.minComplexity)) {
        throw new Error('required complexity is lower than serverOptions.minComplexity');
      }
      const nonce = yield this.crypto.sha256AsHex(this.crypto.utf8StringToArrayBuffer(`${complexity}${salt}${expiration}${this.serverOptions.serverSecret}`));
      const answerBuffer = Buffer.from(params.answer, 'hex');
      if (!(answerBuffer.length >= BbitProofOfWork.MIN_NONCE_SIZE)) {
        throw new Error('invalid answer');
      }
      const ts = this._readTimestamp(answerBuffer, 0);
      if (Math.abs(ts - timestamp) > this.serverOptions.serverTimeout) {
        throw new Error('invalid timestamp');
      }
      const answer = yield this.crypto.sha256AsHex(this.crypto.utf8StringToArrayBuffer(`${nonce}${params.answer}`));
      if (!this.checkComplexity(answer, complexity)) {
        throw new Error('supplied answer does not meet min complexity requirement of ' + complexity);
      }
      return true;
    });
  }
  formatAnswerAsJWT(params) {
    const {
      answer
    } = params;
    const header = {
      alg: 'POW',
      type: 'JWT'
    };
    const payload = Object.assign(Object.assign({}, params), {
      answer: undefined
    });
    return `${this.crypto.base64.stringifyToBase64Url(header)}.${this.crypto.base64.stringifyToBase64Url(payload)}.${answer}`;
  }
}
exports.BbitProofOfWork = BbitProofOfWork;
BbitProofOfWork.MIN_NONCE_SIZE = 8;
BbitProofOfWork.ANSWER_NONCE_SIZE = BbitProofOfWork.MIN_NONCE_SIZE + 8;
