"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitBRN = void 0;
class BbitBRN {
  constructor(env) {
    this.env = env;
  }
  encodeAuthPrincipalBRN(principal) {
    return `brn:${this.env.organizationKey}:${this.env.environmentKey}:auth:principal:${principal._bucket}:${principal._id}`;
  }
  decodeAuthPrincipalBRN(brn) {
    if (!brn) {
      return undefined;
    }
    const [, organizationKey, environmentKey,,, _bucket, _id] = brn.split(':');
    if (!organizationKey || !environmentKey || !_bucket || !_id) {
      return undefined;
    }
    return {
      organizationKey,
      environmentKey,
      _bucket,
      _id
    };
  }
  bbaseBucket(bucket) {
    return `brn:${this.env.organizationKey}:${this.env.environmentKey}:bbase:bucket:${bucket}`;
  }
  bbaseDoc(bucket, docId) {
    return `brn:${this.env.organizationKey}:${this.env.environmentKey}:bbase:doc:${bucket}:${docId !== null && docId !== void 0 ? docId : '_all'}`;
  }
  bbaseDocField(bucket, docId, fieldPath) {
    return `brn:${this.env.organizationKey}:${this.env.environmentKey}:bbase:doc:${bucket}:${docId}:${fieldPath}`;
  }
  custom(keys) {
    return `brn:${this.env.organizationKey}:${this.env.environmentKey}:custom:${keys.join(':')}`;
  }
}
exports.BbitBRN = BbitBRN;
