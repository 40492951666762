"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitFunction = void 0;
class BbitFunction {
  static isFunction(input) {
    return typeof input === 'function';
  }
  static debounce(func, options) {
    let timeout;
    let later;
    const debounced = function () {
      const context = this;
      const args = arguments;
      later = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        return func.apply(context, args);
      };
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (options === null || options === void 0 ? void 0 : options.immediate) {
        return later();
      }
      timeout = setTimeout(later, (options === null || options === void 0 ? void 0 : options.wait) || 0);
    };
    debounced.cancel = () => {
      clearTimeout(timeout);
      timeout = null;
    };
    debounced.flush = () => later();
    return debounced;
  }
  static throttle(func, options) {
    let timeout;
    let later;
    if (!options.hasOwnProperty('trailing')) {
      options.trailing = true;
    }
    const throttled = function () {
      const context = this;
      const args = arguments;
      later = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        if (options === null || options === void 0 ? void 0 : options.trailing) {
          return func.apply(context, args);
        }
      };
      if (!timeout) {
        timeout = setTimeout(later, (options === null || options === void 0 ? void 0 : options.wait) || 0);
        if (options === null || options === void 0 ? void 0 : options.leading) {
          return func.apply(context, args);
        }
      }
    };
    throttled.cancel = () => {
      clearTimeout(timeout);
      timeout = null;
    };
    throttled.flush = () => later();
    return throttled;
  }
}
exports.BbitFunction = BbitFunction;
