"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitSession = void 0;
const luxon_1 = require("luxon");
const rxjs_1 = require("rxjs");
const interfaces_1 = require("../bbase/utils/interfaces");
const log_1 = require("../log/log");
const brn_1 = require("./brn");
const interfaces_2 = require("./interfaces");
const permission_checker_1 = require("./permission-checker");
class BbitSession {
  static createTestSession() {
    const session = new BbitSession({
      organizationKey: 'test',
      environmentKey: 'local'
    }, {});
    session.setSessionAuth({
      isValid: true,
      userId: '#testUser',
      deviceId: '#testDevice',
      branchId: '#default',
      permissions: []
    });
    session.sudo();
    return session;
  }
  static fromRequest(auth, request) {
    const session = new BbitSession({
      organizationKey: auth === null || auth === void 0 ? void 0 : auth.organizationKey,
      environmentKey: auth === null || auth === void 0 ? void 0 : auth.environmentKey
    }, {
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.REFERER]: () => request.headers['referer'],
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.USER_AGENT]: () => request.userAgent,
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.BBIT_APP_VERSION]: () => request.headers['x-bbit-app-version'],
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.BBIT_BUSINESS_LOGIC_VERSION]: () => request.headers['x-bbit-bl-version'],
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.CLIENT_TYPE]: () => request.clientType,
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.SOURCE_COUNTRY]: () => request.clientCountry,
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.SOURCE_IP]: () => request.ip,
      [interfaces_2.BbitAuthPermissionGeneralConditionKeys.BBASE_TOKEN]: () => request.auth.value
    });
    session.setSessionAuth(auth);
    return session;
  }
  constructor(env, conditionValues) {
    this.env = env;
    this.conditionValues = conditionValues;
    this._sessionAuth = new rxjs_1.BehaviorSubject({
      organizationKey: null,
      environmentKey: null,
      branchId: '#default',
      isValid: false,
      audience: null,
      authCheckedAt: null,
      deviceId: null,
      issuer: null,
      mfaCheckedAt: null,
      permissions: [],
      permissionWarnings: [],
      subject: null,
      token: null,
      userId: null,
      validator: null
    });
    this.brn = new brn_1.BbitBRN(env);
    if (!this.conditionValues) {
      this.conditionValues = {};
    }
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.CURRENT_TIME] = () => luxon_1.DateTime.utc().toISO();
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.MULTI_FACTOR_AUTH_PRESENT] = () => this._sessionAuth.value.mfaCheckedAt && luxon_1.DateTime.fromISO(this._sessionAuth.value.mfaCheckedAt).isValid ? 'true' : 'false';
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.MULTI_FACTOR_AUTH_AGE_SECONDS] = () => {
      const checkDate = this._sessionAuth.value.mfaCheckedAt ? luxon_1.DateTime.fromISO(this._sessionAuth.value.mfaCheckedAt) : null;
      if (!(checkDate === null || checkDate === void 0 ? void 0 : checkDate.isValid)) {
        return null;
      }
      return checkDate.diffNow().shiftTo('seconds').seconds + '';
    };
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.AUTH_AGE] = () => {
      const checkDate = this._sessionAuth.value.mfaCheckedAt ? luxon_1.DateTime.fromISO(this._sessionAuth.value.authCheckedAt) : null;
      if (!(checkDate === null || checkDate === void 0 ? void 0 : checkDate.isValid)) {
        return null;
      }
      return checkDate.diffNow().shiftTo('seconds').seconds + '';
    };
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.AUTH_ISSUER] = () => this._sessionAuth.value.issuer;
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.AUTH_SUBJECT] = () => this._sessionAuth.value.subject;
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.AUTH_AUDIENCE] = () => this._sessionAuth.value.audience;
    this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.AUTH_VALIDATOR] = () => this._sessionAuth.value.validator;
  }
  setSessionAuth(auth) {
    this._sessionAuth.next(Object.assign(Object.assign({}, this._sessionAuth.value), auth || {}));
  }
  getPrincipalFromSubject() {
    return this.brn.decodeAuthPrincipalBRN(this._sessionAuth.value.subject);
  }
  getSessionAuth() {
    return this._sessionAuth.value;
  }
  isAuthValid() {
    var _a;
    return this._sessionAuth.value.isValid && ((_a = this._sessionAuth.value.permissions) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }
  getUserId() {
    return this._sessionAuth.value.userId;
  }
  getDeviceId() {
    return this._sessionAuth.value.deviceId;
  }
  getBranchId() {
    return this._sessionAuth.value.branchId;
  }
  getBbaseAuth() {
    return {
      token: (this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.BBASE_TOKEN] ? this.conditionValues[interfaces_2.BbitAuthPermissionGeneralConditionKeys.BBASE_TOKEN]() : undefined) || this._sessionAuth.value.token,
      userId: this._sessionAuth.value.userId,
      deviceId: this._sessionAuth.value.deviceId
    };
  }
  setPermissions(permissions) {
    this._sessionAuth.next(Object.assign(Object.assign({}, this._sessionAuth.value), {
      permissions
    }));
  }
  sudo() {
    this.setPermissions([{
      effect: 'Allow',
      actions: ['*'],
      resources: ['*'],
      reason: 'sudo'
    }]);
  }
  observePermissions() {
    return this._sessionAuth.asObservable().pipe((0, rxjs_1.map)(s => s.permissions));
  }
  listPermissions() {
    return this._sessionAuth.value.permissions;
  }
  listApplicablePermission(params) {
    const res = permission_checker_1.BbitPermissionChecker.listApplicablePermissions(Object.assign(Object.assign({
      permissions: this._sessionAuth.value.permissions
    }, params), {
      principal: {
        _bucket: interfaces_2.BbitAuthDataSchemaKeys.USER,
        _id: this._sessionAuth.value.userId
      },
      conditionValues: this.conditionValues
    }));
    return res;
  }
  getPermission(params) {
    const permissionListRes = this.listApplicablePermission(Object.assign(Object.assign({}, params), {
      returnFirstDeny: true
    }));
    const permissionRes = permission_checker_1.BbitPermissionChecker.reduceApplicablePermission(permissionListRes, params.actions, params.resources);
    const permission = permission_checker_1.BbitPermissionChecker.getPermissionFromResult(permissionRes, params);
    if (params.warnOnDeny && !permission_checker_1.BbitPermissionChecker.isAllowed(permission)) {
      BbitSession._log.warning(`PERMISSION DENIED: user ${this.getUserId()} on device ${this.getDeviceId()} is not allowed to do action ${params.actions} on ${params.resources}`);
    }
    return permission;
  }
  hasReadPermission(bucket, id, warnOnDeny = true) {
    return permission_checker_1.BbitPermissionChecker.isAllowed(this.getPermission({
      actions: [interfaces_1.Bbase2AuthActions.getDoc],
      resources: [this.brn.bbaseDoc(bucket, id)],
      warnOnDeny
    }));
  }
  hasWritePermission(bucket, id, warnOnDeny = true) {
    return permission_checker_1.BbitPermissionChecker.isAllowed(this.getPermission({
      actions: [interfaces_1.Bbase2AuthActions.putDoc],
      resources: [this.brn.bbaseDoc(bucket, id)],
      warnOnDeny
    }));
  }
  hasCustomPermission(customKeys, actions, warnOnDeny = true) {
    return permission_checker_1.BbitPermissionChecker.isAllowed(this.getPermission({
      actions,
      resources: [this.brn.custom(customKeys)],
      warnOnDeny
    }));
  }
  hasPermission(resources, actions, warnOnDeny = true) {
    return permission_checker_1.BbitPermissionChecker.isAllowed(this.getPermission({
      actions,
      resources,
      warnOnDeny
    }));
  }
  isAdmin() {
    return this.hasWritePermission('user');
  }
}
exports.BbitSession = BbitSession;
BbitSession._log = log_1.BbitLog.scope({
  class: 'BbitSession'
});
