"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitBase64 = void 0;
const string_1 = require("../primitives/string");
class BbitBase64 {
  _bufferFromBase64(characters, padChar, base64) {
    var _a, _b;
    const map = characters.split('').reduce((acc, char, index) => Object.assign(acc, {
      [char.charCodeAt(0)]: index
    }), {});
    const paddingLength = padChar ? (_a = base64.match(new RegExp(`^.+?(${padChar}?${padChar}?)$`))) === null || _a === void 0 ? void 0 : _a[1].length : 0;
    let first;
    let second;
    let third;
    let fourth;
    return (_b = base64.match(/.{1,4}/g)) === null || _b === void 0 ? void 0 : _b.reduce((acc, chunk, index) => {
      first = map[chunk.charCodeAt(0)];
      second = map[chunk.charCodeAt(1)];
      third = map[chunk.charCodeAt(2)];
      fourth = map[chunk.charCodeAt(3)];
      acc[3 * index] = first << 2 | second >> 4;
      acc[3 * index + 1] = (second & 0b1111) << 4 | third >> 2;
      acc[3 * index + 2] = (third & 0b11) << 6 | fourth;
      return acc;
    }, new Uint8Array(base64.length * 3 / 4 - paddingLength));
  }
  bufferFromBase64Url(base64Url) {
    return this._bufferFromBase64('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_', '', base64Url);
  }
  bufferFromBase64(base64) {
    return this._bufferFromBase64('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/', '=', base64);
  }
  _bufferToBase64(characters, padChar, base64) {
    function* chunks(arr, n) {
      for (let i = 0; i < arr.length; i += n) {
        yield arr.subarray(i, i + n);
      }
    }
    const map = characters.split('').reduce((acc, char, index) => Object.assign(acc, {
      [index]: char
    }), {});
    const result = [];
    for (const chunk of chunks(new Uint8Array(base64), 3)) {
      result.push(map[chunk[0] >> 2]);
      result.push(map[(chunk[0] & 0b11) << 4 | chunk[1] >> 4]);
      result.push(chunk[1] !== undefined ? map[(chunk[1] & 0b1111) << 2 | chunk[2] >> 6] : padChar);
      result.push(chunk[2] !== undefined ? map[chunk[2] & 0b111111] : padChar);
    }
    return result.join('');
  }
  bufferToBase64(base64) {
    return this._bufferToBase64('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/', '=', base64);
  }
  bufferToBase64Url(base64) {
    return this._bufferToBase64('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_', '', base64);
  }
  unescapeBase64Url(str) {
    return (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/');
  }
  escapeBase64Url(str) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
  encodeBase64Url(str, encoding) {
    return this.escapeBase64Url(Buffer.from(str, encoding || 'utf8').toString('base64'));
  }
  decodeBase64Url(str, encoding) {
    return Buffer.from(this.unescapeBase64Url(str), 'base64').toString(encoding || 'utf8');
  }
  stringifyToBase64Url(input, encoding) {
    return this.encodeBase64Url(string_1.BbitString.stringifyToJson(input), encoding);
  }
  parseFromBase64Url(input, encoding) {
    return JSON.parse(this.decodeBase64Url(input, encoding));
  }
}
exports.BbitBase64 = BbitBase64;
