"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitS3Helper = void 0;
const date_time_1 = require("../primitives/date-time");
const error_1 = require("../primitives/error");
const object_1 = require("../primitives/object");
class BbitS3Helper {
  static getChecksumObject(checksum) {
    if (object_1.BbitObject.isEmpty(checksum)) {
      return {};
    }
    if (checksum.sha256) {
      return {
        ChecksumAlgorithm: 'SHA256',
        ContentSHA256: checksum.sha256
      };
    }
    if (checksum.sha1) {
      return {
        ChecksumAlgorithm: 'SHA1',
        ContentSHA1: checksum.sha1
      };
    }
    if (checksum.md5) {
      return {
        ContentMD5: checksum.md5
      };
    }
    if (checksum.crc32c) {
      return {
        ChecksumAlgorithm: 'CRC32C',
        ContentCRC32C: checksum.crc32c
      };
    }
    if (checksum.crc32) {
      return {
        ChecksumAlgorithm: 'CRC32',
        ContentCRC32: checksum.crc32
      };
    }
    return {};
  }
  static isS3Url(path) {
    if (typeof path === 'string') {
      return /^s3:\/\/.+\/.+/i.test(path);
    }
    return (path === null || path === void 0 ? void 0 : path.hasOwnProperty) && path.hasOwnProperty('bucket') && path.hasOwnProperty('key');
  }
  static parseS3Url(path) {
    if (!BbitS3Helper.isS3Url(path)) {
      throw new error_1.BbitError('invalid-s3-url', {
        url: path
      });
    }
    if (typeof path === 'string') {
      const s3object = path.replace(/^s3:\/\//i, '').split('/');
      return {
        s3Bucket: s3object.shift(),
        s3Key: s3object.join('/')
      };
    }
    return path;
  }
}
exports.BbitS3Helper = BbitS3Helper;
BbitS3Helper.getValidJsDateOrUndefined = input => {
  const dt = date_time_1.BbitDateTime.parse(input);
  return (dt === null || dt === void 0 ? void 0 : dt.isValid) ? dt.toJSDate() : undefined;
};
