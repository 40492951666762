"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bbase2StoreHttp = void 0;
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const http_client_1 = require("../../http/http-client");
const log_1 = require("../../log/log");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const result_1 = require("../../primitives/result");
const string_1 = require("../../primitives/string");
const bbase2_utils_1 = require("../utils/bbase2-utils");
class Bbase2StoreHttp {
  constructor(config) {
    this._config = Object.assign({}, config);
    this._log = log_1.BbitLog.scope({
      package: '@bbitgmbh/bbit.sdk',
      function: 'BbaseHttpStore'
    });
    if (!this._config.url || this._config.url.length === 0) {
      this._config.url = `https://api.onbbit.io/${this._config.organizationKey}_${this._config.environmentKey}/bbase`;
    }
    const err = this._checkConfig();
    if (err) throw err;
    this._httpClient = new http_client_1.BbitHttpClient(this._config.url);
    this._connectionState = new RxJS.BehaviorSubject(this._getDefaultStoreState());
  }
  setAuth(authHeader) {
    this._config.authorization = authHeader;
  }
  subscribeTokenObservable(obs) {
    obs.pipe((0, operators_1.map)(authHeader => {
      this._config.authorization = authHeader;
      return 'bbaseHttpStore token updated';
    })).subscribe(this._log.getObserver());
  }
  _checkConfig() {
    if (!this._config) return new error_1.BbitError('empty-db-config');
    return null;
  }
  _getDefaultStoreState() {
    return {
      backendType: 'http',
      locationKey: this._config.url,
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey,
      latencyMs: 200,
      isPersistent: false,
      supportsAttachments: true
    };
  }
  getEnvironment() {
    return {
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey
    };
  }
  getLatestStoreState() {
    return this._connectionState.value;
  }
  observeStoreState() {
    return this._connectionState.asObservable();
  }
  listBuckets(params) {
    const validRes = string_1.BbitString.validateSystemFieldValue(params, '_buckettype');
    if (result_1.BbitResult.isError(validRes)) {
      return validRes.toObservable();
    }
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const _delete = params._delete ? 'true' : 'false';
      const onlyBuckets = params.onlyBuckets ? params.onlyBuckets.join(',') : undefined;
      const fetchNext = () => {
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/buckets/${encodeURIComponent(params._buckettype)}`,
          query: {
            startKey,
            _delete,
            onlyBuckets
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && result.lastEvaluatedKey && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      buckets: acc.buckets.concat((res === null || res === void 0 ? void 0 : res.buckets) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      buckets: [],
      lastEvaluatedKey: null
    }));
  }
  getBucket(params) {
    for (const field of ['_bucket', '_buckettype']) {
      const validRes = string_1.BbitString.validateSystemFieldValue(params, field);
      if (result_1.BbitResult.isError(validRes)) {
        return validRes.toObservable();
      }
    }
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl: `/bucket/${encodeURIComponent(params._buckettype)}/${encodeURIComponent(params._bucket)}`,
      body: null,
      authorization: this._config.authorization
    });
  }
  putBucket(params) {
    for (const field of ['_bucket', '_buckettype']) {
      const validRes = string_1.BbitString.validateSystemFieldValue(params, field);
      if (result_1.BbitResult.isError(validRes)) {
        return validRes.toObservable();
      }
    }
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl: `/bucket/${encodeURIComponent(params._buckettype)}/${encodeURIComponent(params._bucket)}`,
      body: params,
      authorization: this._config.authorization
    });
  }
  getKeys(params) {
    if (!Array.isArray(params === null || params === void 0 ? void 0 : params.keys)) {
      return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'getKeys({keys})',
        value: params === null || params === void 0 ? void 0 : params.keys,
        reason: 'must be an array'
      }));
    }
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl: `/keys/byId/${encodeURIComponent(params.keys.map(k => `${encodeURIComponent(k._bucket)}/${encodeURIComponent(k._id)}`).join(','))}`,
      body: null,
      authorization: this._config.authorization
    });
  }
  setKeys(params) {
    if (!Array.isArray(params === null || params === void 0 ? void 0 : params.keys)) {
      return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'setKeys({keys})',
        value: params === null || params === void 0 ? void 0 : params.keys,
        reason: 'must be an array'
      }));
    }
    if (params.keys.length === 0) {
      return RxJS.of([]);
    }
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl: '/keys',
      body: {
        keys: bbase2_utils_1.Bbase2Utils.recursiveMarshall(params.keys)
      },
      authorization: this._config.authorization
    });
  }
  listKeys(params) {
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        var _a, _b;
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/keys/list/${encodeURIComponent(params._bucket || 'undefined')}`,
          query: {
            startKey,
            sourceBucket: (_a = params.bySource) === null || _a === void 0 ? void 0 : _a._bucket,
            sourceId: (_b = params.bySource) === null || _b === void 0 ? void 0 : _b._id,
            withTags: params.withTags ? bbase2_utils_1.Bbase2Utils.serializeTags(params.withTags) : undefined
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && result.lastEvaluatedKey && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      keys: acc.keys.concat((res === null || res === void 0 ? void 0 : res.keys) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      keys: [],
      lastEvaluatedKey: null
    }));
  }
  getDoc(params) {
    if (!params) return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
      param: 'bbase2InMemoryStore getDoc param',
      value: params,
      reason: 'must be a non null object'
    }));
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        return fieldValid.toObservable();
      }
    }
    const subUrl = params._rev ? `/revision/${encodeURIComponent(params._bucket)}/${encodeURIComponent(params._id)}/${encodeURIComponent(params._rev)}` : `/doc/latest/${encodeURIComponent(params._bucket)}/${encodeURIComponent(params._id)}`;
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl,
      body: null,
      query: {
        index: params._index,
        format: params._format
      },
      authorization: this._config.authorization
    });
  }
  listDocs(params) {
    const indexes = Array.isArray(params._indexes) ? params._indexes : [params._indexes];
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/doc/list/${encodeURIComponent(params._bucket)}/${encodeURIComponent(indexes.join('-'))}`,
          query: {
            writtenFrom: params.writtenFrom,
            writtenTo: params.writtenTo,
            withTags: params.withTags ? bbase2_utils_1.Bbase2Utils.serializeTags(params.withTags) : undefined,
            startKey,
            format: params.format
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) && result.lastEvaluatedKey !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      docs: acc.docs.concat((res === null || res === void 0 ? void 0 : res.docs) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      docs: [],
      lastEvaluatedKey: null
    }));
  }
  recalculateBucketStats(params) {
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl: `/bucket/recalculateBucketStats/${encodeURIComponent(params._buckettype)}/${encodeURIComponent(params._bucket)}`,
      body: null,
      authorization: this._config.authorization
    });
  }
  listDocRevisions(params) {
    if (!params) return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
      param: 'bbase2InMemoryStore getDoc param',
      value: params,
      reason: 'must be a non null object'
    }));
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        return fieldValid.toObservable();
      }
    }
    const indexes = Array.isArray(params._indexes) ? params._indexes : [params._indexes];
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/doc/revisions/${encodeURIComponent(params._bucket)}/${encodeURIComponent(params._id)}/${encodeURIComponent(indexes.join('-'))}`,
          query: {
            startKey,
            format: params.format
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) && result.lastEvaluatedKey !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      docs: acc.docs.concat((res === null || res === void 0 ? void 0 : res.docs) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      docs: [],
      lastEvaluatedKey: null
    }));
  }
  putDocs(docs, writeAuth) {
    if (!docs) return RxJS.throwError(new error_1.BbitError('invalid-param', {
      param: 'docs',
      value: docs
    }));
    if (!Array.isArray(docs)) {
      docs = [docs];
    }
    if (docs.length === 0) return RxJS.of();
    let errors = [];
    for (let i = 0; i < docs.length; i++) {
      const validationResult = bbase2_utils_1.Bbase2Utils.validateDocument(docs[i], writeAuth, {
        index: i
      });
      if (!validationResult) {
        errors.push(new error_1.BbitError('null-or-undefined', {
          documentIndex: i,
          document: docs[i]
        }));
        continue;
      }
      if (validationResult.errors && validationResult.errors.length > 0) {
        errors = errors.concat(validationResult.errors);
      }
    }
    if (errors.length > 0) {
      return RxJS.throwError(() => errors);
    }
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl: '/doc',
      body: docs,
      authorization: this._config.authorization
    }).pipe((0, operators_1.mergeMap)(resArray => RxJS.of(...resArray)));
  }
  getAttachment(file) {
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl: '/attachment/get',
      body: file,
      authorization: this._config.authorization
    });
  }
  putAttachment(file) {
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl: '/attachment/put',
      body: file,
      authorization: this._config.authorization
    });
  }
  getLockScope(params) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    for (const systemField of ['lockScopeBucket', 'lockScopeKey']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        throw result_1.BbitResult.extractError(fieldValid);
      }
    }
    const subUrl = `/locks/scope/${encodeURIComponent(params.lockScopeBucket)}/${encodeURIComponent(params.lockScopeKey)}`;
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl,
      authorization: this._config.authorization
    }).toPromise();
  }
  setLockScope(params, auth) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        throw result_1.BbitResult.extractError(fieldValid);
      }
    }
    const subUrl = `/locks/scope/${encodeURIComponent(params._bucket)}/${encodeURIComponent(params._id)}`;
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl,
      body: {
        scope: params,
        auth
      },
      authorization: this._config.authorization
    }).toPromise();
  }
  listLockScopes(params) {
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/locks/list-scopes/${encodeURIComponent(params.lockScopeBucket)}`,
          query: {
            startKey
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) && result.lastEvaluatedKey !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      scopes: acc.scopes.concat((res === null || res === void 0 ? void 0 : res.scopes) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      scopes: [],
      lastEvaluatedKey: null
    }));
  }
  getLockScopeStats(params) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    for (const systemField of ['lockScopeBucket', 'lockScopeKey']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        throw result_1.BbitResult.extractError(fieldValid);
      }
    }
    const subUrl = `/locks/scope-stats/${encodeURIComponent(params.lockScopeBucket)}/${encodeURIComponent(params.lockScopeKey)}`;
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl,
      authorization: this._config.authorization
    }).toPromise();
  }
  listLockScopeStats(params) {
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/locks/list-scope-stats/${encodeURIComponent(params.lockScopeBucket)}`,
          query: {
            startKey
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) && result.lastEvaluatedKey !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      scopes: acc.scopes.concat((res === null || res === void 0 ? void 0 : res.scopes) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      scopes: [],
      lastEvaluatedKey: null
    }));
  }
  recalculateLockScope(params, auth) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore recalculateLockScopeStats param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    const subUrl = '/locks/recalculate-scope';
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl,
      body: {
        scope: params,
        auth
      },
      authorization: this._config.authorization
    }).toPromise();
  }
  acquireLocks(input) {
    if (!input) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: input,
        reason: 'must be a non null object'
      });
    }
    if (!input.lockTokens || input.lockTokens.length === 0) {
      return Promise.resolve([]);
    }
    console.debug('bbase http acquireLocks', input);
    const subUrl = '/locks/acquire';
    return this._httpClient.fetchJson({
      method: 'POST',
      subUrl,
      body: input,
      authorization: this._config.authorization
    }).toPromise();
  }
  deleteExpiredLockTokens(params) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    for (const systemField of ['lockScopeBucket', 'lockScopeKey']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        throw result_1.BbitResult.extractError(fieldValid);
      }
    }
    const subUrl = `/locks/expired-lease/${encodeURIComponent(params.lockScopeBucket)}/${encodeURIComponent(params.lockScopeKey)}`;
    return this._httpClient.fetchJson({
      method: 'DELETE',
      subUrl,
      query: {
        types: params.types ? params.types.join(',') : undefined
      },
      body: null,
      authorization: this._config.authorization
    }).toPromise();
  }
  getLockToken(params) {
    if (!params) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'bbase2HttpStore getDoc param',
        value: params,
        reason: 'must be a non null object'
      });
    }
    for (const systemField of ['lockScopeBucket', 'lockScopeKey']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        throw result_1.BbitResult.extractError(fieldValid);
      }
    }
    const subUrl = `/locks/lease/${encodeURIComponent(params.lockScopeBucket)}/${encodeURIComponent(params.lockScopeKey)}/${encodeURIComponent(params.tokenKey)}`;
    return this._httpClient.fetchJson({
      method: 'GET',
      subUrl,
      body: null,
      authorization: this._config.authorization
    }).toPromise();
  }
  listLockTokens(params) {
    return new RxJS.Observable(subscription => {
      let startKey = params.startKey;
      const fetchNext = () => {
        var _a, _b;
        this._httpClient.fetchJson({
          method: 'GET',
          subUrl: `/locks/list-tokens/${encodeURIComponent(params.lockScopeBucket)}/${encodeURIComponent(params.lockScopeKey)}`,
          query: {
            types: params.tokenTypes ? params.tokenTypes.join(',') : undefined,
            sourceBucket: (_a = params.bySource) === null || _a === void 0 ? void 0 : _a._bucket,
            sourceId: (_b = params.bySource) === null || _b === void 0 ? void 0 : _b._id,
            dateFrom: params.dateFrom,
            dateUntil: params.dateUntil,
            startKey,
            limit: params.limit ? `${params.limit}` : undefined,
            scanIndexForward: params.scanIndexForward != null ? params.scanIndexForward ? 'true' : 'false' : undefined
          },
          body: null,
          authorization: this._config.authorization
        }).pipe((0, operators_1.tap)(result => {
          if (!subscription.closed && (result === null || result === void 0 ? void 0 : result.lastEvaluatedKey) && result.lastEvaluatedKey !== startKey && params.retrieveAllPages) {
            startKey = result.lastEvaluatedKey;
            setTimeout(() => fetchNext(), 0);
          } else {
            setTimeout(() => subscription.complete(), 0);
          }
        })).subscribe({
          next: m => subscription.next(m),
          error: err => subscription.error(err)
        });
      };
      fetchNext();
    }).pipe((0, operators_1.reduce)((acc, res) => ({
      tokens: acc.tokens.concat((res === null || res === void 0 ? void 0 : res.tokens) || []),
      lastEvaluatedKey: res === null || res === void 0 ? void 0 : res.lastEvaluatedKey
    }), {
      tokens: [],
      lastEvaluatedKey: null
    }));
  }
}
exports.Bbase2StoreHttp = Bbase2StoreHttp;
