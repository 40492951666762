"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitDefinitions = void 0;
const interfaces_1 = require("../i18n/interfaces");
const interfaces_2 = require("./interfaces");
exports.unitDefinitions = [{
  _id: '#none',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'keine'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'none'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: ''
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: ''
    }
  },
  base: interfaces_2.BbitUnitBases.NONE,
  value: 1,
  offset: 0
}, {
  _id: '#percent',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'prozent'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'percent'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: '%'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: '%'
    }
  },
  base: interfaces_2.BbitUnitBases.RATIO,
  value: 0.01,
  offset: 0
}, {
  _id: '#permille',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'promille'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'per mille'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: '‰'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: '‰'
    }
  },
  base: interfaces_2.BbitUnitBases.RATIO,
  value: 0.001,
  offset: 0
}, {
  _id: '#piece',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Stück',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Stück'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'pieces',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'piece'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Stk',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Stk'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pcs',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'pc'
    }
  },
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'Stuecke',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'Stueck'
      }
    }
  },
  base: interfaces_2.BbitUnitBases.COUNT,
  value: 1,
  round: 1,
  displayDecimalMaximalCount: 0,
  offset: 0
}, {
  _id: '#pair',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Paar'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'pairs',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'pair'
    }
  },
  base: interfaces_2.BbitUnitBases.COUNT,
  value: 2,
  round: 0.5,
  displayDecimalMaximalCount: 1,
  offset: 0
}, {
  _id: '#dozen',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Dutzend'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'dozens',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'dozen'
    }
  },
  base: interfaces_2.BbitUnitBases.COUNT,
  value: 12,
  round: 1 / 12,
  offset: 0
}, {
  _id: '#carton',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kartons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Karton'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cartons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Carton'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kartons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Karton'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cartons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Carton'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#packet',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Schachteln',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Schachtel'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Packets',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Packet'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Schachteln',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Schachtel'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Packets',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Packet'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#bag',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Beutel',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Beutel'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bags',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Bag'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Beutel',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Beutel'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bags',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Bag'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#pack',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pack',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Pack'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'pack',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'pack'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pack',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Pack'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'pack',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'pack'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#box',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kisten',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Kiste'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Boxes',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Box'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kisten',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Kiste'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Boxes',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Box'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#pallet',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Paletten',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Palette'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pallets',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Pallet'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Paletten',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Palette'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pallets',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Pallet'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#blister',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Blister',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Blister'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Blister',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Blister'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Blister',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Blister'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Blister',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Blister'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#bundle',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bünde',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Bund'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'bundles',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'bundle'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bünde',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Bund'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'bundles',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'bundle'
    }
  },
  base: interfaces_2.BbitUnitBases.PACKAGING,
  value: 1,
  offset: 0
}, {
  _id: '#CHF',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Schweizer Franken'
    },
    [interfaces_1.BbitLanguage.FR_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Suisse Franc'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'swiss francs',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'swiss franc'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'CHF'
    },
    [interfaces_1.BbitLanguage.FR_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'CHF'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'CHF'
    }
  },
  countries: ['#CH'],
  base: interfaces_2.BbitUnitBases.CURRENCY,
  round: 0.05,
  value: 1,
  displayDecimalMinimalCount: 2,
  displayDecimalMaximalCount: 2,
  displayUnitBeforeAmount: true,
  offset: 0
}, {
  _id: '#EUR',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Euro',
      [interfaces_1.BbitTranslationType.SYMBOL]: '€'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'euros',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'euro',
      [interfaces_1.BbitTranslationType.SYMBOL]: '€'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'EUR'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'EUR'
    }
  },
  base: interfaces_2.BbitUnitBases.CURRENCY,
  round: 0.01,
  value: 1,
  displayDecimalMinimalCount: 2,
  displayDecimalMaximalCount: 2,
  displayUnitBeforeAmount: true,
  offset: 0
}, {
  _id: '#USD',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'US Dollar',
      [interfaces_1.BbitTranslationType.SYMBOL]: '$'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'us dollars',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'us dollar',
      [interfaces_1.BbitTranslationType.SYMBOL]: '$'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'USD'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'USD'
    }
  },
  countries: ['#US'],
  base: interfaces_2.BbitUnitBases.CURRENCY,
  round: 0.01,
  value: 1,
  displayDecimalMinimalCount: 2,
  displayDecimalMaximalCount: 2,
  displayUnitBeforeAmount: true,
  offset: 0
}, {
  _id: '#GBP',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'GB Pfund',
      [interfaces_1.BbitTranslationType.SYMBOL]: '£'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'gb pounds',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'gb pounds',
      [interfaces_1.BbitTranslationType.SYMBOL]: '£'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'GBP'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'GBP'
    }
  },
  base: interfaces_2.BbitUnitBases.CURRENCY,
  round: 0.01,
  value: 1,
  displayDecimalMinimalCount: 2,
  displayDecimalMaximalCount: 2,
  displayUnitBeforeAmount: true,
  offset: 0
}, {
  _id: '#WIR',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    },
    [interfaces_1.BbitLanguage.FR_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    },
    [interfaces_1.BbitLanguage.FR_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'WIR'
    }
  },
  countries: ['#CH'],
  base: interfaces_2.BbitUnitBases.CURRENCY,
  round: 0.05,
  value: 1,
  displayDecimalMinimalCount: 2,
  displayDecimalMaximalCount: 2,
  displayUnitBeforeAmount: true,
  offset: 0
}, {
  _id: '#meter',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Metern',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Meter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'meters',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'meter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm'
    }
  },
  base: interfaces_2.BbitUnitBases.LENGTH,
  value: 1,
  offset: 0
}, {
  _id: '#inch',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Zoll'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'inches',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'inch',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'in'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Zoll'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Inches',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Inch'
    }
  },
  base: interfaces_2.BbitUnitBases.LENGTH,
  value: 0.0254,
  offset: 0
}, {
  _id: '#foot',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Fuss'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'feet',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'foot',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ft'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Fuss'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'feet',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'foot'
    }
  },
  base: interfaces_2.BbitUnitBases.LENGTH,
  value: 0.3048,
  offset: 0
}, {
  _id: '#yard',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Yard'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'yards',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'yard',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'yd'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Yard'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'yards',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'yard'
    }
  },
  base: interfaces_2.BbitUnitBases.LENGTH,
  value: 0.9144,
  offset: 0
}, {
  _id: '#mile',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Meilen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Meile'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'miles',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'mile',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mi'
    }
  },
  base: interfaces_2.BbitUnitBases.LENGTH,
  value: 1609.344,
  offset: 0
}, {
  _id: '#squaremeter',
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'meter2',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'meter2'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'meters2',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'meter2'
      }
    },
    short: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'm2'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'm2'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quadratmeter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm²'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'square meters',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'square meter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm²'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm²'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm²'
    }
  },
  base: interfaces_2.BbitUnitBases.SURFACE,
  value: 1,
  offset: 0
}, {
  _id: '#squareinch',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quadratzoll',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'in2'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'square inches',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'square inch',
      s: 'sq in',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'in2'
    }
  },
  base: interfaces_2.BbitUnitBases.SURFACE,
  value: 0.00064516,
  offset: 0
}, {
  _id: '#squarefoot',
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'fuss2'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'feet2',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'foot2'
      }
    },
    short: {
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'sq fts',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'sq ft'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quadratfuss',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ft2'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'square feet',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'square foot',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ft2'
    }
  },
  base: interfaces_2.BbitUnitBases.SURFACE,
  value: 0.09290304,
  offset: 0
}, {
  _id: '#squareyard',
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'yard2'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'yard2'
      }
    },
    short: {
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'sq yds',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'sq yd'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quadratyard',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'yd2'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'square yards',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'square yard',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'yd2'
    }
  },
  base: interfaces_2.BbitUnitBases.SURFACE,
  value: 0.83612736,
  offset: 0
}, {
  _id: '#squaremile',
  base: interfaces_2.BbitUnitBases.SURFACE,
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'meile2'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'mile2'
      }
    },
    short: {
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'sq mis',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'sq mi'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quadratmeilen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Quadratmeile',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mi2'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'square miles',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'square mile',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mi2'
    }
  },
  value: 2589988.110336,
  offset: 0
}, {
  _id: '#acre',
  base: interfaces_2.BbitUnitBases.SURFACE,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Acres',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Acre',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ac'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Acres',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Acre',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ac'
    }
  },
  value: 4046.86,
  offset: 0
}, {
  _id: '#hectare',
  base: interfaces_2.BbitUnitBases.SURFACE,
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'Hektaren'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Hektare',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Hektar',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ha'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'hectares',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'hectare',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ha'
    }
  },
  value: 10000,
  offset: 0
}, {
  _id: '#cubicmeter',
  base: interfaces_2.BbitUnitBases.VOLUME,
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikmeter'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'cubicmeters',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'cubicmeter'
      }
    },
    short: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'm3'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'm3'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikmeter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm³'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'cubic meters',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'cubic meter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'm³'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm³'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm³'
    }
  },
  value: 1,
  offset: 0
}, {
  _id: '#litre',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Liter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'l'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'litres',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'litre',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'l'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.001,
  offset: 0
}, {
  _id: '#cuin',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikinch',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'in3'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cubic inch',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cui'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 1.6387064e-5,
  offset: 0
}, {
  _id: '#cuft',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikfuss',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ft3'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cubic foot',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cft'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.028316846592,
  offset: 0
}, {
  _id: '#cuyd',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikyard',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'yd3'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cubic yard',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cys'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.764554857984,
  offset: 0
}, {
  _id: '#teaspoon',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Teelöffel',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'TL'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Teaspoon',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'tsp'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.000005,
  offset: 0
}, {
  _id: '#tablespoon',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Esslöffel',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'EL'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Tablespoon',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'tbsp'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.000015,
  offset: 0
}, {
  _id: '#fluidounce',
  base: interfaces_2.BbitUnitBases.VOLUME,
  aliases: {
    short: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'fl oz'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'fl oz'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Flüssigunzen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Flüssigunze',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'floz'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'fluid ounces',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'fluid ounce',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'floz'
    }
  },
  value: 0.00002957353,
  offset: 0
}, {
  _id: '#cubiccentimeter',
  base: interfaces_2.BbitUnitBases.VOLUME,
  aliases: {
    short: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'ccm'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'ccm'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kubikzentimeter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cm3'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'cubic centimeters',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'cubic centimeter',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cm3'
    }
  },
  value: 1e-6,
  offset: 0
}, {
  _id: '#us-cup',
  base: interfaces_2.BbitUnitBases.VOLUME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'US Cup'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'us cups',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'us cup'
    }
  },
  value: 0.0002365882,
  offset: 0
}, {
  _id: '#us-pint',
  base: interfaces_2.BbitUnitBases.VOLUME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'US Pinte',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Pinte'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'us pints',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'us pint',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'pt'
    }
  },
  value: 0.0004731765,
  offset: 0
}, {
  _id: '#us-gallon',
  base: interfaces_2.BbitUnitBases.VOLUME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'US Gallonen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'US Gallone',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Gallone'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'us gallons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'us gallon',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'gal'
    }
  },
  value: 0.003785412,
  offset: 0
}, {
  _id: 'us-oilbarrel',
  base: interfaces_2.BbitUnitBases.VOLUME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'US Barrel ÖL',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Barrel'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'us barrels',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'us barrel',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'barrel'
    }
  },
  value: 0.1589873,
  offset: 0
}, {
  _id: '#quart',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quart',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'qt'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Quart',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'qt'
    }
  },
  base: interfaces_2.BbitUnitBases.VOLUME,
  value: 0.0009463529,
  offset: 0
}, {
  _id: '#kmh',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kilometer pro Stunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'kmh'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'kilometers per hour',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'kmh'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'km/h'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'km/h'
    }
  },
  base: interfaces_2.BbitUnitBases.VELOCITY,
  value: 3.6,
  offset: 0
}, {
  _id: '#mps',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Meter pro Sekunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'kmh'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'meters per second',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'kmh'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm/s'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'm/s'
    }
  },
  base: interfaces_2.BbitUnitBases.VELOCITY,
  value: 1,
  offset: 0
}, {
  _id: '#decibel',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Dezibel',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'dB'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'decibel',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'dB'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'dB'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'dB'
    }
  },
  base: interfaces_2.BbitUnitBases.LOUDNESS,
  value: 0.1,
  offset: 0
}, {
  _id: '#gram',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Gramm',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'g'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'grams',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'gram',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'g'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'g'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'g'
    }
  },
  base: interfaces_2.BbitUnitBases.MASS,
  value: 0.001,
  offset: 0
}, {
  _id: '#ton',
  base: interfaces_2.BbitUnitBases.MASS,
  value: 1000,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Tonnen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Tonne',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 't'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'tons',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'ton',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 't'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 't'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 't'
    }
  },
  offset: 0
}, {
  _id: '#grain',
  aliases: {
    long: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'Messerspitzen',
        [interfaces_1.BbitTranslationType.SINGULAR]: 'Messerspitze'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Grains',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Grain',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'gr'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'grains',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'grain',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'gr'
    }
  },
  base: interfaces_2.BbitUnitBases.MASS,
  value: 64.79891e-6,
  offset: 0
}, {
  _id: '#ounce',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Unzen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Unze',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'oz'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'ounces',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'ounce',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'oz'
    }
  },
  base: interfaces_2.BbitUnitBases.MASS,
  value: 28.349523125e-3,
  offset: 0
}, {
  _id: '#pound',
  aliases: {
    short: {
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.ABBREVATION]: 'lbm'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pfund',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'pfd'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'pounds',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'pound',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'lb'
    }
  },
  base: interfaces_2.BbitUnitBases.MASS,
  value: 453.59237e-3,
  offset: 0
}, {
  _id: '#second',
  base: interfaces_2.BbitUnitBases.TIME,
  aliases: {
    short: {
      [interfaces_1.BbitLanguage.DE_CH]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'sek'
      },
      [interfaces_1.BbitLanguage.EN_US]: {
        [interfaces_1.BbitTranslationType.PLURAL]: 'sec'
      }
    }
  },
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Sekunden',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Sekunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 's'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'seconds',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'second',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 's'
    }
  },
  value: 1,
  offset: 0
}, {
  _id: '#minute',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Minuten',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Minute',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'min'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'minutes',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'minute',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'min'
    }
  },
  value: 60,
  offset: 0
}, {
  _id: '#hour',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Stunden',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Stunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'std',
      s: 'h'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'hours',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'hour',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'hr',
      s: 'h'
    }
  },
  value: 3600,
  offset: 0
}, {
  _id: '#day',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Tage',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Tag',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'd'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'days',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'day',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'd'
    }
  },
  value: 86400,
  offset: 0
}, {
  _id: '#businessDay',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Arbeitstage',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Arbeitstag',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'ad'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'business days',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'business day',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'bd'
    }
  },
  value: 86400,
  offset: 0
}, {
  _id: '#week',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wochen',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Woche'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'weeks',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'week'
    }
  },
  value: 7 * 86400,
  offset: 0
}, {
  _id: '#month',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Monate',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Monat'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'months',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'month'
    }
  },
  value: 2629800,
  offset: 0
}, {
  _id: '#year',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Jahre',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Jahr'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'years',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'year'
    }
  },
  value: 31557600,
  offset: 0
}, {
  _id: '#decade',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Jahrzehnte',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Jahrzehnt'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'decades',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'decade'
    }
  },
  value: 315576000,
  offset: 0
}, {
  _id: '#century',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Jahrhunderte',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Jahrhundert'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'centuries',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'century',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'c'
    }
  },
  value: 3155760000,
  offset: 0
}, {
  _id: '#millennium',
  base: interfaces_2.BbitUnitBases.TIME,
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Jahrtausende',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'Jahrtausend'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'milleniums',
      [interfaces_1.BbitTranslationType.SINGULAR]: 'millenium'
    }
  },
  value: 31557600000,
  offset: 0
}, {
  _id: '#hertz',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Hertz',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Hz'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'hertz',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'hz'
    }
  },
  base: interfaces_2.BbitUnitBases.FREQUENCY,
  value: 1,
  offset: 0
}, {
  _id: '#radian',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Radiant',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'rad'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Radian',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'rad'
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: 1,
  offset: 0
}, {
  _id: '#degree',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Grad',
      s: '°'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'degree',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'deg',
      s: '°'
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: null,
  offset: 0
}, {
  _id: '#gradian',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Gon',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'gon'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Gradian',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'grad'
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: null,
  offset: 0
}, {
  _id: '#cycle',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Turn',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'tr'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Cycle',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cyc'
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: null,
  offset: 0
}, {
  _id: '#arcsec',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Winkelsekunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'arcsec',
      s: '"'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Arcsecond',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'arcsec',
      s: '"'
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: null,
  offset: 0
}, {
  _id: '#arcmin',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Winkelminute',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'armin',
      s: "'"
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Arcminute',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'arcmin',
      s: "'"
    }
  },
  base: interfaces_2.BbitUnitBases.ANGLE,
  value: null,
  offset: 0
}, {
  _id: '#ampere',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Ampere',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'A'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Ampere',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'A'
    }
  },
  base: interfaces_2.BbitUnitBases.CURRENT,
  value: 1,
  offset: 0
}, {
  _id: '#kelvin',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kelvin',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'K'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kelvin',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'K'
    }
  },
  base: interfaces_2.BbitUnitBases.TEMPERATURE,
  value: 1,
  offset: 0
}, {
  _id: '#celsius',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Grad Celsius',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degC',
      s: '°C'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Degree celsius',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degC',
      s: '°C'
    }
  },
  base: interfaces_2.BbitUnitBases.FREQUENCY,
  value: 1,
  offset: 273.15
}, {
  _id: '#fahrenheit',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Grad Fahrenheit',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degF',
      s: '°F'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Degree fahrenheit',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degF',
      s: '°F'
    }
  },
  base: interfaces_2.BbitUnitBases.FREQUENCY,
  value: 1 / 1.8,
  offset: 459.67
}, {
  _id: '#rankine',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Grad Rankine',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degR',
      s: '°R'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Degree rankine',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'degR',
      s: '°R'
    }
  },
  base: interfaces_2.BbitUnitBases.FREQUENCY,
  value: 1 / 1.8,
  offset: 0
}, {
  _id: '#mole',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Mol',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mol'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Mole',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mol'
    }
  },
  base: interfaces_2.BbitUnitBases.AMOUNT_OF_SUBSTANCE,
  value: 1,
  offset: 0
}, {
  _id: '#candela',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Candela',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cd'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Candela',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cd'
    }
  },
  base: interfaces_2.BbitUnitBases.LUMINOUS_INTENSITY,
  value: 1,
  offset: 0
}, {
  _id: '#newton',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Newton',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'N'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Newton',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'N'
    }
  },
  base: interfaces_2.BbitUnitBases.FORCE,
  value: 1,
  offset: 0
}, {
  _id: '#dyne',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Dyn'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Dyne',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'dyn'
    }
  },
  base: interfaces_2.BbitUnitBases.FORCE,
  value: 0.00001,
  offset: 0
}, {
  _id: '#poundforce',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pound-force',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'lbf'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pound-force',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'lbf'
    }
  },
  base: interfaces_2.BbitUnitBases.FORCE,
  value: 4.4482216152605,
  offset: 0
}, {
  _id: '#kip',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kip'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Kip'
    }
  },
  base: interfaces_2.BbitUnitBases.FORCE,
  value: 4448.2216,
  offset: 0
}, {
  _id: '#joule',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Joule',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'J'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Joule',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'J'
    }
  },
  base: interfaces_2.BbitUnitBases.ENERGY,
  value: 1,
  offset: 0
}, {
  _id: '#erg',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Erg'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Erg'
    }
  },
  base: interfaces_2.BbitUnitBases.ENERGY,
  value: 1e-7,
  offset: 0
}, {
  _id: '#watthour',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wattstunde',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Wh'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Watt-hour',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Wh'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wh'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wh'
    }
  },
  base: interfaces_2.BbitUnitBases.ENERGY,
  value: 3600,
  offset: 0
}, {
  _id: '#british-thermal-unit',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'British thermal unit',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'BTU'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'British thermal unit',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'BTU'
    }
  },
  base: interfaces_2.BbitUnitBases.ENERGY,
  value: 1055.05585262,
  offset: 0
}, {
  _id: '#eletronvolt',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Elektronenvolt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'eV'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Electronvolt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'eV'
    }
  },
  base: interfaces_2.BbitUnitBases.ENERGY,
  value: 1.602176565e-19,
  offset: 0
}, {
  _id: '#watt',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Watt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'W'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Watt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'W'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'W'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'W'
    }
  },
  base: interfaces_2.BbitUnitBases.POWER,
  value: 1,
  offset: 0
}, {
  _id: '#watt-peak',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Watt (Peak)',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Wp'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Watt (Peak)',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Wp'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wp'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Wp'
    }
  },
  base: interfaces_2.BbitUnitBases.POWER,
  value: 1,
  offset: 0
}, {
  _id: '#hp',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'PS'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'HP'
    }
  },
  base: interfaces_2.BbitUnitBases.POWER,
  value: 745.6998715386,
  offset: 0
}, {
  _id: '#volt-ampere',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Voltampere',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'VA'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Volt-ampere',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'VA'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'VA'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'VA'
    }
  },
  base: interfaces_2.BbitUnitBases.POWER,
  value: 1,
  offset: 0
}, {
  _id: '#pascal',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pascal',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Pa'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pascal',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Pa'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 1,
  offset: 0
}, {
  _id: '#psi',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'psi'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'psi'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 6894.75729276459,
  offset: 0
}, {
  _id: '#atm',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Physikalische Atmosphäre',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'atm'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Standard atmosphere',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'atm'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 101325,
  offset: 0
}, {
  _id: '#bar',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bar'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bar'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 100000,
  offset: 0
}, {
  _id: '#torr',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Torr'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Torr'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 133.322,
  offset: 0
}, {
  _id: '#mmHg',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Millimeter Quecksilbersäule',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mmHg'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Millimetre of mercury',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mmHg'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 133.322,
  offset: 0
}, {
  _id: '#mmH20',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Millimeter Wassersäule',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mmH2O'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Millimetre of water',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'mmH2O'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 9.80665,
  offset: 0
}, {
  _id: '#cmH20',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Zentimeter Wassersäule',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cmH2O'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Centimetre of water',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'cmH2O'
    }
  },
  base: interfaces_2.BbitUnitBases.PRESSURE,
  value: 98.0665,
  offset: 0
}, {
  _id: '#coulomb',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Coulomb'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Coulomb'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_CHARGE,
  value: 1,
  offset: 0
}, {
  _id: '#farad',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Farad',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'F'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Farad',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'F'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_CAPACITANCE,
  value: 1,
  offset: 0
}, {
  _id: '#volt',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Volt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'V'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Volt',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'V'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_POTENTIAL,
  value: 1,
  offset: 0
}, {
  _id: '#ohm',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Ohm',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Ω'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Ohm',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Ω'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_RESISTANCE,
  value: 1,
  offset: 0
}, {
  _id: '#henry',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Henry'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Henry'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_INDUCTANCE,
  value: 1,
  offset: 0
}, {
  _id: '#siemens',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Siemens'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Siemens'
    }
  },
  base: interfaces_2.BbitUnitBases.ELECTRIC_CONDUCTANCE,
  value: 1,
  offset: 0
}, {
  _id: '#weber',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Weber'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Weber'
    }
  },
  base: interfaces_2.BbitUnitBases.MAGNETIC_FLUX,
  value: 1,
  offset: 0
}, {
  _id: '#tesla',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Tesla'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Tesla'
    }
  },
  base: interfaces_2.BbitUnitBases.MAGNETIC_FLUX_DENSITY,
  value: 1,
  offset: 0
}, {
  _id: '#bits',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bits',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'b'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bits',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'b'
    }
  },
  base: interfaces_2.BbitUnitBases.BIT,
  value: 1,
  offset: 0
}, {
  _id: '#bytes',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bytes',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'B'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Bytes',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'B'
    }
  },
  base: interfaces_2.BbitUnitBases.BIT,
  value: 8,
  offset: 0
}, {
  _id: '#flatRate',
  name: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pauschal',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Pau.'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Flat rate',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Fr'
    }
  },
  formatAs: {
    [interfaces_1.BbitLanguage.DE_CH]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'Pau.',
      [interfaces_1.BbitTranslationType.ABBREVATION]: 'Pau.'
    },
    [interfaces_1.BbitLanguage.EN_US]: {
      [interfaces_1.BbitTranslationType.PLURAL]: 'fr'
    }
  },
  base: interfaces_2.BbitUnitBases.COUNT,
  value: 1,
  offset: 0
}];
