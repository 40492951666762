"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitFileUtils = void 0;
const luxon_1 = require("luxon");
const string_1 = require("../primitives/string");
const utils_1 = require("../utils/utils");
class BbitFileUtils {
  static doesPatternMatch(transfer, pattern) {
    const items = transfer.items;
    if (items && items.length > 0) {
      let len = items.length;
      while (len--) {
        if (!BbitFileUtils.validatePattern(items[len], pattern)) {
          return false;
        }
      }
    }
    return true;
  }
  static formatFileState(state) {
    var _a, _b;
    let value = ((_a = state.payload) === null || _a === void 0 ? void 0 : _a.fileName) || ((_b = state.payload) === null || _b === void 0 ? void 0 : _b.s3Key) || '';
    const parts = value.split('/');
    value = parts[parts.length - 1];
    return value;
  }
  static formatBitrate(bits) {
    if (typeof bits !== 'number') {
      return '';
    }
    if (bits >= 1000000000) {
      return (bits / 1000000000).toFixed(2) + ' Gbit/s';
    }
    if (bits >= 1000000) {
      return (bits / 1000000).toFixed(2) + ' Mbit/s';
    }
    if (bits >= 1000) {
      return (bits / 1000).toFixed(2) + ' kbit/s';
    }
    return bits.toFixed(2) + ' bit/s';
  }
  static formatDuration(input) {
    const seconds = Math.ceil(input);
    const duration = luxon_1.Duration.fromObject({
      seconds
    }).shiftTo('minutes', 'seconds').toObject();
    return `${duration.minutes > 0 ? duration.minutes + 'min ' : ''}${duration.seconds}s`;
  }
  static createAttachmentFromFile(fileSource, id) {
    return {
      attachmentId: id || utils_1.BbitUtils.makeId({
        leadingHashtag: false
      }),
      contentType: fileSource.type,
      contentLength: fileSource.size,
      fileName: fileSource.name
    };
  }
  static extractFiles(source, event) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!source) {
        return {
          files: [],
          event
        };
      }
      let html;
      try {
        html = source && source.getData && source.getData('text/html');
      } catch (e) {}
      const extractRes = yield BbitFileUtils.extractFilesFromEvent(source.items, source.files);
      if (extractRes.files.length > 0) {
        return {
          files: extractRes.files,
          event
        };
      }
      const extracted = yield BbitFileUtils.extractFilesFromHtml(html);
      return {
        files: extracted,
        event
      };
    });
  }
  static extractFilesFromHtml(html) {
    if (typeof html !== 'string') {
      return Promise.reject(null);
    }
    const urls = [];
    html.replace(/<(img src|img [^>]* src) *=\"([^\"]*)\"/gi, (m, n, src) => {
      urls.push(src);
      return '(replaced)';
    });
    console.log('extracted images from html', JSON.stringify(urls));
    const promises = urls.map(url => BbitFileUtils.urlToBlob(url));
    return Promise.all(promises);
  }
  static urlToBlob(url) {
    console.error('ToDo urlToBlob');
    return Promise.reject('toDo');
  }
  static validatePattern(file, val) {
    if (!val) {
      return true;
    }
    const pattern = string_1.BbitString.globStringToRegex(val);
    let valid = true;
    if (pattern.regexp && pattern.regexp.length) {
      const regexp = new RegExp(pattern.regexp, 'i');
      valid = file.type != null && regexp.test(file.type) || file.name != null && regexp.test(file.name);
    }
    let len = pattern.excludes.length;
    while (len--) {
      const exclude = new RegExp(pattern.excludes[len], 'i');
      valid = valid && (file.type == null || exclude.test(file.type)) && (file.name == null || exclude.test(file.name));
    }
    return valid;
  }
  static extractFilesFromEvent(items, fileList) {
    return __awaiter(this, void 0, void 0, function* () {
      const files = [];
      let totalSize = 0;
      if (items && items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].webkitGetAsEntry && items[i].webkitGetAsEntry() && items[i].webkitGetAsEntry().isDirectory) {
            const entry = items[i].webkitGetAsEntry();
            if (entry != null) {}
          } else {
            const f = items[i].getAsFile();
            if (f != null) {
              files.push(f);
              totalSize += f.size;
            }
          }
        }
      } else {
        if (fileList != null) {
          for (let j = 0; j < fileList.length; j++) {
            const file = fileList.item(j);
            if (file.type || file.size > 0) {
              files.push(file);
              totalSize += file.size;
            }
          }
        }
      }
      return {
        files,
        totalSize
      };
    });
  }
}
exports.BbitFileUtils = BbitFileUtils;
