"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitRxJS = void 0;
const rxjs_1 = require("rxjs");
class BbitRxJS {
  static exponentialBackoffRetry(config) {
    return observable => {
      const {
        maxRetryAttempts = Number.POSITIVE_INFINITY,
        baseMs = 1000,
        maxDelayMs = 10 * 1000,
        onError
      } = config;
      return new rxjs_1.Observable(subscriber => {
        let subscription = null;
        let attempt = 0;
        let timeout = null;
        const stopTimeout = () => {
          if (timeout != null) {
            clearTimeout(timeout);
          }
          timeout = null;
        };
        const doTry = () => {
          subscription = observable.subscribe({
            next: value => {
              if (subscriber.closed) {
                return;
              }
              attempt = 0;
              subscriber.next(value);
            },
            complete: () => {
              if (subscriber.closed) {
                return;
              }
              subscriber.complete();
            },
            error: error => {
              if (subscriber.closed) {
                return;
              }
              stopTimeout();
              if (attempt > maxRetryAttempts || !(error.retryable || config.retryAllErrors)) {
                return subscriber.error(error);
              }
              const jitter = Math.round(Math.random() * baseMs);
              const backoff = Math.pow(2, attempt) * baseMs;
              const delayMs = jitter + Math.min(backoff, maxDelayMs);
              try {
                if (onError) {
                  onError({
                    error,
                    attempt,
                    delayMs
                  });
                }
                attempt++;
                timeout = setTimeout(doTry, delayMs);
              } catch (err) {
                return subscriber.error(err);
              }
            }
          });
        };
        doTry();
        return () => {
          subscriber.complete();
          if (subscription) {
            subscription.unsubscribe();
          }
          stopTimeout();
        };
      });
    };
  }
}
exports.BbitRxJS = BbitRxJS;
