"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitGenericHttpResponse = exports.BbitHttpResponse = void 0;
const luxon_1 = require("luxon");
const session_1 = require("../auth/session");
const crypto_1 = require("../crypto/crypto");
const file_1 = require("../global-context/file");
const s3_helper_1 = require("../global-context/s3-helper");
const log_1 = require("../log/log");
const date_time_1 = require("../primitives/date-time");
const error_1 = require("../primitives/error");
const object_1 = require("../primitives/object");
const string_1 = require("../primitives/string");
const interfaces_1 = require("./interfaces");
const mime_1 = require("./mime");
class BbitHttpResponse {
  static isStatusCodeInGroup(code, groups) {
    return Array.isArray(groups) ? groups.includes(Math.floor(code / 100)) : Math.floor(code / 100) === groups;
  }
  static encodeUrl(url) {
    const ENCODE_CHARS_REGEXP = /(?:[^\x21\x25\x26-\x3B\x3D\x3F-\x5B\x5D\x5F\x61-\x7A\x7E]|%(?:[^0-9A-Fa-f]|[0-9A-Fa-f][^0-9A-Fa-f]|$))+/g;
    const UNMATCHED_SURROGATE_PAIR_REGEXP = /(^|[^\uD800-\uDBFF])[\uDC00-\uDFFF]|[\uD800-\uDBFF]([^\uDC00-\uDFFF]|$)/g;
    const UNMATCHED_SURROGATE_PAIR_REPLACE = '$1\uFFFD$2';
    return String(url).replace(UNMATCHED_SURROGATE_PAIR_REGEXP, UNMATCHED_SURROGATE_PAIR_REPLACE).replace(ENCODE_CHARS_REGEXP, encodeURI);
  }
  static encodeBody(body) {
    return typeof body === 'object' ? string_1.BbitString.stringifyToJson(body) : body && typeof body !== 'string' ? '' + body : body ? body : '';
  }
  static escapeHtml(html) {
    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    };
    return html.replace(/[&<>"']/g, s => entityMap[s]);
  }
  constructor(request, sessionAuth, sessionContext, fileContext, startTime) {
    this.request = request;
    this.sessionAuth = sessionAuth;
    this.sessionContext = sessionContext;
    this.fileContext = fileContext;
    this._statusCode = interfaces_1.BbitHttpCodes.Code200_OK;
    this._headers = {};
    this._serializer = body => string_1.BbitString.stringifyToJson(body);
    this._enableEtag = true;
    this._response = null;
    this._timings = {};
    this._log = log_1.BbitLog.scope({
      class: 'BbitAbstractHttpResponse'
    });
    this.session = session_1.BbitSession.fromRequest(sessionAuth, request);
    this.startTimer('total', 'Total request time', startTime);
  }
  setTimer(timerId, description, started, ended) {
    this._timings[timerId] = {
      started: started,
      ended: ended,
      description
    };
  }
  startTimer(timerId, description, started) {
    this._timings[timerId] = {
      started: started || date_time_1.BbitDateTime.getNowInMs(),
      ended: null,
      description
    };
  }
  endTimer(timerId) {
    if (this._timings[timerId]) {
      this._timings[timerId].ended = date_time_1.BbitDateTime.getNowInMs();
    }
  }
  status(code) {
    this._statusCode = code;
    return this;
  }
  header(key, value, append) {
    const _key = key.toLowerCase();
    const _values = value ? Array.isArray(value) ? value : [value] : [''];
    this._headers[_key] = append ? this.hasHeader(_key) ? this._headers[_key].concat(_values) : _values : _values;
    return this;
  }
  getMultiValueHeader(key, defaultIfUndefined = []) {
    return this._headers[key.toLowerCase()] ? this._headers[key.toLowerCase()] : defaultIfUndefined;
  }
  getHeader(key, defaultIfUndefined = undefined) {
    return this._headers[key.toLowerCase()] ? this._headers[key.toLowerCase()].toString() : defaultIfUndefined;
  }
  getHeaders() {
    return Object.keys(this._headers).reduce((acc, key) => Object.assign(acc, {
      [key]: key === 'set-cookie' ? this._headers[key].slice(-1)[0] : this._headers[key].toString()
    }), {});
  }
  getMultiValueHeaders() {
    return Object.assign({}, this._headers);
  }
  getMultiValueHeaderSerializedV2() {
    return Object.keys(this._headers).reduce((acc, key) => {
      if (key === 'set-cookie') {
        acc.cookies = acc.cookies.concat(this._headers[key]);
        return acc;
      }
      acc.headers = Object.assign(acc.headers, {
        [key]: this._headers[key].join(',')
      });
      return acc;
    }, {
      headers: {},
      cookies: []
    });
  }
  removeHeader(key) {
    delete this._headers[key.toLowerCase()];
    return this;
  }
  hasHeader(key) {
    return this.getHeader(key || '') !== undefined;
  }
  json(body) {
    this.etag(true);
    this.header('Content-Type', 'application/json').send(this._serializer(body));
  }
  html(body) {
    this.header('Content-Type', 'text/html').send(body);
  }
  location(path, expires) {
    if (s3_helper_1.BbitS3Helper.isS3Url(path)) {
      path = this.getSignedS3Url(path, expires);
    }
    this.header('Location', BbitHttpResponse.encodeUrl(path));
    return this;
  }
  redirect(path, statusCode = interfaces_1.BbitHttpCodes.Code302_Found, expires) {
    if (Math.floor(statusCode / 100) !== 3) {
      this.error(new error_1.BbitError('invalid-redirecton-statuscode', {
        statusCode
      }));
    }
    try {
      if (s3_helper_1.BbitS3Helper.isS3Url(path)) {
        path = this.getSignedS3Url(path, expires);
      }
      this.location(path).status(statusCode);
      const url = BbitHttpResponse.escapeHtml(this.getHeader('Location'));
      this.html(`<p>${statusCode} Redirecting to <a href="${url}">${url}</a></p>`);
    } catch (e) {
      this.error(e);
    }
  }
  getSignedS3Url(path, expires) {
    var _a, _b;
    const params = s3_helper_1.BbitS3Helper.parseS3Url(path);
    let Expires = typeof expires === 'string' ? Math.floor(Number.parseFloat(expires)) : expires;
    if (!Expires || Number.isNaN(Expires)) {
      Expires = 900;
    }
    if (!((_a = this.fileContext) === null || _a === void 0 ? void 0 : _a.signUrlSync)) {
      throw new error_1.BbitError('sign-url-not-supported', {
        reason: 'signUrlSync not implemented in fileContext'
      });
    }
    return (_b = this.fileContext) === null || _b === void 0 ? void 0 : _b.signUrlSync(file_1.BbitFileAction.GET, {
      s3Bucket: params.s3Bucket,
      s3Key: params.s3Key
    });
  }
  cookie(opts) {
    let cookieString = (typeof opts.name !== 'string' ? opts.name.toString() : opts.name) + '=' + encodeURIComponent(BbitHttpResponse.encodeBody(opts.value));
    cookieString += opts.domain ? '; Domain=' + opts.domain : '';
    cookieString += opts.expires && typeof opts.expires.toUTCString === 'function' ? '; Expires=' + opts.expires.toUTCString() : '';
    cookieString += opts.httpOnly && opts.httpOnly === true ? '; HttpOnly' : '';
    cookieString += opts.maxAge && !Number.isNaN(opts.maxAge) ? '; MaxAge=' + (opts.maxAge / 1000 | 0) + (!opts.expires ? '; Expires=' + new Date(Date.now() + opts.maxAge).toUTCString() : '') : '';
    cookieString += opts.path ? '; Path=' + opts.path : '; Path=/';
    cookieString += opts.secure && opts.secure === true ? '; Secure' : '';
    cookieString += opts.sameSite !== undefined ? '; SameSite=' + (opts.sameSite === true ? 'Strict' : opts.sameSite === false ? 'Lax' : opts.sameSite) : '';
    this.header('Set-Cookie', cookieString, true);
    return this;
  }
  clearCookie(opts) {
    const options = Object.assign(opts, {
      value: '',
      expires: new Date(1),
      maxAge: -1000
    });
    return this.cookie(options);
  }
  getFilename(path) {
    return path === null || path === void 0 ? void 0 : path.trim().split('\\').pop().split('/').pop();
  }
  getFileExt(path) {
    return path === null || path === void 0 ? void 0 : path.split('.').pop().trim();
  }
  attachment(filepath) {
    const name = this.getFilename(filepath);
    this.header('Content-Disposition', 'attachment' + (name ? '; filename="' + name + '"' : ''));
    if (name) {
      this.contentType(this.getFileExt(name));
    }
    return this;
  }
  download(source) {
    return __awaiter(this, void 0, void 0, function* () {
      this.attachment(source.fileName ? source.fileName : typeof source.file === 'string' ? source.file : source.file.s3Key ? source.file.s3Key : null);
      return this.sendFile(source);
    });
  }
  sendFile(opts) {
    return __awaiter(this, void 0, void 0, function* () {
      let buffer;
      try {
        if (s3_helper_1.BbitS3Helper.isS3Url(opts.file)) {
          const params = s3_helper_1.BbitS3Helper.parseS3Url(opts.file);
          const data = yield this.fileContext.get({
            s3Bucket: params.s3Bucket,
            s3Key: params.s3Key
          });
          buffer = data.body;
          this.modified(luxon_1.DateTime.fromISO(data.lastModified));
          this.contentType(data.contentType);
          this.header('ETag', data.etag);
        } else if (typeof opts.file === 'string') {
          throw new error_1.BbitError('file-support-disabled', {
            reason: 'use S3'
          });
        } else if (Buffer.isBuffer(opts.file)) {
          buffer = opts.file;
        } else {
          throw new error_1.BbitError('invalid-file', {
            path: opts.file
          });
        }
        this.send(buffer);
      } catch (e) {
        if (e.code === 'ENOENT') {
          this.error(new error_1.BbitError('no-such-file', {
            path: opts.file
          }));
        } else {
          this.error(e);
        }
      }
    });
  }
  contentType(type) {
    const cleaned = type.trim().replace(/^\./, '');
    if (/.*\/.*/.test(cleaned)) {
      type = type.trim();
    } else {
      type = mime_1.BbitMime.global().lookup(type) || '';
    }
    if (type && type.length > 0) {
      this.header('Content-Type', type);
    }
    return this;
  }
  cors(opts) {
    var _a;
    if (!opts) {
      opts = {};
    }
    const acao = this.getHeader('Access-Control-Allow-Origin', '*');
    const acam = this.getHeader('Access-Control-Allow-Methods', 'GET, PUT, POST, DELETE, OPTIONS');
    const acah = this.getHeader('Access-Control-Allow-Headers', 'Content-Type, Authorization, Content-Length, X-Requested-With, x-bbit-log');
    const etag = this.getHeader('Access-Control-Expose-Headers', 'Etag, Content-Encoding, x-bbit-request-log, x-bbit-requestid, x-bbit-requestno, x-bbit-auth-required-actions, x-bbit-auth-required-resources');
    this.header('Access-Control-Allow-Origin', opts.origin ? opts.origin : acao);
    this.header('Access-Control-Allow-Methods', opts.methods && opts.methods.length > 0 ? opts.methods.join(', ') : acam);
    this.header('Access-Control-Allow-Headers', opts.headers && opts.headers.length > 0 ? opts.headers.join(', ') : acah);
    if (opts.maxAge && !Number.isNaN(opts.maxAge)) {
      this.header('Access-Control-Max-Age', (opts.maxAge || 0).toString());
    }
    if (opts.credentials) this.header('Access-Control-Allow-Credentials', 'true');
    this.header('Access-Control-Expose-Headers', ((_a = opts.exposeHeaders) === null || _a === void 0 ? void 0 : _a.length) > 0 ? opts.exposeHeaders.join(', ') : etag);
    return this;
  }
  etag(enable) {
    this._enableEtag = !!enable;
    return this;
  }
  generateEtag(data) {
    return crypto_1.BbitCrypto.singleton().sha256AsHex(data);
  }
  cache(maxAge, isPrivate = false) {
    if (maxAge.get('seconds') < 1) {
      this.header('Cache-Control', 'no-cache, no-store, must-revalidate');
    } else {
      this.header('Cache-Control', (isPrivate === true ? 'private, ' : '') + 'max-age=' + Math.floor(maxAge.get('seconds')));
      this.header('Expires', luxon_1.DateTime.utc().plus(maxAge).toISO());
    }
    return this;
  }
  modified(date) {
    if (!date || !date.isValid) {
      return this;
    }
    this.header('Last-Modified', date.toUTC().toISO());
    return this;
  }
  send(input) {
    var _a, _b, _c, _d;
    if (this._response) {
      this._log.warning(new error_1.BbitError('response-already-sent', {
        reason: 'please do not call send() twice'
      }));
      return;
    }
    let body;
    let contentLength = 0;
    let contentType = null;
    let isBase64Encoded = false;
    if (Buffer.isBuffer(input)) {
      contentLength = input.length;
      body = input.toString('base64');
      isBase64Encoded = true;
      contentType = 'application/octet-stream';
    } else {
      switch (true) {
        case typeof body === 'object':
          contentType = 'application/json';
          break;
        default:
          contentType = 'text/plain';
          break;
      }
      body = BbitHttpResponse.encodeBody(input);
      contentLength = body.length;
    }
    if (this.request.headers['origin'] && !this.hasHeader('Access-Control-Allow-Origin')) {
      this.cors({
        origin: this.request.headers['origin'],
        credentials: !!this.request.headers.Authorization
      });
    }
    if (!this.hasHeader('Content-Type')) {
      this.header('Content-Type', mime_1.BbitMime.global().lookup(this.request.path, contentType));
    }
    if (!this.hasHeader('x-bbit-request-id')) {
      this.header('x-bbit-requestid', this.request.requestId);
    }
    if (!this.hasHeader('x-bbit-request-no')) {
      this.header('x-bbit-requestno', `${this.request.requestNumber}`);
    }
    if (!this.hasHeader('x-bbit-request-log') && this.request.requestLog) {
      this.header('x-bbit-request-log', `${this.request.requestLog}`);
    }
    if (!this.hasHeader('Server-Timing')) {
      const timings = Object.keys(this._timings).map(t => {
        var _a;
        if (!t || t.length === 0) {
          return null;
        }
        const timer = this._timings[t];
        if (!timer.ended) {
          this.endTimer(t);
        }
        const name = t.replace(/[,;"]/gi, '');
        const duration = timer.ended - timer.started;
        const output = ((_a = timer.description) === null || _a === void 0 ? void 0 : _a.length) > 0 ? `${name}; dur=${duration.toFixed(3)}` : `${name}; dur=${duration.toFixed(3)}; desc="${timer.description.replace(/"/gi, '\\"')}")}"`;
        return output;
      }).filter(Boolean);
      if (timings.length > 0) {
        this.header('Server-Timing', timings.join(', '));
      }
    }
    if (((_a = this.request) === null || _a === void 0 ? void 0 : _a.headers['if-none-match']) && ((_b = this.request) === null || _b === void 0 ? void 0 : _b.headers['if-none-match']) === this.getHeader('etag')) {
      this.status(304);
      body = '';
      contentLength = 0;
    }
    if (((_c = this.request) === null || _c === void 0 ? void 0 : _c.method) === 'HEAD') {
      body = '';
      contentLength = 0;
    }
    const MAX_CONTENT_LENGTH = 6 * 1000 * 1000;
    if (contentLength > MAX_CONTENT_LENGTH) {
      this.error(new error_1.BbitError('response-content-size-too-big', {
        contentLength,
        MAX_CONTENT_LENGTH
      }));
      return;
    }
    if (this._enableEtag && ['GET', 'HEAD'].includes(this.request.method) && !this.hasHeader('etag') && BbitHttpResponse.isStatusCodeInGroup(this._statusCode, 2)) {
      this.header('etag', `"${this.generateEtag(body)}"`);
    }
    this._response = Object.assign({}, this.request.interface === 'apigatewayv2' || this.request.interface === 'raw' ? Object.assign({}, this.getMultiValueHeaderSerializedV2()) : this.request.hasMultiValueSupport ? {
      multiValueHeaders: this._headers
    } : {
      headers: this.getHeaders()
    }, {
      statusCode: this._statusCode,
      body,
      isBase64Encoded
    }, this.request.interface === 'alb' ? {
      statusDescription: `${this._statusCode} ${((_d = interfaces_1.BbitHttpCodes[this._statusCode]) === null || _d === void 0 ? void 0 : _d.substr(8)) || ''}`
    } : {});
  }
  getResponse() {
    return this._response;
  }
  getTimers() {
    return Object.keys(this._timings).reduce((acc, key) => Object.assign(Object.assign({}, acc), {
      [key]: this._timings[key].ended ? this._timings[key].ended - this._timings[key].started : null
    }), {});
  }
  error(error, code, payload) {
    var _a, _b;
    this._log.error('terminating request with error: ', error);
    if (error && error.hasOwnProperty && error.hasOwnProperty('code') && error.hasOwnProperty('params')) {
      this._statusCode = BbitHttpResponse.isStatusCodeInGroup((_a = error.params) === null || _a === void 0 ? void 0 : _a.statusCode, [4, 5]) ? (_b = error.params) === null || _b === void 0 ? void 0 : _b.statusCode : BbitHttpResponse.isStatusCodeInGroup(code || 0, [4, 5]) ? code : interfaces_1.BbitHttpCodes.Code400_BadRequest;
      this.json(Object.assign(Object.assign({}, payload || {}), {
        error: object_1.BbitObject.serialize(error),
        errorCode: error.code,
        retryable: !!error.retryable
      }));
      return;
    }
    this._statusCode = BbitHttpResponse.isStatusCodeInGroup(code || 0, [4, 5]) ? code : interfaces_1.BbitHttpCodes.Code400_BadRequest;
    this.json(Object.assign(Object.assign({}, payload || {}), {
      error: object_1.BbitObject.serialize(error),
      retryable: false
    }));
    return;
  }
}
exports.BbitHttpResponse = BbitHttpResponse;
class BbitGenericHttpResponse extends BbitHttpResponse {
  getSignedS3Url(path, expires) {
    return (typeof path === 'string' ? path : `s3://${path.bucket}/${path.key}`) + '?expires=' + expires;
  }
  sendFile(opts) {
    throw new error_1.BbitError('sendFile not available on GenericHttpResponse', {
      opts
    });
  }
}
exports.BbitGenericHttpResponse = BbitGenericHttpResponse;
