"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitCrypto = void 0;
const JsHashes = __importStar(require("jshashes"));
const utils_1 = require("../utils/utils");
class BbitCrypto {
  static singleton() {
    if (!BbitCrypto._singleton) {
      BbitCrypto._singleton = new BbitCrypto();
    }
    return BbitCrypto._singleton;
  }
  constructor() {
    var _a, _b, _c;
    this.platform = 'unknown';
    switch (true) {
      case utils_1.BbitUtils.isNode():
        this.platform = 'node';
        this._nodeCrypto = eval('require("crypto")');
        break;
      case utils_1.BbitUtils.isElectron():
      case typeof window !== 'undefined':
        this._webCryptoSubtle = ((_a = window === null || window === void 0 ? void 0 : window.crypto) === null || _a === void 0 ? void 0 : _a.subtle) || ((_b = window === null || window === void 0 ? void 0 : window.crypto) === null || _b === void 0 ? void 0 : _b.webkitSubtle) || ((_c = window === null || window === void 0 ? void 0 : window.msCrypto) === null || _c === void 0 ? void 0 : _c.Subtle);
        this.platform = !!this._webCryptoSubtle ? 'webcrypto' : 'unknown';
        break;
    }
  }
  md5AsHex(input) {
    switch (this.platform) {
      case 'node':
        return this._nodeCrypto.createHash('md5').update(input).digest('hex');
      default:
        return new JsHashes.MD5().hex(input);
    }
  }
  sha256AsHex(input) {
    switch (this.platform) {
      case 'node':
        return this._nodeCrypto.createHash('sha256').update(input).digest('hex');
      default:
        return new JsHashes.SHA256().hex(input);
    }
  }
  hmacSha256AsBase64(input, secret) {
    switch (this.platform) {
      case 'node':
        return this._nodeCrypto.createHmac('sha256', secret).update(input).digest('base64');
      default:
        return new JsHashes.SHA256().b64_hmac(input, secret);
    }
  }
}
exports.BbitCrypto = BbitCrypto;
