"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitUnit = void 0;
const luxon_1 = require("luxon");
const i18n_1 = require("../i18n/i18n");
const date_time_1 = require("../primitives/date-time");
const error_1 = require("../primitives/error");
const number_1 = require("../primitives/number");
const object_1 = require("../primitives/object");
const result_1 = require("../primitives/result");
const string_1 = require("../primitives/string");
const interfaces_1 = require("./interfaces");
const unit_mapping_1 = require("./unit-mapping");
const unit_system_1 = require("./unit-system");
class BbitUnit {
  static roundMarketing(input, precision) {
    if (!input) {
      return 0;
    }
    if (!precision) {
      precision = 0.1;
    }
    const factor = 1 / precision;
    const firstFractionalDigit = Math.floor(input * factor) % 10;
    if (firstFractionalDigit > 50) {
      return Math.floor(input) + 0.9;
    }
    if (firstFractionalDigit > 10) {
      return Math.floor(input) + 0.5;
    }
    if (firstFractionalDigit >= 0) {
      return Math.floor(input) - 0.1;
    }
    if (firstFractionalDigit > -10) {
      return Math.floor(input) - 0.5;
    }
    if (firstFractionalDigit > -50) {
      return Math.floor(input) - 0.9;
    }
    return Math.floor(input);
  }
  constructor(amount, unit = 'none', unitSystem = undefined, convertedFrom = undefined, referenceDate = undefined) {
    this.unitSystem = unitSystem;
    if (!this.unitSystem) {
      this.unitSystem = unit_system_1.bbitDefaultUnitSystem;
    }
    this.unitDef = this.unitSystem.determineUnit(unit);
    if (!this.unitDef) {
      throw new error_1.BbitError('unknown-unit', {
        unit
      });
    }
    this.unit = this.unitDef.name;
    this.amount = amount || 0;
    this.referenceDate = referenceDate;
    this.convertedFrom = convertedFrom;
  }
  static isEqual(unitA, unitB) {
    if (!unitA && !unitB) {
      return true;
    }
    if (!unitA || !unitB) {
      return false;
    }
    if (unitA.amount === 0 && unitB.amount === 0) {
      return true;
    }
    if (unitA.unitSystem !== unitB.unitSystem) {
      return false;
    }
    return BbitUnit.isSameUnit(unitA.unitDef, unitB.unitDef, {
      unitSystem: unitA.unitSystem
    }) && unitA.amount === unitB.amount;
  }
  static min(units, options) {
    if (!units || units.length === 0) {
      return undefined;
    }
    let minUnit = units[0];
    for (let i = 1; i < units.length; i++) {
      let unitB = units[i];
      if (!BbitUnit.isSameUnit(minUnit.unitDef, unitB.unitDef, Object.assign({
        unitSystem: minUnit.unitSystem
      }, options || {}))) {
        unitB = unitB.convertTo(minUnit.unit, Object.assign({
          referenceDate: minUnit.referenceDate
        }, options || {}));
      }
      if (unitB.amount < minUnit.amount) {
        minUnit = unitB;
      }
    }
    return minUnit;
  }
  static max(units, options) {
    if (!units || units.length === 0) {
      return undefined;
    }
    let maxUnit = units[0];
    for (let i = 1; i < units.length; i++) {
      let unitB = units[i];
      if (!BbitUnit.isSameUnit(maxUnit.unitDef, unitB.unitDef, Object.assign({
        unitSystem: maxUnit.unitSystem
      }, options || {}))) {
        unitB = unitB.convertTo(maxUnit.unit, Object.assign({
          referenceDate: maxUnit.referenceDate
        }, options || {}));
      }
      if (unitB.amount > maxUnit.amount) {
        maxUnit = unitB;
      }
    }
    return maxUnit;
  }
  static isSameAmount(unitA, unitB, options) {
    if (!unitA && !unitB) {
      return true;
    }
    if (!unitA || !unitB) {
      return false;
    }
    if (unitA.unitSystem !== unitB.unitSystem) {
      return false;
    }
    if (!BbitUnit.isSameUnit(unitA.unitDef, unitB.unitDef, Object.assign({
      unitSystem: unitA.unitSystem
    }, options || {}))) {
      try {
        unitB = unitB.convertTo(unitA.unit, Object.assign({
          referenceDate: unitA.referenceDate
        }, options || {}));
      } catch (err) {
        return false;
      }
    }
    if (BbitUnit.isSameUnit(unitA.unitDef, unitB.unitDef, Object.assign({
      unitSystem: unitA.unitSystem
    }, options || {}))) {
      return unitA.amount === unitB.amount;
    }
    return false;
  }
  static isSameUnit(unitA, unitB, options) {
    if (!unitA && !unitB) {
      return true;
    }
    if (!unitA || !unitB) {
      return false;
    }
    const unitSystem = (options === null || options === void 0 ? void 0 : options.unitSystem) ? options.unitSystem : unit_system_1.bbitDefaultUnitSystem;
    const a = typeof unitA === 'string' ? unitSystem.determineUnit(unitA) : BbitUnit.isBbitUnit(unitA) ? unitA.unitDef : unitA;
    const b = typeof unitB === 'string' ? unitSystem.determineUnit(unitB) : BbitUnit.isBbitUnit(unitB) ? unitB.unitDef : unitB;
    if ((a === null || a === void 0 ? void 0 : a.unit._id) === (b === null || b === void 0 ? void 0 : b.unit._id) && (a === null || a === void 0 ? void 0 : a.coefficient.value) === (b === null || b === void 0 ? void 0 : b.coefficient.value)) {
      return true;
    }
    return false;
  }
  getUnitBase() {
    return this.getUnitDefinition().unit.base;
  }
  getUnitDefinition() {
    return this.unitDef;
  }
  isSameAmount(otherAmount, options) {
    return BbitUnit.isSameAmount(this, BbitUnit.from(otherAmount, this.unit, this.unitSystem, this.referenceDate), options);
  }
  isEqual(otherUnit) {
    return BbitUnit.isEqual(this, otherUnit);
  }
  convertTo(targetUnit, options) {
    var _a, _b, _c;
    let sourceDef = this.unitDef;
    const targetDef = (targetUnit === null || targetUnit === void 0 ? void 0 : targetUnit.length) > 0 ? this.unitSystem.determineUnit(targetUnit) : sourceDef;
    if (!targetDef) {
      throw new error_1.BbitError('unknown-unit', {
        unit: targetUnit,
        func: 'BbitUnit.convertTo'
      });
    }
    if (sourceDef.unit._id === '#none' && targetDef.unit._id !== '#none') {
      sourceDef = targetDef;
    } else if (sourceDef.unit._id !== '#none' && targetDef.unit._id === '#none') {
      return this;
    } else if (BbitUnit.isSameUnit(sourceDef, targetDef, Object.assign({
      unitSystem: this.unitSystem
    }, options || {}))) {
      return this;
    }
    if (((_a = options === null || options === void 0 ? void 0 : options.unitMappings) === null || _a === void 0 ? void 0 : _a.length) > 0) {
      const mappingGraph = new unit_mapping_1.BbitUnitMapping(Object.assign({
        unitSystem: this.unitSystem
      }, options || {}));
      const mappedAmount = mappingGraph.mapAmount(sourceDef, targetDef, this.amount);
      if (mappedAmount !== undefined) {
        return new BbitUnit(mappedAmount, targetUnit, this.unitSystem, this, this.referenceDate);
      }
    }
    if (targetDef.unit.base !== sourceDef.unit.base) {
      throw new error_1.BbitError('unit-base-mismatch', {
        sourceUnit: sourceDef.name,
        targetUnit: targetDef.name,
        sourceBase: sourceDef.unit.base,
        targetBase: targetDef.unit.base,
        func: 'BbitUnit.convertTo'
      });
    }
    const thisValue = (_b = options === null || options === void 0 ? void 0 : options.sourceUnitValue) !== null && _b !== void 0 ? _b : unit_system_1.BbitUnitSystem.getValueByDate(sourceDef.unit, options === null || options === void 0 ? void 0 : options.referenceDate).value;
    const otherValue = (_c = options === null || options === void 0 ? void 0 : options.targetUnitValue) !== null && _c !== void 0 ? _c : unit_system_1.BbitUnitSystem.getValueByDate(targetDef.unit, options === null || options === void 0 ? void 0 : options.referenceDate).value;
    const thisFactor = thisValue * sourceDef.coefficient.value;
    const otherFactor = otherValue * targetDef.coefficient.value;
    return new BbitUnit(this.amount * (thisFactor / otherFactor), targetUnit, this.unitSystem, this, options === null || options === void 0 ? void 0 : options.referenceDate);
  }
  convertToBaseUnit(options) {
    return this.convertTo(this.unitDef.unit._id, options);
  }
  plus(amount, options) {
    return this.operate('plus', amount, options);
  }
  plusVAT(amount, options) {
    return this.operate('plusVAT', amount, options);
  }
  minus(amount, options) {
    return this.operate('minus', amount, options);
  }
  multiply(amount, options) {
    return this.operate('multiply', amount, options);
  }
  divide(amount, options) {
    return this.operate('divide', amount, options);
  }
  minusVAT(amount, options) {
    return this.operate('minusVAT', amount, options);
  }
  getVATAmount(amount, options) {
    return this.operate('getVATAmount', amount, options);
  }
  getVATPercent(amount, options) {
    return this.operate('getVATPercent', amount, options);
  }
  setAmount(amount) {
    return BbitUnit.from(amount, this.unit, this.unitSystem);
  }
  operate(operation, amount, options) {
    const imported = BbitUnit.from(amount, this.unit, this.unitSystem, this.referenceDate);
    const convertedUnitDef = imported.getUnitDefinition();
    if (operation === 'getVATPercent') {
      const biggerValue = this.amount > imported.amount ? this.amount : imported.amount;
      const lowerValue = this.amount > imported.amount ? imported.amount : this.amount;
      return new BbitUnit(Math.round((biggerValue / lowerValue - 1) * 100 * 10000) / 10000, 'percent', this.unitSystem);
    }
    if (convertedUnitDef.unit.base === interfaces_1.BbitUnitBases.RATIO && !(this.getUnitBase() === interfaces_1.BbitUnitBases.RATIO && convertedUnitDef.unit.base === interfaces_1.BbitUnitBases.RATIO) || (operation === 'plusVAT' || operation === 'minusVAT' || operation === 'getVATAmount') && convertedUnitDef.unit.base === interfaces_1.BbitUnitBases.NONE) {
      const ratio = convertedUnitDef.unit.base === interfaces_1.BbitUnitBases.RATIO ? imported.amount * convertedUnitDef.unit.value : imported.amount * 0.01;
      switch (operation) {
        case 'plus':
        case 'plusVAT':
          return new BbitUnit(this._fixDecimal(this.amount * (1 + ratio)), this.unit, this.unitSystem);
        case 'minusVAT':
          return new BbitUnit(this._fixDecimal(this.amount / (1 + ratio)), this.unit, this.unitSystem);
        case 'getVATAmount':
          return new BbitUnit(this._fixDecimal(this.amount - this.amount / (1 + ratio)), this.unit, this.unitSystem);
        case 'multiply':
          return new BbitUnit(this._fixDecimal(this.amount * ratio), this.unit, this.unitSystem);
        case 'divide':
          return new BbitUnit(this._fixDecimal(this.amount / ratio), this.unit, this.unitSystem);
        case 'minus':
          return new BbitUnit(this._fixDecimal(this.amount - this.amount * ratio), this.unit, this.unitSystem);
        default:
          throw new error_1.BbitError('unknown-operation', {
            operation
          });
      }
    }
    const converted = imported.convertTo(this.unit, Object.assign({
      referenceDate: this.referenceDate
    }, options || {}));
    if (converted.unit) {
      switch (operation) {
        case 'plus':
          return new BbitUnit(this._fixDecimal(this.amount + converted.amount), this.unit, this.unitSystem);
        case 'minus':
          return new BbitUnit(this._fixDecimal(this.amount - converted.amount), this.unit, this.unitSystem);
        case 'multiply':
          return new BbitUnit(this._fixDecimal(this.amount * converted.amount), this.unit, this.unitSystem);
        case 'divide':
          return new BbitUnit(this._fixDecimal(this.amount / converted.amount), this.unit, this.unitSystem);
        case 'plusVAT':
        case 'minusVAT':
          throw new error_1.BbitError('invalid-unit-operation', {
            operation,
            reason: 'amount must be based on a RATIO'
          });
        default:
          throw new error_1.BbitError('unknown-unit-operation', {
            operation
          });
      }
    }
  }
  _fixDecimal(n) {
    return Math.round(n * 1e12) / 1e12;
  }
  round(precision, method) {
    if (!precision) {
      if (!this.unitDef.unit.round) {
        return this;
      }
      precision = this.unitDef.unit.round;
    }
    if (!precision) {
      precision = 0.001;
    }
    const factor = 1 / precision;
    let newValue;
    switch (string_1.BbitString.stripBeginningHashtag(method) || 'commercial') {
      case 'up':
        newValue = Math.ceil(this.amount * factor) / factor;
        break;
      case 'down':
        newValue = Math.floor(this.amount * factor) / factor;
        break;
      case 'priceFraction':
        newValue = Math.round((this.amount - precision) * factor) / factor;
        break;
      case 'commercial':
        newValue = Math.round(this.amount * factor) / factor;
        break;
      case 'marketing':
        newValue = BbitUnit.roundMarketing(this.amount, precision);
        break;
      default:
        throw new error_1.BbitError('unknown-rounding-method', {
          unit: this.unit || '',
          method,
          func: 'BbitUnit.round'
        });
    }
    if (newValue === this.amount) {
      return this;
    }
    return new BbitUnit(newValue, this.unit, this.unitSystem);
  }
  toCurrencyObject() {
    const res = {
      amount: this.amount,
      currency: string_1.BbitString.ensureBeginningHashtag(this.unit.toUpperCase())
    };
    if (this.referenceDate) {
      res.referenceDate = this.referenceDate.toISO();
    }
    if (this.convertedFrom) {
      res['fromAmount'] = this.convertedFrom.amount;
      res['fromUnit'] = this.convertedFrom.unit;
    }
    return res;
  }
  toObject() {
    var _a;
    const res = {
      amount: this.amount,
      unit: this.unit
    };
    if ((_a = this.referenceDate) === null || _a === void 0 ? void 0 : _a.isValid) {
      res.referenceDate = this.referenceDate.toISO();
    }
    if (this.convertedFrom) {
      res['fromAmount'] = this.convertedFrom.amount;
      res['fromUnit'] = this.convertedFrom.unit;
    }
    return res;
  }
  toDuration() {
    const baseUnit = this.convertToBaseUnit();
    switch (baseUnit.unitDef.unit._id) {
      case '#second':
        return luxon_1.Duration.fromObject({
          milliseconds: Math.floor(baseUnit.amount * 1000)
        });
      case '#minute':
        return luxon_1.Duration.fromObject({
          milliseconds: Math.floor(baseUnit.amount * 1000 * 60)
        });
      case '#hour':
        return luxon_1.Duration.fromObject({
          milliseconds: Math.floor(baseUnit.amount * 1000 * 60 * 60)
        });
      case '#day':
        return luxon_1.Duration.fromObject({
          milliseconds: Math.floor(baseUnit.amount * 1000 * 60 * 60 * 24)
        });
      case '#week':
        return luxon_1.Duration.fromObject({
          weeks: baseUnit.amount
        });
      case '#month':
        return luxon_1.Duration.fromObject({
          months: baseUnit.amount
        });
      case '#year':
        return luxon_1.Duration.fromObject({
          years: baseUnit.amount
        });
      case '#decade':
        return luxon_1.Duration.fromObject({
          years: baseUnit.amount * 10
        });
      case '#century':
        return luxon_1.Duration.fromObject({
          years: baseUnit.amount * 100
        });
      case '#millennium':
        return luxon_1.Duration.fromObject({
          years: baseUnit.amount * 1000
        });
      default:
        return luxon_1.Duration.invalid(`unknown-unit-${baseUnit.unitDef.unit._id}`);
    }
  }
  toFormattedProperties(params) {
    var _a, _b, _c, _d, _e, _f;
    const defaultLang = i18n_1.BbitI18n.getLanguage() || 'en-US';
    const lang = string_1.BbitString.stripBeginningHashtag((params === null || params === void 0 ? void 0 : params.language) || defaultLang);
    const unitLang = ((_a = this.unitDef.unit.formatAs) === null || _a === void 0 ? void 0 : _a[lang]) || ((_b = this.unitDef.unit.name) === null || _b === void 0 ? void 0 : _b[lang]) || ((_c = this.unitDef.unit.formatAs) === null || _c === void 0 ? void 0 : _c[defaultLang]) || ((_d = this.unitDef.unit.name) === null || _d === void 0 ? void 0 : _d[defaultLang]);
    let unitFormatted = i18n_1.BbitI18n.t({
      object: {
        [lang]: unitLang
      },
      n: this.amount,
      abbrevation: params === null || params === void 0 ? void 0 : params.abbrevation,
      symbol: params === null || params === void 0 ? void 0 : params.symbol
    }, lang);
    if (!unitFormatted || unitFormatted.length === 0) {
      unitFormatted = this.unit;
    }
    if (this.unitDef.coefficient.value !== 1) {
      unitFormatted = this.unitDef.coefficient.name + unitFormatted;
    }
    const formatted = {
      language: lang,
      unitDefinition: this.unitDef.unit,
      unit: this.unitDef.unit._id === '#none' ? '' : unitFormatted,
      amount: number_1.BbitNumber.format(this.amount, (_e = this.unitDef.unit.displayDecimalMinimalCount) !== null && _e !== void 0 ? _e : 0, (_f = this.unitDef.unit.displayDecimalMaximalCount) !== null && _f !== void 0 ? _f : 3, (params === null || params === void 0 ? void 0 : params.decimalPoint) || '.', (params === null || params === void 0 ? void 0 : params.thousandsSeparator) || "'")
    };
    return formatted;
  }
  toFormattedUnit(lang) {
    return this.toFormattedProperties({
      language: lang
    }).unit;
  }
  toFormattedAmount(lang) {
    return this.toFormattedProperties({
      language: lang
    }).amount;
  }
  toFormattedString(lang) {
    var _a, _b;
    const formatted = this.toFormattedProperties({
      language: lang
    });
    if (formatted.unitDefinition.displayUnitBeforeAmount) {
      return (((_a = formatted.unit) === null || _a === void 0 ? void 0 : _a.length) > 0 ? `${formatted.unit} ` : '') + formatted.amount;
    }
    return formatted.amount + (((_b = formatted.unit) === null || _b === void 0 ? void 0 : _b.length) > 0 ? ` ${formatted.unit}` : '');
  }
  toFormat(params) {
    var _a, _b, _c, _d;
    const formatted = this.toFormattedProperties(params);
    switch (params === null || params === void 0 ? void 0 : params.display) {
      case interfaces_1.BbitUnitDisplayType.ONLY_UNIT:
        return formatted.unit;
      case interfaces_1.BbitUnitDisplayType.ONLY_AMOUNT:
        return formatted.amount;
      case interfaces_1.BbitUnitDisplayType.UNIT_BEFORE_AMOUNT:
        return (((_a = formatted.unit) === null || _a === void 0 ? void 0 : _a.length) > 0 ? `${formatted.unit} ` : '') + formatted.amount;
      case interfaces_1.BbitUnitDisplayType.UNIT_AFTER_AMOUNT:
        return formatted.amount + (((_b = formatted.unit) === null || _b === void 0 ? void 0 : _b.length) > 0 ? ` ${formatted.unit}` : '');
      default:
        return formatted.unitDefinition.displayUnitBeforeAmount ? (((_c = formatted.unit) === null || _c === void 0 ? void 0 : _c.length) > 0 ? `${formatted.unit} ` : '') + formatted.amount : formatted.amount + (((_d = formatted.unit) === null || _d === void 0 ? void 0 : _d.length) > 0 ? ` ${formatted.unit}` : '');
    }
  }
  toString() {
    return this.amount + (this.unit && this.unit.length > 0 && this.unit !== 'none' ? ` ${this.unit}` : '');
  }
  valueOf() {
    return this.amount;
  }
  static parse(input, options) {
    var _a;
    const raw = (input || '').trim().replace(/['`]+/g, '');
    if (raw.length === 0) {
      return null;
    }
    const regex = /^([\p{L}\p{M}]+)?\s*([+-]?[,\.`\d\s]+)\s*([\p{L}\p{M}%‰][\d\^\p{L}\p{M}\.\/]*)?(\s(in|dans)\s([\p{L}\p{N}\p{M}][\d\^\p{L}\p{M}\.\/]*)(\s(am|um|at|en)\s(.*))?)?$/iu;
    const res = regex.exec(raw);
    if (!res) {
      throw new error_1.BbitError('invalid-parse-input', {
        input: raw
      });
    }
    const [, extractedBeforeUnit, extractedAmount, extractedUnit,,, convertToUnit,,, convertDateExpression] = res;
    let convertDate = undefined;
    if (convertDateExpression && convertDateExpression.trim().length > 0) {
      const dateTimeResult = date_time_1.BbitDateTime.parseHumanString('dateTime', convertDateExpression, options === null || options === void 0 ? void 0 : options.languages);
      if (result_1.BbitResult.isError(dateTimeResult)) {
        throw result_1.BbitResult.extractError(dateTimeResult);
      }
      convertDate = dateTimeResult.data;
    }
    const amount = (extractedAmount || '').trim().replace(/[^\d\.,+-]/gi, '');
    let unit = (((extractedBeforeUnit === null || extractedBeforeUnit === void 0 ? void 0 : extractedBeforeUnit.length) > 0 ? extractedBeforeUnit : extractedUnit) || '').trim();
    const baseUnit = (options === null || options === void 0 ? void 0 : options.baseUnit) || ((options === null || options === void 0 ? void 0 : options.defaultUnit) ? new BbitUnit(0, options === null || options === void 0 ? void 0 : options.defaultUnit) : undefined) || new BbitUnit(0);
    if (unit.length === 0) {
      unit = convertToUnit && convertToUnit.trim().length > 0 ? convertToUnit : baseUnit.unit;
    } else {
      const baseUnitDef = (options === null || options === void 0 ? void 0 : options.baseUnit) ? options === null || options === void 0 ? void 0 : options.baseUnit.unitSystem.determineUnit(baseUnit.unit) : undefined;
      const foundUnitDef = baseUnit.unitSystem.determineUnit(unit, (options === null || options === void 0 ? void 0 : options.allowedUnitBases) || (((_a = baseUnitDef === null || baseUnitDef === void 0 ? void 0 : baseUnitDef.unit) === null || _a === void 0 ? void 0 : _a.base) ? [baseUnitDef.unit.base] : undefined), options === null || options === void 0 ? void 0 : options.languages);
      if (!foundUnitDef) {
        throw new error_1.BbitError('unknown-unit', {
          unit,
          options,
          func: 'BbitUnit.parse',
          availableLanguages: baseUnit.unitSystem.getLanguages()
        });
      }
      unit = foundUnitDef.name;
    }
    const referenceDate = baseUnit.referenceDate;
    let output = new BbitUnit(Number.parseFloat(amount), unit, baseUnit.unitSystem, null, referenceDate);
    if (convertToUnit && convertToUnit.trim().length > 0) {
      output = output.convertTo(convertToUnit, Object.assign({
        referenceDate: convertDate
      }, options || {}));
    }
    if (options === null || options === void 0 ? void 0 : options.ensureUnit) {
      output = output.convertTo(options === null || options === void 0 ? void 0 : options.ensureUnit, Object.assign({
        referenceDate: convertDate
      }, options || {}));
    }
    return output;
  }
  static from(amount, unit = null, unitSystem = null, referenceDate = null) {
    if (!unit) {
      unit = 'none';
    }
    if (BbitUnit.isBbitUnit(amount)) {
      return amount;
    }
    if (typeof amount === 'number') {
      return new BbitUnit(amount, unit, unitSystem, null, referenceDate);
    }
    if (typeof amount === 'string') {
      return new BbitUnit(Number.parseFloat(amount), unit, unitSystem, null, referenceDate);
    }
    if (object_1.BbitObject.isEmpty(amount)) {
      return new BbitUnit(0, unit, unitSystem, null, referenceDate);
    }
    if (object_1.BbitObject.isObject(amount)) {
      return new BbitUnit(amount.amount, amount.unit || amount.currency || unit, unitSystem, null, date_time_1.BbitDateTime.parse(amount.referenceDate || referenceDate));
    }
    throw new error_1.BbitError('unsupported-unit-input-type', {
      amount,
      unit,
      func: 'BbitUnit.from',
      reason: 'unknown type ' + typeof amount
    });
  }
  static fromFirstDefined(inputs, unit = null, unitSystem = null, referenceDate = null) {
    for (const input of inputs) {
      if (!object_1.BbitObject.isEmpty(input)) {
        return BbitUnit.from(input, unit, unitSystem, referenceDate);
      }
    }
    return BbitUnit.from(0, unit, unitSystem, referenceDate);
  }
  static isBbitUnit(input) {
    return (input === null || input === void 0 ? void 0 : input.hasOwnProperty) && input.hasOwnProperty('amount') && input.hasOwnProperty('unit') && input.hasOwnProperty('unitSystem') && !!input.convertTo;
  }
  static sum(inputArray, targetUnit, options) {
    let zero = null;
    if (typeof targetUnit === 'string') {
      zero = new BbitUnit(0, targetUnit, options === null || options === void 0 ? void 0 : options.unitSystem);
    } else if (BbitUnit.isBbitUnit(targetUnit)) {
      zero = targetUnit.setAmount(0);
    } else {
      zero = new BbitUnit(0);
    }
    if (!inputArray || inputArray.length === 0) {
      return zero;
    }
    const precision = (options === null || options === void 0 ? void 0 : options.precision) ? 1 / options.precision : 0;
    let amount = inputArray.reduce((acc, element) => {
      const elementValue = (options === null || options === void 0 ? void 0 : options.selector) ? options.selector(element) : element;
      if (!elementValue) {
        return acc;
      }
      const valueUnit = BbitUnit.from(elementValue, zero.unit, zero.unitSystem, zero.referenceDate);
      let value = valueUnit.convertTo(zero.unit, Object.assign({
        referenceDate: zero.referenceDate
      }, options || {})).amount;
      if (precision) {
        value = Math.round(value * precision);
      }
      return acc + value;
    }, 0);
    if (precision) {
      amount = amount / precision;
    }
    return zero.plus(amount);
  }
}
exports.BbitUnit = BbitUnit;
