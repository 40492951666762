"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitObjectArray = void 0;
const array_1 = require("../primitives/array");
const error_1 = require("../primitives/error");
const error_invalid_param_1 = require("../primitives/error-invalid-param");
const object_1 = require("../primitives/object");
const string_1 = require("../primitives/string");
const utils_1 = require("../utils/utils");
class BbitObjectArray {
  static isObjectArray(input) {
    return input && object_1.BbitObject.isObject(input) && Array.isArray(input._arrayOrder);
  }
  static arrayLength(objectArray) {
    var _a;
    if (Array.isArray(objectArray)) {
      return objectArray.length;
    }
    return ((_a = objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) === null || _a === void 0 ? void 0 : _a.length) || 0;
  }
  static getIDs(objectArray) {
    return object_1.BbitObject.clone((objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || []);
  }
  static lastElementId(objectArray) {
    var _a, _b;
    return (_a = objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) === null || _a === void 0 ? void 0 : _a[((_b = objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) === null || _b === void 0 ? void 0 : _b.length) - 1];
  }
  static objectizeArraysDeep(doc, deep = 0) {
    if (deep > 64) {
      throw new error_1.BbitError('max-deep-objectize-array-reached', {
        reason: 'check for circle dependencies',
        doc
      });
    }
    for (const key in doc) {
      const value = doc[key];
      switch (true) {
        case Array.isArray(value):
          if (key !== '_arrayOrder' && !BbitObjectArray.isNonObjectArray(value)) {
            const mapped = BbitObjectArray.objectizeArray(value);
            BbitObjectArray.objectizeArraysDeep(mapped, deep + 1);
            doc[key] = mapped;
          }
          break;
        case object_1.BbitObject.isObject(value):
          BbitObjectArray.objectizeArraysDeep(value, deep + 1);
          break;
      }
    }
  }
  static objectizeArray(array) {
    return BbitObjectArray.toObjectArray(array);
  }
  static toObjectArray(array) {
    const output = BbitObjectArray.create();
    const input = array || [];
    if (!Array.isArray(input)) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'BbitObjectArray.toObjectArray(array)',
        value: input,
        reason: 'must be an array'
      });
    }
    output._arrayOrder = input.map((e, i) => {
      if (!object_1.BbitObject.isObject(e)) {
        throw new error_invalid_param_1.BbitInvalidParamError({
          param: 'array[' + i + ']',
          value: e,
          reason: 'array element must be a plain object'
        });
      }
      const obj = object_1.BbitObject.clone(e);
      let id = obj._id;
      delete obj._id;
      if (!id || id.length === 0) {
        id = utils_1.BbitUtils.makeId();
      }
      output[id] = obj;
      return id;
    });
    return output;
  }
  static isNonObjectArray(array) {
    return array.some(e => !object_1.BbitObject.isObject(e));
  }
  static create() {
    return {
      _arrayOrder: []
    };
  }
  static toNormalArray(objectArray) {
    if (Array.isArray(objectArray)) {
      return objectArray;
    }
    return ((objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || []).map(id => {
      const obj = object_1.BbitObject.clone(objectArray[id]);
      obj._id = id;
      return obj;
    });
  }
  static deobjectizeArray(objectArray) {
    return BbitObjectArray.toNormalArray(objectArray);
  }
  static forEach(objectArray, func) {
    if (Array.isArray(objectArray)) {
      objectArray.forEach((key, index) => {
        var _a;
        return func(key, (_a = objectArray[index]) === null || _a === void 0 ? void 0 : _a._id, index);
      });
      return;
    }
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    objectArray._arrayOrder.forEach((key, index) => {
      func(objectArray[key], key, index);
    });
  }
  static findKey(objectArray, func) {
    var _a;
    if (Array.isArray(objectArray)) {
      return (_a = objectArray.find((key, index) => {
        var _a;
        return func(key, (_a = objectArray[index]) === null || _a === void 0 ? void 0 : _a._id, index);
      })) === null || _a === void 0 ? void 0 : _a._id;
    }
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    const key = objectArray._arrayOrder.find((key, index) => {
      return func(objectArray[key], key, index);
    });
    return key;
  }
  static find(objectArray, func) {
    if (Array.isArray(objectArray)) {
      return objectArray.find((key, index) => {
        var _a;
        return func(key, (_a = objectArray[index]) === null || _a === void 0 ? void 0 : _a._id, index);
      });
    }
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    const key = objectArray._arrayOrder.find((key, index) => {
      return func(objectArray[key], key, index);
    });
    return objectArray && key ? Object.assign(Object.assign({}, objectArray[key] || {}), {
      _id: key
    }) : undefined;
  }
  static map(objectArray, func) {
    if (Array.isArray(objectArray)) {
      return objectArray.map((key, index) => {
        var _a;
        return func(key, (_a = objectArray[index]) === null || _a === void 0 ? void 0 : _a._id, index);
      });
    }
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return [];
    }
    return objectArray._arrayOrder.map((key, index) => {
      return func(objectArray[key], key, index);
    });
  }
  static cloneMap(objectArray, func) {
    const newObj = BbitObjectArray.create();
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return newObj;
    }
    newObj._arrayOrder = objectArray._arrayOrder.map((value, index) => {
      let result = func(objectArray[value], index);
      result._id = objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder[index];
      if (!result || !object_1.BbitObject.isObject(result)) {
        result = {};
      }
      if (!result._id || result._id.length === 0) {
        result._id = utils_1.BbitUtils.makeId();
      }
      newObj[result._id] = result;
      return result._id;
    });
    return newObj;
  }
  static filter(objectArray, func) {
    if (Array.isArray(objectArray)) {
      return objectArray.filter((key, index) => {
        var _a;
        return func(key, (_a = objectArray[index]) === null || _a === void 0 ? void 0 : _a._id, index);
      });
    }
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    return objectArray._arrayOrder.filter((key, index) => {
      return func(objectArray[key], key, index);
    }).map(key => {
      return Object.assign(Object.assign({}, objectArray[key] || {}), {
        _id: key
      });
    });
  }
  static filterObjects(objectArray, func) {
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    objectArray._arrayOrder = objectArray._arrayOrder.filter((key, index) => {
      const result = func(objectArray[key], key, index);
      if (!result) {
        delete objectArray[key];
      }
      return result;
    });
    return objectArray;
  }
  static sortBy(objectArray, keyFunc) {
    if (!(objectArray === null || objectArray === void 0 ? void 0 : objectArray._arrayOrder) || !Array.isArray(objectArray._arrayOrder)) {
      return;
    }
    return Object.assign(Object.assign({}, objectArray), {
      _arrayOrder: array_1.BbitArray.sortBy(objectArray._arrayOrder, key => keyFunc(objectArray[key]))
    });
  }
  static push(objectArray, element) {
    const id = string_1.BbitString.isNotEmpty(element === null || element === void 0 ? void 0 : element._id) ? element._id : utils_1.BbitUtils.makeId();
    const newElementObj = object_1.BbitObject.isObject(element) && !object_1.BbitObject.isEmpty(element) ? element : {};
    delete newElementObj._id;
    objectArray._arrayOrder.push(id);
    objectArray[id] = newElementObj;
    return id;
  }
  static insertAfter(objectArray, afterId, element) {
    const id = string_1.BbitString.isNotEmpty(element === null || element === void 0 ? void 0 : element._id) ? element._id : utils_1.BbitUtils.makeId();
    const newElementObj = object_1.BbitObject.isObject(element) && !object_1.BbitObject.isEmpty(element) ? element : {};
    delete newElementObj._id;
    const arrayOrder = [...(objectArray._arrayOrder || [])];
    const index = arrayOrder.indexOf(afterId);
    if (index >= 0) {
      arrayOrder.splice(index + 1, 0, id);
    } else {
      arrayOrder.push(id);
    }
    objectArray[id] = newElementObj;
    objectArray._arrayOrder = arrayOrder;
    return id;
  }
  static insertBefore(objectArray, beforeId, element) {
    const id = string_1.BbitString.isNotEmpty(element === null || element === void 0 ? void 0 : element._id) ? element._id : utils_1.BbitUtils.makeId();
    const newElementObj = object_1.BbitObject.isObject(element) && !object_1.BbitObject.isEmpty(element) ? element : {};
    delete newElementObj._id;
    const arrayOrder = [...(objectArray._arrayOrder || [])];
    const index = arrayOrder.indexOf(beforeId);
    if (index >= 0) {
      arrayOrder.splice(index, 0, id);
    } else {
      arrayOrder.unshift(id);
    }
    objectArray[id] = newElementObj;
    objectArray._arrayOrder = arrayOrder;
    return id;
  }
  static remove(objectArray, id) {
    objectArray._arrayOrder = objectArray._arrayOrder.filter(e => e !== id);
    delete objectArray[id];
  }
  static moveUp(objectArray, id) {
    const arrayOrder = [...(objectArray._arrayOrder || [])];
    for (let i = 0; i < arrayOrder.length; i++) {
      if (arrayOrder[i] === id) {
        if (i > 0) {
          const tmp = arrayOrder[i - 1];
          arrayOrder[i - 1] = arrayOrder[i];
          arrayOrder[i] = tmp;
        }
        break;
      }
    }
    objectArray._arrayOrder = arrayOrder;
  }
  static moveDown(objectArray, id) {
    const arrayOrder = [...(objectArray._arrayOrder || [])];
    for (let i = 0; i < arrayOrder.length; i++) {
      if (arrayOrder[i] === id) {
        if (i < arrayOrder.length - 1) {
          const tmp = arrayOrder[i + 1];
          arrayOrder[i + 1] = arrayOrder[i];
          arrayOrder[i] = tmp;
        }
        break;
      }
    }
    objectArray._arrayOrder = arrayOrder;
  }
  static getDeletedRowIDs(originalField, currentField) {
    const originalArray = (originalField === null || originalField === void 0 ? void 0 : originalField._arrayOrder) || [];
    const currentArray = (currentField === null || currentField === void 0 ? void 0 : currentField._arrayOrder) || [];
    return originalArray.filter(x => !currentArray.includes(x));
  }
  static getDeletedRows(originalField, currentField) {
    const deletedIDs = BbitObjectArray.getDeletedRowIDs(originalField, currentField);
    return deletedIDs.map(id => originalField[id]);
  }
}
exports.BbitObjectArray = BbitObjectArray;
