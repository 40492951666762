"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitMime = exports.defaultMimes = void 0;
const log_1 = require("../log/log");
exports.defaultMimes = {
  'image/bmp': {
    source: 'iana',
    compressible: true,
    extensions: ['bmp']
  },
  'image/gif': {
    source: 'iana',
    compressible: false,
    extensions: ['gif']
  },
  'image/jpeg': {
    source: 'iana',
    compressible: false,
    extensions: ['jpeg', 'jpg', 'jpe']
  },
  'application/octet-stream': {
    source: 'iana',
    compressible: false,
    extensions: ['bin', 'dms', 'lrf', 'mar', 'so', 'dist', 'distz', 'pkg', 'bpk', 'dump', 'elc', 'deploy', 'exe', 'dll', 'deb', 'dmg', 'iso', 'img', 'msi', 'msp', 'msm', 'buffer']
  }
};
class BbitMime {
  static init(db) {
    BbitMime._singleton = new BbitMime(db);
    return BbitMime._singleton;
  }
  static global() {
    if (!BbitMime._singleton) {
      return BbitMime.init(exports.defaultMimes);
    }
    return BbitMime._singleton;
  }
  constructor(db) {
    this.db = db;
    this.types = {};
    this.extensions = {};
    this._log = log_1.BbitLog.scope({
      class: 'BbitMime'
    });
    if (typeof db !== 'object') {
      db = exports.defaultMimes;
    }
    this.define(db);
    this._defaultType = this.lookup('bin');
  }
  define(map) {
    for (const type in map) {
      const exts = !Array.isArray(map[type]) && map[type].extensions ? map[type].extensions : map[type];
      for (let i = 0; i < exts.length; i++) {
        if (process && process.env.DEBUG_MIME && this.types[exts[i]]) {
          this._log.warning('changes "' + exts[i] + '" extension type from ' + this.types[exts[i]] + ' to ' + type);
        }
        this.types[exts[i]] = type;
      }
      if (!this.extensions[type]) {
        this.extensions[type] = exts[0];
      }
    }
  }
  lookup(path, fallback) {
    const ext = path.replace(/.*[\.\/\\]/, '').toLowerCase();
    return this.types[ext] || fallback || this._defaultType;
  }
  extension(mimeType) {
    const type = mimeType.match(/^\s*([^;\s]*)(?:;|\s|$)/)[1].toLowerCase();
    return this.extensions[type];
  }
  glob(pattern) {
    if (pattern === '*/*') {
      return ['application/octet-stream'];
    }
    const slashIdx = pattern.indexOf('/');
    if (slashIdx === -1 || pattern.slice(slashIdx + 1) !== '*') {
      return [pattern];
    }
    const prefix = pattern.slice(0, slashIdx + 1);
    const result = Object.keys(this.extensions).map(name => {
      if (name.slice(0, slashIdx + 1) === prefix) {
        return name;
      }
    }).filter(n => !!n);
    return result;
  }
  charsetsLookup(mimeType, fallback) {
    return /^text\//.test(mimeType) ? 'UTF-8' : fallback;
  }
}
exports.BbitMime = BbitMime;
