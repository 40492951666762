"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitUnitInterval = void 0;
class BbitUnitInterval {
  static fromUnits(first, second) {
    const s = first;
    const e = second.convertTo(s.unit);
    if (e.amount < s.amount) {
      return new BbitUnitInterval(e, s);
    }
    return new BbitUnitInterval(s, e);
  }
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }
  difference() {
    if (this.start.amount === Number.POSITIVE_INFINITY || this.start.amount === Number.NEGATIVE_INFINITY) {
      return this.start;
    }
    if (this.start.amount === Number.POSITIVE_INFINITY || this.end.amount === Number.NEGATIVE_INFINITY) {
      return this.end;
    }
    return this.end.minus(this.start);
  }
  intersection(otherUnitInterval) {
    const otherStart = otherUnitInterval.start.convertTo(this.start.unit);
    const otherEnd = otherUnitInterval.end.convertTo(this.start.unit);
    const start = Math.max(this.start.amount, otherStart.amount);
    const end = Math.min(this.end.amount, otherEnd.amount);
    if (start > end) {
      return null;
    }
    return new BbitUnitInterval(this.start.setAmount(start), this.end.setAmount(end));
  }
  contains(unit, options) {
    const start = this.start.convertTo(unit.unit);
    const end = this.end.convertTo(unit.unit);
    return unit.amount >= start.amount && ((options === null || options === void 0 ? void 0 : options.inclusiveEnd) ? unit.amount <= end.amount : unit.amount < end.amount);
  }
  combine(otherUnitInterval) {
    const otherStart = otherUnitInterval.start.convertTo(this.start.unit);
    const otherEnd = otherUnitInterval.end.convertTo(this.start.unit);
    const start = Math.min(this.start.amount, otherStart.amount);
    const end = Math.max(this.end.amount, otherEnd.amount);
    return new BbitUnitInterval(this.start.setAmount(start), this.end.setAmount(end));
  }
  max() {
    return this.end;
  }
  min() {
    return this.start;
  }
  toString() {
    return `[${this.start.toString()}, ${this.end.toString()}]`;
  }
}
exports.BbitUnitInterval = BbitUnitInterval;
