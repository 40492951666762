"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitUtils = void 0;
const nanoid_1 = require("nanoid");
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const function_1 = require("../primitives/function");
const object_1 = require("../primitives/object");
class BbitUtils {
  static makeId({
    length = 21,
    onlyLowerChars = false,
    noLookAlikes = true,
    leadingHashtag = true
  } = {}) {
    let alphabet;
    switch (true) {
      case onlyLowerChars && noLookAlikes:
        alphabet = '23456789abcdefghijkmnpqrstwxyz';
        break;
      case !onlyLowerChars && noLookAlikes:
        alphabet = '23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstwxyz';
        break;
      case onlyLowerChars && !noLookAlikes:
        alphabet = 'abcdefghijkmnpqrstuvwxyz1234567890';
        break;
      case !onlyLowerChars && !noLookAlikes:
        alphabet = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890';
        break;
    }
    return (leadingHashtag ? '#' : '') + (0, nanoid_1.customAlphabet)(alphabet, length)();
  }
  static wait(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }
  static parseArn(arn) {
    const [arnPrefix, arnPartition, arnService, arnRegion, arnAccount, ...arnResource] = (arn || '').split(':');
    const arnResourceString = arnResource.join(':');
    const splitted = arnResourceString.split('/');
    let arnResourceType = '';
    let arnResourceId = '';
    if (splitted.length > 1) {
      arnResourceType = splitted.shift();
      arnResourceId = splitted.join('/');
    } else {
      arnResourceId = splitted[0];
    }
    return {
      arnPrefix,
      arnPartition,
      arnService,
      arnRegion,
      arnAccount,
      arnResourceType,
      arnResourceId
    };
  }
  static isNode() {
    if (BbitUtils.isElectron()) {
      return false;
    }
    return Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';
  }
  static isElectron() {
    return !!(typeof window !== 'undefined' && window.bbitElectron);
  }
  static isWeb() {
    return !BbitUtils.isElectron() && !BbitUtils.isNode();
  }
  static isMacOS() {
    return !!(BbitUtils.isElectron() && window.bbitElectron.getProcessPlatform() === 'darwin');
  }
  static isWindows() {
    return !!(BbitUtils.isElectron() && window.bbitElectron.getProcessPlatform() === 'win32');
  }
  static isLinux() {
    return !!(BbitUtils.isElectron() && (window.bbitElectron.getProcessPlatform() === 'aix' || window.bbitElectron.getProcessPlatform() === 'freebsd' || window.bbitElectron.getProcessPlatform() === 'linux' || window.bbitElectron.getProcessPlatform() === 'openbsd' || window.bbitElectron.getProcessPlatform() === 'sunos'));
  }
  static isPromise(obj) {
    return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
  }
  static isObjectEmpty(value) {
    return object_1.BbitObject.isEmpty(value);
  }
  static getGlobalObj() {
    if (!BbitUtils._root) {
      if (typeof self === 'object' && self.self === self) {
        BbitUtils._root = self;
      } else if (typeof window !== 'undefined') {
        BbitUtils._root = window;
      } else if (typeof global !== 'undefined') {
        BbitUtils._root = global;
      } else {
        BbitUtils._root = {};
      }
    }
    return BbitUtils._root;
  }
  static setGlobal(scope, key, value) {
    const root = BbitUtils.getGlobalObj();
    if (!root.bbit) {
      root.bbit = {};
    }
    if (!root.bbit[scope]) {
      root.bbit[scope] = {};
    }
    root.bbit[scope][key] = value;
  }
  static getGlobal(scope, key, defaultValue = null) {
    const root = BbitUtils.getGlobalObj();
    return root.bbit && root.bbit[scope] && root.bbit[scope][key] ? root.bbit[scope][key] : defaultValue;
  }
  static retryableErrorStrategy({
    maxRetryAttempts = 5,
    scalingDuration = 1000,
    jitterScale = 2,
    onRetrySchedule = null
  } = {}) {
    return attempts => {
      const lambda = attempts.pipe((0, operators_1.mergeMap)((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || error && !error.retryable) {
          return RxJS.throwError(error);
        }
        const waitTime = retryAttempt * scalingDuration * (jitterScale > 0 ? Math.random() * jitterScale : 1);
        if (onRetrySchedule && function_1.BbitFunction.isFunction(onRetrySchedule)) {
          const returnVal = onRetrySchedule({
            error,
            maxRetryAttempts,
            retryAttempt,
            waitTime
          });
          if (returnVal && RxJS.isObservable(returnVal)) {
            return returnVal;
          }
        }
        return RxJS.timer(waitTime);
      }));
      return lambda;
    };
  }
}
exports.BbitUtils = BbitUtils;
BbitUtils.makeArrayLengthEven = (array, options = {
  n: 2,
  atEnd: false
}) => {
  if (!array) {
    return [];
  }
  const n = (options === null || options === void 0 ? void 0 : options.n) || 2;
  const leftOvers = array.length % n;
  if (leftOvers === 0) {
    return array;
  }
  return (options === null || options === void 0 ? void 0 : options.atEnd) ? array.concat(Array(n - leftOvers).fill(undefined)) : Array(n - leftOvers).fill(undefined).concat(array);
};
