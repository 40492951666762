"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Semaphore = void 0;
class Semaphore {
  constructor(config) {
    this._queue = [];
    this._config = Object.assign({
      maxConcurrency: 1,
      timeoutInMS: 0,
      timeoutError: new Error('semaphore-timeout')
    }, config || {});
    if (!(this._config.maxConcurrency > 0)) {
      this._config.maxConcurrency = 1;
    }
    this._freeConcurrencyPlaces = this._config.maxConcurrency;
  }
  _acquire() {
    const locked = this.isLocked();
    const ticket = new Promise(r => this._queue.push(r));
    if (!locked) this._dispatch();
    return ticket;
  }
  awaitFree() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.runExclusive(() => true);
      return !this.isLocked();
    });
  }
  acquire(timeoutInMS = 0, timeoutError) {
    const timeout = timeoutInMS || this._config.timeoutInMS;
    if (timeout > 0) {
      return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        let isTimeout = false;
        setTimeout(() => {
          isTimeout = true;
          reject(timeoutError || this._config.timeoutError);
        }, timeout);
        const ticket = yield this._acquire();
        if (isTimeout) {
          const release = ticket[1];
          release();
        } else {
          resolve(ticket);
        }
      }));
    }
    return this._acquire();
  }
  runExclusive(callback_1) {
    return __awaiter(this, arguments, void 0, function* (callback, timeoutInMS = 0, timeoutError) {
      const [value, release] = yield this.acquire(timeoutInMS, timeoutError);
      try {
        return yield callback(value);
      } finally {
        release();
      }
    });
  }
  isLocked() {
    return this._freeConcurrencyPlaces <= 0;
  }
  release() {
    if (this._config.maxConcurrency > 1) {
      throw new Error('this method is unavailable on semaphores with concurrency > 1; use the scoped release returned by acquire instead');
    }
    if (this._currentReleaser) {
      this._currentReleaser();
      this._currentReleaser = undefined;
    }
  }
  _dispatch() {
    const nextConsumer = this._queue.shift();
    if (!nextConsumer) return;
    let released = false;
    this._currentReleaser = () => {
      if (released) return;
      released = true;
      this._freeConcurrencyPlaces++;
      this._dispatch();
    };
    nextConsumer([this._freeConcurrencyPlaces--, this._currentReleaser]);
  }
}
exports.Semaphore = Semaphore;
