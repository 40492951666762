"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bbase2StoreKeyValue = void 0;
const luxon_1 = require("luxon");
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const log_1 = require("../../log/log");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const number_1 = require("../../primitives/number");
const object_1 = require("../../primitives/object");
const result_1 = require("../../primitives/result");
const semaphore_1 = require("../../primitives/semaphore");
const string_1 = require("../../primitives/string");
const utils_1 = require("../../utils/utils");
const bbase2_utils_1 = require("../utils/bbase2-utils");
const interfaces_1 = require("../utils/interfaces");
class Bbase2StoreKeyValue {
  constructor(config) {
    this.inMemoryMap = new Map();
    this._config = Object.assign({}, config);
    this._log = log_1.BbitLog.scope({
      package: '@bbitgmbh/bbit.sdk',
      function: 'BbaseStoreKeyValue'
    });
    this._semaphore = new semaphore_1.Semaphore({
      maxConcurrency: 1
    });
    const err = this._checkConfig();
    if (err) throw err;
    if (!this._config.store) {
      this._config.store = {
        backendType: 'in-memory',
        isPersistent: false,
        getItem: key => __awaiter(this, void 0, void 0, function* () {
          return this.inMemoryMap.get(key);
        }),
        setItem: (key, value) => __awaiter(this, void 0, void 0, function* () {
          this.inMemoryMap.set(key, value);
        }),
        removeItem: key => __awaiter(this, void 0, void 0, function* () {
          this.inMemoryMap.delete(key);
        }),
        clear: () => __awaiter(this, void 0, void 0, function* () {
          for (const key of this.inMemoryMap.keys()) {
            this.inMemoryMap.delete(key);
          }
        })
      };
    }
    this._connectionState = new RxJS.BehaviorSubject(this._getDefaultStoreState());
  }
  _serialize(input) {
    return input ? JSON.stringify(input) : '';
  }
  _deserialize(input, defaultValue) {
    return (input === null || input === void 0 ? void 0 : input.length) > 0 ? JSON.parse(input) : defaultValue;
  }
  getAttachment(file) {
    throw new error_1.BbitError('not-supported', {
      func: 'Bbase2StoreKeyValue.getAttachment',
      params: file
    });
  }
  putAttachment(file) {
    throw new error_1.BbitError('not-supported', {
      func: 'Bbase2StoreKeyValue.putAttachment',
      params: file
    });
  }
  getKeys(params) {
    if (!Array.isArray(params === null || params === void 0 ? void 0 : params.keys)) {
      return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'getKeys({keys})',
        value: params === null || params === void 0 ? void 0 : params.keys,
        reason: 'must be an array'
      }));
    }
    if (params.keys.length === 0) return RxJS.of([]);
    return RxJS.of(...params.keys).pipe((0, operators_1.mergeMap)(bbaseKey => this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/keys/${bbaseKey._bucket}/${bbaseKey._id}`)), (0, operators_1.map)(value => {
      const key = this._deserialize(value, {});
      if (key._expires && luxon_1.DateTime.fromSeconds(key._expires).diffNow().milliseconds < 0) {
        return null;
      }
      return key;
    }), (0, operators_1.reduce)((acc, key) => acc.concat(key), []));
  }
  setKeys(params) {
    if (!Array.isArray(params === null || params === void 0 ? void 0 : params.keys)) {
      return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'setKeys({keys})',
        value: params === null || params === void 0 ? void 0 : params.keys,
        reason: 'must be an array'
      }));
    }
    return RxJS.of(...params.keys).pipe((0, operators_1.mergeMap)(bbaseKey => this._semaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
      var _a, _b;
      if (!bbaseKey) {
        return null;
      }
      const currentKey = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/keys/${bbaseKey._bucket}/${bbaseKey._id}`), {
        _bucket: bbaseKey._bucket,
        _id: bbaseKey._id,
        value: undefined,
        _written: null,
        _expires: null,
        _changedAt: null,
        _changedBy: null,
        _changedOn: null,
        source: {
          _id: null,
          _bucket: null
        }
      });
      if (!bbaseKey.overwrite && currentKey._written) {
        throw new error_1.BbitError('key-already-exists', {
          _bucket: bbaseKey._bucket,
          _id: bbaseKey._id
        });
      }
      if (bbaseKey.incrementBy) {
        if (currentKey.value == null) {
          currentKey.value = number_1.BbitNumber.isNumber(bbaseKey.value) ? bbaseKey.value : 0;
        } else {
          currentKey.value = ((_a = currentKey.value) !== null && _a !== void 0 ? _a : 0) + (bbaseKey.incrementBy || 0);
        }
        currentKey._expires = undefined;
      } else {
        currentKey.value = bbaseKey.value;
        currentKey._expires = ((_b = bbaseKey.expiresAt) === null || _b === void 0 ? void 0 : _b.isValid) ? Math.floor(bbaseKey.expiresAt.toSeconds()) : undefined;
      }
      currentKey._written = Math.floor(luxon_1.DateTime.utc().toSeconds());
      currentKey._changedAt = bbaseKey._changedAt ? bbaseKey._changedAt : luxon_1.DateTime.local().toISO();
      currentKey._changedBy = params.auth.userId;
      currentKey._changedOn = params.auth.deviceId;
      const isRemoval = currentKey.value === undefined;
      if (!isRemoval) {
        yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/keys/${bbaseKey._bucket}/${bbaseKey._id}`, this._serialize(currentKey));
      } else {
        yield this._config.store.removeItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/keys/${bbaseKey._bucket}/${bbaseKey._id}`);
      }
      yield this._updateKeyList({
        storageKey: `bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/keylist/${bbaseKey._bucket}`,
        recordKey: bbaseKey._id,
        _delete: isRemoval
      });
      return currentKey;
    }))), (0, operators_1.reduce)((acc, key) => acc.concat(key), []));
  }
  listKeys(params) {
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => this._semaphore.runExclusive(() => this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/keylist/${params._bucket}`))), (0, operators_1.map)(value => Object.assign(Object.assign({}, this._deserialize(value, {
      keys: []
    })), {
      lastEvaluatedKey: null
    })));
  }
  _checkConfig() {
    if (!this._config) return new error_1.BbitError('empty-db-config');
    return null;
  }
  _getDefaultStoreState() {
    return {
      backendType: this._config.store.backendType,
      locationKey: 'local',
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey,
      latencyMs: 5,
      isPersistent: this._config.store.isPersistent,
      supportsAttachments: false
    };
  }
  getEnvironment() {
    return {
      organizationKey: this._config.organizationKey,
      environmentKey: this._config.environmentKey
    };
  }
  getLatestStoreState() {
    return this._connectionState.value;
  }
  observeStoreState() {
    return this._connectionState.asObservable();
  }
  listBuckets(params) {
    const validRes = string_1.BbitString.validateSystemFieldValue(params, '_buckettype');
    if (result_1.BbitResult.isError(validRes)) {
      return validRes.toObservable();
    }
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/bucketlist/${params._buckettype}`)), (0, operators_1.map)(value => {
      const bucketDict = this._deserialize(value, {});
      return {
        buckets: params.onlyBuckets ? params.onlyBuckets.map(b => bucketDict[b]) : Object.values(bucketDict),
        lastEvaluatedKey: null
      };
    }));
  }
  getBucket(params) {
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/bucketlist/${params._buckettype}`)), (0, operators_1.map)(value => {
      const parsed = this._deserialize(value, {});
      if (!parsed[params._bucket]) {
        throw new error_1.BbitError('bucket-not-found', params);
      }
      return parsed[params._bucket];
    }));
  }
  putBucket(params) {
    for (const field of ['_bucket', '_buckettype']) {
      const validRes = string_1.BbitString.validateSystemFieldValue(params, field);
      if (result_1.BbitResult.isError(validRes)) {
        return validRes.toObservable();
      }
    }
    if (![interfaces_1.Bbase2BucketTypes.DOC, interfaces_1.Bbase2BucketTypes.KEY].includes(params._buckettype)) {
      return RxJS.throwError(new error_1.BbitError('invalid-buckettype', params));
    }
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => __awaiter(this, void 0, void 0, function* () {
      return this._semaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
        const currentList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/bucketlist/${params._buckettype}`), {});
        if (!currentList[params._bucket]) {
          const newBucket = {
            _bucket: params._bucket,
            _buckettype: params._buckettype,
            _id: utils_1.BbitUtils.makeId({
              length: 16,
              leadingHashtag: false,
              noLookAlikes: true,
              onlyLowerChars: true
            }),
            organizationKey: this._config.organizationKey,
            environmentKey: this._config.environmentKey,
            readonly: false,
            size: 0,
            count: 0,
            lastUpdated: 0
          };
          currentList[params._bucket] = newBucket;
        }
        if (params.readonly != null) {
          currentList[params._bucket].readonly = params.readonly;
        }
        if (params.stats != null) {
          const totalIncrement = params.stats.new.countIncrement + params.stats.old.countIncrement;
          const totalSize = params.stats.new.sizeIncrement + params.stats.old.sizeIncrement;
          currentList[params._bucket].size = (currentList[params._bucket].size || 0) + totalSize;
          currentList[params._bucket].count = (currentList[params._bucket].count || 0) + totalIncrement;
        }
        const theBucket = currentList[params._bucket];
        if (params._delete) {
          delete currentList[params._bucket];
        }
        yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/meta/bucketlist/${params._buckettype}`, this._serialize(currentList));
        return theBucket;
      }));
    })));
  }
  getDocMeta(params) {
    return this.getDoc(params);
  }
  getDoc(params) {
    if (!params || !object_1.BbitObject.isObject(params)) return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
      param: 'bbase2InMemoryStore getDoc param',
      value: params,
      reason: 'must be a non null object'
    }));
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        return fieldValid.toObservable();
      }
    }
    this._log.debug('getDoc', params);
    return this.getBucket({
      _bucket: params._bucket,
      _buckettype: interfaces_1.Bbase2BucketTypes.DOC
    }).pipe((0, operators_1.mergeMap)(bucket => __awaiter(this, void 0, void 0, function* () {
      var _a;
      let rev = params._rev;
      if (!rev) {
        const revList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revlist/${bucket._id}/${params._id}`), {
          mainRevs: [],
          conflictedRevs: []
        });
        if (!(((_a = revList === null || revList === void 0 ? void 0 : revList.mainRevs) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
          return null;
        }
        rev = revList.mainRevs[revList.mainRevs.length - 1];
      } else {
        rev = bbase2_utils_1.Bbase2Utils.concatRevision(bbase2_utils_1.Bbase2Utils.splitRevision(rev));
      }
      const doc = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revs/${bucket._id}/${params._id}/${rev}`), null);
      return doc;
    })));
  }
  listDocs(params) {
    return this.getBucket({
      _bucket: params._bucket,
      _buckettype: interfaces_1.Bbase2BucketTypes.DOC
    }).pipe((0, operators_1.mergeMap)(bucket => __awaiter(this, void 0, void 0, function* () {
      const indexes = params._indexes ? Array.isArray(params._indexes) ? params._indexes : [params._indexes] : [interfaces_1.Bbase2Indexes.HEAD];
      const changesIndex = indexes.findIndex(o => o === interfaces_1.Bbase2Indexes.CHANGES);
      if (changesIndex >= 0) {
        indexes.splice(changesIndex, 1, interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED, interfaces_1.Bbase2Indexes.DELETED);
      }
      const docsPerIndex = yield Promise.all(indexes.map(idx => __awaiter(this, void 0, void 0, function* () {
        const indexList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${bucket._id}/${idx}`), {
          revs: []
        });
        if (!(indexList === null || indexList === void 0 ? void 0 : indexList.revs) || indexList.revs.length === 0) {
          return [];
        }
        const docs = yield Promise.all(indexList.revs.map(docMeta => __awaiter(this, void 0, void 0, function* () {
          const doc = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revs/${bucket._id}/${docMeta._id}/${docMeta._rev}`), null);
          return doc;
        })));
        return docs.filter(Boolean);
      })));
      return {
        docs: docsPerIndex.flat(),
        lastEvaluatedKey: null
      };
    })));
  }
  recalculateBucketStats(params) {
    return this.getBucket(params).pipe((0, operators_1.mergeMap)(bucket => __awaiter(this, void 0, void 0, function* () {
      const indexes = [interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED, interfaces_1.Bbase2Indexes.DELETED, interfaces_1.Bbase2Indexes.DRAFT, interfaces_1.Bbase2Indexes.TEMPLATE];
      const docsPerIndex = yield Promise.all(indexes.map(idx => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const indexList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${bucket._id}/${idx}`), {
          revs: []
        });
        return {
          idx,
          count: ((_a = indexList === null || indexList === void 0 ? void 0 : indexList.revs) === null || _a === void 0 ? void 0 : _a.length) || 0,
          size: 0,
          lastUpdated: 0
        };
      })));
      bucket.statsPerIndex = docsPerIndex.reduce((acc, cur) => {
        var _a, _b, _c;
        return Object.assign(Object.assign({}, acc), {
          [cur.idx]: {
            count: cur.count + (((_a = acc[cur.idx]) === null || _a === void 0 ? void 0 : _a.count) || 0),
            size: cur.size + (((_b = acc[cur.idx]) === null || _b === void 0 ? void 0 : _b.size) || 0),
            lastUpdated: Math.max(cur.lastUpdated, ((_c = acc[cur.idx]) === null || _c === void 0 ? void 0 : _c.lastUpdated) || 0)
          }
        });
      }, {});
      return bucket;
    })));
  }
  listDocRevisions(params) {
    if (!params || !object_1.BbitObject.isObject(params)) return RxJS.throwError(new error_invalid_param_1.BbitInvalidParamError({
      param: 'bbase2InMemoryStore getDoc param',
      value: params,
      reason: 'must be a non null object'
    }));
    for (const systemField of ['_bucket', '_id']) {
      const fieldValid = string_1.BbitString.validateSystemFieldValue(params, systemField);
      if (result_1.BbitResult.isError(fieldValid)) {
        return fieldValid.toObservable();
      }
    }
    this._log.debug('getDoc', params);
    return this.getBucket({
      _bucket: params._bucket,
      _buckettype: interfaces_1.Bbase2BucketTypes.DOC
    }).pipe((0, operators_1.mergeMap)(bucket => __awaiter(this, void 0, void 0, function* () {
      const indexes = params._indexes ? Array.isArray(params._indexes) ? params._indexes : [params._indexes] : [interfaces_1.Bbase2Indexes.HEAD];
      const changesIndex = indexes.findIndex(o => o === interfaces_1.Bbase2Indexes.CHANGES);
      if (changesIndex >= 0) {
        indexes.splice(changesIndex, 1, interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED, interfaces_1.Bbase2Indexes.DELETED);
      }
      const revList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revlist/${bucket._id}/${params._id}`), {
        mainRevs: [],
        conflictedRevs: []
      });
      const revsToList = indexes.includes(interfaces_1.Bbase2Indexes.CONFLICTED) ? [].concat(revList.mainRevs, revList.conflictedRevs) : revList.mainRevs;
      const allRevs = yield Promise.all(revsToList.map(rev => __awaiter(this, void 0, void 0, function* () {
        const doc = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revs/${bucket._id}/${params._id}/${rev}`), null);
        if (indexes.includes(doc._index)) {
          return doc;
        }
        return null;
      })));
      return {
        docs: allRevs.filter(Boolean),
        lastEvaluatedKey: null
      };
    })));
  }
  putDocs(docs, writeAuth) {
    return bbase2_utils_1.Bbase2Utils.saveDocs({
      docs,
      writeAuth,
      store: this,
      storeBookingsSaveRes: null,
      internalSave: params => RxJS.of(null).pipe((0, operators_1.mergeMap)(() => __awaiter(this, void 0, void 0, function* () {
        return this._semaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
          var _a, _b;
          const revList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revlist/${params.bucket._id}/${params.newDoc._id}`), {
            mainRevs: [],
            conflictedRevs: []
          });
          if (params.mainIndexes.includes(params.newDoc._index)) {
            const versions = revList.mainRevs.map(rev => bbase2_utils_1.Bbase2Utils.splitRevision(rev)).filter(Boolean).map(rev => rev.version);
            if (versions.includes(params.newDocRev.version)) {
              throw new error_1.BbitError('version-already-exists', {
                _id: params.newDoc._id,
                _rev: params.newDoc._rev
              });
            }
            revList.mainRevs.push(params.newDoc._rev);
            const indexListNew = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${params.bucket._id}/${params.newDoc._index}`), {
              revs: []
            });
            indexListNew.revs = indexListNew.revs.filter(r => r._id !== params.newDoc._id);
            indexListNew.revs.push({
              _id: params.newDoc._id,
              _rev: params.newDoc._rev
            });
            yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${params.bucket._id}/${params.newDoc._index}`, this._serialize(indexListNew));
            if (((_a = params.replacedDoc) === null || _a === void 0 ? void 0 : _a._index) && params.newDoc._index !== ((_b = params.replacedDoc) === null || _b === void 0 ? void 0 : _b._index)) {
              const indexListOld = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${params.bucket._id}/${params.replacedDoc._index}`), {
                revs: []
              });
              indexListOld.revs = indexListOld.revs.filter(r => r._id !== params.replacedDoc._id);
              yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/list/${params.bucket._id}/${params.replacedDoc._index}`, this._serialize(indexListOld));
            }
          } else {
            revList.conflictedRevs.push(params.newDoc._rev);
          }
          yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revlist/${params.bucket._id}/${params.newDoc._id}`, this._serialize(revList));
          yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/doc/revs/${params.bucket._id}/${params.newDoc._id}/${params.newDoc._rev}`, this._serialize(params.newDoc));
          return params;
        }));
      })), (0, operators_1.mergeMap)(saveRes => this.putBucket({
        _bucket: saveRes.bucket._bucket,
        _buckettype: interfaces_1.Bbase2BucketTypes.DOC,
        stats: saveRes.stats
      }).pipe((0, operators_1.map)(() => saveRes))))
    });
  }
  getLockScope(params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!(((_a = params === null || params === void 0 ? void 0 : params.lockScopeBucket) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
        throw new error_invalid_param_1.BbitInvalidParamError({
          param: 'getLockScope(lockScopeBucket)',
          value: params,
          reason: 'must-be-defined'
        });
      }
      const lockScope = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${params.lockScopeBucket}/${params.lockScopeKey}`), null);
      return lockScope;
    });
  }
  _updateKeyList(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const currentKeyList = this._deserialize(yield this._config.store.getItem(params.storageKey), {
        keys: []
      });
      currentKeyList.keys = currentKeyList.keys.filter(r => r !== params.recordKey);
      if (!params._delete) {
        currentKeyList.keys.push(params.recordKey);
      }
      yield this._config.store.setItem(params.storageKey, this._serialize(currentKeyList));
    });
  }
  getLockScopeStats(params) {
    throw new Error('Method not implemented.');
  }
  recalculateLockScope(params, auth) {
    throw new Error('Method not implemented.');
  }
  listLockScopeStats(params) {
    throw new Error('Method not implemented.');
  }
  setLockScope(params, auth) {
    var _a, _b;
    if (!(((_a = params === null || params === void 0 ? void 0 : params._bucket) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'setLockScope(_bucket)',
        value: params,
        reason: 'must-be-defined'
      });
    }
    if (!(((_b = params === null || params === void 0 ? void 0 : params._id) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'setLockScope(_id)',
        value: params,
        reason: 'must-be-defined'
      });
    }
    return this._semaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
      let lockScope = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${params._bucket}/${params._id}`), undefined);
      let hasChanges = false;
      const didExist = !!lockScope;
      if (!didExist) {
        lockScope = {
          _bucket: params._bucket,
          _id: params._id,
          _rev: undefined,
          concurrencyCount: 0,
          leaseCount: 0,
          availableLeaseCount: 0,
          writeProtectedFrom: 0,
          allowMoreLeasesThanConcurrency: params.allowMoreLeasesThanConcurrency,
          allowNegativeConcurrency: params.allowNegativeConcurrency,
          scopeJobPayload: params.scopeJobPayload,
          triggerJobOnScopeLeaseCountChange: params.triggerJobOnScopeLeaseCountChange,
          triggerJobOnScopeConcurrencyCountChange: params.triggerJobOnScopeConcurrencyCountChange,
          statsResolutions: params.statsResolutions,
          settings: params.settings,
          concurrencyCountIn: 0,
          concurrencyCountOut: 0,
          _changedAt: luxon_1.DateTime.local().toISO(),
          _changedBy: auth.userId,
          _changedOn: auth.deviceId
        };
        hasChanges = true;
      }
      if (params._delete) {
        if (lockScope) {
          yield this._config.store.removeItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${params._bucket}/${params._id}`);
        }
      } else {
        const attr = ['unit', 'unitCountFactor', 'allowMoreLeasesThanConcurrency', 'allowNegativeConcurrency', 'scopeJobPayload', 'triggerJobOnScopeLeaseCountChange', 'triggerJobOnScopeConcurrencyCountChange', 'statsResolutions', 'settings'];
        for (const key of attr) {
          if (params[key] !== undefined && params[key] !== lockScope[key] && (lockScope[key] === undefined || params._changePropertyIfDefined)) {
            hasChanges = true;
            lockScope[key] = params[key];
          }
        }
        if (!hasChanges) {
          return lockScope;
        }
        yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${params._bucket}/${params._id}`, this._serialize(Object.assign(Object.assign({}, lockScope), {
          _changedBy: auth.userId,
          _changedAt: luxon_1.DateTime.local().toISO(),
          _changedOn: auth.deviceId
        })));
      }
      yield this._updateKeyList({
        storageKey: `bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope-bucket-list/${params._bucket}`,
        recordKey: params._id,
        _delete: params._delete
      });
      return lockScope;
    }));
  }
  listLockScopes(params) {
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => __awaiter(this, void 0, void 0, function* () {
      const tokenList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope-bucket-list/${params.lockScopeBucket}`), {
        keys: []
      });
      const scopes = yield Promise.all(tokenList.keys.map(lockScopeKey => __awaiter(this, void 0, void 0, function* () {
        const token = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${params.lockScopeBucket}/${lockScopeKey}`), null);
        return token;
      })));
      return {
        scopes,
        lastEvaluatedKey: null
      };
    })));
  }
  acquireLocks(input) {
    return Promise.all(input.lockTokens.map(tokenRequest => __awaiter(this, void 0, void 0, function* () {
      const scope = yield this.setLockScope({
        _bucket: tokenRequest.lockScopeBucket,
        _id: tokenRequest.lockScopeKey,
        _changePropertyIfDefined: false
      }, input.auth);
      return this._semaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        let expireInDuration = null;
        if (tokenRequest.expiresInS != null) {
          if (luxon_1.Duration.isDuration(tokenRequest.expiresInS)) {
            expireInDuration = tokenRequest.expiresInS;
          } else if (typeof tokenRequest.expiresInS === 'string') {
            expireInDuration = luxon_1.Duration.fromISO(tokenRequest.expiresInS);
          } else if (typeof tokenRequest.expiresInS === 'number') {
            expireInDuration = luxon_1.Duration.fromMillis(tokenRequest.expiresInS * 1000);
          } else {
            throw new error_1.BbitError('unknown-acquire-lock-timeout-format', tokenRequest);
          }
        }
        if (!tokenRequest.tokenKey) {
          throw new error_1.BbitError('token-key-must-be-defined', tokenRequest);
        }
        if (!tokenRequest._delete) {
          if (!(tokenRequest === null || tokenRequest === void 0 ? void 0 : tokenRequest.type)) {
            throw new error_1.BbitError('token-type-must-be-defined', tokenRequest);
          }
          if (!((_a = tokenRequest === null || tokenRequest === void 0 ? void 0 : tokenRequest.source) === null || _a === void 0 ? void 0 : _a._bucket)) {
            throw new error_1.BbitError('token-source-bucket-must-be-defined', tokenRequest);
          }
          if (!((_b = tokenRequest === null || tokenRequest === void 0 ? void 0 : tokenRequest.source) === null || _b === void 0 ? void 0 : _b._id)) {
            throw new error_1.BbitError('token-source-id-must-be-defined', tokenRequest);
          }
        }
        const existingLockToken = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/token/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}/${tokenRequest.tokenKey}`), undefined);
        const lockExists = !!(existingLockToken === null || existingLockToken === void 0 ? void 0 : existingLockToken.lockScopeKey);
        if (process === null || process === void 0 ? void 0 : process.env.JEST_WORKER_ID) {
          console.log('debug', {
            lockExists,
            existingLockToken
          });
        }
        const leaseCount = Math.round(tokenRequest.leaseCount || 0);
        const concurrencyCount = Math.round(tokenRequest.concurrencyCount) || 0;
        const writeProtectedFrom = Math.round(tokenRequest.writeProtectedFrom || 0);
        const existingLockLeaseCount = (existingLockToken === null || existingLockToken === void 0 ? void 0 : existingLockToken.leaseCount) || 0;
        const existingLockConcurrencyCount = (existingLockToken === null || existingLockToken === void 0 ? void 0 : existingLockToken.concurrencyCount) || 0;
        const existingWriteProtectedFrom = (existingLockToken === null || existingLockToken === void 0 ? void 0 : existingLockToken.writeProtectedFrom) || 0;
        const leaseDelta = tokenRequest.type !== interfaces_1.Bbase2LockTokenType.LEASE || tokenRequest._delete ? existingLockLeaseCount * -1 : leaseCount - existingLockLeaseCount;
        const concurrencyDelta = tokenRequest._delete || tokenRequest.type !== interfaces_1.Bbase2LockTokenType.CONCURRENCY ? existingLockConcurrencyCount * -1 : concurrencyCount - existingLockConcurrencyCount;
        const writeProtectedFromDelta = tokenRequest._delete || tokenRequest.type !== interfaces_1.Bbase2LockTokenType.WRITE_PROTECTION ? existingWriteProtectedFrom * -1 : writeProtectedFrom - existingWriteProtectedFrom;
        const newLeaseCount = existingLockLeaseCount + leaseDelta;
        const newConcurrencyCount = existingLockConcurrencyCount + concurrencyDelta;
        const newWriteProtectedFrom = existingWriteProtectedFrom + writeProtectedFromDelta;
        const openLeases = scope.concurrencyCount + concurrencyDelta - scope.leaseCount;
        if (!scope.allowMoreLeasesThanConcurrency && tokenRequest.leaseCount !== 0 && openLeases < leaseDelta) {
          throw new error_1.BbitError('acquire-lock-count-exceeds-open-leases', Object.assign({
            concurrencyCount: scope.concurrencyCount,
            concurrencyDelta: concurrencyCount,
            leaseCount: scope.leaseCount
          }, tokenRequest));
        }
        if (!scope.allowNegativeConcurrency && scope.concurrencyCount + concurrencyDelta < 0) {
          throw new error_1.BbitError('acquire-lock-concurrency-must-not-be-below-zero', Object.assign({
            concurrencyCount: scope.concurrencyCount,
            concurrencyDelta: concurrencyCount,
            leaseCount: scope.leaseCount
          }, tokenRequest));
        }
        if (process === null || process === void 0 ? void 0 : process.env.JEST_WORKER_ID) {
          console.log('debug', {
            scope,
            tokenRequest,
            openLeases,
            leaseDelta,
            concurrencyDelta,
            writeProtectedFromDelta
          });
        }
        const hasChange = leaseDelta !== 0 || concurrencyDelta !== 0 || writeProtectedFromDelta !== 0;
        const isEverythingZeroAfterwards = newLeaseCount === 0 && newConcurrencyCount === 0 && newWriteProtectedFrom === 0;
        const deleteLock = lockExists && isEverythingZeroAfterwards;
        const _expires = deleteLock ? Math.floor(luxon_1.DateTime.utc().toSeconds()) : (expireInDuration === null || expireInDuration === void 0 ? void 0 : expireInDuration.get('seconds')) >= 0 ? Math.floor(luxon_1.DateTime.utc().plus(expireInDuration).toSeconds()) : null;
        const writeToken = Object.assign(Object.assign(Object.assign({}, existingLockToken || {}), tokenRequest), {
          concurrencyCount: newConcurrencyCount,
          leaseCount: newLeaseCount,
          writeProtectedFrom: newWriteProtectedFrom,
          utcTimeStampInMS: luxon_1.DateTime.utc().toMillis(),
          _expires,
          _changedBy: input.auth.userId,
          _changedAt: luxon_1.DateTime.local().toISO(),
          _changedOn: input.auth.deviceId
        });
        if (hasChange) {
          if (deleteLock) {
            yield this._config.store.removeItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/token/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}/${tokenRequest.tokenKey}`);
          } else {
            yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/token/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}/${tokenRequest.tokenKey}`, this._serialize(writeToken));
          }
          yield this._updateKeyList({
            storageKey: `bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope-token-list/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}`,
            recordKey: tokenRequest.tokenKey,
            _delete: deleteLock
          });
          const lockScope = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}`), undefined);
          if (process === null || process === void 0 ? void 0 : process.env.JEST_WORKER_ID) {
            console.log('debug', {
              lockScope
            });
          }
          lockScope.leaseCount += leaseDelta;
          lockScope.concurrencyCount += concurrencyDelta;
          lockScope.writeProtectedFrom += writeProtectedFromDelta;
          yield this._config.store.setItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope/${tokenRequest.lockScopeBucket}/${tokenRequest.lockScopeKey}`, this._serialize(Object.assign({}, lockScope)));
          return {
            scope: lockScope,
            token: writeToken
          };
        }
        return {
          scope,
          token: writeToken
        };
      }));
    })));
  }
  deleteExpiredLockTokens(params, auth) {
    return __awaiter(this, void 0, void 0, function* () {
      const now = Math.floor(luxon_1.DateTime.utc().toSeconds());
      const deletedTokens = yield RxJS.of(null).pipe((0, operators_1.mergeMap)(() => this.listLockTokens({
        lockScopeBucket: params.lockScopeBucket,
        lockScopeKey: params.lockScopeKey,
        tokenTypes: params.types,
        retrieveAllPages: true
      })), (0, operators_1.mergeMap)(result => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const expiredLocks = [];
        for (const record of result.tokens) {
          if (record._expires != null && record._expires <= now) {
            expiredLocks.push((_b = (_a = yield this.acquireLocks({
              lockTokens: [Object.assign(Object.assign({}, record), {
                _delete: true
              })],
              auth
            })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.token);
          }
        }
        return expiredLocks;
      })), (0, operators_1.reduce)((acc, value) => acc.concat(value), [])).toPromise();
      return deletedTokens;
    });
  }
  getLockToken(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const lockToken = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/token/${params.lockScopeBucket}/${params.lockScopeKey}/${params.tokenKey}`), undefined);
      return lockToken;
    });
  }
  listLockTokens(params) {
    return RxJS.of(null).pipe((0, operators_1.mergeMap)(() => __awaiter(this, void 0, void 0, function* () {
      const tokenList = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/scope-token-list/${params.lockScopeBucket}/${params.lockScopeKey}`), {
        keys: []
      });
      let tokens = yield Promise.all(tokenList.keys.map(tokenKey => __awaiter(this, void 0, void 0, function* () {
        const token = this._deserialize(yield this._config.store.getItem(`bbase2/${this._config.organizationKey}/${this._config.environmentKey}/lock/token/${params.lockScopeBucket}/${params.lockScopeKey}/${tokenKey}`), null);
        return token;
      })));
      if (params.tokenTypes) {
        tokens = tokens.filter(token => params.tokenTypes.includes(token.type));
      }
      if (params.bySource) {
        tokens = tokens.filter(token => token.source._bucket === params.bySource._bucket && token.source._id === params.bySource._id);
      }
      if (!params.scanIndexForward) {
        tokens = tokens.reverse();
      }
      if (params.limit) {
        tokens = tokens.slice(0, params.limit);
      }
      return {
        tokens,
        lastEvaluatedKey: null
      };
    })));
  }
}
exports.Bbase2StoreKeyValue = Bbase2StoreKeyValue;
