"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitRichTextHelper = void 0;
const log_1 = require("../log/log");
const array_1 = require("../primitives/array");
const interfaces_1 = require("./interfaces");
class BbitRichTextHelper {
  static toHTML(doc, options) {
    const mapNodes = nodes => {
      var _a, _b;
      const html = [];
      if (!nodes || nodes.length === 0) {
        return html;
      }
      for (const o of nodes) {
        switch (o.type) {
          case interfaces_1.BbitRichTextNodeType.HEADING:
            html.push(`<h${o.attrs.level}>${mapNodes(o.content).join('')}</h${o.attrs.level}>`);
            break;
          case interfaces_1.BbitRichTextNodeType.PARAGRAPH:
            html.push(`<p>${((_a = o.content) === null || _a === void 0 ? void 0 : _a.length) > 0 ? mapNodes(o.content).join('') : '<br>'}</p>`);
            break;
          case interfaces_1.BbitRichTextNodeType.BULLET_LIST:
            html.push(`<ul>${mapNodes(o.content).join('')}</ul>`);
            break;
          case interfaces_1.BbitRichTextNodeType.ORDERED_LIST:
            html.push(`<ol>${mapNodes(o.content).join('')}</ol>`);
            break;
          case interfaces_1.BbitRichTextNodeType.LIST_ITEM:
            html.push(`<li>${mapNodes(o.content).join('')}</li>`);
            break;
          case interfaces_1.BbitRichTextNodeType.TEXT:
            {
              let text = o.text;
              const styles = [];
              if (o.marks) {
                for (const m of o.marks) {
                  switch (m.type) {
                    case interfaces_1.BbitRichTextMarkType.DEL:
                      text = `<del>${text}</del>`;
                      break;
                    case interfaces_1.BbitRichTextMarkType.EM:
                      text = `<em>${text}</em>`;
                      break;
                    case interfaces_1.BbitRichTextMarkType.STRONG:
                      text = `<strong>${text}</strong>`;
                      break;
                    case interfaces_1.BbitRichTextMarkType.INS:
                      text = `<ins>${text}</ins>`;
                      break;
                    case interfaces_1.BbitRichTextMarkType.COLOR:
                      if (!(options === null || options === void 0 ? void 0 : options.ignoreColors)) {
                        styles.push(`color: ${m.attrs.color}`);
                      }
                      break;
                    case interfaces_1.BbitRichTextMarkType.LINK:
                      if ((_b = m.attrs) === null || _b === void 0 ? void 0 : _b.href) {
                        text = `<a href="${m.attrs.href}" target="${m.attrs.target || '_blank'}">${text}</a>`;
                      }
                      break;
                    case interfaces_1.BbitRichTextMarkType.BACKGROUND_COLOR:
                      if (!(options === null || options === void 0 ? void 0 : options.ignoreColors)) {
                        styles.push(`background-color: ${m.attrs.color}`);
                      }
                      break;
                    default:
                      BbitRichTextHelper._log.warning(`Mark type ${m.type} is not supported`);
                  }
                }
              }
              if (styles.length > 0) {
                text = `<span style="${styles.join('; ')};">${text}</span>`;
                if (styles.length > 1) {
                  BbitRichTextHelper._log.debug(text);
                }
              }
              html.push(text);
              break;
            }
          default:
            BbitRichTextHelper._log.warning(`Node type ${o.type} is not supported`);
        }
      }
      return html;
    };
    return mapNodes(doc === null || doc === void 0 ? void 0 : doc.content).join('');
  }
  static extractText(doc) {
    const texts = [];
    const extract = (nodes, texts) => {
      const nodeTexts = [];
      if (!nodes || nodes.length === 0) {
        return;
      }
      for (const node of nodes) {
        if (node.type === interfaces_1.BbitRichTextNodeType.TEXT) {
          nodeTexts.push(node.text);
        } else {
          if (node.content) {
            extract(node.content, texts);
          }
        }
      }
      texts.push(array_1.BbitArray.compact(nodeTexts).join(''));
    };
    extract(doc === null || doc === void 0 ? void 0 : doc.content, texts);
    return array_1.BbitArray.compact(texts).join(' ').trim();
  }
  static appendText(doc, text, options) {
    if (!((text === null || text === void 0 ? void 0 : text.length) > 0)) {
      return doc;
    }
    if (!doc) {
      doc = {
        type: interfaces_1.BbitRichTextNodeType.DOC,
        content: []
      };
    }
    const lastNode = doc.content[doc.content.length - 1];
    if (lastNode && lastNode.type === interfaces_1.BbitRichTextNodeType.TEXT && !(options === null || options === void 0 ? void 0 : options.appendOnNewLine)) {
      lastNode.text += text;
    } else {
      doc.content.push({
        type: interfaces_1.BbitRichTextNodeType.PARAGRAPH,
        content: [{
          type: interfaces_1.BbitRichTextNodeType.TEXT,
          text
        }]
      });
    }
    return doc;
  }
}
exports.BbitRichTextHelper = BbitRichTextHelper;
BbitRichTextHelper._log = log_1.BbitLog.scope({
  class: 'BbitRichTextHelper'
});
