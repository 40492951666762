"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitLogInstance = void 0;
const utils_1 = require("../utils/utils");
const result_1 = require("./../primitives/result");
const interfaces_1 = require("./interfaces");
const log_1 = require("./log");
const transport_handler_1 = require("./transport/transport-handler");
class BbitLogInstance {
  constructor(scope) {
    this._scope = scope;
    if (!utils_1.BbitUtils.getGlobal('@bbit/log', 'colors')) {
      utils_1.BbitUtils.setGlobal('@bbit/log', 'colors', []);
    }
  }
  getObserver(options) {
    if (!options) {
      options = {};
    }
    return {
      next: options.logNextLevel ? value => {
        this._log(this._prepareMessage(options.logNextLevel || interfaces_1.BbitLogLevel.DEBUG, options.logMessage, value));
      } : () => {},
      error: err => options.logMessage ? this.error(options.logMessage, err) : this.error(err),
      complete: options.logCompleteLevel ? () => {
        this._log(this._prepareMessage(options.logNextLevel || interfaces_1.BbitLogLevel.DEBUG, options.logMessage, 'COMPLETED'));
      } : () => {}
    };
  }
  updateInstanceScope(scope) {
    this._scope = Object.assign({}, this._scope, scope);
    return this;
  }
  critical(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.CRITICAL, message, ...payload));
  }
  error(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.ERROR, message, ...payload));
  }
  warning(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.WARNING, message, ...payload));
  }
  warn(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.WARNING, message, ...payload));
  }
  success(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.SUCCESS, message, ...payload));
  }
  info(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.INFO, message, ...payload));
  }
  log(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.INFO, message, ...payload));
  }
  logLevel(level, message, ...payload) {
    this._log(this._prepareMessage(level, message, ...payload));
  }
  debug(message, ...payload) {
    this._log(this._prepareMessage(interfaces_1.BbitLogLevel.DEBUG, message, ...payload));
  }
  result(result, ...payload) {
    if (result.logged) {
      return result;
    }
    let level = interfaces_1.BbitLogLevel.INFO;
    switch (result.kind) {
      case result_1.BbitResultKind.CRITICAL_ERROR:
        level = interfaces_1.BbitLogLevel.CRITICAL;
        break;
      case result_1.BbitResultKind.ERROR:
        level = interfaces_1.BbitLogLevel.ERROR;
        break;
      case result_1.BbitResultKind.SUCCESS_WITH_WARNINGS:
        level = interfaces_1.BbitLogLevel.WARNING;
        break;
      case result_1.BbitResultKind.SUCCESS:
        level = interfaces_1.BbitLogLevel.SUCCESS;
        break;
    }
    this._log(this._prepareMessage(level, result.message, ...payload));
    result.logged = true;
    return result;
  }
  _log(message) {
    if (this._isMuted) {
      return;
    }
    const msg = log_1.BbitLog.catchLog(message);
    if (msg) {
      for (const transport of transport_handler_1.BbitLogTransportHandler.getTransports()) {
        transport.log(message);
      }
    }
  }
  _prepareMessage(level, message, ...payload) {
    const msg = {
      message,
      level
    };
    msg.scope = Object.assign(Object.assign({}, log_1.BbitLog.getScopeDefaults()), this._scope || {});
    msg.timestamp = new Date();
    msg.payload = Array.isArray(payload) ? payload : [payload];
    return msg;
  }
  mute() {
    this._isMuted = true;
  }
  unmute() {
    this._isMuted = false;
  }
}
exports.BbitLogInstance = BbitLogInstance;
