"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bbase2FinancialBookingStoreBookings = exports.bbase2FinancialBookingGetLockScopeKeys = exports.bbase2FinancialBookingLockScopeBucketPrefix = void 0;
const luxon_1 = require("luxon");
const error_1 = require("../../primitives/error");
const unit_1 = require("../../unit/unit");
const interfaces_1 = require("./interfaces");
exports.bbase2FinancialBookingLockScopeBucketPrefix = 'financial-bookings';
const bbase2FinancialBookingGetLockScopeKeys = fb => {
  if (!fb.year || !fb.account) {
    throw new error_1.BbitError('invalidFinancialBookingKeys', {
      userMessage: {
        key: 'invalidFinancialBookingKeys',
        n: 1
      }
    });
  }
  return {
    lockScopeBucket: `${exports.bbase2FinancialBookingLockScopeBucketPrefix}-${fb.year}`,
    lockScopeKey: fb.account
  };
};
exports.bbase2FinancialBookingGetLockScopeKeys = bbase2FinancialBookingGetLockScopeKeys;
const bbase2FinancialBookingStoreBookings = (bbaseStore, writeAuth, newDoc, existingTokens) => __awaiter(void 0, void 0, void 0, function* () {
  var _a;
  const newFinancialBooking = ([interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED].includes(newDoc._index) ? newDoc === null || newDoc === void 0 ? void 0 : newDoc._financialBookings : []) || [];
  const existingFinancialTokens = (existingTokens || []).filter(o => o.lockScopeBucket.startsWith(`${exports.bbase2FinancialBookingLockScopeBucketPrefix}-`));
  const storageTokens = newFinancialBooking.map(fb => [Object.assign(Object.assign({}, (0, exports.bbase2FinancialBookingGetLockScopeKeys)({
    year: fb.year,
    account: fb.creditAccount
  })), {
    tokenKey: fb._id,
    type: fb.isReservation ? interfaces_1.Bbase2LockTokenType.LEASE : interfaces_1.Bbase2LockTokenType.CONCURRENCY,
    utcTimeStampInMS: luxon_1.DateTime.fromISO(fb.date).toUTC().toMillis(),
    financialBooking: fb,
    directionFactor: -1,
    _delete: !!fb._delete,
    isOpeningBooking: fb.isOpeningBooking,
    isClosingBooking: fb.isClosingBooking,
    expiresInS: null,
    source: {
      _bucket: newDoc._bucket,
      _id: newDoc._id,
      _rev: newDoc._rev,
      _createdAt: newDoc._createdAt
    }
  }), Object.assign(Object.assign({}, (0, exports.bbase2FinancialBookingGetLockScopeKeys)({
    year: fb.year,
    account: fb.debitAccount
  })), {
    tokenKey: fb._id,
    type: fb.isReservation ? interfaces_1.Bbase2LockTokenType.LEASE : interfaces_1.Bbase2LockTokenType.CONCURRENCY,
    utcTimeStampInMS: luxon_1.DateTime.fromISO(fb.date).toUTC().toMillis(),
    financialBooking: fb,
    directionFactor: 1,
    _delete: !!fb._delete,
    isOpeningBooking: fb.isOpeningBooking,
    isClosingBooking: fb.isClosingBooking,
    expiresInS: null,
    source: {
      _bucket: newDoc._bucket,
      _id: newDoc._id,
      _rev: newDoc._rev,
      _createdAt: newDoc._createdAt
    }
  })]).reduce((acc, val) => acc.concat(val), []);
  const newTokenMap = storageTokens.reduce((acc, val) => {
    acc[`${val.lockScopeBucket}/${val.lockScopeKey}/${val.tokenKey}`] = val;
    return acc;
  }, {});
  const tokensToRemove = existingFinancialTokens.filter(o => !newTokenMap[`${o.lockScopeBucket}/${o.lockScopeKey}/${o.tokenKey}`]).map(o => Object.assign(Object.assign({}, o), {
    _delete: true
  }));
  if (storageTokens.length > 0 || tokensToRemove.length > 0) {
    const lockScopeIndex = {};
    yield Promise.all(storageTokens.map(scope => __awaiter(void 0, void 0, void 0, function* () {
      var _a, _b;
      const scopeKey = `${scope.lockScopeBucket}/${scope.lockScopeKey}`;
      if (!lockScopeIndex[scopeKey]) {
        lockScopeIndex[scopeKey] = {};
        const existingScope = yield bbaseStore.getLockScope({
          lockScopeBucket: scope.lockScopeBucket,
          lockScopeKey: scope.lockScopeKey
        });
        if (!existingScope || !existingScope.settings) {
          lockScopeIndex[scopeKey] = yield bbaseStore.setLockScope({
            _bucket: scope.lockScopeBucket,
            _id: scope.lockScopeKey,
            unit: ((_b = (_a = scope.financialBooking) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.currency) || '#CHF',
            unitCountFactor: 100,
            allowNegativeConcurrency: true,
            allowMoreLeasesThanConcurrency: true,
            statsResolutions: {
              dailyCET: {
                startTimeISO: luxon_1.DateTime.fromSQL('2021-01-01', {
                  zone: 'Europe/Zurich'
                }).startOf('day').toISO(),
                endTimeISO: luxon_1.DateTime.fromSQL('2021-01-01', {
                  zone: 'Europe/Zurich'
                }).endOf('day').toISO()
              }
            },
            _changePropertyIfDefined: false
          }, writeAuth);
        } else {
          lockScopeIndex[scopeKey] = existingScope;
        }
      }
    })));
    for (const booking of storageTokens) {
      const scope = lockScopeIndex[`${booking.lockScopeBucket}/${booking.lockScopeKey}`];
      const count = Math.round(unit_1.BbitUnit.from(booking.financialBooking.amount).multiply((_a = booking.directionFactor) !== null && _a !== void 0 ? _a : 1).amount * (scope.unitCountFactor || 100));
      switch (booking.type) {
        case interfaces_1.Bbase2LockTokenType.LEASE:
          booking.leaseCount = count;
          break;
        case interfaces_1.Bbase2LockTokenType.CONCURRENCY:
          booking.concurrencyCount = count;
          break;
      }
    }
    const result = yield bbaseStore.acquireLocks({
      lockTokens: [].concat(tokensToRemove, storageTokens),
      auth: writeAuth
    });
    return result;
  }
  return [];
});
exports.bbase2FinancialBookingStoreBookings = bbase2FinancialBookingStoreBookings;
