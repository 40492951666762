"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitHttpClient = void 0;
const rxjs_1 = require("rxjs");
const log_1 = require("../log/log");
const error_1 = require("../primitives/error");
const object_1 = require("../primitives/object");
const utils_1 = require("../utils/utils");
class BbitHttpClient {
  static fetch(input, init) {
    return fetch(input, init);
  }
  static getContentType(res) {
    if (res.bodyUsed) return null;
    if (res.status === 204 || res.status === 304) return null;
    const contentType = res.headers.get('content-type');
    if (contentType != null && contentType !== '') {
      return contentType.split(';')[0].toLowerCase();
    }
    return undefined;
  }
  constructor(url) {
    this._baseUrl = url.endsWith('/') ? url.slice(0, -1) : url;
    this.log = log_1.BbitLog.scope({
      function: 'HttpClient',
      package: '@bbit/http-client'
    });
  }
  getBaseUrl() {
    return this._baseUrl;
  }
  fetchJsonEndpoint(params) {
    var _a;
    return this.fetchJson({
      authorization: params.authorization,
      body: params.body,
      bodyRaw: params.bodyRaw,
      bodyToUrlEncode: params.bodyToUrlEncode,
      method: params.method,
      subUrl: `${(params.endpoint + '').startsWith('/') ? '' : '/'}${params.endpoint}${((_a = params.endpointResource) === null || _a === void 0 ? void 0 : _a.length) > 0 ? `/${params.endpointResource}` : ''}`,
      subUrlParams: params.endpointParams
    });
  }
  fetchJson(params = {}) {
    return (0, rxjs_1.from)(this.requestJson(params));
  }
  requestJson(params = {}) {
    const urlParams = '?' + (params.query ? Object.entries(object_1.BbitObject.pickBy(params.query)).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&') : '');
    let subUrl = params.subUrl || '';
    if (params.subUrlParams) {
      for (const key in params.subUrlParams) {
        const value = params.subUrlParams[key];
        subUrl = subUrl.replace(new RegExp(':' + key, 'g'), encodeURIComponent(value));
      }
    }
    const url = this._baseUrl + subUrl + (urlParams.length > 2 ? urlParams : '');
    if (params.bodyToUrlEncode) {
      params.bodyRaw = BbitHttpClient.createWWWFormUrlEncodedString(params.bodyToUrlEncode);
    }
    return BbitHttpClient.fetch(url, {
      body: params.bodyRaw ? params.bodyRaw : params.body ? JSON.stringify(params.body) : undefined,
      credentials: 'same-origin',
      headers: object_1.BbitObject.pickBy(Object.assign(Object.assign({
        'Content-Length': undefined,
        'Content-Type': 'application/json',
        accept: 'application/json',
        authorization: params.authorization,
        'x-bbit-log': 'verbose'
      }, params.bodyToUrlEncode ? {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Length': '' + params.bodyRaw.length
      } : {}), params.headers || {})),
      method: params.method || 'GET'
    }).then(response => __awaiter(this, void 0, void 0, function* () {
      const contentType = params.enforceResponseContentType || BbitHttpClient.getContentType(response);
      if (!contentType) return contentType;
      let body = undefined;
      switch (true) {
        case contentType.endsWith('json'):
          {
            const text = yield response.text();
            if (text.length === 0) {
              this.log.warning('http-response-body-json-is-empty', {
                method: params.method,
                status: response.status,
                url: response.url
              });
            }
            try {
              body = text.length > 0 ? JSON.parse(text) : undefined;
            } catch (err) {
              const formatError = new error_1.BbitError('http-response-body-json-is-invalid', {
                error: err,
                body: text,
                method: params.method,
                status: response.status,
                url: response.url
              });
              this.log.error(formatError);
              return Promise.reject(formatError);
            }
            break;
          }
        case contentType.startsWith('text/'):
          {
            const textBody = yield response.text();
            if (textBody) {
              try {
                body = JSON.parse(textBody);
              } catch (err) {
                this.log.warning('error-while-parsing-body-returning-string', {
                  method: params.method,
                  status: response.status,
                  url: response.url,
                  err
                });
                body = textBody;
              }
            } else {
              this.log.warning('http-response-body-json-is-empty', {
                method: params.method,
                status: response.status,
                url: response.url
              });
            }
            break;
          }
        case contentType === 'application/octet-stream':
          {
            const buffer = yield response.arrayBuffer();
            const decoder = new TextDecoder('utf-8');
            const bufferString = decoder.decode(buffer);
            if (bufferString) {
              try {
                body = JSON.parse(bufferString);
              } catch (err) {
                this.log.warning('error-while-parsing-body-returning-string', {
                  method: params.method,
                  status: response.status,
                  url: response.url,
                  err
                });
                body = bufferString;
              }
            } else {
              this.log.warning('http-response-body-json-is-empty', {
                method: params.method,
                status: response.status,
                url: response.url
              });
            }
            break;
          }
        default:
          this.log.warning('http-response-unknown-content-type', {
            contentType,
            fromHeader: response.headers.get('content-type'),
            method: params.method,
            status: response.status,
            url: response.url
          });
          body = yield response.arrayBuffer();
          break;
      }
      if (!response.ok) {
        if (body && body.code && body.code.length > 0) {
          const apiError = new error_1.BbitError(body.code, Object.assign({}, body.params || {}, {
            method: params.method,
            reason: response.statusText,
            status: response.status,
            url: response.url
          }));
          this.log.error(apiError);
          return Promise.reject(apiError);
        }
        const retryNumber = params.retryNumber || 0;
        if ((body === null || body === void 0 ? void 0 : body.message) === 'Not Found' && response.status === 404 && retryNumber < 1) {
          console.error('API GATEWAY lambda error on ' + response.url + ', retry once...');
          yield utils_1.BbitUtils.wait(100);
          return this.fetchJson(Object.assign(Object.assign({}, params), {
            retryNumber: retryNumber + 1
          }));
        }
        const requestError = new error_1.BbitError('http-request-rejected', {
          body,
          method: params.method,
          reason: response.statusText,
          status: response.status,
          url: response.url
        });
        this.log.error(requestError);
        return Promise.reject(requestError);
      }
      this.log.debug(params.method + ' (' + response.status + ') ' + url, body);
      return Promise.resolve(body);
    }), error => {
      const networkError = new error_1.BbitError('http-request-network-error', {
        error,
        method: params.method,
        retryable: true,
        url
      });
      this.log.error('Error on http ' + (params.method || 'GET') + ' ' + url, {
        error
      });
      return Promise.reject(networkError);
    });
  }
}
exports.BbitHttpClient = BbitHttpClient;
BbitHttpClient.createWWWFormUrlEncodedString = obj => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] != null) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};
