"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitSRP = void 0;
const universal_crypto_1 = require("./universal-crypto");
class BbitSRP {
  constructor(crypto = universal_crypto_1.BbitUniversalCrypto.singleton()) {
    this.crypto = crypto;
    this.debug = false;
  }
  init4096SrpConstants() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this._constants) {
        const g = BigInt(2);
        const N = BigInt('0x' + 'FFFFFFFFFFFFFFFFC90FDAA22168C234C4C6628B80DC1CD1' + '29024E088A67CC74020BBEA63B139B22514A08798E3404DD' + 'EF9519B3CD3A431B302B0A6DF25F14374FE1356D6D51C245' + 'E485B576625E7EC6F44C42E9A637ED6B0BFF5CB6F406B7ED' + 'EE386BFB5A899FA5AE9F24117C4B1FE649286651ECE45B3D' + 'C2007CB8A163BF0598DA48361C55D39A69163FA8FD24CF5F' + '83655D23DCA3AD961C62F356208552BB9ED529077096966D' + '670C354E4ABC9804F1746C08CA18217C32905E462E36CE3B' + 'E39E772C180E86039B2783A2EC07A28FB5C55DF06F4C52C9' + 'DE2BCBF6955817183995497CEA956AE515D2261898FA0510' + '15728E5A8AAAC42DAD33170D04507A33A85521ABDF1CBA64' + 'ECFB850458DBEF0A8AEA71575D060C7DB3970F85A6E1E4C7' + 'ABF5AE8CDB0933D71E8C94E04A25619DCEE3D2261AD2EE6B' + 'F12FFA06D98A0864D87602733EC86A64521F2B18177B200C' + 'BBE117577A615D6C770988C0BAD946E208E24FA074E5AB31' + '43DB5BFCE0FD108E4B82D120A93AD2CAFFFFFFFFFFFFFFFF');
        const k = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(`${this.crypto.padHex(N.toString(16))}${this.crypto.padHex(g.toString(16))}`));
        this._constants = {
          g,
          N,
          k
        };
      }
      return this._constants;
    });
  }
  formatDate(d) {
    const parts = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short',
      hour12: false
    }).formatToParts(d);
    const p = type => {
      var _a;
      return (_a = parts.find(part => part.type === type)) === null || _a === void 0 ? void 0 : _a.value;
    };
    return [p('weekday'), p('month'), p('day'), [p('hour'), p('minute'), p('second')].join(':'), p('timeZoneName'), p('year')].join(' ');
  }
  calculateCognitoSrpSignature(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        smallA,
        largeAHex,
        srpBHex,
        salt,
        userPoolId,
        username,
        password,
        secretBlock
      } = params;
      const aPlusBHex = this.crypto.padHex(largeAHex) + this.crypto.padHex(srpBHex);
      const u = yield this.crypto.webCrypto.subtle.digest('SHA-256', this.crypto.hexToArrayBuffer(aPlusBHex));
      const [, userPoolName] = userPoolId.split('_');
      const usernamePasswordHash = yield this.crypto.webCrypto.subtle.digest('SHA-256', new TextEncoder().encode(`${userPoolName}${username}:${password}`));
      const x = yield this.crypto.webCrypto.subtle.digest('SHA-256', yield new Blob([this.crypto.hexToArrayBuffer(this.crypto.padHex(salt)), usernamePasswordHash]).arrayBuffer());
      const {
        g,
        N,
        k
      } = yield this.init4096SrpConstants();
      const gModPowXN = this.crypto.modPow(g, this.crypto.arrayBufferToBigInt(x), N);
      const int = BigInt(`0x${srpBHex}`) - k * gModPowXN;
      const s = this.crypto.modPow(int, smallA + this.crypto.arrayBufferToBigInt(u) * this.crypto.arrayBufferToBigInt(x), N);
      const ikmHex = this.crypto.padHex(s.toString(16));
      const saltHkdfHex = this.crypto.padHex(this.crypto.arrayBufferToHex(u));
      const infoBits = new Uint8Array([...'Caldera Derived Key'.split('').map(c => c.charCodeAt(0)), 1]).buffer;
      const prkKey = yield this.crypto.webCrypto.subtle.importKey('raw', this.crypto.hexToArrayBuffer(saltHkdfHex), {
        name: 'HMAC',
        hash: {
          name: 'SHA-256'
        }
      }, false, ['sign']);
      const prk = yield this.crypto.webCrypto.subtle.sign('HMAC', prkKey, this.crypto.hexToArrayBuffer(ikmHex));
      const hkdfKey = yield this.crypto.webCrypto.subtle.importKey('raw', prk, {
        name: 'HMAC',
        hash: {
          name: 'SHA-256'
        }
      }, false, ['sign']);
      const hkdf = (yield this.crypto.webCrypto.subtle.sign('HMAC', hkdfKey, infoBits)).slice(0, 16);
      const timestamp = this.formatDate(new Date());
      const parts = [userPoolName.split('').map(c => c.charCodeAt(0)), username.split('').map(c => c.charCodeAt(0)), ...this.crypto.base64.bufferFromBase64(secretBlock), timestamp.split('').map(c => c.charCodeAt(0))].flat();
      const msg = new Uint8Array(parts).buffer;
      const signatureKey = yield this.crypto.webCrypto.subtle.importKey('raw', hkdf, {
        name: 'HMAC',
        hash: {
          name: 'SHA-256'
        }
      }, false, ['sign']);
      const signatureString = yield this.crypto.webCrypto.subtle.sign('HMAC', signatureKey, msg);
      return {
        timestamp,
        passwordClaimSignature: this.crypto.base64.bufferToBase64(signatureString)
      };
    });
  }
  stepSetup_computeVerifier(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        N,
        g
      } = this._constants ? this._constants : yield this.init4096SrpConstants();
      const parts = [params.salt.split('').map(c => c.charCodeAt(0)), params.identifier.split('').map(c => c.charCodeAt(0)), params.password.split('').map(c => c.charCodeAt(0))].flat();
      const msg = new Uint8Array(parts).buffer;
      const x = yield this.crypto.sha256AsBigInt(msg);
      const v = this.crypto.modPow(g, x, N);
      if (this.debug) {
        console.log('step setup', Object.assign(Object.assign({}, params), {
          x,
          v
        }));
      }
      return {
        x,
        verifierHex: v.toString(16)
      };
    });
  }
  step0_computeA() {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        N,
        g
      } = this._constants ? this._constants : yield this.init4096SrpConstants();
      const a = this.crypto.generateRandomNumber(128);
      const A = this.crypto.modPow(g, a, N);
      return {
        clientSecretExponent: a.toString(16),
        clientPublicValueA: A.toString(16)
      };
    });
  }
  step1_computeServerPublicValueB(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        verifierHex,
        serverSecretExponent
      } = params;
      const {
        N,
        g,
        k
      } = yield this.init4096SrpConstants();
      const v = this.crypto.hexToBigInt(verifierHex);
      const b = serverSecretExponent ? this.crypto.hexToBigInt(serverSecretExponent) : this.crypto.generateRandomNumber(128);
      const B = this.crypto.modulo(k * v + this.crypto.modPow(g, b, N), N);
      if (this.debug) {
        console.log('step 1', {
          v,
          k,
          b,
          B
        });
      }
      return {
        serverSecretExponent: b.toString(16),
        serverPublicValueB: B.toString(16)
      };
    });
  }
  step2_computeChallengeResponseM1(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        N,
        g,
        k
      } = yield this.init4096SrpConstants();
      const {
        clientSecretExponent,
        clientPublicValueA
      } = params.clientSecretExponent && params.clientPublicValueA ? {
        clientPublicValueA: params.clientPublicValueA,
        clientSecretExponent: params.clientSecretExponent
      } : yield this.step0_computeA();
      const {
        serverPublicValueB
      } = params;
      const a = this.crypto.hexToBigInt(clientSecretExponent);
      const B = this.crypto.hexToBigInt(serverPublicValueB);
      const u = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(`${this.crypto.padHex(clientPublicValueA)}${this.crypto.padHex(serverPublicValueB)}`));
      const {
        x,
        verifierHex
      } = yield this.stepSetup_computeVerifier({
        identifier: params.identifier,
        password: params.password,
        salt: params.salt
      });
      const S = this.crypto.modPow(B - k * this.crypto.modPow(g, x, N), a + u * x, N);
      const M1 = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(clientPublicValueA) + this.crypto.padHex(serverPublicValueB) + this.crypto.padHex(S.toString(16))));
      const K = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(S.toString(16))));
      if (this.debug) {
        const A = this.crypto.hexToBigInt(clientPublicValueA);
        console.log('step 2', {
          A,
          a,
          B,
          u,
          x,
          verifierHex,
          S,
          M1
        });
      }
      return {
        clientPublicValueA: clientPublicValueA,
        clientSecretExponent: clientSecretExponent,
        clientChallengeResponseM1: M1.toString(16),
        sessionKeyK: K.toString(16)
      };
    });
  }
  step3_validateClientChallengeResponseM1(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        clientPublicValueA,
        verifierHex,
        serverSecretExponent,
        clientChallengeResponseM1
      } = params;
      const {
        N
      } = this._constants ? this._constants : yield this.init4096SrpConstants();
      const A = this.crypto.hexToBigInt(clientPublicValueA);
      const v = this.crypto.hexToBigInt(verifierHex);
      const b = this.crypto.hexToBigInt(serverSecretExponent);
      const {
        serverPublicValueB
      } = yield this.step1_computeServerPublicValueB(params);
      const u = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(`${this.crypto.padHex(clientPublicValueA)}${this.crypto.padHex(serverPublicValueB)}`));
      const S = this.crypto.modPow(A * this.crypto.modPow(v, u, N), b, N);
      const M1 = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(clientPublicValueA) + this.crypto.padHex(serverPublicValueB) + this.crypto.padHex(S.toString(16))));
      const K = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(S.toString(16))));
      if (this.debug) {
        const B = this.crypto.hexToBigInt(serverPublicValueB);
        console.log('step 3', {
          A,
          B,
          b,
          u,
          v,
          S,
          M1,
          K
        });
      }
      const M1Client = this.crypto.hexToBigInt(clientChallengeResponseM1);
      if (M1 !== M1Client) {
        if (this.debug) {
          console.error('Invalid clientChallengeResponseM1', {
            server: M1,
            client: M1Client
          });
        }
        throw new Error('Invalid clientChallengeResponseM1');
      }
      return {
        valid: true,
        sessionKeyK: K.toString(16),
        serverChallengeResponseM2: (yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(clientPublicValueA) + this.crypto.padHex(M1.toString(16)) + this.crypto.padHex(K.toString(16))))).toString(16)
      };
    });
  }
  step4_validateServerChallengeM2(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        serverChallengeResponseM2,
        clientPublicValueA,
        clientChallengeResponseM1,
        sessionKeyK
      } = params;
      const M2 = yield this.crypto.sha256AsBigInt(this.crypto.hexToArrayBuffer(this.crypto.padHex(clientPublicValueA) + this.crypto.padHex(clientChallengeResponseM1) + this.crypto.padHex(sessionKeyK)));
      if (this.debug) {
        console.log('step 4', {
          M2
        });
      }
      const M2Server = this.crypto.hexToBigInt(serverChallengeResponseM2);
      if (M2 !== M2Server) {
        if (this.debug) {
          console.error('Invalid serverChallengeM2', {
            server: M2,
            client: M2Server
          });
        }
        throw new Error('Invalid serverChallengeM2');
      }
      return {
        valid: true
      };
    });
  }
}
exports.BbitSRP = BbitSRP;
