"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitRegistry = void 0;
const luxon_1 = require("luxon");
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const error_invalid_param_1 = require("../primitives/error-invalid-param");
const function_1 = require("../primitives/function");
const object_1 = require("../primitives/object");
const result_1 = require("./../primitives/result");
class BbitRegistry {
  constructor(_config) {
    this._config = _config;
    if (!this._config) {
      this._config = {
        onExistingKey: 'fail'
      };
    }
    this._onChanged = new RxJS.Subject();
    this.clear();
  }
  dump() {
    return object_1.BbitObject.cloneDeep(this._properties);
  }
  observeAll() {
    return this._onChanged.asObservable();
  }
  observeKey(partKey, sortKey) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    return this.observeAll().pipe((0, operators_1.map)(value => value.pairs.find(pair => pair.partKey === partKey && pair.sortKey === sortKey)), (0, operators_1.filter)(value => !!value));
  }
  observePartitionKey(partKey) {
    if (!partKey) {
      partKey = '';
    }
    return this.observeAll().pipe((0, operators_1.map)(value => value.pairs.find(pair => pair.partKey === partKey)), (0, operators_1.filter)(value => !!value));
  }
  clear() {
    const pairs = this.listPairs();
    this._properties = {};
    this._deletedProperties = {};
    this._onChanged.next({
      action: 'clear',
      pairs
    });
  }
  has(partKey, sortKey) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    return this._properties.hasOwnProperty(partKey) && this._properties[partKey].hasOwnProperty(sortKey);
  }
  hasNotExpired(partKey, sortKey) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    if (this._properties.hasOwnProperty(partKey) && this._properties[partKey].hasOwnProperty(sortKey)) {
      const element = this._properties[partKey][sortKey];
      if (element.expiresAt && element.expiresAt.diffNow().milliseconds < 0) {
        return false;
      }
      return true;
    }
    return false;
  }
  _setProperty(properties, pair, loadingObservable, opts) {
    if (!properties[pair.partKey]) {
      properties[pair.partKey] = {};
    }
    if (!properties[pair.partKey][pair.sortKey]) {
      properties[pair.partKey][pair.sortKey] = {
        beforeExpiringHandler: [],
        expiresAt: null,
        expiresIn: null,
        loadingSubject: null,
        modified: opts.setModifiedTo,
        value: pair.value
      };
    }
    if (loadingObservable) {
      properties[pair.partKey][pair.sortKey].loadingSubject = new RxJS.Subject();
      loadingObservable.pipe((0, operators_1.delay)(0), (0, operators_1.map)(retrievedPairs => {
        const relevantPairs = retrievedPairs.filter(rPair => rPair.partKey === pair.partKey && rPair.sortKey === pair.sortKey);
        relevantPairs.forEach(retrievedPair => {
          if (this.has(retrievedPair.partKey, retrievedPair.sortKey)) {
            this._setProperty(this._properties, retrievedPair, undefined, opts);
            this._onChanged.next({
              action: 'set',
              pairs: [retrievedPair]
            });
          }
        });
        return relevantPairs;
      }), (0, operators_1.filter)(retrievedPairs => retrievedPairs && retrievedPairs.length > 0), properties[pair.partKey][pair.sortKey].expiresIn ? (0, operators_1.timeout)(properties[pair.partKey][pair.sortKey].expiresIn.milliseconds) : (0, operators_1.map)(v => v), (0, operators_1.catchError)(err => {
        if (this.has(pair.partKey, pair.sortKey)) {
          this._properties[pair.partKey][pair.sortKey].loadingSubject = null;
        }
        this.discard(pair.partKey, pair.sortKey);
        return RxJS.throwError(() => err);
      }), (0, operators_1.finalize)(() => {
        if (this.has(pair.partKey, pair.sortKey)) {
          this._properties[pair.partKey][pair.sortKey].loadingSubject = null;
        }
      })).subscribe(properties[pair.partKey][pair.sortKey].loadingSubject);
    }
    properties[pair.partKey][pair.sortKey].beforeExpiringHandler.forEach(handler => {
      clearTimeout(handler);
    });
    properties[pair.partKey][pair.sortKey].modified = opts.setModifiedTo;
    properties[pair.partKey][pair.sortKey].value = pair.value;
    properties[pair.partKey][pair.sortKey].expiresIn = opts.expiresIn;
    properties[pair.partKey][pair.sortKey].expiresAt = opts.expiresIn ? luxon_1.DateTime.local().plus(opts.expiresIn) : undefined;
    properties[pair.partKey][pair.sortKey].beforeExpiringHandler = opts.expiresIn ? (this._config.onExpiresBeforeHandler || []).map(handlerConfig => {
      if (opts.expiresIn) {
        const callIn = Math.max(opts.expiresIn.minus(handlerConfig.before).milliseconds, 0);
        return setTimeout(() => handlerConfig.func({
          partKey: pair.partKey,
          sortKey: pair.sortKey,
          value: this.get(pair.partKey, pair.sortKey)
        }), callIn);
      }
    }).filter(Boolean) : [];
  }
  set(values, opts) {
    if (!opts) {
      opts = {};
    }
    if (!opts.hasOwnProperty('emptyAll')) {
      opts.emptyAll = false;
    }
    if (!opts.hasOwnProperty('setModifiedTo')) {
      opts.setModifiedTo = true;
    }
    if (!opts.expiresIn) {
      opts.expiresIn = this._config.defaultExpiresIn;
    }
    if (!values || values.length === 0) {
      return result_1.BbitResult.createSuccess();
    }
    let pairs = values.map(value => {
      if (value === null || value === void 0 ? void 0 : value.doc) {
        return {
          partKey: value.doc._bucket || '',
          sortKey: value.doc._id || '',
          value: value.doc
        };
      }
      if (!value.partKey) {
        value.partKey = '';
      }
      if (!value.sortKey) {
        value.sortKey = '';
      }
      return value;
    });
    switch (this._config.onExistingKey) {
      case 'fail':
        {
          const existingKey = (pairs || []).find(pair => this.hasNotExpired(pair.partKey, pair.sortKey));
          if (existingKey) {
            return result_1.BbitResult.createError(new error_invalid_param_1.BbitInvalidParamError({
              param: 'key',
              reason: 'key-already-exists',
              value: existingKey
            }));
          }
          break;
        }
      case 'existingKeyHandler':
        try {
          pairs = (pairs || []).map(pair => {
            if (this.hasNotExpired(pair.partKey, pair.sortKey)) {
              return this._config.onExistingKeyHandler(pair);
            }
            return pair;
          });
        } catch (err) {
          return result_1.BbitResult.createError(err);
        }
        break;
    }
    const batchLoadingSubject = opts.batchLoadingObservable && RxJS.isObservable(opts.batchLoadingObservable) ? new RxJS.Subject() : null;
    if (batchLoadingSubject) {
      opts.batchLoadingObservable.subscribe(batchLoadingSubject);
    }
    const newProps = opts.emptyAll ? {} : Object.assign({}, this._properties);
    pairs.forEach(pair => {
      let loadingObservable;
      if (!batchLoadingSubject && RxJS.isObservable(pair.loadingObservable)) {
        loadingObservable = pair.loadingObservable.pipe((0, operators_1.map)(value => [{
          partKey: pair.partKey,
          sortKey: pair.sortKey,
          value
        }]));
      } else if (batchLoadingSubject) {
        loadingObservable = batchLoadingSubject.asObservable();
      }
      this._setProperty(newProps, pair, loadingObservable, opts);
    });
    this._properties = newProps;
    this._onChanged.next({
      action: 'set',
      pairs
    });
    return result_1.BbitResult.createSuccess();
  }
  batchDiscard(pairs) {
    if (!pairs || pairs.length === 0) {
      return result_1.BbitResult.createSuccess();
    }
    const newProps = Object.assign({}, this._properties);
    pairs = pairs.map(pair => {
      if (!this.has(pair.partKey, pair.sortKey)) {
        return null;
      }
      delete newProps[pair.partKey || ''][pair.sortKey || ''];
      if (object_1.BbitObject.isEmpty(newProps[pair.partKey || ''])) {
        delete newProps[pair.partKey || ''];
      }
      return pair;
    });
    this._properties = newProps;
    this._onChanged.next({
      action: 'discard',
      pairs
    });
    return result_1.BbitResult.createSuccess();
  }
  discard(partKey, sortKey) {
    if (!this.has(partKey, sortKey)) {
      return undefined;
    }
    return this.batchDiscard([{
      partKey,
      sortKey,
      value: this._properties[partKey || ''][sortKey || ''].value
    }]);
  }
  discardExpired() {
    return this.batchDiscard(this.listExpiredPairs());
  }
  batchDelete(pairs) {
    if (!pairs || pairs.length === 0) {
      return result_1.BbitResult.createSuccess();
    }
    const newProps = Object.assign({}, this._properties);
    pairs = pairs.map(pair => {
      const partKey = pair.partKey || '';
      const sortKey = pair.sortKey || '';
      if (!this.has(partKey, sortKey)) {
        return null;
      }
      if (!this._deletedProperties[partKey]) {
        this._deletedProperties[partKey] = {};
      }
      this._deletedProperties[partKey][sortKey] = newProps[partKey][sortKey];
      delete newProps[partKey][sortKey];
      if (object_1.BbitObject.isEmpty(newProps[partKey])) {
        delete newProps[partKey];
      }
      return pair;
    });
    this._properties = newProps;
    this._onChanged.next({
      action: 'delete',
      pairs
    });
    return result_1.BbitResult.createSuccess();
  }
  delete(partKey, sortKey) {
    if (!this.has(partKey, sortKey)) {
      return undefined;
    }
    return this.batchDelete([{
      partKey,
      sortKey,
      value: this._properties[partKey || ''][sortKey || ''].value
    }]);
  }
  batchGet(pairs, notSetValue) {
    if (!pairs || pairs.length === 0) {
      return [];
    }
    return pairs.map(pair => {
      pair.value = this.get(pair.partKey, pair.sortKey, notSetValue);
      return pair.value;
    });
  }
  get(partKey, sortKey, notSetValue) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    return this.hasNotExpired(partKey, sortKey) ? this._properties[partKey][sortKey].value : notSetValue;
  }
  batchFetch(pairs, options) {
    if (!options) {
      options = {};
    }
    if (!pairs || pairs.length === 0) {
      return RxJS.EMPTY;
    }
    const missingAndNotLoading = pairs.filter(pair => !this.hasNotExpired(pair.partKey, pair.sortKey));
    if (missingAndNotLoading.length === 0) {
      this.batchGet(pairs, options.notSetValue);
      return RxJS.of(...pairs);
    }
    if (!options.onMissingBatchLoader && function_1.BbitFunction.isFunction(this._config.defaultBatchLoader)) {
      options.onMissingBatchLoader = this._config.defaultBatchLoader(pairs);
    }
    if (RxJS.isObservable(options.onMissingBatchLoader)) {
      const setRes = this.set(missingAndNotLoading, {
        batchLoadingObservable: options.onMissingBatchLoader,
        emptyAll: false,
        expiresIn: options.expiresIn,
        setModifiedTo: false
      });
      if (result_1.BbitResult.isError(setRes)) {
        return setRes.toObservable();
      }
      const loadingObs = pairs.map(pair => this.getLoadingObservable(pair.partKey, pair.sortKey, options.notSetValue));
      return RxJS.merge(loadingObs).pipe((0, operators_1.mergeAll)());
    }
    return RxJS.merge(pairs.map(pair => this.fetch(pair.partKey, pair.sortKey, options))).pipe((0, operators_1.mergeAll)());
  }
  fetch(partKey, sortKey, options) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    if (!options) {
      options = {};
    }
    if (!this.hasNotExpired(partKey, sortKey)) {
      if (!options.onMissingSingleLoader && function_1.BbitFunction.isFunction(this._config.defaultSingleLoader)) {
        options.onMissingSingleLoader = this._config.defaultSingleLoader({
          partKey,
          sortKey
        });
      }
      if (RxJS.isObservable(options.onMissingSingleLoader)) {
        const setRes = this.set([{
          loadingObservable: options.onMissingSingleLoader,
          partKey,
          sortKey,
          value: undefined
        }], {
          emptyAll: false,
          expiresIn: options.expiresIn,
          setModifiedTo: false
        });
        if (result_1.BbitResult.isError(setRes)) {
          return setRes.toObservable();
        }
      }
    }
    return this.getLoadingObservable(partKey, sortKey, options.notSetValue);
  }
  getLoadingObservable(partKey, sortKey, notSetValue) {
    if (!partKey) {
      partKey = '';
    }
    if (!sortKey) {
      sortKey = '';
    }
    if (this.hasNotExpired(partKey, sortKey)) {
      if (this._properties[partKey][sortKey].loadingSubject) {
        return this._properties[partKey][sortKey].loadingSubject.asObservable().pipe((0, operators_1.map)(p => p.filter(v => v.partKey === partKey && v.sortKey === sortKey)), (0, operators_1.filter)(v => v && v.length > 0), (0, operators_1.mergeMap)(v => RxJS.of(...v)));
      }
      return RxJS.of({
        partKey,
        sortKey,
        value: this._properties[partKey][sortKey].value
      });
    }
    return RxJS.of({
      partKey,
      sortKey,
      value: notSetValue
    });
  }
  resetAllModified() {
    this._deletedProperties = {};
    const newProps = Object.assign({}, this._properties);
    object_1.BbitObject.forOwn(newProps, partition => {
      object_1.BbitObject.forOwn(partition, val => {
        val.modified = false;
      });
    });
    this._properties = newProps;
    this._onChanged.next({
      action: 'resetModified',
      pairs: this.listPairs()
    });
    return result_1.BbitResult.createSuccess();
  }
  isModified(partKey, sortKey) {
    return this.has(partKey, sortKey) ? this._properties[partKey || ''][sortKey || ''].modified : undefined;
  }
  listValues() {
    return Object.values(this._properties).flatMap(partition => Object.values(partition).map(entry => entry.value));
  }
  listPairs() {
    return Object.entries(this._properties || {}).flatMap(([partKey, partition]) => Object.entries(partition || {}).map(([sortKey, entry]) => ({
      partKey,
      sortKey,
      value: entry.value
    })));
  }
  listExpiredPairs() {
    return Object.entries(this._properties || {}).flatMap(([partKey, partition]) => Object.entries(partition || {}).map(([sortKey, entry]) => entry.expiresAt && entry.expiresAt.diffNow().milliseconds > 0 ? {
      partKey,
      sortKey,
      value: entry.value
    } : null).filter(Boolean));
  }
  listModifiedPairs() {
    return {
      changed: Object.entries(this._properties || {}).flatMap(([partKey, partition]) => Object.entries(partition).map(([sortKey, entry]) => entry.modified ? {
        partKey,
        sortKey,
        value: entry.value
      } : null).filter(Boolean)),
      deleted: Object.entries(this._deletedProperties || {}).flatMap(([partKey, partition]) => Object.entries(partition).map(([sortKey, entry]) => ({
        partKey,
        sortKey,
        value: entry.value
      })))
    };
  }
  serialise() {
    return object_1.BbitObject.mapValues(this._properties, partition => object_1.BbitObject.mapValues(partition, entry => entry.value));
  }
  deserialize(serializedInput, replace) {
    if (!serializedInput || !object_1.BbitObject.isObject(serializedInput)) {
      return result_1.BbitResult.createError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'serializedInput',
        reason: 'must be a plain object',
        value: serializedInput
      }));
    }
    return this.set(Object.entries(serializedInput).flatMap(([partKey, partition]) => object_1.BbitObject.isObject(partition) ? Object.entries(partition).map(([sortKey, value]) => ({
      partKey,
      sortKey,
      value
    })) : [{
      partKey,
      value: partition
    }]), {
      emptyAll: replace,
      setModifiedTo: false
    });
  }
}
exports.BbitRegistry = BbitRegistry;
