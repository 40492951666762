"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listLookupKeys = exports.combineLookupKeyBucketString = void 0;
const rxjs_1 = require("rxjs");
const array_1 = require("../../primitives/array");
const string_1 = require("../../primitives/string");
const interfaces_1 = require("./interfaces");
const combineLookupKeyBucketString = params => {
  if (!params.bucket || !params.fields || params.fields.length === 0) {
    console.error('Cannot generate _bucket for lookup key, missing params', params);
    throw new Error('Cannot generate _bucket for lookup key, missing params');
  }
  const sortedFields = array_1.BbitArray.sortBy(params.fields, o => o.field);
  const _bucket = `${interfaces_1.lookupKeyBbaseBucketPrefix}${params.bucket}-${params.prefix ? params.prefix + '-' : ''}${sortedFields.map(o => o.field).sort().join('-')}-${sortedFields.filter(o => !(o === undefined || o === null)).map(o => o.value).join('-')}`;
  return string_1.BbitString.slugify(_bucket);
};
exports.combineLookupKeyBucketString = combineLookupKeyBucketString;
const listLookupKeys = params => __awaiter(void 0, void 0, void 0, function* () {
  const _bucket = (0, exports.combineLookupKeyBucketString)({
    prefix: params.prefix,
    bucket: params.bucket,
    fields: params.fields
  });
  const result = yield (0, rxjs_1.lastValueFrom)(params.bbase.listKeys({
    _bucket,
    retrieveAllPages: true
  }));
  return result.keys;
});
exports.listLookupKeys = listLookupKeys;
