"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitJsonWebToken = void 0;
const luxon_1 = require("luxon");
const error_1 = require("../primitives/error");
const interfaces_1 = require("./interfaces");
const universal_crypto_1 = require("./universal-crypto");
class BbitJsonWebToken {
  static encodeJWT(bbitCrypto, params) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!(params === null || params === void 0 ? void 0 : params.header)) {
        throw new Error('header is required');
      }
      if (!(params === null || params === void 0 ? void 0 : params.payload)) {
        throw new Error('payload is required');
      }
      if (!params.jwk) {
        throw new Error('jwk is required');
      }
      if (!params.jwk.alg) {
        throw new Error('alg is required');
      }
      const partialToken = `${params.header}.${params.payload}`;
      const signature = bbitCrypto.base64.bufferToBase64Url(yield bbitCrypto.encryptOrSignWithJWK({
        key: params.jwk,
        data: bbitCrypto.utf8StringToArrayBuffer(partialToken)
      }));
      return {
        jwt: partialToken + '.' + signature,
        signature
      };
    });
  }
  constructor(jwtString, crypto = universal_crypto_1.BbitUniversalCrypto.singleton()) {
    this.crypto = crypto;
    this._headerString = '';
    this._payloadString = '';
    this.header = {};
    this.payload = {};
    this.signature = '';
    if (jwtString) {
      const [header, payload, signature] = jwtString.split('.');
      this._headerString = header;
      this._payloadString = payload;
      this.header = crypto.base64.parseFromBase64Url(header);
      this.payload = crypto.base64.parseFromBase64Url(payload);
      this.signature = signature;
    }
  }
  getHeaderAsBase64Url() {
    var _a;
    return ((_a = this._headerString) === null || _a === void 0 ? void 0 : _a.length) > 0 ? this._headerString : this.crypto.base64.stringifyToBase64Url(this.header);
  }
  getPayloadAsBase64Url() {
    var _a;
    return ((_a = this._payloadString) === null || _a === void 0 ? void 0 : _a.length) > 0 ? this._payloadString : this.crypto.base64.stringifyToBase64Url(this.payload);
  }
  sign(jwk, params) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!jwk) {
        throw new Error('jwk is required');
      }
      if (!jwk.alg) {
        throw new Error('jwk alg is required');
      }
      if (!(params === null || params === void 0 ? void 0 : params.expiresIn) || !luxon_1.Duration.isDuration(params.expiresIn) || params.expiresIn.as('seconds') <= 0) {
        throw new Error('expiresIn is required');
      }
      if (!(params === null || params === void 0 ? void 0 : params.issuer)) {
        throw new Error('issuer is required');
      }
      const now = luxon_1.DateTime.isDateTime(params.now) ? params.now.toUTC() : luxon_1.DateTime.utc();
      this.header.alg = jwk.alg;
      this.header.typ = this.header.typ || 'JWT';
      this.header.kid = jwk.kid;
      this.payload.sub = params.subject || this.payload.sub;
      this.payload.aud = params.audience || this.payload.aud;
      this.payload.iss = params.issuer;
      this.payload.iat = Math.floor(now.toSeconds());
      this.payload.exp = Math.floor(now.plus(params.expiresIn).toSeconds());
      this.payload.nbf = Math.floor((params.notValidBefore || now).toSeconds());
      this.payload.jti = this.payload.jti || this.crypto.generateRandomString(16);
      if (!this.payload.sub) {
        throw new Error('sub is required');
      }
      if (!this.payload.aud) {
        throw new Error('aud is required');
      }
      this._headerString = '';
      this._payloadString = '';
      const {
        jwt,
        signature
      } = yield BbitJsonWebToken.encodeJWT(this.crypto, {
        header: this.getHeaderAsBase64Url(),
        payload: this.getPayloadAsBase64Url(),
        jwk
      });
      this.signature = signature;
      return jwt;
    });
  }
  verify(jwk, params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!(jwk === null || jwk === void 0 ? void 0 : jwk.alg)) {
        throw new Error('jwtVerifyJwkMissing');
      }
      if (!params.clockTolerance) {
        throw new Error('jwtVerifyClockToleranceMissing');
      }
      if (jwk.alg !== this.header.alg) {
        throw new error_1.BbitError('jwtAlgMismatch', {
          expected: jwk.alg,
          provided: this.header.alg
        });
      }
      const now = luxon_1.DateTime.utc();
      const exp = this.payload.exp || 0;
      const nbf = this.payload.nbf || 0;
      const iat = this.payload.iat || 0;
      if (!params.ignoreExpiration) {
        if (now.minus(params.clockTolerance).toSeconds() >= exp) {
          throw new error_1.BbitError('jwtExpired', {
            exp,
            diff: Math.floor(now.toSeconds() - exp)
          });
        }
      }
      if (!params.ignoreNotBefore) {
        if (now.plus(params.clockTolerance).toSeconds() < nbf) {
          throw new error_1.BbitError('jwtNotYetValid', {
            nbf,
            diff: Math.floor(nbf - now.toSeconds())
          });
        }
      }
      if (!params.ignoreIssuedAt) {
        if (now.plus(params.clockTolerance).toSeconds() < iat) {
          throw new error_1.BbitError('jwtIssuedInTheFuture', {
            iat,
            diff: Math.floor(iat - now.toSeconds())
          });
        }
      }
      if (Array.isArray(params.allowedAudiences) && params.allowedAudiences.length > 0) {
        const tokenAudience = this.payload.aud || [];
        const tokenAudiences = Array.isArray(tokenAudience) ? tokenAudience : [tokenAudience];
        if (!tokenAudiences.some(aud => params.allowedAudiences.includes(aud))) {
          throw new error_1.BbitError('jwtAudienceNotAllowed', {
            aud: tokenAudiences,
            allowed: params.allowedAudiences
          });
        }
      }
      if (Array.isArray(params.allowedSubjects) && params.allowedSubjects.length > 0) {
        const tokenSubject = this.payload.sub || '';
        const tokenSubjects = Array.isArray(tokenSubject) ? tokenSubject : [tokenSubject];
        if (!tokenSubjects.some(sub => params.allowedSubjects.includes(sub))) {
          throw new error_1.BbitError('jwtSubjectNotAllowed', {
            sub: tokenSubjects,
            allowed: params.allowedSubjects
          });
        }
      }
      if (Array.isArray(params.allowedTokenUsages) && params.allowedTokenUsages.length > 0) {
        const tokenUsage = this.payload.token_use || '';
        const tokenUsages = Array.isArray(tokenUsage) ? tokenUsage : [tokenUsage];
        if (!tokenUsages.some(token_usage => params.allowedTokenUsages.includes(token_usage))) {
          throw new error_1.BbitError('jwtTokenUsageNotAllowed', {
            token_usage: tokenUsages,
            allowed: params.allowedTokenUsages
          });
        }
      }
      if (Array.isArray(params.allowedOriginTokenIDs) && params.allowedOriginTokenIDs.length > 0) {
        const tokenOriginTokenID = this.payload.origin_jti || '';
        const tokenOriginTokenIDs = Array.isArray(tokenOriginTokenID) ? tokenOriginTokenID : [tokenOriginTokenID];
        if (!tokenOriginTokenIDs.some(origin_token_id => params.allowedOriginTokenIDs.includes(origin_token_id))) {
          throw new error_1.BbitError('jwtOriginTokenIDNotAllowed', {
            origin_token_id: tokenOriginTokenIDs,
            allowed: params.allowedOriginTokenIDs
          });
        }
      }
      if ((jwk.alg.startsWith('RS') || jwk.alg.startsWith('ES')) && ((_a = jwk.key_ops) === null || _a === void 0 ? void 0 : _a.includes(interfaces_1.BbitJoseKeyOperation.VERIFY))) {
        const partialToken = this.getHeaderAsBase64Url() + '.' + this.getPayloadAsBase64Url();
        yield this.crypto.decryptOrVerifyWithJWK({
          jwk,
          data: this.crypto.utf8StringToArrayBuffer(partialToken),
          signature: this.crypto.base64.bufferFromBase64Url(this.signature)
        });
      } else {
        const encodedJWT = yield BbitJsonWebToken.encodeJWT(this.crypto, {
          header: this.getHeaderAsBase64Url(),
          payload: this.getPayloadAsBase64Url(),
          jwk
        });
        if (encodedJWT.signature !== this.signature) {
          throw new Error('jwtInvalidSignature' + jwk.alg);
        }
      }
      return {
        audience: this.payload.aud
      };
    });
  }
}
exports.BbitJsonWebToken = BbitJsonWebToken;
