"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitUniversalCrypto = void 0;
const base64_1 = require("./base64");
class BbitUniversalCrypto {
  static singleton() {
    if (BbitUniversalCrypto._singletonInstance) {
      return BbitUniversalCrypto._singletonInstance;
    }
    if (!BbitUniversalCrypto._singletonInstance) {
      BbitUniversalCrypto.initSingleton(globalThis === null || globalThis === void 0 ? void 0 : globalThis.crypto);
    }
    if (!BbitUniversalCrypto._singletonInstance) {
      BbitUniversalCrypto.initSingleton(typeof window !== 'undefined' ? window.crypto : undefined);
    }
    if (!BbitUniversalCrypto._singletonInstance) {
      throw new Error('BbitUniversalCrypto not initialized');
    }
    return BbitUniversalCrypto._singletonInstance;
  }
  constructor(webCrypto = globalThis.crypto) {
    this.webCrypto = webCrypto;
    this.base64 = new base64_1.BbitBase64();
    if (!webCrypto) {
      throw new Error('webCrypto is not available');
    }
  }
  modulo(base, mod) {
    return (base % mod + mod) % mod;
  }
  modPow(base, exp, mod) {
    let result = BigInt(1);
    let x = this.modulo(base, mod);
    while (exp > BigInt(0)) {
      if (this.modulo(exp, BigInt(2))) {
        result = this.modulo(result * x, mod);
      }
      exp = exp / BigInt(2);
      x = this.modulo(x * x, mod);
    }
    return result;
  }
  padHex(hexStr) {
    hexStr = hexStr.length % 2 ? `0${hexStr}` : hexStr;
    hexStr = Number.parseInt(hexStr.slice(0, 2), 16) >> 7 ? `00${hexStr}` : hexStr;
    return hexStr;
  }
  hexToArrayBuffer(hexStr) {
    var _a;
    if (hexStr.length % 2 !== 0) {
      throw new Error('hex string should have even number of characters');
    }
    const octets = (_a = hexStr.match(/.{2}/gi)) === null || _a === void 0 ? void 0 : _a.map(m => Number.parseInt(m, 16));
    return new Uint8Array(octets);
  }
  utf8StringToArrayBuffer(str) {
    return new TextEncoder().encode(str);
  }
  arrayBufferToHex(arrBuf) {
    return [...new Uint8Array(arrBuf)].map(x => x.toString(16).padStart(2, '0')).join('');
  }
  arrayBufferToBigInt(arrBuf) {
    return BigInt(`0x${this.arrayBufferToHex(arrBuf)}`);
  }
  hexToBigInt(hexStringOrArrayBuffer) {
    return typeof hexStringOrArrayBuffer === 'string' ? BigInt(hexStringOrArrayBuffer.startsWith('0x') ? hexStringOrArrayBuffer : `0x${hexStringOrArrayBuffer}`) : this.arrayBufferToBigInt(hexStringOrArrayBuffer);
  }
  generateRandomNumber(byteLength = 128) {
    const randomValues = new Uint8Array(byteLength);
    this.webCrypto.getRandomValues(randomValues);
    return this.arrayBufferToBigInt(randomValues.buffer);
  }
  generateRandomString(byteLength = 128) {
    return this.base64.bufferToBase64Url(this.webCrypto.getRandomValues(new Uint8Array(byteLength)));
  }
  sha256AsBigInt(arrayBuffer) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.arrayBufferToBigInt(yield this.webCrypto.subtle.digest('SHA-256', arrayBuffer));
    });
  }
  sha256AsHex(arrayBuffer) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.arrayBufferToHex(yield this.webCrypto.subtle.digest('SHA-256', arrayBuffer));
    });
  }
  sha256HmacAsBase64(key, data) {
    return __awaiter(this, void 0, void 0, function* () {
      const jwk = yield this.createJWK({
        algorithm: 'HS256',
        secret: key
      });
      return this.base64.bufferToBase64(yield this.encryptOrSignWithJWK({
        key: jwk.privateKey,
        data: this.utf8StringToArrayBuffer(data)
      }));
    });
  }
  createJWK(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const kid = params.keyId || this.generateRandomString(16);
      let key;
      switch (params === null || params === void 0 ? void 0 : params.algorithm) {
        case 'A256GCM':
        case 'A192GCM':
        case 'A128GCM':
          {
            const aesGcmLength = Number.parseInt(params.algorithm.slice(1, 4));
            if (params.secret) {
              const hexString = this.arrayBufferToHex(this.utf8StringToArrayBuffer(params.secret));
              const correctLengthString = hexString + '0'.repeat(aesGcmLength / 4 - hexString.length);
              key = yield this.webCrypto.subtle.importKey('raw', this.hexToArrayBuffer(correctLengthString), {
                name: 'AES-GCM'
              }, true, ['encrypt', 'decrypt']);
            } else {
              key = yield this.webCrypto.subtle.generateKey({
                name: 'AES-GCM',
                length: aesGcmLength
              }, true, ['encrypt', 'decrypt']);
            }
            return {
              privateKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', key))
            };
          }
        case 'HS256':
        case 'HS384':
        case 'HS512':
          {
            if (!params.secret) {
              throw new Error('secret is required');
            }
            const hsKey = yield this.webCrypto.subtle.importKey('raw', this.utf8StringToArrayBuffer(params.secret), {
              name: 'HMAC',
              hash: {
                name: `SHA-${params.algorithm.slice(-3)}`
              }
            }, true, ['sign', 'verify']);
            return {
              privateKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', hsKey))
            };
          }
        case 'PS256':
        case 'PS384':
        case 'PS512':
          throw new Error('not implemented');
        case 'RS256':
        case 'RS384':
        case 'RS512':
          {
            const rsKey = yield this.webCrypto.subtle.generateKey({
              name: 'RSASSA-PKCS1-v1_5',
              modulusLength: (params === null || params === void 0 ? void 0 : params.rsaModulusLength) || 2048,
              publicExponent: (params === null || params === void 0 ? void 0 : params.rsaPublicExponent) || new Uint8Array([1, 0, 1]),
              hash: {
                name: `SHA-${params.algorithm.slice(-3)}`
              }
            }, true, ['sign', 'verify']);
            return {
              privateKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', rsKey.privateKey)),
              publicKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', rsKey.publicKey))
            };
          }
        case 'ES256':
        case 'ES384':
        case 'ES512':
          {
            const esKey = yield this.webCrypto.subtle.generateKey({
              name: 'ECDSA',
              namedCurve: `P-${params.algorithm.slice(-3)}`
            }, true, ['sign', 'verify']);
            return {
              privateKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', esKey.privateKey)),
              publicKey: Object.assign({
                kid
              }, yield this.webCrypto.subtle.exportKey('jwk', esKey.privateKey))
            };
          }
        case 'EdDSA':
          throw new Error('not implemented');
        default:
          throw new Error(`alg ${key === null || key === void 0 ? void 0 : key.alg} is not supported`);
      }
    });
  }
  importJwk(key) {
    return __awaiter(this, void 0, void 0, function* () {
      switch (key === null || key === void 0 ? void 0 : key.alg) {
        case 'A256GCM':
        case 'A192GCM':
        case 'A128GCM':
          return {
            signParams: undefined,
            encryptParams: {
              name: 'AES-GCM',
              iv: key.iv
            },
            key: yield this.webCrypto.subtle.importKey('jwk', key, key.alg, true, ['encrypt', 'decrypt'])
          };
        case 'HS256':
        case 'HS384':
        case 'HS512':
          {
            const hmacParams = {
              hash: `SHA-${key.alg.slice(-3)}`,
              name: 'HMAC'
            };
            return {
              signParams: hmacParams,
              key: yield this.webCrypto.subtle.importKey('jwk', key, hmacParams, true, ['sign', 'verify'])
            };
          }
        case 'PS256':
        case 'PS384':
        case 'PS512':
          return {
            signParams: {
              hash: `SHA-${key.alg.slice(-3)}`,
              name: 'RSA-PSS',
              saltLength: alg.slice(-3) >> 3
            },
            key: yield this.webCrypto.subtle.importKey('jwk', key, key.alg, true, ['sign', 'verify'])
          };
        case 'RS256':
        case 'RS384':
        case 'RS512':
          {
            const rsParams = {
              hash: `SHA-${key.alg.slice(-3)}`,
              name: 'RSASSA-PKCS1-v1_5'
            };
            return {
              signParams: rsParams,
              key: yield this.webCrypto.subtle.importKey('jwk', key, rsParams, true, key.key_ops || ['sign', 'verify'])
            };
          }
        case 'ES256':
        case 'ES384':
        case 'ES512':
          return {
            signParams: {
              hash: `SHA-${key.alg.slice(-3)}`,
              name: 'ECDSA',
              namedCurve: `P-${key.alg.slice(-3)}`
            },
            key: yield this.webCrypto.subtle.importKey('jwk', key, key.alg, true, ['sign', 'verify'])
          };
        default:
          throw new Error(`alg ${key === null || key === void 0 ? void 0 : key.alg} is not supported`);
      }
    });
  }
  encryptOrSignWithJWK(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        signParams,
        encryptParams,
        key
      } = yield this.importJwk(params.key);
      return signParams ? this.webCrypto.subtle.sign(signParams, key, params.data) : this.webCrypto.subtle.encrypt(encryptParams, key, params.data);
    });
  }
  decryptOrVerifyWithJWK(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const {
        signParams,
        encryptParams,
        key
      } = yield this.importJwk(params.jwk);
      return signParams ? this.webCrypto.subtle.verify(signParams, key, params.signature, params.data).then(result => {
        if (!result) {
          throw new Error('invalid signature ' + params.jwk.alg);
        }
        return params.signature;
      }) : this.webCrypto.subtle.decrypt(encryptParams, key, params.data);
    });
  }
}
exports.BbitUniversalCrypto = BbitUniversalCrypto;
BbitUniversalCrypto.initSingleton = webCrypto => {
  BbitUniversalCrypto._singletonInstance = webCrypto ? new BbitUniversalCrypto(webCrypto) : undefined;
};
