"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitCache = exports.BbitCacheHandler = void 0;
const luxon_1 = require("luxon");
const log_1 = require("../log/log");
const error_1 = require("../primitives/error");
const result_1 = require("../primitives/result");
class BbitCacheHandler {
  constructor(definition) {
    this.definition = definition;
    this.log = log_1.BbitLog.scope({
      class: 'BbitCache'
    });
    this.key = definition.key;
  }
  updateFunc(definition) {
    this.definition = definition;
    this.clearCache();
  }
  clearCache() {
    this.lastFetch = undefined;
    this.data = undefined;
  }
  getCacheValue(params) {
    const wasNeverFetched = !this.lastFetch;
    const hadFetchError = this.lastFetch && result_1.BbitResult.isError(this.data);
    const maxCacheDurationReached = this.lastFetch && this.lastFetch.plus({
      seconds: params.maxCacheSeconds
    }).diffNow().milliseconds < 0;
    const needsReload = wasNeverFetched || hadFetchError || maxCacheDurationReached;
    const cacheRes = {
      needsReload,
      wasNeverFetched,
      hadFetchError,
      maxCacheDurationReached,
      value: result_1.BbitResult.isOk(this.data) ? this.data.data : params.valueIfNotExists,
      result: this.data
    };
    return cacheRes;
  }
  loadViaCache(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const current = this.getCacheValue(params);
      if (current.needsReload) {
        if (!this.fetchPromise) {
          this.fetchPromise = this.definition.cacheFetchFunc(Object.assign({
            globalContext: undefined
          }, params)).then(data => {
            this.fetchPromise = undefined;
            this.data = result_1.BbitResult.createSuccess(data);
            this.lastFetch = luxon_1.DateTime.local();
            this.errorCount = 0;
          }, err => {
            this.errorCount = (this.errorCount || 0) + 1;
            this.fetchPromise = undefined;
            this.data = result_1.BbitResult.createError(err);
            this.lastFetch = luxon_1.DateTime.local();
            if (this.errorCount > (this.definition.maxErrorFetchCount || 2)) {
              this.log.error('error fetching cache ' + this.key, {
                errorCount: this.errorCount
              }, err);
              return Promise.reject(err);
            }
            this.log.warn('error fetching cache ' + this.key, {
              errorCount: this.errorCount
            }, err);
          });
        }
        yield this.fetchPromise;
        return this.getCacheValue(params).value;
      }
      return current.value;
    });
  }
}
exports.BbitCacheHandler = BbitCacheHandler;
class BbitCache {
  constructor(globalContext) {
    this.globalContext = globalContext;
    this._caches = {};
  }
  ensureCacheHandler(cacheHandler) {
    let handler;
    if (this._caches[cacheHandler.key]) {
      this._caches[cacheHandler.key].updateFunc(cacheHandler);
      handler = this._caches[cacheHandler.key];
    } else {
      handler = this.registerCacheHandler(cacheHandler);
    }
    if (cacheHandler.clearCache) {
      handler.clearCache();
    }
    return handler;
  }
  clearCache(params) {
    if (this._caches[params.key]) {
      this._caches[params.key].clearCache();
    }
  }
  isCacheRegistered(params) {
    return !!this._caches[params.key];
  }
  registerCacheHandler(cacheHandler, options) {
    if (this._caches[cacheHandler.key] && !(options === null || options === void 0 ? void 0 : options.overwriteExisting)) {
      throw new error_1.BbitError('cache.already-registered', {
        key: cacheHandler.key
      });
    }
    this._caches[cacheHandler.key] = new BbitCacheHandler(cacheHandler);
    return this._caches[cacheHandler.key];
  }
  getCacheHandler(params) {
    if (!this._caches[params.key]) {
      throw new error_1.BbitError('cache.not-registered', {
        key: params.key
      });
    }
    return this._caches[params.key];
  }
  getCacheValue(params) {
    return this.getCacheHandler(params).getCacheValue(params);
  }
  loadViaCache(params) {
    return this.getCacheHandler(params).loadViaCache(params);
  }
  loadViaCacheDef(handlerDef, params) {
    if (!handlerDef.key) throw new error_1.BbitError('cache.key-missing');
    const cacheHandler = !this.isCacheRegistered({
      key: handlerDef.key
    }) ? this.registerCacheHandler(handlerDef) : this.getCacheHandler({
      key: handlerDef.key
    });
    return cacheHandler.loadViaCache(Object.assign({
      globalContext: this.globalContext
    }, params));
  }
}
exports.BbitCache = BbitCache;
