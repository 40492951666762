"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitLog = void 0;
const array_1 = require("../primitives/array");
const interfaces_1 = require("./interfaces");
const log_instance_1 = require("./log.instance");
class BbitLog {
  static enablePrettyPrint() {
    BbitLog._prettyPrint = true;
  }
  static disablePrettyPrint() {
    BbitLog._prettyPrint = false;
  }
  static getScopeDefaults() {
    return BbitLog._scopeDefaults;
  }
  static setScopeDefaults(scope) {
    for (const key in scope) {
      if (scope.hasOwnProperty(key)) {
        BbitLog._scopeDefaults[key] = scope[key];
      }
    }
  }
  static logLevelToLevelNumber(logLevel) {
    switch (logLevel) {
      case interfaces_1.BbitLogLevel.CRITICAL:
        return 10;
      case interfaces_1.BbitLogLevel.ERROR:
        return 8;
      case interfaces_1.BbitLogLevel.WARNING:
        return 6;
      case interfaces_1.BbitLogLevel.SUCCESS:
        return 4;
      case interfaces_1.BbitLogLevel.INFO:
        return 2;
      default:
        return 1;
    }
  }
  static catchLog(msg) {
    var _a;
    if ((_a = BbitLog._catchLogParams) === null || _a === void 0 ? void 0 : _a.minLevelForTriggeringOutput) {
      const msgLevel = BbitLog.logLevelToLevelNumber(msg.level);
      const minLevelForTriggeringOutput = BbitLog.logLevelToLevelNumber(BbitLog._catchLogParams.minLevelForTriggeringOutput);
      const minLevelForTriggeringSave = BbitLog.logLevelToLevelNumber(BbitLog._catchLogParams.minLevelForTriggeringSave);
      const minLevelForRecording = BbitLog.logLevelToLevelNumber(BbitLog._catchLogParams.minLevelForRecording);
      if (msgLevel >= minLevelForRecording) {
        if (!BbitLog._catchedLogs) {
          BbitLog._catchedLogs = [];
        }
        BbitLog._catchedLogs.push(msg);
      }
      if (msgLevel >= minLevelForTriggeringSave) {
        BbitLog._catchedLogsNeedsToBeSaved = true;
      }
      if (msgLevel >= minLevelForTriggeringOutput) {
        return msg;
      }
      return undefined;
    }
    return msg;
  }
  static startCatchingLogs(params) {
    if (!(params === null || params === void 0 ? void 0 : params.minLevelForTriggeringOutput)) {
      throw new Error('BbitLog.getCatchLogParams: minLevelForTriggeringOutput is not set');
    }
    if (!(params === null || params === void 0 ? void 0 : params.minLevelForRecording)) {
      throw new Error('BbitLog.getCatchLogParams: minLevelForRecording is not set');
    }
    if (!(params === null || params === void 0 ? void 0 : params.minLevelForTriggeringSave)) {
      throw new Error('BbitLog.getCatchLogParams: minLevelForTriggeringSave is not set');
    }
    console.debug('BbitLog.startCatchingLogs', params);
    BbitLog._catchLogParams = params;
    BbitLog._catchedLogs = [];
  }
  static stopCatchingLogs(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const catchedLogsNeedsToBeSaved = BbitLog._catchedLogsNeedsToBeSaved;
      const logCount = BbitLog._catchedLogs.length;
      console.debug('BbitLog.stopCatchingLogs', {
        catchedLogsNeedsToBeSaved,
        hasSaveFunction: !!params.saveFunction
      });
      if (params.saveFunction && catchedLogsNeedsToBeSaved) {
        try {
          yield params.saveFunction({
            logs: BbitLog._catchedLogs
          });
        } catch (e) {
          console.error('error saving logs', e);
        }
      }
      BbitLog._catchedLogs = [];
      BbitLog._catchLogParams = undefined;
      BbitLog._catchedLogsNeedsToBeSaved = false;
      return {
        catchedLogsNeedsToBeSaved,
        logCount
      };
    });
  }
  static isPrettyPrintEnabled() {
    return BbitLog._prettyPrint;
  }
  static global() {
    return BbitLog._globalLogInstance;
  }
  static scope(scope) {
    return new log_instance_1.BbitLogInstance(scope);
  }
  static disableConsole() {
    BbitLog.isConsoleDisabled = true;
  }
  static enableConsole() {
    BbitLog.isConsoleDisabled = false;
  }
  static disablePackageInConsole(pkg) {
    BbitLog.disabledConsolePackages = array_1.BbitArray.remove(BbitLog.disabledConsolePackages, pkg);
  }
  static enablePackageInConsole(pkg) {
    if (!BbitLog.disabledConsolePackages.find(p => p === pkg)) {
      BbitLog.disabledConsolePackages.push(pkg);
    }
  }
  static setConfigFromProcessEnv(defaultEnv) {
    const env = Object.assign(Object.assign({}, defaultEnv || {}), (process === null || process === void 0 ? void 0 : process.env) || {});
    if (env.AWS_LAMBDA_FUNCTION_NAME) {
      BbitLog.setScopeDefaults({
        deviceId: '#lambda',
        deviceName: env.AWS_LAMBDA_FUNCTION_NAME
      });
      BbitLog.disablePrettyPrint();
    }
    if (env.BBIT_LOG_PRETTY_PRINT) {
      BbitLog.enablePrettyPrint();
    }
    if (env.BBIT_OUTPUT_MIN_LEVEL || env.BBIT_RECORD_MIN_LEVEL || env.BBIT_RECORD_SAVE_MIN_LEVEL) {
      const minLevelForTriggeringOutput = env.BBIT_OUTPUT_MIN_LEVEL || env.BBIT_RECORD_SAVE_MIN_LEVEL || env.BBIT_RECORD_MIN_LEVEL;
      const minLevelForRecording = env.BBIT_RECORD_MIN_LEVEL || env.BBIT_OUTPUT_MIN_LEVEL || env.BBIT_RECORD_SAVE_MIN_LEVEL;
      const minLevelForTriggeringSave = env.BBIT_RECORD_SAVE_MIN_LEVEL || env.BBIT_OUTPUT_MIN_LEVEL || env.BBIT_RECORD_MIN_LEVEL;
      BbitLog.startCatchingLogs({
        minLevelForTriggeringOutput,
        minLevelForRecording,
        minLevelForTriggeringSave
      });
    }
  }
  constructor() {}
}
exports.BbitLog = BbitLog;
BbitLog._globalLogInstance = new log_instance_1.BbitLogInstance();
BbitLog._prettyPrint = true;
BbitLog.isConsoleDisabled = false;
BbitLog.disabledConsolePackages = [];
BbitLog._scopeDefaults = {};
BbitLog._catchedLogsNeedsToBeSaved = false;
