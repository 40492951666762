"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitTime = void 0;
const luxon_1 = require("luxon");
const array_1 = require("./array");
class BbitTime {
  constructor(invalidReason, hour, minute, second, millisecond, offset, zoneName, zone) {
    this.invalidReason = invalidReason;
    this.isValid = !this.invalidReason;
    if (!zoneName && !zone) {
      const {
        defaultZone
      } = luxon_1.Settings;
      if (typeof defaultZone === 'string') {
        zoneName = defaultZone;
      } else {
        zone = defaultZone;
      }
    }
    if (zoneName && !zone) {
      zone = new luxon_1.IANAZone(zoneName);
      if (!(zone === null || zone === void 0 ? void 0 : zone.isValid)) {
        this.invalidReason = `Invalid zone name ${zoneName}`;
        this.isValid = false;
      }
    } else if (!(zone === null || zone === void 0 ? void 0 : zone.isValid)) {
      this.invalidReason = 'Invalid zone';
      this.isValid = false;
    }
    this.zoneName = zoneName || zone.name;
    this.zone = zone;
    this.offset = offset;
    const d = luxon_1.Duration.fromObject({
      hours: hour,
      minutes: minute,
      seconds: second,
      milliseconds: millisecond
    }).shiftTo('hours', 'minutes', 'seconds', 'milliseconds');
    this.millisecond = d.milliseconds;
    this.second = d.seconds;
    this.minute = d.minutes;
    this.hour = d.hours;
  }
  static padLeft(input, length = 2) {
    return input.toString().padStart(length, '0');
  }
  static parseOffset(input) {
    if (!input || input === 'Z') {
      return 0;
    }
    const regex = /([+-])(\d+):(\d+)/i;
    const matches = regex.exec(input);
    if (matches) {
      const offsetHours = Number.parseFloat(matches[2]);
      const offsetMinutes = Number.parseFloat(matches[3]);
      const sign = matches[1] === '-' ? -1 : 1;
      return sign * offsetHours * 60 + offsetMinutes;
    }
    return 0;
  }
  static formatOffset(offset) {
    const sign = offset < 0 ? '-' : '+';
    const offsetHours = Math.floor(Math.abs(offset / 60));
    const offsetMinutes = Math.floor(Math.abs(offset % 60));
    return `${sign}${BbitTime.padLeft(offsetHours)}:${BbitTime.padLeft(offsetMinutes)}`;
  }
  static fromISOTime(timeString, opts) {
    const regex = /T?([\d\.,]+):([\d\.,]+):([\d\.,]+)([+-]\d+:\d+)?(Z)?(\s(.+))?/i;
    const matches = regex.exec(timeString);
    if (matches) {
      const hour = Number.parseFloat(matches[1]);
      const minute = Number.parseFloat(matches[2]);
      const second = Number.parseFloat(matches[3]);
      const offset = BbitTime.parseOffset(matches[4] || matches[5]);
      const zoneName = (opts === null || opts === void 0 ? void 0 : opts.zone) || matches[7];
      return new BbitTime(undefined, hour, minute, second, 0, offset, zoneName);
    }
    return new BbitTime(`unknown input format ${timeString}`, 0, 0, 0, 0);
  }
  static fromDateTime(dateTime) {
    if (!luxon_1.DateTime.isDateTime(dateTime) || !(dateTime === null || dateTime === void 0 ? void 0 : dateTime.isValid)) {
      return new BbitTime(dateTime && luxon_1.DateTime.isDateTime(dateTime) ? dateTime.invalidReason : 'expected DateTime, received ' + typeof dateTime, 0, 0, 0, 0);
    }
    return new BbitTime(undefined, dateTime.hour, dateTime.minute, dateTime.second, dateTime.millisecond, dateTime.offset, dateTime.zoneName);
  }
  roundTo(unit) {
    const now = luxon_1.DateTime.now().setZone(this.zoneName);
    const dt = luxon_1.DateTime.fromObject({
      year: (now === null || now === void 0 ? void 0 : now.year) || 1970,
      month: (now === null || now === void 0 ? void 0 : now.month) || 1,
      day: (now === null || now === void 0 ? void 0 : now.day) || 1,
      hour: this.hour,
      minute: this.minute,
      second: this.second,
      millisecond: this.millisecond
    }, {
      zone: this.zoneName
    });
    const seconds = dt.second;
    const milliseconds = dt.millisecond;
    let roundedDt = dt.set({
      second: 0,
      millisecond: 0
    });
    if (seconds >= 30 || seconds === 29 && milliseconds > 500) {
      roundedDt = roundedDt.plus({
        minutes: 1
      });
    }
    return new BbitTime('', roundedDt.hour, roundedDt.minute, 0, 0, roundedDt.offset, roundedDt.zoneName);
  }
  toISOTime(opts) {
    if (!this.isValid) {
      return undefined;
    }
    return `${!(opts === null || opts === void 0 ? void 0 : opts.skipPrefix) ? 'T' : ''}${BbitTime.padLeft(this.hour)}:${BbitTime.padLeft(this.minute)}:${BbitTime.padLeft(this.second)}.${BbitTime.padLeft(this.millisecond, 3)}${(opts === null || opts === void 0 ? void 0 : opts.includeOffset) ? `${BbitTime.formatOffset(this.offset)}` : ''}${!(opts === null || opts === void 0 ? void 0 : opts.skipZone) ? ` ${this.zoneName}` : ''}`;
  }
  toString() {
    return this.toISOTime();
  }
  toFormat(format) {
    return this.toDurationIgnoringDST().toFormat(format);
  }
  toObject() {
    return {
      hour: this.hour,
      minute: this.minute,
      second: this.second,
      millisecond: this.millisecond,
      zone: this.zoneName
    };
  }
  setToDateTime(referenceTimeStamp) {
    if (!this.isValid) {
      return luxon_1.DateTime.invalid(this.invalidReason || 'invalid BbitTime');
    }
    const hour = this.hour % 24;
    const plusDays = Math.floor(this.hour / 24);
    const zoneDate = luxon_1.DateTime.isDateTime(referenceTimeStamp) ? referenceTimeStamp === null || referenceTimeStamp === void 0 ? void 0 : referenceTimeStamp.setZone(this.zoneName) : referenceTimeStamp;
    return luxon_1.DateTime.fromObject({
      year: (zoneDate === null || zoneDate === void 0 ? void 0 : zoneDate.year) || 1970,
      month: (zoneDate === null || zoneDate === void 0 ? void 0 : zoneDate.month) || 1,
      day: (zoneDate === null || zoneDate === void 0 ? void 0 : zoneDate.day) || 1,
      hour,
      minute: this.minute,
      second: this.second,
      millisecond: this.millisecond
    }, {
      zone: this.zoneName
    }).plus({
      days: plusDays
    });
  }
  toDurationIgnoringDST() {
    return luxon_1.Duration.fromObject({
      hours: this.hour,
      minutes: this.minute,
      seconds: this.second,
      milliseconds: this.millisecond
    });
  }
}
exports.BbitTime = BbitTime;
BbitTime.distributeWeeklyShifts = input => {
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  let definedDays = weekdays.filter(day => {
    var _a;
    return (((_a = input === null || input === void 0 ? void 0 : input.shifts) === null || _a === void 0 ? void 0 : _a[day]) || []).length > 0;
  });
  const employmentPercentage = !(input === null || input === void 0 ? void 0 : input.employmentPercentage) || input.employmentPercentage > 100 || input.employmentPercentage < 0 ? 100 : input.employmentPercentage;
  const workDayCount = (input === null || input === void 0 ? void 0 : input.manualWorkDayCount) || definedDays.length || 5;
  const hoursPerWeek = ((input === null || input === void 0 ? void 0 : input.weeklyWorkHours) || 45) * (employmentPercentage / 100);
  const hoursPerWorkday = hoursPerWeek / workDayCount;
  if (!(input === null || input === void 0 ? void 0 : input.shifts)) {
    return {
      hoursPerWeek,
      hoursPerWorkday,
      shifts: undefined
    };
  }
  const maximalShiftLength = !input.maximalShiftLength || input.maximalShiftLength.as('hours') < 0 || input.maximalShiftLength.as('hours') > 24 ? luxon_1.Duration.fromObject({
    hours: 6
  }) : input.maximalShiftLength;
  const minimalBreakBetweenShifts = !input.minimalBreakAfterMaxShiftLength || input.minimalBreakAfterMaxShiftLength.as('hours') < 0 || input.minimalBreakAfterMaxShiftLength.as('hours') > 24 ? luxon_1.Duration.fromObject({
    hours: 0.5
  }) : input.minimalBreakAfterMaxShiftLength;
  const secondsPerWorkday = Math.floor(hoursPerWorkday * 60 * 60);
  const newWeeklyShifts = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };
  if (definedDays.length !== workDayCount) {
    definedDays = weekdays.slice(0, 5);
  }
  for (const day of definedDays) {
    let currentDayShifts = array_1.BbitArray.sortBy(luxon_1.Interval.merge((input.shifts[day] || []).map(shift => {
      const from = BbitTime.fromISOTime(shift.from).setToDateTime();
      let until = BbitTime.fromISOTime(shift.until).setToDateTime();
      if (+until < +from) {
        until = until.plus({
          days: 1
        });
      }
      return luxon_1.Interval.fromDateTimes(from, until);
    })), shift => shift.start.toMillis());
    let remainingDaySeconds = input.ensureHoursPerWorkday ? secondsPerWorkday : currentDayShifts.reduce((acc, shift) => acc + Math.floor(shift.toDuration().as('seconds')), 0);
    const newShifts = [];
    while (remainingDaySeconds > 0) {
      const earliestStart = newShifts.length > 0 ? newShifts[newShifts.length - 1].end : BbitTime.fromISOTime('T08:00:00').setToDateTime();
      let currentShift = currentDayShifts.length > 0 ? currentDayShifts.shift() : input.ensureHoursPerWorkday ? luxon_1.Interval.fromDateTimes(earliestStart, earliestStart.plus({
        seconds: remainingDaySeconds
      })) : undefined;
      if (!currentShift) {
        break;
      }
      if (+currentShift.start < +earliestStart) {
        currentShift = currentShift.mapEndpoints(endpoint => endpoint.plus(earliestStart.diff(currentShift.start)));
      }
      if (currentShift.toDuration().as('seconds') > maximalShiftLength.as('seconds')) {
        const breakStart = currentShift.start.plus(maximalShiftLength);
        const splitShift = currentShift.splitAt(breakStart);
        const shortenedShift = splitShift.shift();
        newShifts.push(shortenedShift);
        remainingDaySeconds -= Math.floor(shortenedShift.toDuration().as('seconds'));
        const breakInterval = luxon_1.Interval.after(breakStart, minimalBreakBetweenShifts);
        newShifts.push(breakInterval);
        newShifts.push(breakInterval);
        currentDayShifts = array_1.BbitArray.sortBy(luxon_1.Interval.merge([...currentDayShifts, ...splitShift]), shift => shift.start.toMillis());
      } else if (currentShift.toDuration().as('seconds') > remainingDaySeconds) {
        const shortenedSift = luxon_1.Interval.after(currentShift.start, luxon_1.Duration.fromObject({
          seconds: remainingDaySeconds
        }));
        newShifts.push(shortenedSift);
        remainingDaySeconds = 0;
      } else {
        newShifts.push(currentShift);
        remainingDaySeconds -= Math.floor(currentShift.toDuration().as('seconds'));
      }
    }
    newWeeklyShifts[day] = luxon_1.Interval.xor(newShifts).map(interval => ({
      from: BbitTime.fromDateTime(interval.start).toISOTime(),
      until: BbitTime.fromDateTime(interval.end).toISOTime()
    }));
  }
  return {
    hoursPerWeek,
    hoursPerWorkday,
    shifts: newWeeklyShifts
  };
};
