"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFileInMemoryContext = void 0;
const error_1 = require("../primitives/error");
const createFileInMemoryContext = () => {
  const _data = new Map();
  const getBucketObj = params => {
    if (!_data.has(params.s3Bucket)) {
      _data.set(params.s3Bucket, new Map());
    }
    const bucket = _data.get(params.s3Bucket);
    if (!bucket.has(params.s3Key)) {
      bucket.set(params.s3Key, {
        data: Buffer.from(''),
        meta: {},
        isSet: false
      });
    }
    return bucket.get(params.s3Key);
  };
  return {
    getS3AttachmentBucket: () => Promise.resolve('__in-memory-attachments__'),
    getS3PrivateAssetBucket: () => Promise.resolve('__in-memory-private-assets'),
    getS3PublicAssetCacheBucket: () => Promise.resolve('__in-memory-public-assets__'),
    head: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      return {
        s3Bucket: params.s3Bucket,
        s3Key: params.s3Key,
        etag: obj.isSet ? 'in-memory' : undefined
      };
    }),
    get: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      if (!obj.isSet) {
        throw new error_1.BbitError('Object not found', params);
      }
      return {
        s3Bucket: params.s3Bucket,
        s3Key: params.s3Key,
        body: obj.data
      };
    }),
    put: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      if (typeof params.body === 'string') {
        obj.data = Buffer.from(params.body);
      } else if (Buffer.isBuffer(params.body)) {
        obj.data = params.body;
      } else {
        obj.data = Buffer.from(params.body);
      }
      obj.isSet = true;
      return {
        s3Bucket: params.s3Bucket,
        s3Key: params.s3Key,
        etag: 'in-memory'
      };
    }),
    delete: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      obj.isSet = false;
      obj.data = undefined;
      return {
        s3Bucket: params.s3Bucket,
        s3Key: params.s3Key
      };
    }),
    copy: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      return {
        s3Bucket: params.s3Bucket,
        s3Key: params.s3Key,
        etag: obj.isSet ? 'in-memory' : undefined
      };
    }),
    signUrl: (operation, params) => __awaiter(void 0, void 0, void 0, function* () {
      return `in-memory://${params.s3Bucket}/${params.s3Key}?operation=${operation}`;
    }),
    signUrlSync: (operation, params) => {
      return `in-memory://${params.s3Bucket}/${params.s3Key}?operation=${operation}`;
    },
    httpDownload: params => __awaiter(void 0, void 0, void 0, function* () {
      throw new error_1.BbitError('Not implemented', params);
    }),
    httpUpload: params => __awaiter(void 0, void 0, void 0, function* () {
      throw new error_1.BbitError('Not implemented', params);
    }),
    transform: params => __awaiter(void 0, void 0, void 0, function* () {
      throw new error_1.BbitError('Not implemented', params);
    })
  };
};
exports.createFileInMemoryContext = createFileInMemoryContext;
