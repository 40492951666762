"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bbitDefaultUnitSystem = exports.BbitUnitSystem = exports.BASE_UNIT_DIMENSIONS = void 0;
const luxon_1 = require("luxon");
const i18n_1 = require("../i18n/i18n");
const object_array_1 = require("../object-array/object-array");
const array_1 = require("../primitives/array");
const error_1 = require("../primitives/error");
const string_1 = require("../primitives/string");
const interfaces_1 = require("./interfaces");
const unit_definitions_1 = require("./unit-definitions");
const PREFIXES = {
  [interfaces_1.BbitUnitTextVariant.GLOBAL]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.SHORT]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    da: {
      name: 'da',
      value: 1e1,
      scientific: false
    },
    h: {
      name: 'h',
      value: 1e2,
      scientific: false
    },
    k: {
      name: 'k',
      value: 1e3,
      scientific: true
    },
    M: {
      name: 'M',
      value: 1e6,
      scientific: true
    },
    G: {
      name: 'G',
      value: 1e9,
      scientific: true
    },
    T: {
      name: 'T',
      value: 1e12,
      scientific: true
    },
    P: {
      name: 'P',
      value: 1e15,
      scientific: true
    },
    E: {
      name: 'E',
      value: 1e18,
      scientific: true
    },
    Z: {
      name: 'Z',
      value: 1e21,
      scientific: true
    },
    Y: {
      name: 'Y',
      value: 1e24,
      scientific: true
    },
    d: {
      name: 'd',
      value: 1e-1,
      scientific: false
    },
    c: {
      name: 'c',
      value: 1e-2,
      scientific: false
    },
    m: {
      name: 'm',
      value: 1e-3,
      scientific: true
    },
    u: {
      name: 'u',
      value: 1e-6,
      scientific: true
    },
    n: {
      name: 'n',
      value: 1e-9,
      scientific: true
    },
    p: {
      name: 'p',
      value: 1e-12,
      scientific: true
    },
    f: {
      name: 'f',
      value: 1e-15,
      scientific: true
    },
    a: {
      name: 'a',
      value: 1e-18,
      scientific: true
    },
    z: {
      name: 'z',
      value: 1e-21,
      scientific: true
    },
    y: {
      name: 'y',
      value: 1e-24,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.LONG]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    deca: {
      name: 'deca',
      value: 1e1,
      scientific: false
    },
    hecto: {
      name: 'hecto',
      value: 1e2,
      scientific: false
    },
    kilo: {
      name: 'kilo',
      value: 1e3,
      scientific: true
    },
    mega: {
      name: 'mega',
      value: 1e6,
      scientific: true
    },
    giga: {
      name: 'giga',
      value: 1e9,
      scientific: true
    },
    tera: {
      name: 'tera',
      value: 1e12,
      scientific: true
    },
    peta: {
      name: 'peta',
      value: 1e15,
      scientific: true
    },
    exa: {
      name: 'exa',
      value: 1e18,
      scientific: true
    },
    zetta: {
      name: 'zetta',
      value: 1e21,
      scientific: true
    },
    yotta: {
      name: 'yotta',
      value: 1e24,
      scientific: true
    },
    deci: {
      name: 'deci',
      value: 1e-1,
      scientific: false
    },
    centi: {
      name: 'centi',
      value: 1e-2,
      scientific: false
    },
    milli: {
      name: 'milli',
      value: 1e-3,
      scientific: true
    },
    micro: {
      name: 'micro',
      value: 1e-6,
      scientific: true
    },
    nano: {
      name: 'nano',
      value: 1e-9,
      scientific: true
    },
    pico: {
      name: 'pico',
      value: 1e-12,
      scientific: true
    },
    femto: {
      name: 'femto',
      value: 1e-15,
      scientific: true
    },
    atto: {
      name: 'atto',
      value: 1e-18,
      scientific: true
    },
    zepto: {
      name: 'zepto',
      value: 1e-21,
      scientific: true
    },
    yocto: {
      name: 'yocto',
      value: 1e-24,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.SQUARED]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    da: {
      name: 'da',
      value: 1e2,
      scientific: false
    },
    h: {
      name: 'h',
      value: 1e4,
      scientific: false
    },
    k: {
      name: 'k',
      value: 1e6,
      scientific: true
    },
    M: {
      name: 'M',
      value: 1e12,
      scientific: true
    },
    G: {
      name: 'G',
      value: 1e18,
      scientific: true
    },
    T: {
      name: 'T',
      value: 1e24,
      scientific: true
    },
    P: {
      name: 'P',
      value: 1e30,
      scientific: true
    },
    E: {
      name: 'E',
      value: 1e36,
      scientific: true
    },
    Z: {
      name: 'Z',
      value: 1e42,
      scientific: true
    },
    Y: {
      name: 'Y',
      value: 1e48,
      scientific: true
    },
    d: {
      name: 'd',
      value: 1e-2,
      scientific: false
    },
    c: {
      name: 'c',
      value: 1e-4,
      scientific: false
    },
    m: {
      name: 'm',
      value: 1e-6,
      scientific: true
    },
    u: {
      name: 'u',
      value: 1e-12,
      scientific: true
    },
    n: {
      name: 'n',
      value: 1e-18,
      scientific: true
    },
    p: {
      name: 'p',
      value: 1e-24,
      scientific: true
    },
    f: {
      name: 'f',
      value: 1e-30,
      scientific: true
    },
    a: {
      name: 'a',
      value: 1e-36,
      scientific: true
    },
    z: {
      name: 'z',
      value: 1e-42,
      scientific: true
    },
    y: {
      name: 'y',
      value: 1e-48,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.CUBIC]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    da: {
      name: 'da',
      value: 1e3,
      scientific: false
    },
    h: {
      name: 'h',
      value: 1e6,
      scientific: false
    },
    k: {
      name: 'k',
      value: 1e9,
      scientific: true
    },
    M: {
      name: 'M',
      value: 1e18,
      scientific: true
    },
    G: {
      name: 'G',
      value: 1e27,
      scientific: true
    },
    T: {
      name: 'T',
      value: 1e36,
      scientific: true
    },
    P: {
      name: 'P',
      value: 1e45,
      scientific: true
    },
    E: {
      name: 'E',
      value: 1e54,
      scientific: true
    },
    Z: {
      name: 'Z',
      value: 1e63,
      scientific: true
    },
    Y: {
      name: 'Y',
      value: 1e72,
      scientific: true
    },
    d: {
      name: 'd',
      value: 1e-3,
      scientific: false
    },
    c: {
      name: 'c',
      value: 1e-6,
      scientific: false
    },
    m: {
      name: 'm',
      value: 1e-9,
      scientific: true
    },
    u: {
      name: 'u',
      value: 1e-18,
      scientific: true
    },
    n: {
      name: 'n',
      value: 1e-27,
      scientific: true
    },
    p: {
      name: 'p',
      value: 1e-36,
      scientific: true
    },
    f: {
      name: 'f',
      value: 1e-45,
      scientific: true
    },
    a: {
      name: 'a',
      value: 1e-54,
      scientific: true
    },
    z: {
      name: 'z',
      value: 1e-63,
      scientific: true
    },
    y: {
      name: 'y',
      value: 1e-72,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.BINARY_SHORT_SI]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    k: {
      name: 'k',
      value: 1e3,
      scientific: true
    },
    M: {
      name: 'M',
      value: 1e6,
      scientific: true
    },
    G: {
      name: 'G',
      value: 1e9,
      scientific: true
    },
    T: {
      name: 'T',
      value: 1e12,
      scientific: true
    },
    P: {
      name: 'P',
      value: 1e15,
      scientific: true
    },
    E: {
      name: 'E',
      value: 1e18,
      scientific: true
    },
    Z: {
      name: 'Z',
      value: 1e21,
      scientific: true
    },
    Y: {
      name: 'Y',
      value: 1e24,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.BINARY_SHORT_IEC]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    Ki: {
      name: 'Ki',
      value: 1024,
      scientific: true
    },
    Mi: {
      name: 'Mi',
      value: Math.pow(1024, 2),
      scientific: true
    },
    Gi: {
      name: 'Gi',
      value: Math.pow(1024, 3),
      scientific: true
    },
    Ti: {
      name: 'Ti',
      value: Math.pow(1024, 4),
      scientific: true
    },
    Pi: {
      name: 'Pi',
      value: Math.pow(1024, 5),
      scientific: true
    },
    Ei: {
      name: 'Ei',
      value: Math.pow(1024, 6),
      scientific: true
    },
    Zi: {
      name: 'Zi',
      value: Math.pow(1024, 7),
      scientific: true
    },
    Yi: {
      name: 'Yi',
      value: Math.pow(1024, 8),
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.BINARY_LONG_SI]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    kilo: {
      name: 'kilo',
      value: 1e3,
      scientific: true
    },
    mega: {
      name: 'mega',
      value: 1e6,
      scientific: true
    },
    giga: {
      name: 'giga',
      value: 1e9,
      scientific: true
    },
    tera: {
      name: 'tera',
      value: 1e12,
      scientific: true
    },
    peta: {
      name: 'peta',
      value: 1e15,
      scientific: true
    },
    exa: {
      name: 'exa',
      value: 1e18,
      scientific: true
    },
    zetta: {
      name: 'zetta',
      value: 1e21,
      scientific: true
    },
    yotta: {
      name: 'yotta',
      value: 1e24,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.BINARY_LONG_IEC]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    kibi: {
      name: 'kibi',
      value: 1024,
      scientific: true
    },
    mebi: {
      name: 'mebi',
      value: Math.pow(1024, 2),
      scientific: true
    },
    gibi: {
      name: 'gibi',
      value: Math.pow(1024, 3),
      scientific: true
    },
    tebi: {
      name: 'tebi',
      value: Math.pow(1024, 4),
      scientific: true
    },
    pebi: {
      name: 'pebi',
      value: Math.pow(1024, 5),
      scientific: true
    },
    exi: {
      name: 'exi',
      value: Math.pow(1024, 6),
      scientific: true
    },
    zebi: {
      name: 'zebi',
      value: Math.pow(1024, 7),
      scientific: true
    },
    yobi: {
      name: 'yobi',
      value: Math.pow(1024, 8),
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.BTU]: {
    '': {
      name: '',
      value: 1,
      scientific: true
    },
    MM: {
      name: 'MM',
      value: 1e6,
      scientific: true
    }
  },
  [interfaces_1.BbitUnitTextVariant.FORMAT]: {}
};
exports.BASE_UNIT_DIMENSIONS = {
  [interfaces_1.BbitUnitBases.NONE]: {},
  [interfaces_1.BbitUnitBases.RATIO]: {},
  [interfaces_1.BbitUnitBases.PACKAGING]: {},
  [interfaces_1.BbitUnitBases.MASS]: {
    [interfaces_1.BbitUnitDimensions.MASS]: 1
  },
  [interfaces_1.BbitUnitBases.LENGTH]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1
  },
  [interfaces_1.BbitUnitBases.TIME]: {
    [interfaces_1.BbitUnitDimensions.TIME]: 1
  },
  [interfaces_1.BbitUnitBases.CURRENT]: {
    [interfaces_1.BbitUnitDimensions.CURRENT]: 1
  },
  [interfaces_1.BbitUnitBases.TEMPERATURE]: {
    [interfaces_1.BbitUnitDimensions.TEMPERATURE]: 1
  },
  [interfaces_1.BbitUnitBases.LUMINOUS_INTENSITY]: {
    [interfaces_1.BbitUnitDimensions.LUMINOUS_INTENSITY]: 1
  },
  [interfaces_1.BbitUnitBases.AMOUNT_OF_SUBSTANCE]: {
    [interfaces_1.BbitUnitDimensions.AMOUNT_OF_SUBSTANCE]: 1
  },
  [interfaces_1.BbitUnitBases.ANGLE]: {
    [interfaces_1.BbitUnitDimensions.ANGLE]: 1
  },
  [interfaces_1.BbitUnitBases.BIT]: {
    [interfaces_1.BbitUnitDimensions.BIT]: 1
  },
  [interfaces_1.BbitUnitBases.CURRENCY]: {
    [interfaces_1.BbitUnitDimensions.CURRENCY]: 1
  },
  [interfaces_1.BbitUnitBases.COUNT]: {
    [interfaces_1.BbitUnitDimensions.COUNT]: 1
  },
  [interfaces_1.BbitUnitBases.FORCE]: {
    [interfaces_1.BbitUnitDimensions.FORCE]: 1
  },
  [interfaces_1.BbitUnitBases.SURFACE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 2
  },
  [interfaces_1.BbitUnitBases.VOLUME]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 3
  },
  [interfaces_1.BbitUnitBases.ENERGY]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -2
  },
  [interfaces_1.BbitUnitBases.POWER]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -3
  },
  [interfaces_1.BbitUnitBases.PRESSURE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: -1,
    [interfaces_1.BbitUnitDimensions.TIME]: -2
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_CHARGE]: {
    [interfaces_1.BbitUnitDimensions.TIME]: 1,
    [interfaces_1.BbitUnitDimensions.CURRENT]: 1
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_CAPACITANCE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: -1,
    [interfaces_1.BbitUnitDimensions.MASS]: -2,
    [interfaces_1.BbitUnitDimensions.TIME]: 4,
    [interfaces_1.BbitUnitDimensions.CURRENT]: 2
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_POTENTIAL]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -3,
    [interfaces_1.BbitUnitDimensions.CURRENT]: -1
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_RESISTANCE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -3,
    [interfaces_1.BbitUnitDimensions.CURRENT]: -2
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_INDUCTANCE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -2,
    [interfaces_1.BbitUnitDimensions.CURRENT]: -2
  },
  [interfaces_1.BbitUnitBases.ELECTRIC_CONDUCTANCE]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: -1,
    [interfaces_1.BbitUnitDimensions.MASS]: -2,
    [interfaces_1.BbitUnitDimensions.TIME]: 3,
    [interfaces_1.BbitUnitDimensions.CURRENT]: 2
  },
  [interfaces_1.BbitUnitBases.MAGNETIC_FLUX]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.MASS]: 2,
    [interfaces_1.BbitUnitDimensions.TIME]: -2,
    [interfaces_1.BbitUnitDimensions.CURRENT]: -1
  },
  [interfaces_1.BbitUnitBases.MAGNETIC_FLUX_DENSITY]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.TIME]: -2,
    [interfaces_1.BbitUnitDimensions.CURRENT]: -1
  },
  [interfaces_1.BbitUnitBases.FREQUENCY]: {
    [interfaces_1.BbitUnitDimensions.TIME]: -1
  },
  [interfaces_1.BbitUnitBases.VELOCITY]: {
    [interfaces_1.BbitUnitDimensions.LENGTH]: 1,
    [interfaces_1.BbitUnitDimensions.TIME]: -1
  },
  [interfaces_1.BbitUnitBases.LOUDNESS]: {}
};
class BbitUnitSystem {
  constructor() {
    this.PREFIXES = PREFIXES;
    this.additionalUnits = [];
  }
  ensureUnitIndex() {
    if (!this.unitNameIndexByBase) {
      this.rebuildUnitIndex();
    }
  }
  rebuildUnitIndex() {
    var _a, _b;
    const allUnits = unit_definitions_1.unitDefinitions.concat(this.additionalUnits);
    const languages = array_1.BbitArray.unique(allUnits.flatMap(unit => Object.keys(unit.name)));
    const sameBaseDuplicates = [];
    const outputByBase = {};
    for (const language of languages) {
      outputByBase[language] = {};
      for (const unit of allUnits) {
        let validPrefixes = Object.assign({}, PREFIXES[interfaces_1.BbitUnitTextVariant.GLOBAL]);
        switch (unit.base) {
          case interfaces_1.BbitUnitBases.CURRENCY:
          case interfaces_1.BbitUnitBases.RATIO:
          case interfaces_1.BbitUnitBases.NONE:
          case interfaces_1.BbitUnitBases.TEMPERATURE:
            break;
          case interfaces_1.BbitUnitBases.TIME:
          case interfaces_1.BbitUnitBases.LENGTH:
          case interfaces_1.BbitUnitBases.MASS:
          case interfaces_1.BbitUnitBases.VOLUME:
          case interfaces_1.BbitUnitBases.POWER:
          case interfaces_1.BbitUnitBases.ENERGY:
            validPrefixes = Object.assign(Object.assign({}, PREFIXES[interfaces_1.BbitUnitTextVariant.SHORT]), PREFIXES[interfaces_1.BbitUnitTextVariant.LONG]);
            break;
        }
        if (!outputByBase[language][unit.base]) {
          outputByBase[language][unit.base] = {};
        }
        const prefixList = Object.values(validPrefixes) || [];
        for (const coefficient of prefixList) {
          const aliases = array_1.BbitArray.unique([].concat(unit.formatAs ? Object.values(unit.formatAs[language] || {}) : [], unit.name ? Object.values(unit.name[language] || {}) : [], ((_a = unit.aliases) === null || _a === void 0 ? void 0 : _a.long) ? Object.values(unit.aliases.long[language] || {}) : [], ((_b = unit.aliases) === null || _b === void 0 ? void 0 : _b.short) ? Object.values(unit.aliases.short[language] || {}) : []));
          for (const alias of aliases) {
            const name = coefficient.name + BbitUnitSystem.normalizeUnit(alias.replace(/\s+/gi, ''), false);
            if (!outputByBase[language][unit.base][name]) {
              outputByBase[language][unit.base][name] = {
                language,
                name,
                unit,
                coefficient
              };
            } else {
              if (!(unit === outputByBase[language][unit.base][name].unit && coefficient === outputByBase[language][unit.base][name].coefficient)) {
                sameBaseDuplicates.push({
                  language,
                  name,
                  unit,
                  coefficient,
                  existingUnit: outputByBase[language][unit.base][name].unit,
                  existingCoefficient: outputByBase[language][unit.base][name].coefficient
                });
              }
            }
          }
        }
      }
    }
    this.unitNameIndexByBase = outputByBase;
    return {
      sameBaseDuplicates
    };
  }
  static normalizeUnit(input, firstCharNotToLowerCase) {
    const str = string_1.BbitString.removeDiacritics(string_1.BbitString.trim(input || '', ' _-')).replace(/[\s\.,]+/gi, '-').replace(/-+/gi, '-').replace(/^-|-$/g, '');
    if (firstCharNotToLowerCase) {
      return str.charAt(0) + str.slice(1).toLowerCase();
    }
    return str.toLowerCase();
  }
  getLanguages() {
    return Object.keys(this.unitNameIndexByBase || {});
  }
  determineUnit(unitId, allowedBases, languages, noLanguageFallback) {
    var _a, _b;
    if (!unitId || unitId.length === 0) {
      return undefined;
    }
    this.ensureUnitIndex();
    if (!languages || languages.length === 0) {
      languages = [i18n_1.BbitI18n.getLanguage()];
    } else {
      const unknownLanguages = languages.filter(l => !this.unitNameIndexByBase[l]);
      if (unknownLanguages.length === languages.length) {
        throw new error_1.BbitError('no-specified-unit-language-is-known', {
          languages,
          availableLanguages: Object.keys(this.unitNameIndexByBase)
        });
      }
    }
    if (!noLanguageFallback) {
      languages = array_1.BbitArray.unique(languages.concat(Object.keys(this.unitNameIndexByBase)));
    }
    languages = languages.filter(Boolean);
    if (!allowedBases || allowedBases.length === 0 || allowedBases.indexOf(interfaces_1.BbitUnitBases.NONE) >= 0) {
      if (!languages[0]) {
        throw new error_1.BbitError('no-unit-languages-defined-in-index', {
          languages
        });
      }
      allowedBases = Object.keys(this.unitNameIndexByBase[languages[0]] || {});
    }
    allowedBases = array_1.BbitArray.unique(allowedBases);
    const lookFor = string_1.BbitString.stripBeginningHashtag(unitId).replace(/[\s\^]+/gi, '').replace(/\.$/i, '');
    const typoVariants = array_1.BbitArray.unique([string_1.BbitString.stripBeginningHashtag(lookFor).replace(/\.$/i, ''), BbitUnitSystem.normalizeUnit(lookFor, true), BbitUnitSystem.normalizeUnit(lookFor, false)]);
    for (const name of typoVariants) {
      for (const lang of languages) {
        for (const base of allowedBases) {
          const found = (_b = (_a = this.unitNameIndexByBase[lang]) === null || _a === void 0 ? void 0 : _a[base]) === null || _b === void 0 ? void 0 : _b[name];
          if (found) {
            return found;
          }
        }
      }
    }
    return undefined;
  }
  static getValueByDate(unitDef, date) {
    if (!date || !date.isValid) {
      return {
        timespan: null,
        value: unitDef.value
      };
    }
    if (unitDef.valuePerDate) {
      let validDates = object_array_1.BbitObjectArray.filter(unitDef.valuePerDate, def => luxon_1.Interval.fromDateTimes(luxon_1.DateTime.fromISO(def.from), luxon_1.DateTime.fromISO(def.until)).contains(date));
      if (validDates.length > 1) {
        validDates = array_1.BbitArray.sortBy(validDates, d => d.from).reverse();
      }
      if (validDates.length > 0) {
        const def = validDates[0];
        return {
          timespan: luxon_1.Interval.fromDateTimes(luxon_1.DateTime.fromISO(def.from), luxon_1.DateTime.fromISO(def.until)),
          value: def.value
        };
      }
    }
    return {
      timespan: null,
      value: unitDef.value
    };
  }
}
exports.BbitUnitSystem = BbitUnitSystem;
exports.bbitDefaultUnitSystem = new BbitUnitSystem();
