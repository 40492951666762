"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitSchemalessDocRegistry = void 0;
const immer_1 = require("immer");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const array_1 = require("../../primitives/array");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const object_1 = require("../../primitives/object");
const result_1 = require("../../primitives/result");
const utils_1 = require("../../utils/utils");
const bbase2_utils_1 = require("../utils/bbase2-utils");
const interfaces_1 = require("../utils/interfaces");
const bucket_index_1 = require("./bucket-index");
const schemaless_doc_1 = require("./schemaless-doc");
class BbitSchemalessDocRegistry {
  constructor(bbaseStore) {
    this.bbaseStore = bbaseStore;
    this._revChanges = new rxjs_1.Subject();
    this._states = {};
    if (!bbaseStore) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'constructor.bbaseStore',
        reason: 'must not be null',
        value: bbaseStore
      });
    }
  }
  _initBucketState(doc) {
    if (!doc._bucket || doc._bucket.length === 0) {
      return result_1.BbitResult.createError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'doc',
        value: doc,
        reason: 'must have a non empty _bucket property'
      }));
    }
    if (!this._states[doc._bucket]) {
      this._states[doc._bucket] = {
        docs: {},
        indexes: {}
      };
      for (const index of [interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED, interfaces_1.Bbase2Indexes.DELETED, interfaces_1.Bbase2Indexes.CONFLICTED, interfaces_1.Bbase2Indexes.TEMPLATE, interfaces_1.Bbase2Indexes.DRAFT]) {
        this._states[doc._bucket].indexes[index] = new bucket_index_1.BbitBucketIndex(doc._bucket, index);
      }
    }
    return result_1.BbitResult.createSuccess();
  }
  _initCacheForDoc(doc, schemalessDoc) {
    const bucketInit = this._initBucketState(doc);
    if (result_1.BbitResult.isError(bucketInit)) {
      return bucketInit;
    }
    if (!doc._id || doc._id.length === 0) {
      return result_1.BbitResult.createError(new error_invalid_param_1.BbitInvalidParamError({
        param: 'doc',
        value: doc,
        reason: 'must have a non empty _id property'
      }));
    }
    if (!this._states[doc._bucket].docs[doc._id]) {
      const head = schemalessDoc ? schemalessDoc : new schemaless_doc_1.BbitSchemalessDocument(doc, this.bbaseStore);
      this._states[doc._bucket].docs[doc._id] = {
        head,
        revs: {},
        revChangeSub: head.observeRevChanges().subscribe({
          next: msg => this._revChanges.next(msg),
          complete: () => {
            this._states[doc._bucket].docs[doc._id] = undefined;
          }
        })
      };
    }
    if (doc._rev && !this._states[doc._bucket].docs[doc._id].revs[doc._rev]) {
      this._states[doc._bucket].docs[doc._id].revs[doc._rev] = new schemaless_doc_1.BbitSchemalessDocument(Object.assign(Object.assign({}, doc), {
        readonly: true
      }), this.bbaseStore);
    }
    return result_1.BbitResult.createSuccess();
  }
  setLatestServerRev(params) {
    var _a, _b, _c, _d, _e, _f, _g;
    if ((_b = (_a = this._states) === null || _a === void 0 ? void 0 : _a[params._bucket]) === null || _b === void 0 ? void 0 : _b.indexes) {
      for (const i of Object.keys(this._states[params._bucket].indexes)) {
        (_c = this._states[params._bucket].indexes[i]) === null || _c === void 0 ? void 0 : _c.setLatestServerRev(params);
      }
    }
    if ((_g = (_f = (_e = (_d = this._states) === null || _d === void 0 ? void 0 : _d[params._bucket]) === null || _e === void 0 ? void 0 : _e.docs) === null || _f === void 0 ? void 0 : _f[params._id]) === null || _g === void 0 ? void 0 : _g.head) {
      this._states[params._bucket].docs[params._id].head.setLatestServerRev(params._rev);
    }
  }
  observeRevisionChanges() {
    return this._revChanges.asObservable();
  }
  getSchemalessDoc(doc) {
    const initRes = this._initCacheForDoc(doc);
    if (result_1.BbitResult.isError(initRes)) {
      return initRes;
    }
    if (doc._rev) {
      if (doc._rev === this._states[doc._bucket].docs[doc._id].head.getRev()) {
        console.warn('loading head revision of document as readonly revision history', Object.assign({}, doc));
      }
      return result_1.BbitResult.createSuccess(this._states[doc._bucket].docs[doc._id].revs[doc._rev]);
    }
    return result_1.BbitResult.createSuccess(this._states[doc._bucket].docs[doc._id].head);
  }
  clearCache() {
    this._states = {};
  }
  ensureCache(caches, options) {
    return __awaiter(this, void 0, void 0, function* () {
      const res = yield Promise.all(caches.map(c => __awaiter(this, void 0, void 0, function* () {
        switch (c.type) {
          case 'doc':
            {
              const docRes = yield this.retrieveDoc(c, options);
              return Object.assign(Object.assign({}, c), docRes);
            }
          case 'list':
            {
              const list = yield this.retrieveList(Object.assign(Object.assign({}, c), {
                maxCacheSeconds: options === null || options === void 0 ? void 0 : options.maxCacheSeconds
              }));
              return Object.assign(Object.assign({}, c), {
                list
              });
            }
        }
      })));
      return res;
    });
  }
  hasSchemalessDoc(doc) {
    var _a, _b;
    return !!((_b = (_a = this._states[doc._bucket]) === null || _a === void 0 ? void 0 : _a.docs[doc._id]) === null || _b === void 0 ? void 0 : _b.head);
  }
  createNew(inputDoc) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      const clonedDoc = object_1.BbitObject.cloneDeep(inputDoc);
      if (!clonedDoc._id || clonedDoc._id.length === 0) {
        clonedDoc._id = utils_1.BbitUtils.makeId();
      }
      delete clonedDoc._rev;
      clonedDoc._index = interfaces_1.Bbase2Indexes.HEAD;
      const newDoc = yield this.getSchemalessDoc({
        _bucket: clonedDoc._bucket,
        _id: clonedDoc._id
      }).toPromise();
      if (((_a = bbase2_utils_1.Bbase2Utils.splitRevision(newDoc.getRev())) === null || _a === void 0 ? void 0 : _a.version) > 0) {
        console.warn(new Error('trying to create new document over an existing id, aborting import'), {
          _bucket: newDoc.getBucket(),
          _id: newDoc.getId(),
          inputDoc
        });
      } else {
        yield newDoc.import({
          doc: clonedDoc,
          isImmutable: true,
          reapplyChanges: false
        });
      }
      return newDoc;
    });
  }
  clearSchemalessDoc(doc) {
    if (this.hasSchemalessDoc(doc)) {
      delete this._states[doc._bucket].docs[doc._id];
    }
  }
  importDoc(doc, options) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (((_a = bbase2_utils_1.Bbase2Utils.splitRevision(doc._rev)) === null || _a === void 0 ? void 0 : _a.version) > 0 && (options === null || options === void 0 ? void 0 : options.isOldRevision)) {
        const initDoc = yield this.getSchemalessDoc(doc).toPromise();
        return yield initDoc.import({
          doc,
          isImmutable: (options === null || options === void 0 ? void 0 : options.isInputImmutable) || Object.isFrozen(doc),
          reapplyChanges: false
        });
      }
      const isExisting = this.hasSchemalessDoc(doc);
      const schemalessdoc = isExisting ? this._states[doc._bucket].docs[doc._id].head : new schemaless_doc_1.BbitSchemalessDocument({
        _bucket: doc._bucket,
        _id: doc._id
      }, this.bbaseStore);
      let docToAdd = doc;
      if (options === null || options === void 0 ? void 0 : options.overwrite) {
        if (Object.isFrozen(doc)) {
          docToAdd = (0, immer_1.produce)(doc, draft => {
            Object.assign(draft, options.overwrite);
          });
        } else {
          docToAdd = Object.assign(doc, options.overwrite);
        }
      }
      const headRes = yield schemalessdoc.import({
        doc: docToAdd,
        isImmutable: (options === null || options === void 0 ? void 0 : options.isInputImmutable) || Object.isFrozen(doc),
        reapplyChanges: options === null || options === void 0 ? void 0 : options.reapplyChanges
      });
      if (!isExisting) {
        const initRes = this._initCacheForDoc(doc, schemalessdoc);
        if (result_1.BbitResult.isError(initRes)) {
          return initRes.toPromise();
        }
      }
      return headRes;
    });
  }
  batchImportRevisionsIntoCache(docs, options) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!docs) {
        return [];
      }
      const input = array_1.BbitArray.isArray(docs) ? docs : [docs];
      if (input.length === 0) {
        return [];
      }
      return Promise.all(input.map(doc => this.importDoc(doc, options)));
    });
  }
  retrieveDoc(doc, options = {}) {
    const res = this.getSchemalessDoc(doc);
    if (result_1.BbitResult.isError(res)) {
      throw result_1.BbitResult.extractError(res);
    }
    return res.data.retrieveFromDatabase(options);
  }
  retrieveList(params) {
    const initRes = this._initBucketState(params);
    if (result_1.BbitResult.isError(initRes)) {
      return initRes.toPromise();
    }
    if (!this._states[params._bucket].indexes[params._index]) {
      throw new error_1.BbitError('unsupported-index-for-list-doc-ids', {
        doc: params
      });
    }
    return this._states[params._bucket].indexes[params._index].retrieveFromDatabase({
      bbaseStore: this.bbaseStore,
      maxCacheSeconds: params.maxCacheSeconds,
      docImporter: doc => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
          yield this.importDoc(doc, {
            isOldRevision: false,
            isInputImmutable: true,
            reapplyChanges: (_a = params === null || params === void 0 ? void 0 : params.reapplyChanges) !== null && _a !== void 0 ? _a : true
          });
        } catch (err) {
          console.error('error on list importDoc', err);
        }
      })
    });
  }
  listSchemalessDocs(params) {
    return __awaiter(this, void 0, void 0, function* () {
      const docs = yield this.retrieveList(params);
      return docs.map(d => this.getSchemalessDoc(d)).filter(d => result_1.BbitResult.isOk(d)).map(r => r.data);
    });
  }
  batchRetrieveDocs(docs, options = {}) {
    var _a;
    return (0, rxjs_1.of)(...docs).pipe((0, operators_1.mergeMap)(doc => __awaiter(this, void 0, void 0, function* () {
      try {
        return result_1.BbitResult.createSuccess(yield this.retrieveDoc(doc, options));
      } catch (err) {
        if (options.loadErrorHandler) {
          return yield options.loadErrorHandler(Object.assign(Object.assign({}, doc), {
            error: err
          }));
        }
        throw err;
      }
    }), (_a = options === null || options === void 0 ? void 0 : options.maxConcurrent) !== null && _a !== void 0 ? _a : 20), (0, operators_1.reduce)((acc, val) => acc.concat(val), []));
  }
}
exports.BbitSchemalessDocRegistry = BbitSchemalessDocRegistry;
