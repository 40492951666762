"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CalendarUtils = void 0;
const luxon_1 = require("luxon");
const i18n_1 = require("../i18n/i18n");
const array_1 = require("../primitives/array");
const date_time_1 = require("../primitives/date-time");
class CalendarUtils {
  static parseMonth(date, options) {
    if (!options) {
      options = {};
    }
    if (!options.timezone) {
      options.timezone = i18n_1.BbitI18n.getTimezone();
    }
    if (!date) {
      date = luxon_1.DateTime.local().setZone(options.timezone).startOf('day');
    }
    const firstDayOfMonth = date.startOf('month');
    const lastDayOfMonth = date.endOf('month');
    const firstDayToDisplay = firstDayOfMonth.startOf('week');
    const days = [];
    const today = luxon_1.DateTime.local().setZone(options.timezone).startOf('day');
    for (let i = 0; i <= 41; i++) {
      const dayDate = firstDayToDisplay.plus({
        days: i
      });
      days.push({
        _options: options,
        day: dayDate.day,
        date: dayDate,
        today: date_time_1.BbitDateTime.isSameDay(today, dayDate),
        active: dayDate.hasSame(firstDayOfMonth, 'month'),
        events: [],
        timezone: options.timezone
      });
    }
    const weeks = array_1.BbitArray.chunkArray(days, 7).map(o => {
      return {
        _options: options,
        weekNumber: o[0].date.weekNumber,
        startDate: o[0].date.startOf('day'),
        endDate: o[6].date.endOf('day'),
        days: o,
        timezone: options.timezone
      };
    });
    return {
      _options: options,
      startDate: firstDayOfMonth.startOf('day'),
      endDate: lastDayOfMonth.endOf('day'),
      weeks: weeks,
      timezone: options.timezone
    };
  }
  static parseWeek(date, options) {
    if (!options) {
      options = {};
    }
    if (!options.timezone) {
      options.timezone = i18n_1.BbitI18n.getTimezone();
    }
    if (!date) {
      date = luxon_1.DateTime.local().setZone(options.timezone).startOf('day');
    }
    const days = [];
    const today = luxon_1.DateTime.local().setZone(options.timezone).startOf('day');
    if (options.givenDayOnly) {
      days.push({
        _options: options,
        day: date.day,
        date: date,
        today: date_time_1.BbitDateTime.isSameDay(today, date),
        active: true,
        events: [],
        timezone: options.timezone
      });
    } else {
      const firstDayOfWeek = date.startOf('week');
      for (let i = 0; i <= 6; i++) {
        const dayDate = firstDayOfWeek.plus({
          days: i
        });
        days.push({
          _options: options,
          day: dayDate.day,
          date: dayDate,
          today: date_time_1.BbitDateTime.isSameDay(today, dayDate),
          active: dayDate.hasSame(firstDayOfWeek, 'month'),
          events: [],
          timezone: options.timezone
        });
      }
    }
    return {
      _options: options,
      weekNumber: days[0].date.weekNumber,
      startDate: days[0].date.startOf('day'),
      endDate: days[days.length - 1].date.endOf('day'),
      days: days,
      timezone: options.timezone
    };
  }
  static getNextMonth(currentMonth) {
    const date = currentMonth.startDate.plus({
      months: 1
    });
    return CalendarUtils.parseMonth(date, currentMonth._options);
  }
  static getPreviousMonth(currentMonth) {
    const date = currentMonth.startDate.minus({
      months: 1
    });
    return CalendarUtils.parseMonth(date, currentMonth._options);
  }
  static getNextWeek(currentWeek) {
    let date;
    if (currentWeek._options.givenDayOnly) {
      date = currentWeek.startDate.plus({
        days: 1
      });
    } else {
      date = currentWeek.startDate.plus({
        weeks: 1
      });
    }
    return CalendarUtils.parseWeek(date, currentWeek._options);
  }
  static getPreviousWeek(currentWeek) {
    let date;
    if (currentWeek._options.givenDayOnly) {
      date = currentWeek.startDate.minus({
        days: 1
      });
    } else {
      date = currentWeek.startDate.minus({
        weeks: 1
      });
    }
    return CalendarUtils.parseWeek(date, currentWeek._options);
  }
  static areDatesEqual(date1, date2) {
    if (!date1 || !date2) {
      return false;
    }
    return date1.hasSame(date2, 'year') && date1.hasSame(date2, 'month') && date1.hasSame(date2, 'day');
  }
  static isDateInRange(date, startDate, endDate) {
    const start = startDate.startOf('day');
    const end = endDate.endOf('day');
    if (date >= start && date <= end) {
      return true;
    }
    return false;
  }
}
exports.CalendarUtils = CalendarUtils;
