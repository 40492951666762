"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitLogTransportConsole = void 0;
const object_1 = require("../../primitives/object");
const interfaces_1 = require("../interfaces");
const log_1 = require("../log");
class BbitLogTransportConsole extends interfaces_1.BbitLogTransport {
  getKey() {
    return 'console';
  }
  log(msg) {
    switch (msg.level) {
      case interfaces_1.BbitLogLevel.INFO:
      case interfaces_1.BbitLogLevel.SUCCESS:
      case interfaces_1.BbitLogLevel.DEBUG:
      case interfaces_1.BbitLogLevel.WARNING:
        if (log_1.BbitLog.isConsoleDisabled) {
          return;
        }
        if (msg.scope && msg.scope.package && log_1.BbitLog.disabledConsolePackages.find(o => o === msg.scope.package)) {
          return;
        }
    }
    let log = console.log;
    let state = '';
    switch (msg.level) {
      case interfaces_1.BbitLogLevel.CRITICAL:
        state = '[CRITICAL]';
        log = console.error;
        break;
      case interfaces_1.BbitLogLevel.ERROR:
        state = '[ERROR]';
        log = console.error;
        break;
      case interfaces_1.BbitLogLevel.WARNING:
        state = '[WARNING]';
        log = console.warn;
        break;
      case interfaces_1.BbitLogLevel.SUCCESS:
        state = '[SUCCESS]';
        break;
      case interfaces_1.BbitLogLevel.INFO:
        state = '[INFO]';
        break;
      case interfaces_1.BbitLogLevel.DEBUG:
        state = '[DEBUG]';
        break;
    }
    if (!log_1.BbitLog.isPrettyPrintEnabled()) {
      log(msg);
      return;
    }
    let pack = '';
    let cls = '';
    let func = '';
    if (msg.scope) {
      if (msg.scope.package) {
        pack = `<${msg.scope.package.trim()}>`;
      }
      if (msg.scope.class) {
        cls = `(${msg.scope.class.trim()})`;
      }
      if (msg.scope.function) {
        func = `{${msg.scope.function}}`;
      }
    }
    let text = '';
    if (msg.message instanceof Error) {
      text = msg.message.message;
    } else {
      text = msg.message;
    }
    let toLog = '';
    if (state) {
      toLog = `${toLog.trim()} ${state}`;
    }
    if (pack) {
      toLog = `${toLog.trim()} ${pack}`;
    }
    if (cls) {
      toLog = `${toLog.trim()} ${cls}`;
    }
    if (func) {
      toLog = `${toLog.trim()} ${func.trim()}`;
    }
    if (text) {
      toLog = `${toLog.trim()} ${text.trim()}`;
    }
    const payload = object_1.BbitObject.clone(msg.payload || []);
    log(toLog, ...payload);
  }
}
exports.BbitLogTransportConsole = BbitLogTransportConsole;
