"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitPermissionChecker = void 0;
const crypto_1 = require("../crypto/crypto");
const date_time_1 = require("../primitives/date-time");
const result_1 = require("../primitives/result");
const string_1 = require("../primitives/string");
const interfaces_1 = require("./interfaces");
class BbitPermissionChecker {
  static getPermission(params) {
    return BbitPermissionChecker.reduceApplicablePermission(BbitPermissionChecker.listApplicablePermissions(Object.assign({
      returnFirstDeny: true
    }, params)), params === null || params === void 0 ? void 0 : params.actions, params === null || params === void 0 ? void 0 : params.resources);
  }
  static calcRefreshTokenChallenge(password, deviceId) {
    if (!password || password.length === 0) {
      return undefined;
    }
    deviceId = string_1.BbitString.stripBeginningHashtag(deviceId);
    const crypto = new crypto_1.BbitCrypto();
    for (let i = 0; i < 4; i++) {
      switch (i % 2) {
        case 0:
          password = crypto.sha256AsHex(deviceId + password);
          break;
        case 1:
          password = crypto.sha256AsHex(password + deviceId);
          break;
      }
    }
    return password;
  }
  static calcRefreshTokenPassword(passwordChallenge, randomTokenId, serverSecretValue) {
    if (!passwordChallenge || passwordChallenge.length === 0) {
      return undefined;
    }
    let password = passwordChallenge;
    const crypto = new crypto_1.BbitCrypto();
    for (let i = 0; i < 42; i++) {
      switch (i % 4) {
        case 0:
          password = crypto.sha256AsHex(randomTokenId + password + serverSecretValue);
          break;
        case 1:
          password = crypto.sha256AsHex(serverSecretValue + password + randomTokenId);
          break;
        case 2:
          password = crypto.sha256AsHex(serverSecretValue + randomTokenId + password);
          break;
        case 3:
          password = crypto.sha256AsHex(password + serverSecretValue + randomTokenId);
          break;
      }
    }
    return password;
  }
  static reduceApplicablePermission(applicablePermissions, actions, resources) {
    let perms;
    if (result_1.BbitResult.isResult(applicablePermissions)) {
      if (result_1.BbitResult.isError(applicablePermissions)) {
        return applicablePermissions;
      }
      perms = applicablePermissions.data;
    } else {
      perms = applicablePermissions;
    }
    if (!((perms === null || perms === void 0 ? void 0 : perms.length) > 0)) {
      return result_1.BbitResult.createSuccess({
        effect: 'Deny',
        evaluatedPath: 'implicit',
        principal: null,
        actions,
        resources,
        reason: 'no applicable permission',
        conditions: undefined
      });
    }
    const firstDeny = perms.find(p => p.effect === 'Deny');
    return result_1.BbitResult.createSuccess(firstDeny ? firstDeny : perms[0]);
  }
  static listApplicablePermissions(params) {
    var _a, _b, _c;
    if (!(((_a = params === null || params === void 0 ? void 0 : params.permissions) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
      return result_1.BbitResult.createSuccess([{
        effect: 'Deny',
        evaluatedPath: 'implicit',
        principal: null,
        actions: params.actions,
        resources: params.resources,
        reason: 'no permissions found',
        conditions: undefined
      }]);
    }
    if (!(((_b = params === null || params === void 0 ? void 0 : params.actions) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
      return result_1.BbitResult.createSuccess([{
        effect: 'Deny',
        evaluatedPath: 'implicit',
        principal: null,
        actions: params.actions,
        resources: params.resources,
        reason: 'no action found',
        conditions: undefined
      }]);
    }
    if (!(((_c = params === null || params === void 0 ? void 0 : params.resources) === null || _c === void 0 ? void 0 : _c.length) > 0)) {
      return result_1.BbitResult.createSuccess([{
        effect: 'Deny',
        evaluatedPath: 'implicit',
        principal: null,
        actions: params.actions,
        resources: params.resources,
        reason: 'no resource found',
        conditions: undefined
      }]);
    }
    let firstDenyPermission;
    const filteredPermission = params.permissions.filter(permission => {
      var _a;
      if (firstDenyPermission) return false;
      if (!permission.effect) return false;
      const checks = ['actions', 'resources'];
      for (const check of checks) {
        const currentPatterns = Array.isArray(permission[check]) ? permission[check] : [permission[check]];
        if (!((currentPatterns === null || currentPatterns === void 0 ? void 0 : currentPatterns.length) > 0)) {
          return false;
        }
        if (!currentPatterns.includes('*')) {
          let regexes;
          if (params.customPatternRegex) {
            regexes = params.customPatternRegex(currentPatterns, check);
          } else {
            regexes = {
              include: new RegExp('^' + currentPatterns.map(s => '(' + string_1.BbitString.escapeRegExp(params.checkOnlyNodesCount > 0 ? s.split(':').slice(0, params.checkOnlyNodesCount).join(':') : s).replace(/\\\*/gi, '[^:]*') + ')').join('|') + '$', 'i'),
              excludes: []
            };
          }
          const currentItem = params[check];
          if (!currentItem.includes('*')) {
            const matchingPattern = currentItem.find(s => regexes.include.test(s));
            if (!matchingPattern) {
              return false;
            }
            if (((_a = regexes.excludes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
              for (const exclude of regexes.excludes) {
                const excludingPattern = currentItem.find(s => exclude.test(s));
                if (excludingPattern) {
                  return false;
                }
              }
            }
          }
        }
      }
      const conditionValid = BbitPermissionChecker.areConditionsValid(permission.conditions, params.conditionValues);
      if (!conditionValid) {
        return false;
      }
      if (params.returnFirstDeny && permission.effect === 'Deny') {
        firstDenyPermission = permission;
      }
      return true;
    });
    return result_1.BbitResult.createSuccess(firstDenyPermission ? [firstDenyPermission] : filteredPermission);
  }
  static areConditionsValid(conditions, conditionValues) {
    if ((conditions === null || conditions === void 0 ? void 0 : conditions.length) > 0) {
      for (const condition of conditions) {
        const getKeyValueFunc = conditionValues[condition.key];
        if (!getKeyValueFunc) {
          continue;
        }
        const getKeyValue = getKeyValueFunc();
        const keyType = condition.key.split(':').shift();
        const values = condition.values.length >= 1 ? condition.values : [null];
        let leftValue;
        let rightValues;
        switch (keyType) {
          case 'string':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? getKeyValue.toLowerCase() : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? value.toLowerCase() : null);
            break;
          case 'number':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? Number.parseFloat(getKeyValue) : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? Number.parseFloat(value) : null);
            break;
          case 'date':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? date_time_1.BbitDateTime.parse(getKeyValue).toSeconds() : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? date_time_1.BbitDateTime.parse(value).toSeconds() : null);
            break;
          case 'boolean':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? BbitPermissionChecker.parseBoolean(getKeyValue) : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? BbitPermissionChecker.parseBoolean(value) : null);
            break;
          case 'version':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? BbitPermissionChecker.parseVersion(getKeyValue) : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? BbitPermissionChecker.parseVersion(value) : null);
            break;
          case 'ip':
            leftValue = (getKeyValue === null || getKeyValue === void 0 ? void 0 : getKeyValue.length) > 0 ? BbitPermissionChecker.parseIp(getKeyValue) : null;
            rightValues = values.map(value => (value === null || value === void 0 ? void 0 : value.length) > 0 ? BbitPermissionChecker.parseIp(value) : null);
            break;
          default:
            continue;
        }
        if (rightValues.some(rightValue => !BbitPermissionChecker.conditionValueValidator(leftValue, condition.operator, rightValue))) {
          return false;
        }
      }
    }
    return true;
  }
  static parseVersion(value) {
    const cleaned = value.replace(/\s+/gi, '');
    const versionRegex = /^v?(\d+)\.(\d+)\.(\d+)(\-([\w\-_])+\.(\d+))?$/i;
    let match;
    if ((match = versionRegex.exec(cleaned)) !== null) {
      const [, major, minor, patch,, prerelease, prereleaseVersion] = match;
      const padNumber = number => ('0'.repeat(8) + number).slice(-8);
      return `${padNumber(major)}.${padNumber(minor || '0')}.${padNumber(patch || '0')}-${prerelease || 'zzzzzz'}.${padNumber(prereleaseVersion || '0')}`;
    }
    return undefined;
  }
  static parseIp(value) {
    const splitIp = value.split('.');
    if (splitIp.length !== 4) {
      return undefined;
    }
    return splitIp.reduce((ipInt, octet) => (ipInt << 8) + Number.parseInt(octet, 10), 0) >>> 0;
  }
  static parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    }
    if (!value || value.length === 0) {
      return null;
    }
    switch (value.substr(0, 1).toLowerCase()) {
      case 't':
      case '1':
      case 'y':
      case 'i':
      case 'j':
      case 'o':
        return true;
      case 'f':
      case '0':
      case 'n':
        return false;
      default:
        return undefined;
    }
  }
  static conditionValueValidator(leftValue, operator, rightValue) {
    switch (operator) {
      case interfaces_1.BbitAuthPermissionConditionOperators.EQUALS:
        return leftValue === rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.NOT_EQUALS:
        return leftValue !== rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.GREATER_THAN:
        return leftValue > rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.GREATER_THAN_EQUALS:
        return leftValue >= rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.LESS_THAN:
        return leftValue < rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.LESS_THAN_EQUALS:
        return leftValue <= rightValue;
      case interfaces_1.BbitAuthPermissionConditionOperators.IS_DEFINED:
        return leftValue !== null && leftValue !== undefined;
      case interfaces_1.BbitAuthPermissionConditionOperators.IS_NOT_DEFINED:
        return !(leftValue !== null && leftValue !== undefined);
    }
  }
  static getPermissionFromResult(input, params) {
    return result_1.BbitResult.isOk(input) ? input.data : {
      effect: 'Deny',
      error: result_1.BbitResult.extractError(input),
      action: params.actions,
      resources: params.resources
    };
  }
  static getPermissionListFromResult(input, params) {
    return result_1.BbitResult.isOk(input) ? input.data : [{
      effect: 'Deny',
      error: result_1.BbitResult.extractError(input),
      action: params.actions,
      resources: params.resources
    }];
  }
  static isAllowed(perm) {
    return (perm === null || perm === void 0 ? void 0 : perm.effect) === 'Allow';
  }
}
exports.BbitPermissionChecker = BbitPermissionChecker;
