"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createS3InMemoryContext = void 0;
const error_1 = require("../primitives/error");
const createS3InMemoryContext = () => {
  const _data = new Map();
  const getBucketObj = params => {
    if (!_data.has(params.bucket)) {
      _data.set(params.bucket, new Map());
    }
    const bucket = _data.get(params.bucket);
    if (!bucket.has(params.key)) {
      bucket.set(params.key, {
        data: Buffer.from(''),
        meta: {},
        isSet: false
      });
    }
    return bucket.get(params.key);
  };
  return {
    getAttachmentBucket: () => Promise.resolve('__in-memory-attachments__'),
    getPrivateAssetBucket: () => Promise.resolve('__in-memory-private-assets'),
    getPublicAssetCacheBucket: () => Promise.resolve('__in-memory-public-assets__'),
    headObject: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      return {
        bucket: params.bucket,
        key: params.key,
        eTag: obj.isSet ? 'in-memory' : undefined
      };
    }),
    getObject: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      if (!obj.isSet) {
        throw new error_1.BbitError('Object not found', params);
      }
      return {
        bucket: params.bucket,
        key: params.key,
        body: obj.data
      };
    }),
    putObject: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      if (typeof params.body === 'string') {
        obj.data = Buffer.from(params.body);
      } else if (Buffer.isBuffer(params.body)) {
        obj.data = params.body;
      } else {
        obj.data = Buffer.from(params.body);
      }
      obj.isSet = true;
      return {
        bucket: params.bucket,
        key: params.key,
        eTag: 'in-memory'
      };
    }),
    downloadObjectFrom: params => __awaiter(void 0, void 0, void 0, function* () {
      throw new error_1.BbitError('Not implemented', params);
    }),
    uploadObjectTo: params => __awaiter(void 0, void 0, void 0, function* () {
      throw new error_1.BbitError('Not implemented', params);
    }),
    deleteObject: params => __awaiter(void 0, void 0, void 0, function* () {
      const obj = getBucketObj(params);
      obj.isSet = false;
      obj.data = undefined;
      return {
        bucket: params.bucket,
        key: params.key
      };
    }),
    getSignedUrl: (operation, params) => __awaiter(void 0, void 0, void 0, function* () {
      return `in-memory://${params.bucket}/${params.key}?operation=${operation}`;
    })
  };
};
exports.createS3InMemoryContext = createS3InMemoryContext;
