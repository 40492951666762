"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitJWKStore = void 0;
const rxjs_1 = require("rxjs");
const http_client_1 = require("../http/http-client");
const error_1 = require("../primitives/error");
const semaphore_1 = require("../primitives/semaphore");
const utils_1 = require("../utils/utils");
const interfaces_1 = require("./interfaces");
class BbitJWKStore {
  static ensureTrailingSlash(input) {
    return `${(input || '').replace(/\/$/i, '')}/`;
  }
  constructor(issuerClaim, selfIssuerPrivateKeyLoader = undefined, selfIssuerPrivateKeyGenerator = undefined) {
    this.selfIssuerPrivateKeyLoader = selfIssuerPrivateKeyLoader;
    this.selfIssuerPrivateKeyGenerator = selfIssuerPrivateKeyGenerator;
    this._hasInitLoad = false;
    this._keys = {};
    this._loadMutex = new semaphore_1.Semaphore({
      maxConcurrency: 1
    });
    this._issuerClient = new http_client_1.BbitHttpClient(issuerClaim);
  }
  loadDefaultSigningKey(params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!(((_a = this._defaultSigningKeyId) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
        yield this.importIssuerKeys();
      }
      if (!this._defaultSigningKeyId) {
        if (this.selfIssuerPrivateKeyGenerator) {
          this.importJWKS(yield this.selfIssuerPrivateKeyGenerator(params));
        }
        if (!this._defaultSigningKeyId) {
          throw new error_1.BbitError('no-private-key-available', {
            func: 'jwks.loadDefaultSigningKey',
            reason: 'there is not key with iat property available for signing'
          });
        }
      }
      return this.getByKeyId(this._defaultSigningKeyId);
    });
  }
  ensureInit() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this._hasInitLoad) {
        yield this.importIssuerKeys({
          ignoreErrors: true
        });
        this._hasInitLoad = true;
      }
    });
  }
  getDefaultSigningKeyId() {
    return this._defaultSigningKeyId;
  }
  getOpenIdConfig() {
    return this._openIdConfig;
  }
  fetchOpenIdConfig() {
    return __awaiter(this, void 0, void 0, function* () {
      const content = yield this._issuerClient.requestJson({
        method: 'GET',
        subUrl: '/.well-known/openid-configuration'
      });
      if (!content || content.issuer !== this.getIssuerClaim()) {
        throw new error_1.BbitError('invalid-openid-config-received', {
          issuerMatch: (content === null || content === void 0 ? void 0 : content.issuer) !== this.getIssuerClaim(),
          content
        });
      }
      this._openIdConfig = content;
      return content;
    });
  }
  isSelfIssuer() {
    return !!this.selfIssuerPrivateKeyLoader;
  }
  getIssuerClaim() {
    return `${this._issuerClient.getBaseUrl()}/`;
  }
  importIssuerKeys(params) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.isSelfIssuer()) {
        const jwks = yield this.selfIssuerPrivateKeyLoader(params);
        return this.importJWKS(jwks, params);
      }
      const fetchKeys = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        let content = undefined;
        try {
          content = yield (0, rxjs_1.lastValueFrom)(((_b = (_a = this._openIdConfig) === null || _a === void 0 ? void 0 : _a.jwks_uri) === null || _b === void 0 ? void 0 : _b.length) > 0 ? new http_client_1.BbitHttpClient(this._openIdConfig.jwks_uri).fetchJson() : this._issuerClient.fetchJson({
            method: 'GET',
            subUrl: '/.well-known/jwks.json'
          }));
        } catch (err) {
          console.error(err);
        }
        if (!content || !content.keys) {
          if (!this._openIdConfig) {
            yield this.fetchOpenIdConfig();
            return fetchKeys();
          }
          throw new error_1.BbitError('invalid-jwks-received', {
            jwksUrl: ((_d = (_c = this._openIdConfig) === null || _c === void 0 ? void 0 : _c.jwks_uri) === null || _d === void 0 ? void 0 : _d.length) > 0 ? this._openIdConfig.jwks_uri : `${this._issuerClient.getBaseUrl()}/.well-known/jwks.json`,
            content
          });
        }
        return this.importJWKS(content, params);
      });
      return this._loadMutex.runExclusive(() => fetchKeys());
    });
  }
  exportJWKS(withPrivate) {
    return {
      keys: Object.values(this._keys).map(key => Object.assign(Object.assign({}, key), withPrivate ? {} : {
        secret: undefined,
        k: undefined,
        d: undefined,
        p: undefined,
        q: undefined,
        dp: undefined,
        dq: undefined,
        qi: undefined
      }))
    };
  }
  importJWKS(keystore, params) {
    if (!keystore || !keystore.keys) {
      throw new error_1.BbitError('invalid-jwks', {
        keystore
      });
    }
    keystore.keys.forEach(jwk => {
      try {
        return this.add(jwk);
      } catch (err) {
        if (!(params === null || params === void 0 ? void 0 : params.ignoreErrors)) {
          throw err;
        }
        return undefined;
      }
    });
    const issuedAt = Object.values(this._keys).map(k => k.iat).filter(Boolean);
    if (issuedAt.length > 0) {
      const latestIssuedAt = issuedAt.sort().pop();
      this._defaultSigningKeyId = Object.values(this._keys).find(k => k.iat === latestIssuedAt).kid;
    }
    return this._defaultSigningKeyId;
  }
  add(key) {
    if (!key) {
      return;
    }
    const kid = key.kid || utils_1.BbitUtils.makeId();
    key.kid = kid;
    if (!key.key_ops || key.key_ops.length === 0) {
      key.key_ops = [interfaces_1.BbitJoseKeyOperation.VERIFY];
    }
    this._keys[kid] = key;
    return kid;
  }
  allKeyIDs() {
    return Object.keys(this._keys);
  }
  allKeys() {
    return Object.values(this._keys);
  }
  removeByKeyId(keyId) {
    delete this._keys[keyId];
  }
  getByKeyId(keyId) {
    return this._keys[keyId];
  }
  loadByKeyId(keyId_1) {
    return __awaiter(this, arguments, void 0, function* (keyId, tries = 0) {
      const key = this.getByKeyId(keyId);
      if (!key) {
        if (tries > 1) {
          throw new error_1.BbitError('max-retries-reached', {
            func: 'jwks.loadByKeyId',
            tries,
            keyId
          });
        }
        if (this._loadMutex.isLocked()) {
          yield this._loadMutex.awaitFree();
        } else {
          yield this.importIssuerKeys({
            ignoreErrors: true,
            kid: keyId
          });
        }
        return this.loadByKeyId(keyId, (tries || 0) + 1);
      }
      return key;
    });
  }
  signJWT(jwt, params) {
    return __awaiter(this, void 0, void 0, function* () {
      const key = params.keyId ? this.getByKeyId(params.keyId) : yield this.loadDefaultSigningKey({
        kid: params.keyId,
        ignoreErrors: false
      });
      if (!key || !key.kid) {
        throw new error_1.BbitError('no-key-available', {
          func: 'jwks.signJWT',
          reason: 'there is not key with iat property available for signing'
        });
      }
      const removeTrailingSlash = input => input.replace(/\/$/i, '');
      return jwt.sign(key, Object.assign(Object.assign({}, params), {
        issuer: removeTrailingSlash(this._issuerClient.getBaseUrl())
      }));
    });
  }
  verifyJWT(jwt, params) {
    if (!jwt) {
      throw new error_1.BbitError('jwtMissing', {
        jwt
      });
    }
    const key = this.getByKeyId(jwt.header.kid);
    if (!key) {
      throw new error_1.BbitError('jwtUnknownKeyId', {
        jwt
      });
    }
    const removeTrailingSlash = input => input.replace(/\/$/i, '');
    const expected = removeTrailingSlash(jwt.payload.iss);
    const got = removeTrailingSlash(this._issuerClient.getBaseUrl());
    if (expected !== got) {
      throw new error_1.BbitError('jwtIssuerMismatch', {
        expected,
        got
      });
    }
    return jwt.verify(key, params);
  }
}
exports.BbitJWKStore = BbitJWKStore;
