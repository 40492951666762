"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitObject = void 0;
const luxon_1 = require("luxon");
const rxjs_1 = require("rxjs");
const crypto_1 = require("../crypto/crypto");
const array_1 = require("./array");
const string_1 = require("./string");
class BbitObject {
  static isObjectLike(input) {
    if (input == null || typeof input !== 'object') {
      return false;
    }
    return true;
  }
  static isObject(input) {
    if (input == null || typeof input !== 'object' || Array.isArray(input)) {
      return false;
    }
    if (Object.getPrototypeOf(input) === null) {
      return true;
    }
    let proto = input;
    while (Object.getPrototypeOf(proto) !== null) {
      proto = Object.getPrototypeOf(proto);
    }
    return Object.getPrototypeOf(input) === proto;
  }
  static isEmpty(obj) {
    if (obj == null || obj === undefined) {
      return true;
    }
    return BbitObject.isObject(obj) ? !Object.keys(obj).some(key => {
      return obj[key] !== undefined;
    }) : array_1.BbitArray.isArray(obj) ? obj.length === 0 : false;
  }
  static merge(...objects) {
    const obj = objects.filter(o => o != null);
    if (obj.length === 0) {
      return null;
    }
    return obj.reduce((acc, o) => {
      for (const key of Object.keys(o)) {
        if (BbitObject.isObject(o[key])) {
          if (!acc[key]) {
            Object.assign(acc, {
              [key]: {}
            });
          }
          acc[key] = BbitObject.merge(acc[key], o[key]);
        } else {
          Object.assign(acc, {
            [key]: BbitObject.clone(o[key])
          });
        }
      }
      return acc;
    }, {});
  }
  static get(obj, path) {
    if (!BbitObject.isObject(obj) || path.length === 0) {
      return undefined;
    }
    return path.reduce((a, b) => a ? a[b] : null, obj);
  }
  static set(obj, path, value) {
    if (!BbitObject.isObject(obj) || path.length === 0) {
      return obj;
    }
    const rootCopy = Object.assign({}, obj);
    let current = rootCopy;
    for (let i = 0; i < path.length - 1; i++) {
      current[path[i]] = Object.assign({}, current[path[i]] || {});
      current = current[path[i]];
    }
    current[path[path.length - 1]] = value;
    return rootCopy;
  }
  static forOwn(obj, callback) {
    if (!BbitObject.isObject(obj)) {
      return;
    }
    for (const key of Object.keys(obj)) {
      callback(obj[key], key);
    }
  }
  static mapKeys(obj, callback) {
    if (!BbitObject.isObject(obj)) {
      return obj;
    }
    const result = {};
    for (const key of Object.keys(obj)) {
      result[callback(obj[key], key)] = obj[key];
    }
    return result;
  }
  static mapValues(obj, callback) {
    if (!BbitObject.isObject(obj)) {
      return obj;
    }
    const result = {};
    for (const key of Object.keys(obj)) {
      result[key] = callback(obj[key], key);
    }
    return result;
  }
  static mapValuesAsync(obj, callback, options) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!BbitObject.isObject(obj)) {
        return Promise.resolve(obj);
      }
      const result = yield (0, rxjs_1.lastValueFrom)((0, rxjs_1.of)(...Object.keys(obj)).pipe((0, rxjs_1.mergeMap)(key => __awaiter(this, void 0, void 0, function* () {
        return {
          key,
          val: yield callback(obj[key], key)
        };
      }), options === null || options === void 0 ? void 0 : options.concurrencyCount), (0, rxjs_1.reduce)((acc, item) => {
        acc[item.key] = item.val;
        return acc;
      }, {})));
      return result;
    });
  }
  static clone(obj) {
    if (array_1.BbitArray.isArray(obj)) {
      return [...obj];
    }
    if (!BbitObject.isObject(obj)) {
      return obj;
    }
    return Object.assign({}, obj);
  }
  static cloneDeep(obj, params) {
    if (!params) {
      params = {};
    }
    if (params.maxLevel && params.startLevel && params.startLevel > params.maxLevel) {
      return obj;
    }
    if (array_1.BbitArray.isArray(obj)) {
      return obj.map(item => BbitObject.cloneDeep(item, Object.assign(Object.assign({}, params), {
        startLevel: (params.startLevel || 0) + 1
      })));
    }
    if (!BbitObject.isObject(obj)) {
      return obj;
    }
    const result = {};
    for (const key of Object.keys(obj)) {
      result[key] = BbitObject.cloneDeep(obj[key], Object.assign(Object.assign({}, params), {
        startLevel: (params.startLevel || 0) + 1
      }));
    }
    return result;
  }
  static omitBy(input, keys) {
    if (!BbitObject.isObject(input)) {
      return input;
    }
    return Object.keys(input).reduce((acc, key) => {
      if (Array.isArray(keys) && !keys.includes(key) || typeof keys === 'function' && !keys(input[key], key)) {
        acc[key] = input[key];
      }
      return acc;
    }, {});
  }
  static pickBy(input, keys) {
    if (!BbitObject.isObject(input)) {
      return input;
    }
    return Object.keys(input).reduce((acc, key) => {
      if (Array.isArray(keys) && keys.includes(key) || typeof keys === 'function' && keys(input[key], key) || !keys && input[key] !== undefined && input[key] !== null) {
        acc[key] = input[key];
      }
      return acc;
    }, {});
  }
  static serialize(obj, params) {
    return __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (!params) {
        params = {};
      }
      if (!params.skipObjects) {
        params.skipObjects = new Set();
      }
      if (!params.currentDepth) {
        params.currentDepth = 0;
      }
      if (params.maxDepth && params.currentDepth > params.maxDepth) {
        return undefined;
      }
      if (obj === undefined) {
        return undefined;
      }
      if (obj === null) {
        return null;
      }
      if (typeof obj === 'string') {
        return obj;
      }
      if (array_1.BbitArray.isArray(obj)) {
        if (params.skipObjects.has(obj)) {
          return params.discardNonSerializableProperties ? undefined : '[circular array]';
        }
        params.skipObjects.add(obj);
        const arr = params.maxArrayLength ? obj.slice(0, params.maxArrayLength) : obj;
        params.currentDepth++;
        return yield Promise.all(arr.map(item => BbitObject.serialize(item, params)));
      }
      if (Number.isNaN(obj)) {
        return 'NaN';
      }
      if (typeof obj === 'boolean') {
        return obj;
      }
      if (typeof obj === 'number') {
        return obj;
      }
      if (typeof obj === 'bigint') {
        return obj.toString();
      }
      if (typeof obj === 'symbol') {
        return obj.toString();
      }
      if ((0, rxjs_1.isObservable)(obj)) {
        if (params.resolvePromisesWithTimeout) {
          const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error('Promise timeout'));
            }, params.resolvePromisesWithTimeout);
          });
          return BbitObject.serialize(yield Promise.race([(0, rxjs_1.lastValueFrom)(obj), timeoutPromise]), params);
        }
        return params.discardNonSerializableProperties ? undefined : '[rxjs-observable]';
      }
      if (obj instanceof Date) {
        return obj.toISOString();
      }
      if (luxon_1.DateTime.isDateTime(obj)) {
        return obj.toISO();
      }
      if (obj instanceof RegExp) {
        return obj.toString();
      }
      if (obj instanceof Error) {
        return BbitObject.serialize({
          name: obj.name,
          message: obj.message,
          stack: obj.stack,
          code: obj.code,
          cause: obj.cause
        }, params);
      }
      if (typeof Buffer === 'function' && Buffer.isBuffer(obj)) {
        return params.bufferEncoding ? obj.toString(params.bufferEncoding) : params.discardNonSerializableProperties ? undefined : '[object Buffer]';
      }
      if (obj instanceof Map) {
        return BbitObject.serialize(Array.from(obj.entries()), params);
      }
      if (obj instanceof Set) {
        return BbitObject.serialize(Array.from(obj), params);
      }
      if (obj instanceof Promise) {
        if (params.resolvePromisesWithTimeout) {
          const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              reject(new Error('Promise timeout'));
            }, params.resolvePromisesWithTimeout);
          });
          return BbitObject.serialize(yield Promise.race([obj, timeoutPromise]), params);
        }
        return params.discardNonSerializableProperties ? undefined : '[promise]';
      }
      if (BbitObject.isObject(obj)) {
        if (params.skipObjects.has(obj)) {
          return params.discardNonSerializableProperties ? undefined : '[!circular object]';
        }
        params.skipObjects.add(obj);
        params.currentDepth++;
        const newObj = {};
        const keys = params.sortObjectKeys ? Object.keys(obj).sort() : Object.keys(obj);
        yield Promise.all(keys.map(key => __awaiter(this, void 0, void 0, function* () {
          return newObj[key] = yield BbitObject.serialize(obj[key], params);
        })));
        return newObj;
      }
      if (typeof obj === 'function') {
        return params.discardNonSerializableProperties ? undefined : `[function ${(_a = obj.name) !== null && _a !== void 0 ? _a : 'anonymous'}]`;
      }
      return '[type ' + typeof obj + ']';
    });
  }
  static toUrlEncodedString(inputObject) {
    if (!inputObject) {
      return undefined;
    }
    return Object.entries(inputObject).map(([key, value]) => string_1.BbitString.isNotEmpty(key) && string_1.BbitString.isNotEmpty(value) ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : undefined).filter(Boolean).join('&');
  }
  static fromUrlEncodedString(inputString) {
    const result = {};
    if (string_1.BbitString.isEmpty(inputString)) {
      return result;
    }
    for (const item of inputString.split('&')) {
      const [key, value] = item.split('=');
      result[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return result;
  }
  static stringify(obj, params) {
    var _a;
    if (!params) {
      params = {};
    }
    if (!params.skipObjects) {
      params.skipObjects = new Set();
    }
    if (obj === undefined) {
      return '';
    }
    if (obj === null) {
      return 'null';
    }
    if (typeof obj === 'string') {
      return '"' + obj + '"';
    }
    if (array_1.BbitArray.isArray(obj)) {
      if (params.skipObjects.has(obj)) {
        return params.discardNonStringifiableProperties ? undefined : '"[!circular:array]"';
      }
      params.skipObjects.add(obj);
      const arr = params.maxArrayLength ? obj.slice(0, params.maxArrayLength) : obj;
      return '[' + arr.map(item => BbitObject.stringify(item, params)).join(',') + ']';
    }
    if (Number.isNaN(obj)) {
      return '"NaN"';
    }
    if (typeof obj === 'boolean') {
      return obj ? 'true' : 'false';
    }
    if (typeof obj === 'number') {
      return obj.toString();
    }
    if (typeof obj === 'function') {
      return params.includeFunctionNames ? `[Function: ${(_a = obj.name) !== null && _a !== void 0 ? _a : 'anonymous'}]` : '';
    }
    if (obj instanceof Date) {
      return obj.toISOString();
    }
    if (obj instanceof RegExp) {
      return obj.toString();
    }
    if (obj instanceof Error) {
      return BbitObject.stringify({
        name: obj.name,
        message: obj.message,
        stack: obj.stack,
        code: obj.code,
        cause: obj.cause
      }, params);
    }
    if (obj instanceof Map) {
      return BbitObject.stringify(Array.from(obj.entries()), params);
    }
    if (obj instanceof Set) {
      return BbitObject.stringify(Array.from(obj), params);
    }
    if (obj instanceof Promise) {
      return '"[Promise]"';
    }
    if (BbitObject.isObject(obj)) {
      const result = [];
      if (params.skipObjects.has(obj)) {
        return '"[!circular:object]"';
      }
      params.skipObjects.add(obj);
      for (const key of params.orderKeys ? Object.keys(obj).sort() : Object.keys(obj)) {
        if (obj[key] !== undefined) {
          result.push('"' + key + '"' + ':' + BbitObject.stringify(obj[key], params));
        }
      }
      return '{' + result.join(',') + '}';
    }
    return '"[!' + typeof obj + ']"';
  }
  static calculateObjectHash(input) {
    const calcObj = BbitObject.omitBy(input, ['_rev', '_revTree', '_written', '_expires', '_size']);
    const stringifiedObj = BbitObject.stringify(calcObj, {
      orderKeys: true
    });
    return crypto_1.BbitCrypto.singleton().md5AsHex(stringifiedObj);
  }
}
exports.BbitObject = BbitObject;
BbitObject.isEqual = (obj1, obj2, params) => {
  if (obj1 === obj2) {
    return true;
  }
  if (!params) {
    params = {};
  }
  if (!params.skipObjects) {
    params.skipObjects = new Set();
  }
  const areObjects = BbitObject.isObject(obj1) && BbitObject.isObject(obj2);
  const areArrays = array_1.BbitArray.isArray(obj1) && array_1.BbitArray.isArray(obj2);
  if (!areObjects && !areArrays) {
    return obj1 === obj2;
  }
  if (areArrays) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    if (params.skipObjects.has(obj1) && params.skipObjects.has(obj2)) {
      return true;
    }
    params.skipObjects.add(obj1);
    params.skipObjects.add(obj2);
    for (let i = 0; i < obj1.length; i++) {
      if (!BbitObject.isEqual(obj1[i], obj2[i], params)) {
        return false;
      }
    }
    return true;
  }
  if (areObjects) {
    const keys1 = (params === null || params === void 0 ? void 0 : params.detectMissingProperties) ? Object.keys(obj1) : Object.keys(obj1).filter(key => obj1[key] !== undefined);
    const keys2 = (params === null || params === void 0 ? void 0 : params.detectMissingProperties) ? Object.keys(obj2) : Object.keys(obj2).filter(key => obj2[key] !== undefined);
    if (keys1.length !== keys2.length) {
      return false;
    }
    if (params.skipObjects.has(obj1) && params.skipObjects.has(obj2)) {
      return true;
    }
    params.skipObjects.add(obj1);
    params.skipObjects.add(obj2);
    const notInFirst = keys2.filter(key => !keys1.includes(key));
    if (notInFirst.length > 0) {
      return false;
    }
    for (const key of keys1) {
      if (!BbitObject.isEqual(obj1[key], obj2[key], params)) {
        return false;
      }
    }
    return true;
  }
  return false;
};
