"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitBucketIndex = void 0;
const luxon_1 = require("luxon");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const semaphore_1 = require("../../primitives/semaphore");
const interfaces_1 = require("../utils/interfaces");
class BbitBucketIndex {
  constructor(_bucket, _index) {
    this._bucket = _bucket;
    this._index = _index;
    this._loadSemaphore = new semaphore_1.Semaphore({
      maxConcurrency: 1
    });
    this._docList = [];
  }
  setLatestServerRev(params) {
    this._lastRetrieve = undefined;
  }
  retrieveFromDatabase(options) {
    return this._loadSemaphore.runExclusive(() => __awaiter(this, void 0, void 0, function* () {
      var _a;
      if (this._lastRetrieve && Math.abs(this._lastRetrieve.diffNow().get('seconds')) < ((_a = options === null || options === void 0 ? void 0 : options.maxCacheSeconds) !== null && _a !== void 0 ? _a : 60)) {
        return this._docList;
      }
      const docList = yield (0, rxjs_1.lastValueFrom)((0, rxjs_1.of)({
        _bucket: this._bucket,
        _indexes: [this._index]
      }).pipe((0, operators_1.mergeMap)(src => options.bbaseStore.listDocs(Object.assign(Object.assign({}, src), {
        retrieveAllPages: true,
        format: interfaces_1.Bbase2DocListFormats.UNPACKED_DOC
      }))), (0, operators_1.mergeMap)(res => {
        var _a;
        if (!(((_a = res.docs) === null || _a === void 0 ? void 0 : _a.length) > 0)) {
          return Promise.resolve([]);
        }
        if (options.docImporter) {
          return Promise.all(res.docs.map(doc => options.docImporter(doc).then(() => ({
            _bucket: doc._bucket,
            _id: doc._id,
            _headRevHint: doc._rev
          }))));
        }
        return Promise.resolve(res.docs.map(doc => ({
          _bucket: doc._bucket,
          _id: doc._id,
          _headRevHint: doc._rev
        })));
      }), (0, operators_1.reduce)((acc, val) => acc.concat(val), [])));
      this._docList = docList;
      this._lastRetrieve = luxon_1.DateTime.local();
      return docList;
    }));
  }
}
exports.BbitBucketIndex = BbitBucketIndex;
