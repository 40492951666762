"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isChristmasDay = exports.isEasterDay = exports.isNewYearsDay = exports.getEasterMonthAndDay = void 0;
const luxon_1 = require("luxon");
const getEasterMonthAndDay = year => {
  const f = Math.floor;
  const G = year % 19;
  const C = f(year / 100);
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
  const L = I - J;
  const month = 3 + f((L + 40) / 44);
  const day = L + 28 - 31 * f(month / 4);
  return [month, day];
};
exports.getEasterMonthAndDay = getEasterMonthAndDay;
const isNewYearsDay = inst => {
  const matchesMonth = inst.month === 1;
  const matchesDay = inst.day === 1;
  return matchesMonth && matchesDay;
};
exports.isNewYearsDay = isNewYearsDay;
const isEasterDay = inst => {
  const instanceYear = inst.year;
  const [easterMonthForInstanceYear, easterDayForInstanceYear] = (0, exports.getEasterMonthAndDay)(instanceYear);
  const easterDay = luxon_1.DateTime.fromObject({
    year: instanceYear,
    month: easterMonthForInstanceYear,
    day: easterDayForInstanceYear
  });
  const matchesMonth = inst.month === easterDay.month;
  const matchesDay = inst.day === easterDay.day;
  return matchesMonth && matchesDay;
};
exports.isEasterDay = isEasterDay;
const isChristmasDay = inst => {
  const matchesMonth = inst.month === 12;
  const matchesDay = inst.day === 25;
  return matchesMonth && matchesDay;
};
exports.isChristmasDay = isChristmasDay;
