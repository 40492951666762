"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitNumber = void 0;
const array_1 = require("./array");
const string_1 = require("./string");
class BbitNumber {
  static isNumber(input) {
    return typeof input === 'number';
  }
  static parseInteger(input) {
    if (BbitNumber.isNumber(input)) {
      return input;
    }
    if (typeof input === 'string' && /^(\-|\+)?([0-9]+|Infinity|NaN)$/.test(input)) {
      return Number(input);
    }
    return Number.NaN;
  }
  static getDecimalSeparator(language) {
    if (!language) {
      language = '';
    }
    switch (language.substr(0, 2)) {
      case 'de':
      case 'fr':
        return [',', '.'];
      default:
        return ['.'];
    }
  }
  static parseNumber(input, options) {
    var _a;
    if (input && (Number.isFinite(input) || Number.isNaN(input))) {
      return input;
    }
    if (!input || !string_1.BbitString.isString(input) || input.length === 0) {
      return undefined;
    }
    const digitSystems = {
      b: {
        base: 2,
        digits: '01'
      },
      d: {
        base: 10,
        digits: '0-9'
      },
      o: {
        base: 8,
        digits: '0-7'
      },
      x: {
        base: 16,
        digits: '0-9a-f'
      }
    };
    const digitSystemMatch = /^\s*[-+]?0([xbdo])/.exec(input);
    let digitSystem;
    if (digitSystemMatch !== null) {
      digitSystem = digitSystems[digitSystemMatch[1]];
      input = input.trim().substr(2);
    } else {
      digitSystem = digitSystems.d;
      input = input.trim();
    }
    if (options === null || options === void 0 ? void 0 : options.language) {
      if (!options.decimalSeparators) {
        options.decimalSeparators = [];
      }
      options.decimalSeparators = options.decimalSeparators.concat(BbitNumber.getDecimalSeparator(options.language));
    }
    if (((_a = options === null || options === void 0 ? void 0 : options.decimalSeparators) === null || _a === void 0 ? void 0 : _a.length) > 0) {
      input = input.replace(new RegExp(`[${string_1.BbitString.escapeRegExp(array_1.BbitArray.unique(options.decimalSeparators).join(''))}]`, 'gi'), '.');
    }
    input = input.replace(new RegExp(/[^\+\-\.0-9a-z]/, 'gi'), '').toLowerCase();
    const parts = input.split('.');
    if (parts.length > 2) {
      return Number.NaN;
    }
    const numerals = parts[0];
    const limitNumerals = Math.pow(2, 53).toString(digitSystem.base);
    if (numerals.length > limitNumerals.length || numerals.length === limitNumerals.length && numerals > limitNumerals) {
      return null;
    }
    if (parts.length === 2 && digitSystem.base === 10) {
      return Number.parseFloat(input);
    }
    return Number.parseInt(parts[0], digitSystem.base);
  }
  static round(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(Number(num + 'e+' + scale)) + 'e-' + scale);
    }
    const arr = ('' + num).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    return +(Math.round(Number(+arr[0] + 'e' + sig + (+arr[1] + scale))) + 'e-' + scale);
  }
  static divideZeroSafe(value, divisor) {
    return value / (!divisor ? Number.EPSILON : divisor);
  }
  static format(number, minDecimals, maxDecimals, decPoint, thousandsSep) {
    const minDec = !Number.isFinite(+minDecimals) ? 0 : Math.abs(minDecimals);
    const n = !Number.isFinite(+number) ? 0 : +number;
    const percent = !Number.isFinite(+maxDecimals) ? 0 : Math.abs(maxDecimals);
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
    const toFixedFix = (n, innerPercentage) => {
      const k = Math.pow(10, innerPercentage);
      return Math.round(n * k) / k;
    };
    const s = (percent ? toFixedFix(n, percent) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < minDec) {
      s[1] = s[1] || '';
      s[1] += new Array(minDec - s[1].length + 1).join('0');
    }
    const res = s.join(dec);
    return res;
  }
}
exports.BbitNumber = BbitNumber;
