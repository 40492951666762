"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitRawHttpRequest = void 0;
const luxon_1 = require("luxon");
const utils_1 = require("../utils/utils");
const interfaces_1 = require("./interfaces");
class BbitRawHttpRequest {
  static parseJsonBody(body) {
    if (!body) {
      return;
    }
    try {
      return JSON.parse(body);
    } catch (e) {
      return body;
    }
  }
  constructor(input) {
    this.requestId = utils_1.BbitUtils.makeId();
    this.requestStart = luxon_1.DateTime.local();
    this.requestNumber = ++BbitRawHttpRequest._requestCount;
    this.isColdStart = this.requestNumber <= 1;
    this.rawPath = input.fullPath;
    this.hasMultiValueSupport = false;
    this.method = interfaces_1.BbitHttpMethods[input.method];
    let mapping;
    this.path = this.rawPath.replace(/^\/([^\/]+)/i, substr => {
      mapping = substr.replace(/^\//, '');
      return '';
    });
    if (!this.path.startsWith('/')) {
      this.path = '/' + this.path;
    }
    this.mapping = mapping;
    this.multiValueQuery = Object.keys(input.query || {}).reduce((qs, key) => Object.assign(qs, {
      [key]: input.query[key].split(',').map(v => decodeURIComponent(v))
    }), {});
    this.query = Object.keys(this.multiValueQuery).reduce((qs, key) => Object.assign(qs, {
      [key]: decodeURIComponent(this.multiValueQuery[key].slice(-1)[0])
    }), {});
    this.rawHeaders = input.rawHeaders || {};
    this.multiValueHeaders = Object.keys(this.rawHeaders).reduce((headers, key) => Object.assign(headers, {
      [key.toLowerCase()]: this.rawHeaders[key].split(',')
    }), {});
    this.headers = Object.keys(this.multiValueHeaders).reduce((qs, key) => Object.assign(qs, {
      [key]: this.multiValueHeaders[key].slice(-1)[0]
    }), {});
    this.userAgent = input.userAgent || 'test';
    this.cookies = input.cookies || {};
    this.auth = input.auth || {
      type: 'none',
      value: undefined
    };
    this.ip = '127.0.0.1';
    this.interface = 'raw';
    this.pathParameters = {};
    this.stageVariables = {};
    this.isBase64Encoded = false;
    this.clientType = 'desktop';
    this.clientCountry = 'CH';
    this.rawBody = input.body || null;
    this.body = BbitRawHttpRequest.parseJsonBody(input.body);
    this.cookies = this.headers.cookie ? this.headers.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split('=');
      return Object.assign(acc, {
        [key]: BbitRawHttpRequest.parseJsonBody(decodeURIComponent(value))
      });
    }, {}) : {};
  }
}
exports.BbitRawHttpRequest = BbitRawHttpRequest;
BbitRawHttpRequest._requestCount = 0;
