"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitArray = void 0;
class BbitArray {
  static isArray(input) {
    return Array.isArray(input);
  }
  static times(n, func) {
    if (n <= 0) return undefined;
    if (!func) {
      func = i => i;
    }
    return Array.from(Array(n), (_, i) => func(i));
  }
  static compact(input) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.filter(Boolean);
  }
  static chunkArray(input, n) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    if (n <= 0) return undefined;
    return Array.from(Array(Math.ceil(input.length / n)), (_, i) => input.slice(i * n, i * n + n));
  }
  static unique(input) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return Array.from(new Set(input));
  }
  static uniqueBy(input, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    const seen = new Set();
    return input.filter(item => {
      const k = keyFunc(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }
  static flattenDeep(input) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.reduce((a, b) => a.concat(Array.isArray(b) ? BbitArray.flattenDeep(b) : b), []);
  }
  static last(input) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input[input.length - 1];
  }
  static remove(input, value) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.filter(e => e !== value);
  }
  static removeBy(input, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.filter(e => !keyFunc(e));
  }
  static groupBy(input, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.reduce((acc, obj) => {
      const key = keyFunc(obj);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  static keyBy(input, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.reduce((acc, obj) => {
      const key = keyFunc(obj);
      acc[key] = obj;
      return acc;
    }, {});
  }
  static sortBy(input, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.map(e => ({
      e,
      k: keyFunc(e)
    })).sort((a, b) => a.k < b.k ? -1 : a.k > b.k ? 1 : 0).map(e => e.e);
  }
  static orderBy(input, keyFunc, orderBy) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return input.map(e => ({
      e,
      k: keyFunc(e)
    })).sort((a, b) => {
      for (let i = 0; i < orderBy.length; i++) {
        if (a.k[i] < b.k[i]) {
          return orderBy[i] === 'desc' ? 1 : -1;
        }
        if (a.k[i] > b.k[i]) {
          return orderBy[i] === 'desc' ? -1 : 1;
        }
      }
      return 0;
    }).map(e => e.e);
  }
  static maxBy(input, keyFunc, defaultIFEmpty) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    if (input.length === 0) {
      return defaultIFEmpty;
    }
    return input.map(e => ({
      e,
      k: keyFunc(e)
    })).reduce((a, b) => a.k > b.k ? a : b).e;
  }
  static minBy(input, keyFunc, defaultIFEmpty) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    if (input.length === 0) {
      return defaultIFEmpty;
    }
    return input.map(e => ({
      e,
      k: keyFunc(e)
    })).reduce((a, b) => a.k < b.k ? a : b).e;
  }
  static differenceBy(input, input2, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    const seen = new Set();
    input2.forEach(e => seen.add(keyFunc(e)));
    return input.filter(e => !seen.has(keyFunc(e)));
  }
  static symmetricDifferenceBy(input, input2, keyFunc) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    return [].concat(BbitArray.differenceBy(input, input2, keyFunc), BbitArray.differenceBy(input2, input, keyFunc));
  }
  static topologicalUniqueSortBy(input, keyFunc, options) {
    input = input || [];
    if (!Array.isArray(input)) {
      return undefined;
    }
    const nodes = new Map();
    input.forEach(e => {
      const {
        uniqueNodeId,
        dependsOnNodeIds
      } = keyFunc(e);
      if (uniqueNodeId && !nodes.has(uniqueNodeId)) {
        nodes.set(uniqueNodeId, {
          dependsOn: dependsOnNodeIds || [],
          input: e
        });
      } else if (options === null || options === void 0 ? void 0 : options.throwOnNonUniqueNodeId) {
        throw new Error(`topologicalUniqueSortBy: Non unique key: ${uniqueNodeId}`);
      }
    });
    const sorted = [];
    const visited = new Set();
    function visit(nodeId, dependsOn) {
      if (visited.has(nodeId)) {
        return;
      }
      visited.add(nodeId);
      for (const dependency of dependsOn) {
        if (nodes.has(dependency)) {
          visit(dependency, nodes.get(dependency).dependsOn);
        }
      }
      sorted.push(nodeId);
    }
    for (const [nodeId, node] of nodes) {
      visit(nodeId, node.dependsOn);
    }
    return sorted.map(nodeId => nodes.get(nodeId).input);
  }
}
exports.BbitArray = BbitArray;
BbitArray.makeLengthEven = (array, options = {
  n: 2,
  atEnd: false
}) => {
  if (!array) {
    return [];
  }
  const n = (options === null || options === void 0 ? void 0 : options.n) || 2;
  const leftOvers = array.length % n;
  if (leftOvers === 0) {
    return array;
  }
  return (options === null || options === void 0 ? void 0 : options.atEnd) ? array.concat(Array(n - leftOvers).fill(undefined)) : Array(n - leftOvers).fill(undefined).concat(array);
};
BbitArray.shuffle = array => {
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }
  const a = [...array];
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
BbitArray.randomPick = array => {
  if (!Array.isArray(array) || array.length === 0) {
    return undefined;
  }
  return array[Math.floor(Math.random() * array.length)];
};
BbitArray.cartesianProduct = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b) || a.length === 0 || b.length === 0) {
    return [];
  }
  return a.reduce((acc, val) => acc.concat(b.map(d => [val, d])), []);
};
