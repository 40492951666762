(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() : typeof define === 'function' && define.amd ? define(factory) : (global = global || self, global['fast-stringify'] = factory());
})(this, function () {
  'use strict';

  /**
   * @function getReferenceKey
   *
   * @description
   * get the reference key for the circular value
   *
   * @param keys the keys to build the reference key from
   * @param cutoff the maximum number of keys to include
   * @returns the reference key
   */
  function getReferenceKey(keys, cutoff) {
    return keys.slice(0, cutoff).join('.') || '.';
  }
  /**
   * @function getCutoff
   *
   * @description
   * faster `Array.prototype.indexOf` implementation build for slicing / splicing
   *
   * @param array the array to match the value in
   * @param value the value to match
   * @returns the matching index, or -1
   */

  function getCutoff(array, value) {
    var length = array.length;
    for (var index = 0; index < length; ++index) {
      if (array[index] === value) {
        return index + 1;
      }
    }
    return 0;
  }

  /**
   * @function createReplacer
   *
   * @description
   * create a replacer method that handles circular values
   *
   * @param [replacer] a custom replacer to use for non-circular values
   * @param [circularReplacer] a custom replacer to use for circular methods
   * @returns the value to stringify
   */
  function createReplacer(replacer, circularReplacer) {
    var hasReplacer = typeof replacer === 'function';
    var hasCircularReplacer = typeof circularReplacer === 'function';
    var cache = [];
    var keys = [];
    return function replace(key, value) {
      if (typeof value === 'object') {
        if (cache.length) {
          var thisCutoff = getCutoff(cache, this);
          if (thisCutoff === 0) {
            cache[cache.length] = this;
          } else {
            cache.splice(thisCutoff);
            keys.splice(thisCutoff);
          }
          keys[keys.length] = key;
          var valueCutoff = getCutoff(cache, value);
          if (valueCutoff !== 0) {
            return hasCircularReplacer ? circularReplacer.call(this, key, value, getReferenceKey(keys, valueCutoff)) : "[ref=" + getReferenceKey(keys, valueCutoff) + "]";
          }
        } else {
          cache[0] = value;
          keys[0] = key;
        }
      }
      return hasReplacer ? replacer.call(this, key, value) : value;
    };
  }
  /**
   * @function stringify
   *
   * @description
   * strinigifer that handles circular values
   *
   * @param the value to stringify
   * @param [replacer] a custom replacer function for handling standard values
   * @param [indent] the number of spaces to indent the output by
   * @param [circularReplacer] a custom replacer function for handling circular values
   * @returns the stringified output
   */

  function stringify(value, replacer, indent, circularReplacer) {
    return JSON.stringify(value, createReplacer(replacer, circularReplacer), indent);
  }
  return stringify;
});
