"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitLogTransportHandler = void 0;
const console_1 = require("./console");
class BbitLogTransportHandler {
  static addTransport(transport) {
    BbitLogTransportHandler._transports.push(transport);
  }
  static getTransports() {
    return BbitLogTransportHandler._transports;
  }
  static getTransport(key) {
    return BbitLogTransportHandler._transports.find(o => {
      return o.getKey() === key;
    });
  }
  static getConsoleTransport() {
    return BbitLogTransportHandler.getTransport('console');
  }
  static clearTransports() {
    BbitLogTransportHandler._transports = [];
  }
}
exports.BbitLogTransportHandler = BbitLogTransportHandler;
BbitLogTransportHandler._transports = [new console_1.BbitLogTransportConsole()];
