"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitInvalidParamError = void 0;
const error_1 = require("./error");
class BbitInvalidParamError extends error_1.BbitError {
  constructor(params) {
    super('invalid-param', params);
    const actualProto = new.target.prototype;
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      this.__proto__ = actualProto;
    }
  }
}
exports.BbitInvalidParamError = BbitInvalidParamError;
