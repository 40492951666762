"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitCodeWithChecksum = void 0;
class BbitCodeWithChecksum {
  static calculateChecksum(gtin) {
    return BbitCodeWithChecksum.calculateAdvancedChecksum(gtin, {
      weightingPattern: '31',
      modulo: 10,
      stripLastChar: true
    });
  }
  static calculateUPCChecksum(upc) {
    return BbitCodeWithChecksum.calculateAdvancedChecksum(upc, {
      weightingPattern: '13',
      modulo: 10,
      stripLastChar: true
    });
  }
  static calculateAdvancedChecksum(inputCode, options) {
    const toInt = numString => {
      return Number.parseInt(numString, 10);
    };
    const getFactorFromPattern = num => {
      return Number.parseInt(options.weightingPattern.charAt(num % options.weightingPattern.length), 10);
    };
    inputCode = Number.parseInt(inputCode, 10).toString();
    const chunks = inputCode.split('').map(toInt).reverse();
    let checksum = 0;
    if (options.stripLastChar) {
      chunks.shift();
    }
    chunks.forEach((n, index) => {
      checksum += getFactorFromPattern(index) * n;
    });
    if (options.modulo) {
      checksum %= options.modulo;
      checksum = checksum === 0 ? 0 : options.modulo - checksum;
    }
    return checksum;
  }
  static validate(input) {
    input = input ? input.replace(/[^\/\d]/g, '') : '';
    if (/^\d{8,14}$/.exec(Number.parseInt(input).toString()) === null) {
      return false;
    }
    const checksum = Number.parseInt(input.substring(input.length - 1), 10);
    const calcChecksum = BbitCodeWithChecksum.calculateChecksum(input);
    return checksum === calcChecksum;
  }
  static formatOASI(input) {
    input = input ? input.replace(/[^\/\d]/g, '') : '';
    return [input.length > 0 ? input.substr(0, 3) : '', input.length > 3 ? input.substr(3, 4) : '', input.length > 7 ? input.substr(7, 4) : '', input.length > 11 ? input.substr(11, 3) : ''].filter(e => e && e.length > 0).join('.');
  }
}
exports.BbitCodeWithChecksum = BbitCodeWithChecksum;
