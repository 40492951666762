"use strict";

var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitDateTime = void 0;
const luxon_1 = require("luxon");
const date_time_helper_1 = require("./date-time.helper");
const error_1 = require("./error");
const error_invalid_param_1 = require("./error-invalid-param");
const result_1 = require("./result");
const string_1 = require("./string");
const time_1 = require("./time");
class BbitDateTime {
  constructor(dateTime) {
    this.dateTime = dateTime;
  }
  static getCurrentUnixTimestamp() {
    return Date.now ? Date.now() : new Date().getTime();
  }
  static isJsDate(obj) {
    return obj instanceof Date && !Number.isNaN(obj.getTime());
  }
  static getNowInMs() {
    let result;
    if (typeof performance !== 'undefined' && performance && performance.now) {
      return performance.now();
    }
    if (typeof process !== 'undefined' && (process === null || process === void 0 ? void 0 : process.hrtime)) {
      const {
        hrtime
      } = process;
      const getNanoSeconds = () => {
        const hr = hrtime();
        return hr[0] * 1e9 + hr[1];
      };
      if (!_a.loadTime) {
        const moduleLoadTime = getNanoSeconds();
        const upTime = process.uptime() * 1e9;
        _a.loadTime = moduleLoadTime - upTime;
      }
      result = (getNanoSeconds() - _a.loadTime) / 1e6;
    } else if (Date.now) {
      if (!_a.loadTime) {
        _a.loadTime = Date.now();
      }
      result = Date.now() - _a.loadTime;
      _a.loadTime = Date.now();
    } else {
      if (!_a.loadTime) {
        _a.loadTime = new Date().getTime();
      }
      result = new Date().getTime() - _a.loadTime;
      _a.loadTime = new Date().getTime();
    }
    return result;
  }
  static roundDateToResolution(date, resolutionInSeconds, up, deltaSeconds) {
    let luxonDate = date;
    if (!(resolutionInSeconds > 0)) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'resolutionInSeconds',
        value: resolutionInSeconds,
        reason: 'must be greater than 0'
      });
    }
    if (deltaSeconds) {
      luxonDate = luxonDate.plus({
        seconds: deltaSeconds
      });
    }
    luxonDate = luxonDate.minus({
      seconds: luxonDate.toSeconds() % resolutionInSeconds
    });
    if (up) {
      luxonDate = luxonDate.plus({
        seconds: resolutionInSeconds
      });
    }
    return luxonDate;
  }
  static getDDHHMMSS(luxonDate) {
    return luxonDate.toUTC().toFormat('ddHHmmss');
  }
  static getYYYYMM(luxonDate) {
    return luxonDate.toUTC().toFormat('yyyyMM');
  }
  static parse(parseInput) {
    var _b;
    if (result_1.BbitResult.isResult(parseInput)) {
      if (result_1.BbitResult.isError(parseInput)) {
        return luxon_1.DateTime.invalid(((_b = result_1.BbitResult.extractError(parseInput)) === null || _b === void 0 ? void 0 : _b.toString()) || 'no-parse-input');
      }
      parseInput = parseInput.data;
    }
    switch (true) {
      case parseInput === null || parseInput === undefined:
        return parseInput;
      case luxon_1.DateTime.isDateTime(parseInput):
        return parseInput;
      case string_1.BbitString.isString(parseInput):
        return luxon_1.DateTime.fromISO(parseInput);
      case _a.isJsDate(parseInput):
        return luxon_1.DateTime.fromJSDate(parseInput);
      case Number.isFinite(parseInput):
        return luxon_1.DateTime.fromMillis(parseInput);
      default:
        return luxon_1.DateTime.invalid('unknown input', string_1.BbitString.stringifyToJson(parseInput));
    }
  }
  static setDateTimeParser(parser) {
    if (!parser) {
      parser = {
        parseDate: (format, input) => {
          const parsed = Date.parse(input);
          if (!parsed || Number.isNaN(parsed)) {
            return result_1.BbitResult.createError(new error_1.BbitError('invalid-date', {
              input
            }));
          }
          return result_1.BbitResult.createSuccess(luxon_1.DateTime.fromMillis(parsed));
        },
        parseDateTimeString: params => {
          let input = params.input;
          if (params.format === 'time') {
            input = `01.01.1970, ${params.input}`;
          }
          const parsed = Date.parse(input);
          if (!parsed || Number.isNaN(parsed)) {
            return result_1.BbitResult.createError(new error_1.BbitError('invalid-date', params));
          }
          return result_1.BbitResult.createSuccess(luxon_1.DateTime.fromMillis(parsed));
        }
      };
    }
    _a.datetimeParser = parser;
  }
  static parseDateTimeString(params) {
    var _b;
    if (!_a.datetimeParser) {
      _a.setDateTimeParser(undefined);
    }
    if (!(((_b = params === null || params === void 0 ? void 0 : params.input) === null || _b === void 0 ? void 0 : _b.length) > 0)) {
      return result_1.BbitResult.createSuccess(undefined);
    }
    return _a.datetimeParser.parseDateTimeString(params);
  }
  static parseHumanString(format, input, languages) {
    if (!_a.datetimeParser) {
      _a.setDateTimeParser(undefined);
    }
    if (!((input === null || input === void 0 ? void 0 : input.length) > 0)) {
      return result_1.BbitResult.createSuccess(undefined);
    }
    if (!languages || languages.length === 0) {
      languages = ['en', 'de'];
    }
    let res = result_1.BbitResult.createError('no-language');
    for (const language of languages) {
      if (format === 'time') {
        input = `01.01.1970, ${input}`;
      }
      res = _a.datetimeParser.parseDate(format, input, language);
      if (result_1.BbitResult.isOk(res)) {
        return res;
      }
    }
    return res;
  }
  static get(parseInput) {
    const date = _a.parse(parseInput);
    if (date && !date.isValid) {
      return result_1.BbitResult.createError(date.invalidExplanation);
    }
    return result_1.BbitResult.createSuccess(date);
  }
  static isOffOrBlockTime({
    from,
    offTimes,
    blockTimes,
    treatTimeSlotAsWholeDay,
    allowOffTime
  }) {
    const expandIntervalToWholeDay = interval => luxon_1.Interval.fromDateTimes(interval.start.startOf('day'), interval.end);
    if (treatTimeSlotAsWholeDay) {
      from = from.startOf('day');
    }
    return !allowOffTime && offTimes.some(offTime => (treatTimeSlotAsWholeDay ? expandIntervalToWholeDay(offTime) : offTime).contains(from)) || blockTimes.some(blockTime => (treatTimeSlotAsWholeDay ? expandIntervalToWholeDay(blockTime) : blockTime).contains(from)) || false;
  }
  static isSameDay(a, b) {
    return a.year === b.year && a.month === b.month && a.day === b.day;
  }
  static getAvailableRestOfDayFrom({
    from,
    offTimes,
    blockTimes,
    allowOffTime,
    weeklyShifts,
    isNegative
  }) {
    if (!(from === null || from === void 0 ? void 0 : from.isValid)) {
      throw new error_invalid_param_1.BbitInvalidParamError({
        param: 'from',
        value: from,
        reason: 'must be a defined and valid DateTime'
      });
    }
    for (const blockTime of blockTimes || []) {
      if (!(blockTime === null || blockTime === void 0 ? void 0 : blockTime.isValid)) {
        throw new error_1.BbitError('invalid-block-time', {
          blockTime,
          invalidReason: blockTime === null || blockTime === void 0 ? void 0 : blockTime.invalidExplanation
        });
      }
    }
    if (allowOffTime) {
      for (const offTime of offTimes || []) {
        if (!(offTime === null || offTime === void 0 ? void 0 : offTime.isValid)) {
          throw new error_1.BbitError('invalid-off-time', {
            offTime,
            invalidReason: offTime === null || offTime === void 0 ? void 0 : offTime.invalidExplanation
          });
        }
      }
    }
    const presenceIntervals = luxon_1.Interval.merge(allowOffTime ? [isNegative ? luxon_1.Interval.fromDateTimes(from.startOf('day'), from) : luxon_1.Interval.fromDateTimes(from, from.endOf('day'))] : _a.getBusinessShifts({
      from,
      weeklyShifts
    }));
    const excludeRestOfDay = isNegative ? luxon_1.Interval.fromDateTimes(from, from.endOf('day')) : luxon_1.Interval.fromDateTimes(from.startOf('day'), from);
    const xorIntervals = presenceIntervals.concat([excludeRestOfDay], [excludeRestOfDay], blockTimes || [], blockTimes || [], allowOffTime ? [] : offTimes, allowOffTime ? [] : offTimes).filter(Boolean);
    const r = luxon_1.Interval.xor(xorIntervals);
    return isNegative ? r.reverse() : r;
  }
  static splitDurationInDaysAndTimes(duration) {
    const obj = duration.toObject();
    return {
      days: luxon_1.Duration.fromObject({
        years: obj.years,
        months: obj.months,
        weeks: obj.weeks,
        days: obj.days
      }),
      times: luxon_1.Duration.fromObject({
        hours: obj.hours,
        minutes: obj.minutes,
        seconds: obj.seconds,
        milliseconds: obj.milliseconds
      })
    };
  }
  static minusBusinessDuration({
    from,
    workDuration,
    blockTimes,
    allowOffTime,
    weeklyShifts,
    offTimes,
    maximalDayCount
  }) {
    return _a.plusBusinessDuration({
      from,
      workDuration: workDuration.negate(),
      blockTimes,
      allowOffTime,
      weeklyShifts,
      offTimes,
      maximalDayCount
    });
  }
  static totalIntervalDuration(intervals) {
    return intervals.reduce((acc, curr) => acc.plus(curr.toDuration()), luxon_1.Duration.fromMillis(0));
  }
  static isHoliday(date, cfg) {
    const holidays = (cfg === null || cfg === void 0 ? void 0 : cfg.holidays) || [];
    const matchers = [theDate => holidays.some(h => h.interval.contains(theDate))].concat((cfg === null || cfg === void 0 ? void 0 : cfg.holidayMatchers) || [date_time_helper_1.isEasterDay, date_time_helper_1.isChristmasDay, date_time_helper_1.isNewYearsDay]);
    const isDayAnyHoliday = matchers.some(holidayMatcher => {
      return holidayMatcher(date);
    });
    return isDayAnyHoliday;
  }
}
exports.BbitDateTime = BbitDateTime;
_a = BbitDateTime;
BbitDateTime.loadTime = 0;
BbitDateTime.getWeekdayName = day => {
  switch (day === null || day === void 0 ? void 0 : day.weekday) {
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    case 7:
      return 'sunday';
    default:
      return undefined;
  }
};
BbitDateTime.getBusinessShifts = ({
  from,
  weeklyShifts
}) => {
  const weekdayName = _a.getWeekdayName(from);
  return ((weeklyShifts === null || weeklyShifts === void 0 ? void 0 : weeklyShifts[weekdayName]) || [{
    from: 'T00:00:00.000',
    until: 'T23:59:59.999'
  }]).map(shift => luxon_1.Interval.fromDateTimes(time_1.BbitTime.fromISOTime(shift.from).setToDateTime(from), time_1.BbitTime.fromISOTime(shift.until).setToDateTime(from)));
};
BbitDateTime.hasBusinessShifts = ({
  from,
  weeklyShifts,
  allowOffTime
}) => {
  if (allowOffTime) {
    return true;
  }
  const weekdayName = _a.getWeekdayName(from);
  return ((weeklyShifts === null || weeklyShifts === void 0 ? void 0 : weeklyShifts[weekdayName]) || [{
    from: 'T00:00:00.000',
    until: 'T23:59:59.999'
  }]).length > 0;
};
BbitDateTime.plusBusinessDuration = ({
  from,
  workDuration,
  blockTimes,
  allowOffTime,
  weeklyShifts,
  offTimes,
  maximalDayCount,
  treatTimeSlotAsWholeDay
}) => {
  const workingIntervals = [];
  let calcDay = from;
  if (!maximalDayCount || maximalDayCount < 0) {
    maximalDayCount = 1200;
  }
  const splittedDuration = _a.splitDurationInDaysAndTimes(workDuration);
  let remainingDurationDays = splittedDuration.days.shiftTo('days').as('days') || 0;
  if (remainingDurationDays !== 0) {
    const isDaysNegative = remainingDurationDays < 0;
    remainingDurationDays = Math.round(Math.abs(remainingDurationDays));
    while (remainingDurationDays > 0) {
      const oneDayByDirection = isDaysNegative ? -1 : 1;
      maximalDayCount--;
      calcDay = calcDay.plus({
        days: oneDayByDirection
      });
      if (_a.hasBusinessShifts({
        from: calcDay,
        weeklyShifts,
        allowOffTime
      }) && !_a.isOffOrBlockTime({
        from: calcDay,
        offTimes,
        blockTimes,
        treatTimeSlotAsWholeDay: treatTimeSlotAsWholeDay !== null && treatTimeSlotAsWholeDay !== void 0 ? treatTimeSlotAsWholeDay : true,
        allowOffTime
      })) {
        remainingDurationDays--;
      }
    }
  }
  let endDate = calcDay;
  let remainingDurationMS = splittedDuration.times.shiftTo('milliseconds').as('milliseconds') || 0;
  if (remainingDurationMS === 0) {
    return {
      workingIntervals,
      endDate
    };
  }
  const isTimeNegative = remainingDurationMS < 0;
  remainingDurationMS = Math.abs(remainingDurationMS);
  while (remainingDurationMS > 0 && maximalDayCount > 0 && calcDay) {
    const available = _a.getAvailableRestOfDayFrom({
      from: calcDay,
      offTimes,
      blockTimes,
      weeklyShifts,
      allowOffTime,
      isNegative: isTimeNegative
    });
    for (let i = 0; i < available.length && remainingDurationMS > 0; i++) {
      const availableInterval = available[i];
      const availableDurationMS = availableInterval.toDuration().shiftTo('milliseconds').as('milliseconds');
      if (availableDurationMS >= remainingDurationMS) {
        if (isTimeNegative) {
          endDate = availableInterval.end.minus({
            milliseconds: remainingDurationMS
          });
          workingIntervals.push(luxon_1.Interval.fromDateTimes(endDate, availableInterval.end));
        } else {
          endDate = availableInterval.start.plus({
            milliseconds: remainingDurationMS
          });
          workingIntervals.push(luxon_1.Interval.fromDateTimes(availableInterval.start, endDate));
        }
        remainingDurationMS = 0;
        return {
          workingIntervals,
          endDate
        };
      }
      remainingDurationMS -= availableDurationMS;
      workingIntervals.push(availableInterval);
    }
    maximalDayCount--;
    calcDay = isTimeNegative ? calcDay.minus({
      days: 1
    }).endOf('day') : calcDay.plus({
      days: 1
    }).startOf('day');
  }
  throw new error_1.BbitError('plusBusinessDuration: reached max day count limit', {
    maximalDayCount,
    from: from.toISO(),
    workDuration: workDuration.toISO()
  });
};
BbitDateTime.diffBusinessDuration = ({
  from,
  until,
  blockTimes,
  allowOffTime,
  weeklyShifts,
  offTimes
}) => {
  let workingIntervals = [];
  const isBackwards = +from > +until;
  const start = isBackwards ? until : from;
  const end = isBackwards ? from : until;
  let fullBusinessDayCount = 0;
  let calcDay = start;
  while (calcDay < end) {
    const isCalcDaySameAsEndDay = _a.isSameDay(calcDay, end);
    const additionalBlocks = isCalcDaySameAsEndDay && +end < +end.endOf('day') ? [luxon_1.Interval.fromDateTimes(end, end.endOf('day'))] : [];
    const workSlots = _a.getAvailableRestOfDayFrom({
      from: calcDay,
      offTimes,
      blockTimes: blockTimes.concat(additionalBlocks),
      weeklyShifts,
      allowOffTime,
      isNegative: false
    });
    if (workSlots.length > 0) {
      fullBusinessDayCount += isCalcDaySameAsEndDay ? 0 : 1;
      workingIntervals = workingIntervals.concat(workSlots);
    }
    calcDay = calcDay.endOf('day').plus({
      milliseconds: 1
    });
  }
  if (isBackwards) {
    workingIntervals = workingIntervals.reverse();
    fullBusinessDayCount *= -1;
  }
  return {
    isBackwards,
    fullBusinessDayCount,
    workingIntervals
  };
};
