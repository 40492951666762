"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BbitUnitMapping = void 0;
const unit_1 = require("./unit");
class BbitUnitMapping {
  constructor(options) {
    this._graph = ((options === null || options === void 0 ? void 0 : options.unitMappings) || []).reduce((graph, mapping) => {
      const sourceUnit = unit_1.BbitUnit.from(mapping.source);
      const targetUnit = unit_1.BbitUnit.from(mapping.target);
      const innerSourceDef = sourceUnit.unitDef;
      const innerTargetDef = targetUnit.unitDef;
      if (!graph[innerSourceDef.unit._id]) {
        graph[innerSourceDef.unit._id] = {};
      }
      graph[innerSourceDef.unit._id][innerTargetDef.unit._id] = {
        sourceAmount: sourceUnit.amount * innerSourceDef.coefficient.value,
        targetAmount: targetUnit.amount * innerTargetDef.coefficient.value,
        unit: targetUnit.unit
      };
      if (!graph[innerTargetDef.unit._id]) {
        graph[innerTargetDef.unit._id] = {};
      }
      graph[innerTargetDef.unit._id][innerSourceDef.unit._id] = {
        sourceAmount: targetUnit.amount * innerTargetDef.coefficient.value,
        targetAmount: sourceUnit.amount * innerSourceDef.coefficient.value,
        unit: sourceUnit.unit
      };
      return graph;
    }, {});
  }
  recurseGraph(currentPath, targetUnit) {
    var _a;
    const sourceUnit = currentPath[currentPath.length - 1];
    if (sourceUnit === targetUnit) {
      return undefined;
    }
    if (currentPath.includes(targetUnit)) {
      return undefined;
    }
    if ((_a = this._graph[sourceUnit]) === null || _a === void 0 ? void 0 : _a[targetUnit]) {
      return currentPath.concat(targetUnit);
    }
    if (this._graph[sourceUnit]) {
      for (const newSourceUnit in this._graph[sourceUnit]) {
        if (currentPath.includes(newSourceUnit)) {
          continue;
        }
        const path = this.recurseGraph(currentPath.concat(newSourceUnit), targetUnit);
        if (path) {
          return path;
        }
      }
    }
  }
  mapAmount(sourceDef, targetDef, amount) {
    const foundPath = this.recurseGraph([sourceDef.unit._id], targetDef.unit._id);
    if (foundPath) {
      const factors = [];
      factors.push(sourceDef.coefficient.value || 1);
      for (let i = 0; i < foundPath.length - 1; i++) {
        const sourceUnit = foundPath[i];
        const targetUnit = foundPath[i + 1];
        const factor = this._graph[sourceUnit][targetUnit];
        factors.push(1 / factor.sourceAmount);
        factors.push(factor.targetAmount);
      }
      factors.push(1 / (targetDef.coefficient.value || 1));
      return factors.reduce((a, f) => a * f, amount);
    }
    return undefined;
  }
}
exports.BbitUnitMapping = BbitUnitMapping;
