"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IpcClient = void 0;
class IpcClient {
  static setHandler(handler) {
    IpcClient._handler = handler;
    if (typeof window !== 'undefined') {
      window.bbitIpcHandler = handler;
    }
  }
  static invoke(channel, params) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!IpcClient._handler) {
        if (typeof window !== 'undefined') {
          if (window.bbitIpcHandler) {
            IpcClient._handler = window.bbitIpcHandler;
          }
        }
        if (!IpcClient._handler) {
          throw new Error('bbit IPC handler is not set!');
        }
      }
      return yield IpcClient._handler.invoke(channel, params);
    });
  }
  static on(channel) {
    return IpcClient._handler.on(channel);
  }
}
exports.IpcClient = IpcClient;
