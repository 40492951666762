"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function () {
  var ownKeys = function (o) {
    ownKeys = Object.getOwnPropertyNames || function (o) {
      var ar = [];
      for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
      return ar;
    };
    return ownKeys(o);
  };
  return function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
    __setModuleDefault(result, mod);
    return result;
  };
}();
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Bbase2Utils = void 0;
const luxon_1 = require("luxon");
const RxJS = __importStar(require("rxjs"));
const operators_1 = require("rxjs/operators");
const crypto_1 = require("../../crypto/crypto");
const log_1 = require("../../log/log");
const array_1 = require("../../primitives/array");
const date_time_1 = require("../../primitives/date-time");
const error_1 = require("../../primitives/error");
const error_invalid_param_1 = require("../../primitives/error-invalid-param");
const number_1 = require("../../primitives/number");
const object_1 = require("../../primitives/object");
const result_1 = require("../../primitives/result");
const string_1 = require("../../primitives/string");
const utils_1 = require("../../utils/utils");
const cost_bookings_1 = require("./cost-bookings");
const financial_bookings_1 = require("./financial-bookings");
const interfaces_1 = require("./interfaces");
const stock_bookings_1 = require("./stock-bookings");
const work_time_bookings_1 = require("./work-time-bookings");
class Bbase2Utils {
  static generateBbaseId() {
    return utils_1.BbitUtils.makeId({
      leadingHashtag: true,
      length: 21,
      noLookAlikes: true,
      onlyLowerChars: false
    });
  }
  static calcDynamoDbReadSize(v) {
    return Math.ceil(Bbase2Utils.calcDynamoDbRawJsonSize(v) / 1024 / 4);
  }
  static calcDynamoDbWriteSize(v) {
    return Math.ceil(Bbase2Utils.calcDynamoDbRawJsonSize(v) / 1024);
  }
  static calcDynamoDbStorageSize(v) {
    return Bbase2Utils.calcDynamoDbRawJsonSize(v) + 100;
  }
  static calcDynamoDbRawJsonSize(v) {
    let s = 0;
    if (v === undefined || typeof v === 'undefined') {
      s += 0;
    } else if (typeof v === 'string') {
      s += Buffer.from(v, 'utf8').length;
    } else if (typeof v === 'number') {
      let n = v.toString();
      n = n.replace(/e[+-]\d+$/, '');
      n = n.replace(/^-/, '');
      n = n.replace('.', '');
      n = n.replace(/^0+/, '');
      n = n.replace(/0{1,38}$/, '');
      let len = n.length;
      len = len > 38 ? 38 : len;
      s += Math.ceil(len / 2) + 1;
    } else if (v instanceof Buffer) {
      s += v.length;
    } else if (typeof v === 'boolean' || v === null) {
      s += 1;
    } else if (typeof v === 'object' && !(v instanceof Buffer)) {
      s += 3;
      if (Array.isArray(v)) {
        s += v.reduce((s, v) => s + Bbase2Utils.calcDynamoDbRawJsonSize(v), 0);
      } else if (object_1.BbitObject.isObject(v)) {
        s += Object.keys(v).reduce((innerS, k) => {
          const attrV = v[k];
          innerS += Buffer.from(k, 'utf8').length;
          innerS += Bbase2Utils.calcDynamoDbRawJsonSize(attrV);
          return innerS;
        }, 0);
      }
    } else {
      Bbase2Utils._log.warning(new error_1.BbitError('unknown-dynamodb-type', {
        type: typeof v,
        value: v
      }));
    }
    return s;
  }
  static chunkArray(input, n) {
    if (n <= 0) return undefined;
    return Array.from(Array(Math.ceil(input.length / n)), (_, i) => input.slice(i * n, i * n + n));
  }
  static recursiveEnsureIDs(object, justCheck = false, isParentAnArrayOrRoot = true, doNotAllowIDs = {}) {
    if (!object_1.BbitObject.isObject(doNotAllowIDs)) {
      doNotAllowIDs = {};
    }
    if (object_1.BbitObject.isObject(object)) {
      if (isParentAnArrayOrRoot) {
        const validationRes = string_1.BbitString.validateSystemFieldValue(object, '_id');
        if (result_1.BbitResult.isError(validationRes)) {
          if (justCheck) {
            return false;
          }
          object._id = Bbase2Utils.generateBbaseId();
        }
        if (doNotAllowIDs[object._id]) {
          if (justCheck) {
            return false;
          }
          object._id = Bbase2Utils.generateBbaseId();
        }
        doNotAllowIDs[object._id] = true;
      }
      for (const item in object) {
        if (object.hasOwnProperty(item) && !Bbase2Utils.recursiveEnsureIDs(object[item], justCheck, false, doNotAllowIDs)) return false;
      }
    } else if (Array.isArray(object)) {
      for (const item of object) {
        if (!Bbase2Utils.recursiveEnsureIDs(item, justCheck, true, doNotAllowIDs)) return false;
      }
    }
    return true;
  }
  static splitRevision(revision) {
    if (revision === null || revision === void 0 ? void 0 : revision.split) {
      const parts = revision.split('-');
      if (parts.length !== 2) return null;
      const version = number_1.BbitNumber.parseInteger(parts[0]);
      if (Number.isNaN(version) || version === Number.POSITIVE_INFINITY || version === Number.NEGATIVE_INFINITY || version < 0 || version > 999999) return null;
      return {
        version,
        hash: parts[1]
      };
    }
    return undefined;
  }
  static concatRevision(revision, shortFormat = false) {
    return `${shortFormat ? revision.version : Bbase2Utils.getVersionNumberString(revision.version)}-${revision.hash}`;
  }
  static serializeTags(tags) {
    if (!tags || utils_1.BbitUtils.isObjectEmpty(tags)) {
      return null;
    }
    const cleanupRegex = /[\/:]/gi;
    const serialized = Object.keys(tags).sort().map(key => tags[key] != null && (key === null || key === void 0 ? void 0 : key.length) > 0 ? `${key.replace(cleanupRegex, '')}:${tags[key].replace(cleanupRegex, '')}` : null).filter(Boolean).join('/');
    return `/${serialized}/`;
  }
  static deserializeTags(tags) {
    if (!tags || tags.length === 0) {
      return undefined;
    }
    const obj = tags.split('/').reduce((acc, val) => {
      if (val.trim().length === 0) {
        return acc;
      }
      const [key, value] = val.split(':');
      acc[key] = value;
      return acc;
    }, {});
    return obj;
  }
  static incrementRevisionString(inputDoc, incrementNumberBy, maxRevTree) {
    const baseVersion = inputDoc._rev ? Bbase2Utils.splitRevision(inputDoc._rev) : {
      version: 0,
      hash: null
    };
    const hash = object_1.BbitObject.calculateObjectHash(inputDoc);
    if (baseVersion && baseVersion.hash === hash) {
      return baseVersion;
    }
    if (baseVersion.version > 0) {
      if (!inputDoc._revTree) inputDoc._revTree = [];
      inputDoc._revTree.unshift(inputDoc._rev);
      if (inputDoc._revTree.length > maxRevTree) {
        inputDoc._revTree.splice(maxRevTree);
      }
    }
    const newVersion = {
      version: baseVersion.version + (incrementNumberBy || 0),
      hash
    };
    inputDoc._rev = Bbase2Utils.concatRevision(newVersion);
    return newVersion;
  }
  static getVersionNumberString(input) {
    return `${input}`.padStart(9, '0');
  }
  static getUnixEpochMillisString(input) {
    return `${input}`.padStart(15, '0');
  }
  static hashCode(input, modulo) {
    let h;
    for (let i = 0; i < input.length; i++) {
      h = Math.imul(31, h) + input.charCodeAt(i) | 0;
    }
    return modulo > 0 ? Math.abs(h) % modulo : Number.NaN;
  }
  static getLookupKeyPartition(config, doc) {
    let partition = 0;
    if (config.partitionCount > 1) {
      const md5OfId = crypto_1.BbitCrypto.singleton().md5AsHex(string_1.BbitString.stripBeginningHashtag(doc._id));
      const toNumber = buf => buf.readUInt16BE(0) * 0xffffffff + buf.readUInt32BE(2);
      partition = toNumber(Buffer.from(md5OfId, 'hex')) % config.partitionCount;
    }
    return `${config.prefix}-v${config.indexVersion}-p${partition}`;
  }
  static validateDocument(doc, auth, context) {
    return Bbase2Utils._validateAndFixDocument(doc, auth, false, context);
  }
  static fixDocument(doc, auth, context) {
    return Bbase2Utils._validateAndFixDocument(doc, auth, true, context);
  }
  static _validateAndFixDocument(doc, auth, doModify, context) {
    const result = {
      errors: [],
      repairs: []
    };
    if (!doc || !object_1.BbitObject.isObject(doc)) {
      throw new error_invalid_param_1.BbitInvalidParamError(Object.assign({}, context, {
        param: doc,
        reason: 'param must be an object'
      }));
    }
    if (doModify) {
      Bbase2Utils.recursiveEnsureIDs(doc, !doModify);
    }
    for (const field of ['_bucket', '_id']) {
      const validationRes = string_1.BbitString.validateSystemFieldValue(doc, field);
      if (result_1.BbitResult.isError(validationRes)) {
        result.errors.push(validationRes.message);
      }
    }
    if (doModify && !doc._index) {
      doc._index = interfaces_1.Bbase2Indexes.HEAD;
    }
    const rev = Bbase2Utils.splitRevision(doc._rev) || {
      version: 0,
      hash: null
    };
    if (doModify) {
      if (rev.version === 0) {
        doc._rev = undefined;
      }
      Bbase2Utils.incrementRevisionString(doc, 1, Bbase2Utils.MAX_REVISION_TREE);
    }
    if (doModify) {
      const timezone = auth.clientTimezone && luxon_1.IANAZone.isValidZone(auth.clientTimezone) ? auth.clientTimezone : 'Europe/Zurich';
      if (auth.isImportFrom) {
        if (!doc._changedAt) {
          doc._changedAt || luxon_1.DateTime.utc().setZone(timezone).toISO() || luxon_1.DateTime.utc().toISO();
        }
        if (!doc._changedBy) {
          doc._changedBy = auth.userId;
        }
        if (!doc._changedOn) {
          doc._changedOn = auth.deviceId;
        }
        doc._importedFrom = auth.isImportFrom;
      } else {
        if (!(auth === null || auth === void 0 ? void 0 : auth.userId) || auth.userId.length === 0) {
          result.errors.push(new error_1.BbitError('bbase-auth-invalid-user-id', {
            userId: auth === null || auth === void 0 ? void 0 : auth.userId
          }));
        }
        if (!(auth === null || auth === void 0 ? void 0 : auth.deviceId) || auth.deviceId.length === 0) {
          result.errors.push(new error_1.BbitError('bbase-auth-invalid-device-id', {
            deviceId: auth === null || auth === void 0 ? void 0 : auth.deviceId
          }));
        }
        doc._changedAt = luxon_1.DateTime.utc().setZone(timezone).toISO() || luxon_1.DateTime.utc().toISO();
        doc._changedBy = auth.userId;
        doc._changedOn = auth.deviceId;
        if (rev.version === 0) {
          doc._createdAt = luxon_1.DateTime.utc().setZone(timezone).toISO() || luxon_1.DateTime.utc().toISO();
          doc._createdBy = auth.userId;
          doc._createdOn = auth.deviceId;
        }
      }
    }
    return result;
  }
  static recursiveMarshall(obj) {
    switch (true) {
      case luxon_1.DateTime.isDateTime(obj):
        return obj.toISO();
      case date_time_1.BbitDateTime.isJsDate(obj):
        return obj ? obj.toISOString() : obj;
      case number_1.BbitNumber.isNumber(obj):
        return obj;
      case string_1.BbitString.isString(obj):
        return obj && obj.length > 0 ? obj : undefined;
      case object_1.BbitObject.isObject(obj):
        return object_1.BbitObject.mapValues(obj, val => Bbase2Utils.recursiveMarshall(val));
      case Array.isArray(obj):
        return obj.map(val => Bbase2Utils.recursiveMarshall(val));
      default:
        return obj;
    }
  }
  static saveDocs(params) {
    if (!params || !params.docs || !params.store) return RxJS.throwError(() => new error_invalid_param_1.BbitInvalidParamError({
      param: 'docs',
      reason: 'must be a non null array',
      value: params.docs
    }));
    const docs = Array.isArray(params.docs) ? params.docs : [params.docs];
    if (docs.length === 0) return RxJS.of();
    const copiedDocs = object_1.BbitObject.cloneDeep(docs);
    const timestamp = Math.floor(luxon_1.DateTime.utc().toMillis());
    let errors = [];
    for (let i = 0; i < copiedDocs.length; i++) {
      copiedDocs[i]._written = timestamp;
      const validationResult = Bbase2Utils.fixDocument(copiedDocs[i], params.writeAuth, {
        arrayIndex: i
      });
      if (!validationResult) {
        errors.push(new error_1.BbitError('null-or-undefined', {
          documentIndex: i,
          document: copiedDocs[i]
        }));
        continue;
      }
      copiedDocs[i] = Bbase2Utils.recursiveMarshall(copiedDocs[i]);
      copiedDocs[i]._size = Bbase2Utils.calcDynamoDbStorageSize(copiedDocs[i]);
      if (validationResult.errors && validationResult.errors.length > 0) {
        errors = errors.concat(validationResult.errors);
      }
    }
    if (errors.length > 0) {
      return RxJS.throwError(errors);
    }
    const mainIndexes = [interfaces_1.Bbase2Indexes.HEAD, interfaces_1.Bbase2Indexes.ARCHIVED, interfaces_1.Bbase2Indexes.DELETED, interfaces_1.Bbase2Indexes.TEMPLATE, interfaces_1.Bbase2Indexes.DRAFT];
    const _internalWriteDocument = internalParams => {
      var _a, _b, _c;
      for (const field of ['_bucket', '_id', '_rev', '_written', '_index']) {
        if (!internalParams.newDoc[field]) {
          return RxJS.throwError(new error_1.BbitError('assertion-failed', {
            param: 'doc',
            value: internalParams.newDoc,
            reason: `property ${field} must not be empty`
          }));
        }
      }
      if (!mainIndexes.concat([interfaces_1.Bbase2Indexes.CONFLICTED]).includes(internalParams.newDoc._index)) {
        return RxJS.throwError(new error_1.BbitError('bbase-unknown-index', {
          param: 'newDoc',
          value: internalParams.newDoc,
          reason: `unknown bucket index ${internalParams.newDoc._index}`
        }));
      }
      const newDocRev = Bbase2Utils.splitRevision(internalParams.newDoc._rev);
      if (!newDocRev) {
        return RxJS.throwError(new error_1.BbitError('assertion-failed', {
          param: 'newDoc',
          value: internalParams.newDoc,
          reason: 'invalid property revision'
        }));
      }
      const oldDocRev = internalParams.replacedDoc ? Bbase2Utils.splitRevision(internalParams.replacedDoc._rev) : {
        version: 0,
        hash: 'does-not-exist'
      };
      if (!oldDocRev) {
        return RxJS.throwError(() => new error_1.BbitError('assertion-failed', {
          param: 'replacedDoc',
          value: internalParams.replacedDoc,
          reason: 'invalid property revision'
        }));
      }
      if (mainIndexes.includes(internalParams.newDoc._index) && newDocRev.version === oldDocRev.version) {
        internalParams.newDoc._index = interfaces_1.Bbase2Indexes.CONFLICTED;
        internalParams.newDoc._conflictedReason = `version-already-exists {"version"=${newDocRev.version}}`;
      }
      newDocRev.hash = object_1.BbitObject.calculateObjectHash(newDocRev);
      if (newDocRev.hash === oldDocRev.hash) {
        return RxJS.of(Object.assign(Object.assign({}, internalParams), {
          newDoc: internalParams.replacedDoc
        }));
      }
      internalParams.newDoc._rev = Bbase2Utils.concatRevision(newDocRev);
      const isIndexChange = ((_a = internalParams.replacedDoc) === null || _a === void 0 ? void 0 : _a._index) && internalParams.replacedDoc._index !== internalParams.newDoc._index;
      return params.internalSave(Object.assign(Object.assign({}, internalParams), {
        mainIndexes,
        newDocRev,
        oldDocRev,
        stats: {
          new: {
            countIncrement: 1,
            index: internalParams.newDoc._index,
            sizeIncrement: internalParams.newDoc._size || 0
          },
          old: {
            countIncrement: isIndexChange ? -1 : 0,
            index: isIndexChange ? (_b = internalParams.replacedDoc) === null || _b === void 0 ? void 0 : _b._index : undefined,
            sizeIncrement: isIndexChange ? (((_c = internalParams.replacedDoc) === null || _c === void 0 ? void 0 : _c._size) || 0) * -1 : 0
          }
        }
      }));
    };
    const groupedByBucket = array_1.BbitArray.groupBy(copiedDocs, doc => doc._bucket);
    const perBucketWriteObservables = Object.keys(groupedByBucket).map(bucketId => {
      const docs = groupedByBucket[bucketId];
      if (!docs || !Array.isArray(docs)) return RxJS.throwError(() => new error_invalid_param_1.BbitInvalidParamError({
        param: 'docs',
        value: docs,
        reason: 'must be non empty array'
      }));
      if (docs.length === 0) return RxJS.of();
      return params.store.putBucket({
        _bucket: bucketId,
        _buckettype: interfaces_1.Bbase2BucketTypes.DOC,
        auth: params.writeAuth
      }).pipe((0, operators_1.mergeMap)(bucket => {
        return RxJS.merge(docs.map(newDoc => (!Bbase2Utils.splitRevision(newDoc._rev) || !mainIndexes.includes(newDoc._index) ? RxJS.of(null) : params.store.getDoc({
          _bucket: newDoc._bucket,
          _id: newDoc._id
        })).pipe((0, operators_1.map)(replacedDoc => ({
          bucket,
          newDoc,
          replacedDoc
        }))))).pipe((0, operators_1.mergeAll)(), (0, operators_1.reduce)((acc, val) => acc.concat(val), []));
      }), (0, operators_1.mergeMap)(docList => RxJS.of(...docList).pipe((0, operators_1.mergeMap)(docPair => _internalWriteDocument(docPair).pipe((0, operators_1.catchError)(err => {
        if (err) {
          switch (err.code) {
            case 'version-already-exists':
            case 'unindex-of-old-version-already-happened':
              docPair.newDoc._index = interfaces_1.Bbase2Indexes.CONFLICTED;
              docPair.newDoc._conflictedReason = err.toString();
              return _internalWriteDocument(docPair);
            case 'revision-already-exists':
              return RxJS.of(docPair);
          }
        }
        return RxJS.throwError(() => err);
      }))), (0, operators_1.mergeMap)(docRes => __awaiter(this, void 0, void 0, function* () {
        const {
          newDoc,
          replacedDoc
        } = docRes;
        let error = '';
        if (mainIndexes.includes(newDoc._index)) {
          const [tokenRes, keysRes] = yield Promise.all([RxJS.lastValueFrom(params.store.listLockTokens({
            lockScopeBucket: undefined,
            lockScopeKey: undefined,
            bySource: {
              _bucket: newDoc._bucket,
              _id: newDoc._id
            },
            retrieveAllPages: true
          })), RxJS.lastValueFrom(params.store.listKeys({
            _bucket: undefined,
            bySource: {
              _bucket: newDoc._bucket,
              _id: newDoc._id
            },
            retrieveAllPages: true
          }))]);
          const createFinancialBookings = () => __awaiter(this, void 0, void 0, function* () {
            try {
              docRes._financialBookingTokens = yield (0, financial_bookings_1.bbase2FinancialBookingStoreBookings)(params.store, params.writeAuth, newDoc, tokenRes.tokens);
              return false;
            } catch (err) {
              console.error(err);
              docRes._financialBookingError = `${err}`;
              error += `FB: ${err}`;
            }
            return true;
          });
          const createStockBookings = () => __awaiter(this, void 0, void 0, function* () {
            try {
              docRes._stockBookingTokens = yield (0, stock_bookings_1.bbase2StockBookingStoreBookings)(params.store, params.writeAuth, newDoc, tokenRes.tokens);
              return false;
            } catch (err) {
              console.error(err);
              docRes._stockBookingError = `${err}`;
              error += `SB: ${err}`;
            }
            return true;
          });
          const createCostBookings = () => __awaiter(this, void 0, void 0, function* () {
            try {
              docRes._costBookingTokens = yield (0, cost_bookings_1.bbase2CostBookingStoreBookings)(params.store, params.writeAuth, newDoc, tokenRes.tokens);
              return false;
            } catch (err) {
              console.error(err);
              docRes._costBookingError = `${err}`;
              error += `CB: ${err}`;
            }
            return true;
          });
          const createWorkTimeBookings = () => __awaiter(this, void 0, void 0, function* () {
            try {
              docRes._workTimeBookingTokens = yield (0, work_time_bookings_1.bbase2WorkTimeBookingStoreBookings)(params.store, params.writeAuth, newDoc, tokenRes.tokens);
              return false;
            } catch (err) {
              console.error(err);
              docRes._workTimeBookingError = `${err}`;
              error += `WT: ${err}`;
            }
            return true;
          });
          const updateLookupKeys = () => __awaiter(this, void 0, void 0, function* () {
            try {
              const replacedLookupKeys = (keysRes.keys || []).filter(k => {
                var _a;
                return (_a = k._bucket) === null || _a === void 0 ? void 0 : _a.startsWith(interfaces_1.lookupKeyBbaseBucketPrefix);
              }).map(k => Object.assign(Object.assign({}, k), {
                overwrite: true,
                value: undefined,
                source: undefined,
                meta: undefined,
                _delete: true
              }));
              const newLookupKeys = Bbase2Utils.lookupKeysDictToKeysArray(newDoc, newDoc._lookupKeys);
              const toDelete = replacedLookupKeys.filter(lk => {
                const found = newLookupKeys.find(nlk => nlk._bucket === lk._bucket && nlk._id === lk._id);
                return !found;
              });
              const newOrChangedKeys = newLookupKeys.filter(lk => {
                const found = replacedLookupKeys.find(rlk => rlk._bucket === lk._bucket && rlk._id === lk._id);
                return !found || found.value !== lk.value;
              });
              const tokenSetRes = yield RxJS.lastValueFrom(params.store.setKeys({
                keys: toDelete.concat(newOrChangedKeys),
                auth: params.writeAuth
              }));
              docRes._lookupKeysTokens = tokenSetRes;
              return false;
            } catch (err) {
              console.error(err);
              docRes._lookupKeysError = `${err}`;
              error += `LK: ${err}`;
            }
            return true;
          });
          const [_financialBookingsFailed, _stockBookingsFailed, _costBookingsFailed, _workTimeBookingsFailed, _lookupKeysFailed] = yield Promise.all([createFinancialBookings(), createStockBookings(), createCostBookings(), createWorkTimeBookings(), updateLookupKeys()]);
          const _timeTrackingBookingsFailed = false;
          if (params.storeBookingsSaveRes && (error === null || error === void 0 ? void 0 : error.length) > 0) {
            yield params.storeBookingsSaveRes(Object.assign(Object.assign({}, docRes), {
              _financialBookingsFailed,
              _stockBookingsFailed,
              _costBookingsFailed,
              _workTimeBookingsFailed,
              _timeTrackingBookingsFailed,
              _lookupKeysFailed,
              _bookingsInfo: (error === null || error === void 0 ? void 0 : error.length) ? error : undefined
            }));
          }
          if (params.postDocSave) {
            yield params.postDocSave(Object.assign({}, docRes)).catch(err => {
              console.error(err);
              return Promise.resolve();
            });
          }
        }
        return docRes;
      })))));
    });
    return RxJS.merge(perBucketWriteObservables).pipe((0, operators_1.mergeAll)());
  }
}
exports.Bbase2Utils = Bbase2Utils;
Bbase2Utils._log = log_1.BbitLog.scope({
  class: 'Bbase2Utils'
});
Bbase2Utils.MAX_PAYLOAD_SIZE_BYTES = 399 * 1024;
Bbase2Utils.MAX_REVISION_TREE = 5;
Bbase2Utils.META_LOOKUP_KEY_MARKER = '-mmmm-';
Bbase2Utils.META_LOOKUP_KEY_LISTFIELDS = 'lf';
Bbase2Utils.lookupKeysDictToKeysArray = (newDoc, dict) => {
  if (!dict || !newDoc) return [];
  let changedAt = date_time_1.BbitDateTime.parse(newDoc._changedAt);
  if (!(changedAt === null || changedAt === void 0 ? void 0 : changedAt.isValid)) {
    changedAt = luxon_1.DateTime.utc();
  }
  const id = changedAt.toUTC().toFormat('yyyyMMdd-HHmmss') + '/' + string_1.BbitString.stripBeginningHashtag(newDoc._id);
  const metaVars = {};
  const lookupKeys = [];
  for (const [key, value] of Object.entries(dict || {})) {
    if (key.includes(Bbase2Utils.META_LOOKUP_KEY_MARKER)) {
      const partAfterMeta = key.split(Bbase2Utils.META_LOOKUP_KEY_MARKER)[1];
      metaVars[partAfterMeta] = value === null || value === void 0 ? void 0 : value.meta;
    } else {
      lookupKeys.push(key);
    }
  }
  return lookupKeys.map(key => {
    const value = Object.assign({}, dict[key]);
    const [_bucket, ...sortKeyParts] = key.split('/');
    const sortKey = sortKeyParts === null || sortKeyParts === void 0 ? void 0 : sortKeyParts.join('/');
    const _id = (sortKey === null || sortKey === void 0 ? void 0 : sortKey.length) > 0 ? sortKey : id;
    value._index = newDoc._index;
    const expiresAt = value.expiresAt ? luxon_1.DateTime.fromISO(value.expiresAt) : undefined;
    const docSpecificMeta = value.meta || {};
    delete value._id;
    delete value._bucket;
    delete value.meta;
    delete value.sortKey;
    return {
      _bucket,
      _id,
      value: value._delete ? undefined : string_1.BbitString.stringifyToJson(value),
      expiresAt,
      overwrite: true,
      source: {
        _bucket: newDoc._bucket,
        _id: newDoc._id
      },
      meta: Object.assign(Object.assign({
        _changedAt: newDoc._changedAt,
        _index: newDoc._index,
        _title: newDoc._title
      }, value.includeListFieldsAsMeta ? metaVars[Bbase2Utils.META_LOOKUP_KEY_LISTFIELDS] : {}), docSpecificMeta)
    };
  });
};
